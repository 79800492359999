export interface MenuFeature {
    success: boolean;
    data: MenuItem[] | SysSubjects[] | SysRoles[];
    message: string;
}

export interface MenuItem {
    id: number;
    parent_id: number;
    subject_id: number;
    descr: string;
    seq: number;
    front_code: MenuCode;
    icon: string;
    menu_roles?: MenuRoles[];
    concat_role_descr?: string;
    subject_table_name?: string;
}

export enum MenuCode {
    MENU_SETTINGS = 'menu-settings',
    PERMISSION_SETTINGS = 'permission-settings',
    CONTRACTORS = 'contractors',
    ACCOUNTS = 'accounts',
    USER_ACCOUNTS = 'profile',
    USER_GUIDE = 'info'
}

export interface MenuRoles {
    id: number;
    menu_id: number;
    role_id: number;
}

export interface SysRoles {
    id: number,
    account_type_id: number,
    descr: string,
    name: string,
    is_deleted: boolean,
    created_at: any,
    updated_at: any,
    deleted_at: any
}

export interface DataMenuSettingsSubset {
    id?: number
    subject_id : number
    descr : string
    front_code : string
    icon : string
    roles : number[]
}

export interface SysSubjects {
    id: number,
    class_name: string,
    descr: string,
    table_name: string
}
