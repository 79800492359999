<div class="contactor-edit-profile">

    <div style="display: flex; align-items: center; padding: 0 0 15px 15px;">
        
        <button style="margin-right:15px" title="Вернуться к списку" mat-mini-fab color="grey" (click)="backToList()">
            <!-- <mat-icon class="badge-icon">back</mat-icon> -->
            <mat-icon>arrow_back</mat-icon>
        </button>
        <h3>Карточка контрагента</h3>
    </div>
    

    <mat-card class="contactor-edit_card">
        <mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate"></mat-progress-bar>

        <mat-card-content class="contactor-edit_content">
                <ul *ngFor="let section of userAccountSections">
                    <li *ngFor="let field of section.fields">
                        <div class="contactor-edit_title">{{ field.label }}:</div>
                        <div>
                            {{ isDateField(field.property) ? (userAccountsInfo?.[field.property] | date) : userAccountsInfo?.[field.property] }}
                        </div>
                    </li>
                </ul>
        </mat-card-content>
    </mat-card>

    <!-- <mat-accordion [multi]="false">

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Учетные данные</mat-panel-title>
            </mat-expansion-panel-header>

            <credentials [userId]="userId" [userAccountsInfo]="userAccountsInfo" [access]="access"/>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Основной аккаунт</mat-panel-title>
            </mat-expansion-panel-header>

            <main-account [userId]="userId" [accounts]="accounts$ | async" (change)="reload()"/>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Дополнительные аккаунты</mat-panel-title>
            </mat-expansion-panel-header>

            <additional-accounts [userId]="userId" [accountsData]="dataAccounts$ | async" (change)="reload()"/>
        </mat-expansion-panel>

    </mat-accordion> -->

</div>



<mat-accordion  [multi]="false">
    <mat-expansion-panel
        #rla="routerLinkActive"
        routerLinkActive="active"
        [routerLink]="item.route"
        [expanded]="rla.isActive"
        [disabled]="rla.isActive"
        *ngFor="let item of routes$ | async">
        <mat-expansion-panel-header>
            <mat-panel-title>{{item.name}}</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="rla.isActive">
            <ng-container *cdkPortalHost="profileRouterOutlet"></ng-container>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<ng-template cdkPortal #profileRouterOutlet="cdkPortal">
    <router-outlet></router-outlet>
</ng-template>
