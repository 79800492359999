<h1 mat-dialog-title class="title">{{ type !== 'add' ? 'Редактировать' : 'Добавить' }} пункт меню Личного кабинета</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>Наименование</mat-label>
            <input formControlName="descr" matInput type="text" placeholder="Наименование">
            <mat-error *ngIf="form.get('descr').hasError('required')">
                Поле обязательное для заполнения
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Таблица БД</mat-label>
            <mat-select formControlName="subject_id">
                <mat-option [value]="null">Ничего</mat-option>
                <mat-option *ngFor="let subjects of sysSubjectsList" [value]="subjects.id">{{subjects.descr}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Код соответствия front_end</mat-label>
            <input formControlName="front_code" matInput type="text" placeholder="Код соответствия front_end">
            <mat-error *ngIf="form.get('front_code').hasError('required')">
                Поле обязательное для заполнения
            </mat-error>
        </mat-form-field>

        <div class="icon-block">
            <mat-form-field>
                <mat-label>Иконка</mat-label>
                <input formControlName="icon" matInput type="text" placeholder="Наименование">
                <mat-error *ngIf="form.get('icon').hasError('required')">
                    Поле обязательное для заполнения
                </mat-error>
            </mat-form-field>
            <div class="icon-block__icon">
                <mat-icon>{{ form.get('icon').value }}</mat-icon>
            </div>
        </div>

        <mat-form-field>
            <mat-label>Доступ для ролей</mat-label>
            <mat-select formControlName="roles" multiple>
                <mat-option *ngFor="let roles of sysRolesList" [value]="roles.id">{{roles.descr}}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('roles').hasError('required')">
                Поле обязательное для заполнения
            </mat-error>
        </mat-form-field>
        <div mat-dialog-actions>
            <app-button
                [label]="'Сохранить'"
                [icon]="'save'"
                [disabled]="form.invalid"
                [type]="ButtonComponentSettings.ButtonType.primaryButton"
                (onclick)="submit()" />
            <app-button
                [label]="'Отменить'"
                [icon]="'close'"
                [type]="ButtonComponentSettings.ButtonType.primaryButton"
                (onclick)="close()"/>
        </div>
    </form>
</div>
