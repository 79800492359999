import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { BASE_NOMINATIM_URL, BASE_OSM_URL } from 'src/app/app.constants';
import { NominatimResponse } from '../models/nominatim-response.model';

@Injectable({
    providedIn: 'root'
})
export class GeocodingService {

    constructor(private http: HttpClient) {
    }

    getContourMapArea(data: NominatimResponse) {
        const full = !!data.osmType.match(/^way|relation|highway$/);
        return this.http.get(`https://${BASE_OSM_URL}/api/0.6/${data.osmType}/${data.osmId}${full ? '/full' : ''}`, {
            responseType: "text", headers: {
                'content-type': 'application/xml',
                'Accept': 'application/xml',
            }
        })
    }

    addressLookup(req?: any): Observable<NominatimResponse[]> {
        // let url = `https://${BASE_NOMINATIM_URL}/search?format=json&q=${req}&${DEFAULT_VIEW_BOX}&bounded=1`;
        let url = `https://${BASE_NOMINATIM_URL}/search?format=json&q=${req}`;
        return this.http
            .get(url).pipe(
                map((data: any[]) => data.map((item: any) => new NominatimResponse(
                        item.lat,
                        item.lon,
                        item.display_name,
                        item.osm_id,
                        item.osm_type
                    ))
                )
            )
    }

    latLonResult(req?: any) {
        const latLon = req.split(',');
        return of([
            new NominatimResponse(
                latLon[0],
                latLon[1],
                'Координаты'
            ),
            new NominatimResponse(
                latLon[1],
                latLon[0],
                'Реверс координаты'
            ),
        ])
    }

}
