<div (click)="closeModal()" class="modal-close">CLOSE</div>
<h1 mat-dialog-title class="imus-row title">Смена пароля</h1>
<div mat-dialog-content>
    <form [autocomplete]="false" [formGroup]="password.formGroup" (ngSubmit)="onSubmit()">
        <div class="imus-row">
            <div class="imus-row-item imus-form-item">
                <app-imus-input required="true"
                                [type]="inputType.password"
                                imusRequired
                                placeholder='Введите текущий пароль'
                                formControlName="currentPassword"></app-imus-input>
                <field-errors formFieldName="currentPassword">
                    <field-error-label rule="wrongPassword" message="Неверный пароль!"></field-error-label>
                    <field-error-label rule="required"
                                       message="Необходимо ввести текущий пароль"></field-error-label>
                </field-errors>
            </div>
        </div>
        <ng-container formGroupName="passGroup">
            <div class="imus-row">
                <div class="imus-row-item imus-form-item">
                    <app-imus-input required="true"
                                    [type]="inputType.password"
                                    imusRequired
                                    placeholder='Введите новый пароль'
                                    formControlName="password"></app-imus-input>
                    <field-errors formFieldName="password">
                        <field-error-label rule="minlength"
                                           message="Минимальная длина 8 символов"></field-error-label>
                        <field-error-label rule="required"
                                           message="Необходимо ввести новый пароль"></field-error-label>
                    </field-errors>
                </div>
            </div>
            <div class="imus-row">
                <div class="imus-row-item imus-form-item">
                    <app-imus-input required="true"
                                    [type]="inputType.password"
                                    imusRequired
                                    placeholder='Повторите новый пароль'
                                    formControlName="confirmPassword"></app-imus-input>
                    <field-errors formFieldName="confirmPassword">
                        <field-error-label rule="minlength"
                                           message="Минимальная длина 8 символов"></field-error-label>
                        <field-error-label rule="required"
                                           message="Необходимо повторить новый пароль"></field-error-label>
                    </field-errors>
                    <field-errors formFieldName="passGroup">
                        <field-error-label rule="mismatch" message="Пароли не совпадают"></field-error-label>
                    </field-errors>
                </div>
            </div>
        </ng-container>
        <div class="imus-row">
            <app-button [disabled]="password.formGroup.invalid"
                        [isSubmitted]="true"
                        [type]="buttonComponentSettings.ButtonType.primaryButton"
                        [fullWidth]="true"
                        [label]="'Отправить'"></app-button>
        </div>
    </form>
</div>
