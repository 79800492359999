import { ProjectTypes } from "../models/project";

export namespace MediaFilesUtility{

    const imageExts = [ "jpeg","png","gif","bmp","svg","tiff","jpg"];
    const videoExt = [ "avi", "mpeg", "flv"];
    const streamExt = [ "mp4", "webm", "ogv"];

    export function getExtention(fileName: string) {
        if(!fileName) return null;
    
        const pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        let m = fileName.match(pattern);  
            
        if(m && m.length >= 2)
          return m[1];
        else
          return null;
      }        

      export function getMediaType(src: string){        
        const ext = getExtention(src);
        
        if(imageExts.some(e => e == ext))
          return 'image';

        if(streamExt.some(e => e == ext))
          return 'stream';

        if(videoExt.some(e => e == ext))
          return 'video';

          return null;
      }
}