import { AfterViewInit, Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { ImusDestroyService } from "@services/destroy.service";
import { debounceTime } from "rxjs/operators";

type EventType = 'focusout' | 'keydown.enter'

/**
 * Директива для наблюдения за событиями на элементе и оповещении об этом через EventEmitter eventToEdit
 * */
@Directive({
    selector: '[observationEvent]',
    providers: [ImusDestroyService]
})
export class ObservationEventToEditDirective implements AfterViewInit {
    private readonly _event$ = new Subject<void>();
    /**
     * Оповещение о событии
     *
     * @remark Пока наблюдаются события 'focusout' | 'keydown.enter'
     * @see EventType
     * */
    @Output()
    eventToEdit = new EventEmitter<EventType>();

    /**
     * Оповещение о событии, триггер
     * */
    @Output()
    eventTrigger = new EventEmitter<void>();

    @HostListener('keydown.enter')
    private _onEnter(): void {
        this.eventToEdit.emit('keydown.enter');
        this._event$.next();
    }

    @HostListener('focusout')
    private _onFocusOut(): void {
        this.eventToEdit.emit('focusout');
        this._event$.next();
    }

    constructor(
        private readonly _destroy$: ImusDestroyService
    ) {}

    ngAfterViewInit(): void {
        this._event$.pipe(
            debounceTime(10),
            takeUntil(this._destroy$)
        ).subscribe(() => this.eventTrigger.emit())
    }

}
