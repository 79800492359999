<div class="imus-row" *ngIf="(files$ | async) as files">
    <div class="imus-row-item">
        <p class="imus-row file-container" *ngFor="let file of files">
            <span [title]="file.name" class="title">{{file.name}}</span>
            <ng-container
                [ngSwitch]="uploadedInspectionStatus[file.name + file.webkitRelativePath]?.loading$ | async">
                <ng-container *ngSwitchCase="false">
                    <button
                        *ngIf="uploadedInspectionStatus[file.name + file.webkitRelativePath]?.success$ | async"
                        mat-icon-button
                        title="Файл загружен" color="primary">
                        <mat-icon>done</mat-icon>
                    </button>
                    <ng-container *ngIf="uploadedInspectionStatus[file.name + file.webkitRelativePath]?.fail$ | async">
                        <button
                            (click)="reload(file)"
                            mat-icon-button
                            title="Повторить загрузку" color="primary">
                            <mat-icon>restart_alt</mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            title="Ошибка загрузки" color="warn">
                            <mat-icon>error</mat-icon>
                        </button>
                    </ng-container>
                </ng-container>
                <mat-spinner
                    *ngSwitchDefault
                    diameter="20">
                </mat-spinner>
            </ng-container>
        </p>
    </div>
</div>
