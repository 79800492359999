<mat-dialog-header [title]="'3D view'">
    <section class="mat-dialog-header-buttons">
        <button mat-icon-button (click)="refreshData()">
          <mat-icon>refresh</mat-icon>
      </button>
        <button mat-icon-button (click)="refreshData()">
        <mat-icon>download</mat-icon>
    </button>
    </section>
</mat-dialog-header>

<!-- class="p-10-0" -->
<mat-dialog-content>
    <section class="viewer-container">

        <section #guiContainer class="gui-container"></section>

        <section #canvasContainer class="canvas-container">
            <!-- <canvas
                #canvas
                [style.width.px]="canvasSizes.width"
                [style.height.px]="canvasSizes.height"
            ></canvas> -->
        </section>

        <section class="viewer-tools">
            <!-- <app-button title="Просмотр в 3D" (onclick)="viewIn3d(item)" icon="view_in_ar" [type]="buttonType.icon" [disabled]="!item.files.length" [primary]="item.files.length > 0" style="position: absolute; right: 0; top: -8px;"></app-button> -->
            <app-button (click)="moveCameraDist(200)" title="234" icon="view_in_ar" [type]="buttonType.icon" style="position: absolute; left: 10px"></app-button>

            <app-button (click)="meshTransparentToggle()" title="234" icon="view_in_ar" [type]="buttonType.icon"></app-button>
            <app-button title="234" icon="square_foot" [type]="buttonType.icon"></app-button>
            <app-button (click)="moveCameraPostion(0.1784823319201786, -41.80485002072024, 0.9983825321404098)" title="234" icon="3d_rotation" [type]="buttonType.icon"></app-button>

            <app-button (click)="download()" title="234" icon="download" [type]="buttonType.icon" style="position: absolute; right: 10px"></app-button>
        </section>
    </section>
</mat-dialog-content>

<!-- <div class="modal">
  <div class="modal-close" (click)="closeModal()">CLOSE</div>
  <mat-dialog-content>

    <h2 mat-dialog-title>Слой выключен</h2>
      <p>Слой выключен или ни один слой не выбран</p>



  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
  </mat-dialog-actions>
</div> -->