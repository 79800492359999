/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
// import { HttpRequestInterceptor } from './httpRequestInterceptor';
import { JwtInterceptor } from './jwt.interceptor';
import { RetryRequestInterceptor } from './RetryRequestInterceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  // { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: DigestAuthInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
//   { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
//   { provide: HTTP_INTERCEPTORS, useClass: RetryRequestInterceptor, multi: true },
];
