import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { PlotRef } from '@shared/models';
import {
    ControlContainer,
    FormGroup,
    FormGroupDirective,
} from '@angular/forms';
import { Observable, ReplaySubject, shareReplay } from 'rxjs';
import { CodeCategoryService } from '@services/code-category.service';
import { CodeCategory } from '@shared/models/code-category';
import { editPanelForm } from '@app/modules/main/modules/details-info-panel/components/details-edit-panel/details-edit-panel-form.models';
import { EditingFieldNameProvider } from '@app/modules/main/modules/details-info-panel/editing-field-name.provider';
import { ImusDestroyService } from '@services/destroy.service';
import { LayersStateService, PlotService } from '@shared/services';
import { map } from 'rxjs/operators';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from '@angular/material/dialog';
import { ThreeViewComponent } from '@app/shared/components/three-view/three-view.component';

@Component({
    selector: 'app-details-plot-view',
    templateUrl: './details-plot.view.component.html',
    styleUrls: ['./details-plot.view.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsPlotViewComponent {
    private readonly _plot$ = new ReplaySubject<PlotRef>(1);
    private readonly _current$ = new ReplaySubject<string>(1);
    public readonly plot$: Observable<PlotRef> = this._plot$;
    public readonly codeCategory = CodeCategory;
    public readonly current$: Observable<string> = this._current$;

    buttonType = ButtonComponentSettings.ButtonType;

    @Input()
    form: FormGroup;
    @Input()
    set item(value: PlotRef) {
        this._plot$.next(value);
    }

    @Input()
    set editingField(name: string) {
        this._current$.next(name);
    }

    @Input()
    isLayerGlobal: boolean = false;

    @Output()
    fieldValueChange = new EventEmitter<
        editPanelForm.EntityFieldEventType<PlotRef>
    >();

    public operationsList = [
        { key: false, value: 'Отключен' },
        { key: true, value: 'Включен' },
    ];
    public readonly plotTypes$ = this.service.plotTypes$;
    public editableLayer$ = this._plot$.pipe(
        map((plot) => this.layersStateService.state.getLayerById(plot?.layer)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    constructor(
        public readonly categoryList: CodeCategoryService,
        public readonly editingFieldName: EditingFieldNameProvider,
        private readonly service: PlotService,
        private readonly _cdRef: ChangeDetectorRef,
        private readonly _destroy$: ImusDestroyService,
        public readonly layersStateService: LayersStateService,
        public matDialog: MatDialog
    ) {}

    editField(item: PlotRef, value: string | number, fieldName: string): void {
        if (value === item[fieldName]) {
            return;
        }
        this.fieldValueChange.emit({ item, fieldName, value });
    }

    viewIn3d(item: PlotRef) {
        console.log('viewIn3d', item);

        const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        // dialogConfig.id = 'modal-login-component';
        this.matDialog.open(ThreeViewComponent, {
            maxWidth: '90vw',
            // minWidth: ''
            // maxHeight: '90vh',
            maxHeight: '100vh',
            // width: '70vw',
            // height: '80vh',
            panelClass: 'three-dialog',
            data: { item },
        });
    }
}
