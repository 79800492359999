<ng-content></ng-content>
<input
  #fileInput
  class="file-input"
  type="file"
  [webkitdirectory]="structure$ | async"
  [multiple]="multiple$ | async"
  [accept]="accept$ | async"
  [title]="label$ | async"
  [readonly]="readonly$ | async"
  (click)="$event.stopPropagation()"
  (change)="preload($event)"
  [ngModel]="null"
  (dragenter)="dragover = true"
  (dragleave)="dragover = false"
  (dragend)="dragover = false" />
