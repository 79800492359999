import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-dev-index-page',
  templateUrl: './dev-index-page.component.html',
  styleUrls: ['./dev-index-page.component.scss']
})
export class DevIndexPageComponent implements OnInit {

    public moduleRoutes: Route[];
    public staticRoutes: any[] = [
        {
            name: 'Index',
            path: '/'
        },
        {
            name: 'API Docs',
            path: 'https://backend.imus.city/docs/',
            target: '_blank'
        },
        {
            name: 'GitHub',
            path: 'https://github.com/TARIS-ROBOT/2023-angular-2',
            target: '_blank'
        },

        {
            name: 'Material Components',
            path: 'https://material.angular.io/components/categories',
            target: '_blank'
        },
        {
            name: 'Material Icons list',
            path: 'https://fonts.google.com/icons',
            target: '_blank'
        },

    ]

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        ) {
        // this.devRoutes = devRoutes;
        // console.log(devRoutes);
    }

    ngOnInit() {
        this.moduleRoutes = this.getModuleRoutes();
        // console.log('this.moduleRoutes', this.moduleRoutes);
    }

    private getModuleRoutes(): Route[] {
        return (this.router.config.find(routeConfig =>
            routeConfig.path === 'dev'
        ) as any)._loadedRoutes;
    }

}
