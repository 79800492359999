<div class="account-inputs">
    <mat-progress-bar *ngIf="isLoaded" mode="indeterminate"></mat-progress-bar>
    <mat-table
        [dataSource]="menuSettings">

        <ng-container matColumnDef="icon">
            <mat-header-cell *matHeaderCellDef> Иконка </mat-header-cell>
            <mat-cell *matCellDef="let element"> <mat-icon>{{ element.icon }}</mat-icon> </mat-cell>
        </ng-container>

        <ng-container matColumnDef="descr">
            <mat-header-cell *matHeaderCellDef> Наименование </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.descr }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="subject_table_name">
            <mat-header-cell *matHeaderCellDef> Таблица БД </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.subject_table_name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="front_code">
            <mat-header-cell *matHeaderCellDef> Код front-end </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.front_code }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="concat_role_descr">
            <mat-header-cell *matHeaderCellDef> Доступно ролям </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.concat_role_descr | formatString }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell class="table_action" *matHeaderCellDef> Действия </mat-header-cell>
            <mat-cell class="actions-icon" *matCellDef="let element; let i = index">
                <mat-icon (click)="actionDown(element, i)">keyboard_arrow_down</mat-icon>
                <mat-icon (click)="actionUp(element, i)">keyboard_arrow_up</mat-icon>
                <mat-icon (click)="actionEdit(element)">edit</mat-icon>
                <mat-icon (click)="actionDelete(element)">delete_forever</mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div class="footer-actions" style="justify-content: space-between;">
        <app-button
            [label]="'Обновить ЛК'"
            [icon]="'refresh'"
            [primary]="true"
            (onclick)="reload()" />
        <app-button
            [label]="'Добавить пункт меню'"
            [icon]="'add'"
            [primary]="true"
            (onclick)="actionAdd()"/>
    </div>
</div>
