import { Component, Input, ViewChild } from '@angular/core';
import { MapService } from '@app/modules/map/services';
import { MapComponent } from '@app/shared/modules/openlayers/openlayers.module';
import { AuthenticationService } from '@app/shared/services';
import { fromLonLat, get, toLonLat, transform, transformExtent } from 'ol/proj';
// import { RastrBindService } from '../../services/rastr-bind.service';
import { LayerAddFormService } from '@app/modules/main/modules/layer-add/services/layer-add-form.service';
import { Extent, getCenter } from 'ol/extent';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import { Draw, Interaction, Modify, Select, Snap } from 'ol/interaction';
import { Icon, Stroke, Style } from 'ol/style';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { MatTabGroup } from '@angular/material/tabs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '@app/modules/main/dialogs/alert.dialog/alert.dialog.component';
// import { ImageCanvas } from 'ol';
import ImageCanvasSource from 'ol/source/ImageCanvas';
import ImageLayer from 'ol/layer/Image';
import { RastrBindService } from '@app/modules/main/modules/layer-add/services/rastr-bind.service';
import { Point, SimpleGeometry } from 'ol/geom';
import GeoImage from 'ol-ext/source/GeoImage';
// import GeoImageLayer from '@siedlerchr/types-ol-ext/layer/GeoImageLayer'
// import GeoImageLayer from 'ol-ext/layer/GeoImageLayer'
import ImageSource from 'ol/source/Image';
import { add, Coordinate } from 'ol/coordinate';
import { BehaviorSubject, catchError, debounceTime, filter, finalize, map, of, switchMap, takeUntil, tap } from 'rxjs';
// import { features } from 'process';

@Component({
    selector: 'app-rastr-editor-page',
    templateUrl: './rastr-editor-page.component.html',
    styleUrls: ['./rastr-editor-page.component.scss']
})
export class RastrEditorPageComponent {

    @Input('center') center: Coordinate = [37.6219993829727173, 55.7418555021286011];
    @Input('zoom') zoom: number = 10;

    // subscription: Subscription;

    @ViewChild('map') public map: MapComponent;
    @ViewChild('tabs') tabGroup: MatTabGroup;


    public readonly uploaded$ = new BehaviorSubject(false);
    public geoImgLayer: ImageLayer<GeoImage>;
    firstImageURL: any;
    public readonly imageUploaded$ = new BehaviorSubject(false);
    public imageUploadFileControl = new FormControl<File[]>(
        null,
        Validators.required
    );
    public imageFormGroup = new FormGroup({
        imageUploadFileControl: this.imageUploadFileControl,
    });

    public readonly posUploaded$ = new BehaviorSubject(false);
    public posUploadFileControl = new FormControl<File[]>(
        null,
        Validators.required
    );
    public posFormGroup = new FormGroup({
        posUploadFileControl: this.posUploadFileControl,
    });

    public imageSize: number[] = [900, 500];
    public imageExtent: Extent; // | SimpleGeometry;
    // public imagePoints: Coordinate[] = [[20,10],this.imageSize]
    public imagePoints: Coordinate[] = [[20,10],[0,0]]
    public get imageCenter(): Coordinate { 
        if (!this.imageExtent) return fromLonLat(this.center)
        // return getCenter(this.imageExtent);
        return [(this.imageExtent[0] + this.imageExtent[2]) / 2, (this.imageExtent[1] + this.imageExtent[3]) / 2]
    }
    public get imageScale(): number {
        return this.imageSize && this.imageExtent 
                // ? 1.35 * Math.hypot((this.imageExtent[3] - this.imageExtent[1]), (this.imageExtent[2] - this.imageExtent[0])) 
                //     / Math.hypot(this.imageSize[1], this.imageSize[1]) / (this.imageSize[0] / this.imageSize[1])
                ? Math.hypot((this.imageExtent[3] - this.imageExtent[1]), (this.imageExtent[2] - this.imageExtent[0])) 
                    / Math.hypot(this.imageSize[0], this.imageSize[1])
                : 1
    }
    public get imageAngle(): number {
        if (!this.imageSize || !this.imageExtent) return 0
        // let angle = - widthSign*hSign*Math.atan(Math.abs((this.imageExtent[3] - this.imageExtent[1]))/Math.abs((this.imageExtent[2] - this.imageExtent[0]))) 
        let angle = - Math.atan((this.imageExtent[3] - this.imageExtent[1])/(this.imageExtent[2] - this.imageExtent[0])) 
                    + Math.atan(this.imageSize[1]/this.imageSize[0])
        if (this.imageExtent[2] - this.imageExtent[0] < 0) angle += Math.PI 
        return angle
    }
    public imageExtentCoords: Polygon;


    public layerVector: VectorLayer<VectorSource>;
    public sourceVector: VectorSource;

    public rastrLayers: any[] = [{
        name: 'Kremlin.jpg',
        src: 'https://backend.imus.city/storage/media/223/Kremlin.jpg',
    },

    {
        name: 'Moscow.jpg',
        src: 'https://backend.imus.city/storage/media/310/Moscow.jpg'
    }
    ]

    typesOfShoes: string[] = ['Kremlin.jpg'];
    folders: any[] = [
        {
            name: 'Photos',
            updated: new Date('1/1/16'),
        },
        {
            name: 'Recipes',
            updated: new Date('1/17/16'),
        },
        {
            name: 'Work',
            updated: new Date('1/28/16'),
        },
    ];
    notes: any[] = [
        {
            name: 'Vacation Itinerary',
            updated: new Date('2/20/16'),
        },
        {
            name: 'Kitchen Remodel',
            updated: new Date('1/18/16'),
        },
    ];

    // public imageExtent = toLonLat([this.center[0] - this.imageSize[0] / 2, this.center[1] - this.imageSize[1] / 2]);
    // public imageExtent = toLonLat([this.center[0] - (this.imageSize[0] / 2), this.center[1] - (this.imageSize[1] / 2)]);

    // public imageExtent = [-2460660.814556394,-2935181.886150768, 2460660.814556394 ,2935181.886150768];


    // .push(this.center[0], this.center[1])

    constructor(
        public rastrBindService: RastrBindService,
        private layerAddFormService: LayerAddFormService,
        private readonly _destroy$: ImusDestroyService,
        private readonly _matDialog: MatDialog,
        public mapService: MapService,
        private authenticationService: AuthenticationService,
    ) {

    }


    ngOnInit() {

        let centerTemp = fromLonLat(this.center);
        this.imageExtent = [centerTemp[0] - this.imagePoints[0][0], centerTemp[1] - this.imagePoints[0][1], centerTemp[0] + this.imageSize[0], centerTemp[1] + this.imageSize[1]];
        this.imageExtentCoords = fromExtent(this.imageExtent);


        this.rastrBindService.bindSelected$.subscribe((newExtent: any) => {
            console.log('newExtent', newExtent);
            if (newExtent) {
                this.imageExtent = newExtent;
            }

        })

        // Image
        this.imageUploadFileControl.valueChanges
            .pipe(
                debounceTime(50),
                tap(() => this.imageUploaded$.next(true)),
                switchMap((files) =>
                    (files && files[0]?.size > 1000000000
                        ? this._matDialog
                            .open(AlertDialogComponent, {
                                width: '480px',
                                data: {
                                    okButtonText: 'Продолжить',
                                    cancelButtonText: 'Отменить',
                                    message:
                                        'Файл слишком большой будет долго загружаться, точно хотите его загрузить?',
                                },
                            })
                            .afterClosed()
                            .pipe(map((result) => (result ? files : [])))
                        : of(files)
                    ).pipe(
                        tap(() => {
                            return this.layerAddFormService
                                .readImage(files)
                                .subscribe((result) => {
                                    console.log('readImage result', result);
                                    this.firstImageURL = result.src;
                                    this.imageSize = [result.w, result.h]
                                    // this.imagePoints[1] = this.imageSize
                                    // this.rastrBindService.imageUrl.next(result.src);
                                });
                        }),
                        switchMap((files) => {
                            // console.log('image-file', files);
                            // this.showPreview(files);
                            this.layerAddFormService.readImage(files)
                                .subscribe((result) => {
                                    console.log('readImage result1', result);
                                    this.firstImageURL = result.src;
                                    this.imageSize = [result.w, result.h]
                                    // this.imagePoints[1] = this.imageSize
                                })

                            return this.layerAddFormService
                                .uploadRastrImageFile(files[0])
                                .pipe(
                                    finalize(() =>
                                        this.imageUploaded$.next(false)
                                    ),
                                    finalize(() => {
                                        this.imageUploadFileControl.setValue(
                                            files,
                                            { emitEvent: false }
                                        );
                                        // setTimeout(
                                        //     () =>
                                        //         (this.stepper.selectedIndex = 1),
                                        //     500
                                        // );
                                    }),
                                    catchError(() => {
                                        this.imageUploadFileControl.setErrors({
                                            serverError:
                                                'Файл не загружен, попробуйте ещё раз',
                                        });
                                        return of(undefined);
                                    })
                                );
                        })
                    )
                ),
                takeUntil(this._destroy$)
            )
            .subscribe((files) =>
                this.imageUploadFileControl.setValue(null, { emitEvent: false })
            );

    }




    ngAfterViewInit(): void {

        console.log('imageExtent', this.imageExtent);

        this.mapService.map = this.map.instance;
        // this.mapService
        //     .getMap()
        //     .on('pointerdrag', this.onPointerDrag.bind(this));




        


        this.mapService.getMap().on('moveend', () => {
            let user = this.authenticationService.currentUserValue;
            if (user) {
                let centerCoords = transform(
                    this.mapService.getMap().getView().getCenter(),
                    this.mapService
                        .getMap()
                        .getView()
                        .getProjection()
                        .getCode(),
                    'EPSG:4326'
                );
                user.start_lon = centerCoords[0].toString();
                user.start_lat = centerCoords[1].toString();
                user.start_zoom = this.mapService.getMap().getView().getZoom();
                this.authenticationService.currentUserValue = user;
            }
        });

        // TODO BAD?
        this.mapService.addTileLayer();
        this.mapService.setTileSource();

        // this.mapService.zoomToExtent(transformExtent(this.imageExtent, 'EPSG:3857','EPSG:4326'))
        this.mapService.getMap().getView().fit(this.imageExtent, {
            // size: this.getMap().getSize(),
            padding: [200, 200, 200, 200],
            duration: 1000,
            maxZoom: 20
        });

        function createStyle(src, img) {
            return new Style({
                image: new Icon({
                    anchor: [0.5, 0.96],
                    crossOrigin: 'anonymous',
                    src: src,
                    img: img,
                    imgSize: img ? [img.width, img.height] : undefined,
                }),
            });
        }


        let canvasFunction = (extent?, resolution?, pixelRatio?, size?, projection?) => {

            let map = this.mapService.getMap();

            var numPieCharts = 750, coordinates = [], data = [], colors = [];
            var i: any, p: any;
            for (i = 0; i < numPieCharts; i++) {
                coordinates.push([-180 + 360 * Math.random(), -90 + 180 * Math.random()]);
                p = 100 * Math.random();
                data.push([p, 100 - p]);
                colors.push([
                    '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6),
                    '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6)]);
            }

            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var canvasWidth = size[0], canvasHeight = size[1];
            canvas.setAttribute('width', canvasWidth);
            canvas.setAttribute('height', canvasHeight);

            // Canvas extent is different than map extent, so compute delta between
            // left-top of map and canvas extent.
            var mapExtent = map.getView().calculateExtent(this.mapService.map.getSize())
            var canvasOrigin = map.getPixelFromCoordinate([extent[0], extent[3]]);
            var mapOrigin = map.getPixelFromCoordinate([mapExtent[0], mapExtent[3]]);
            var delta = [mapOrigin[0] - canvasOrigin[0], mapOrigin[1] - canvasOrigin[1]]

            var radius = 15;

            // Track the accumulated arcs drawn
            var totalArc = -90 * Math.PI / 180;
            var percentToRadians = 1 / 100 * 360 * Math.PI / 180;
            var wedgeRadians;

            function drawWedge(coordinate, percent, color) {

                var point = transform(coordinate, 'EPSG:4326', 'EPSG:3857');
                var pixel = map.getPixelFromCoordinate(point);
                var cX = pixel[0] + delta[0], cY = pixel[1] + delta[1];

                // Compute size of the wedge in radians
                wedgeRadians = percent * percentToRadians;

                // Draw
                context.save();
                context.beginPath();
                context.moveTo(cX, cY);
                context.arc(cX, cY, radius, totalArc, totalArc + wedgeRadians, false);
                context.closePath();
                context.fillStyle = color;
                context.fill();
                context.lineWidth = 1;
                context.strokeStyle = '#666666';
                context.stroke();
                context.restore();

                // Accumulate the size of wedges
                totalArc += wedgeRadians;
            }

            var drawPie = function (coordinate, data, colors) {
                for (var i = 0; i < data.length; i++) {
                    drawWedge(coordinate, data[i], colors[i]);
                }
            }

            for (var index = 0; index < coordinates.length; index++) {
                drawPie(coordinates[index], data[index], colors[index]);
            }

            return canvas;
        };

        let canvasLayer = new ImageLayer({
            source: new ImageCanvasSource({
                canvasFunction: canvasFunction,
                projection: 'EPSG:3857'
            })
        });

        // this.geoImgLayer = new ImageLayer<GeoImage>({//new ol.layer.GeoImage({
        //     // name: "Georef",
        //     opacity: .7,
        //     source: new GeoImage({
        //         // url: 'src/assets/data/Moscow/Moscow.jpg',
        //         image: this.firstImageURL,
        //         imageCenter: [37.6219993829727173, 55.7418555021286011],
        //         imageScale: [.001, .001],
        //         // imageCrop: [xmin,ymin,xmax,ymax],
        //         imageCrop: null,
        //         imageRotate: Number(15 * Math.PI / 180),
        //         // imageMask: [[273137.343,6242443.14],[273137.343,6245428.14],[276392.157,6245428.14],[276392.157,6242443.14],[273137.343,6242443.14]],
        //         imageMask: null,
        //         // projection: 'EPSG:3857',
        //         // attributions: [ "<a href='http://www.geoportail.gouv.fr/actualite/181/telechargez-les-cartes-et-photographies-aeriennes-historiques'>Photo historique &copy; IGN</a>" ]
        //     })
        // });

        // canvasLayer.on

        // this.mapService.map.addLayer(this.geoImgLayer);






        this.sourceVector = new VectorSource();
        this.layerVector = new VectorLayer({
            source: this.sourceVector,
            style: {
                'fill-color': 'rgba(255, 255, 255, 0.2)',
                'stroke-color': '#ffcc33',
                'stroke-width': 2,
                'circle-radius': 7,
                'circle-fill-color': '#ffcc33',
            },
        });

        this.mapService.map.addLayer(this.layerVector);





        const extent = get('EPSG:3857').getExtent().slice();
        extent[0] += extent[0];
        extent[2] += extent[2];

        const modify = new Modify({ source: this.sourceVector });
        this.mapService.map.addInteraction(modify);
        modify.on

        let draw: Interaction, snap: Interaction; // global so we can remove them later
        // const typeSelect: any = document.getElementById('type');

        // addInteractions() {
        draw = new Draw({
            source: this.sourceVector,
            // type: typeSelect.value,
            type: 'Point',

        });
        this.mapService.map.addInteraction(draw);
        
        this.sourceVector.on('addfeature', (event: VectorSourceEvent) => {
            console.log('addfeature', event)
            console.log('event.target.getFeatures()', event.target.getFeatures())
            if (event.target.getFeatures().length === 2) this.mapService.map.removeInteraction(draw);
            
            let centerTemp = (<Point>event.feature.getGeometry()).getCoordinates()
            // let centerTemp = fromLonLat(coordinate);
            // this.imageExtent = [centerTemp[0], centerTemp[1] ,centerTemp[0] + this.imageSize[0] / 2, centerTemp[1] + this.imageSize[1] / 2];
            // this.imageExtentCoords = fromExtent(this.imageExtent);
            event.feature.setProperties({
                id: event.target.getFeatures().length
            })

            if (!this.imageExtent) {
                this.rastrBindService.imageExtent.next([centerTemp[0] - this.imagePoints[0][0], centerTemp[1] - this.imagePoints[0][1], centerTemp[0] + this.imageSize[0], centerTemp[1] + this.imageSize[1]]);
            } else {
                // this.rastrBindService.imageExtent.next(this.sourceVector.getExtent());
                this.rastrBindService.imageExtent.next([this.imageExtent[0],this.imageExtent[1], centerTemp[0], centerTemp[1]]);
            }
            this.imageExtent = this.rastrBindService.imageExtent.value

            
        })
        this.sourceVector.on('changefeature', (event: VectorSourceEvent) => {
            // let extent: Extent = this.rastrBindService.imageExtent.value;
            // console.log('changefeature',draw)
            // console.log('event.target.getFeatures().length',event.target.getFeatures().length)
            if (event.target.getFeatures().length === 2) {
                let extent: Extent = []
                this.sourceVector.getFeatures().sort((a,b) => a.get('id')-b.get('id')).forEach(feature => {
                    let coords = (<Point>feature.getGeometry()).getCoordinates()
                    const i = feature.get('id') - 1 
                    coords[0] -=  this.imagePoints[i][0]
                    coords[1] -=  this.imagePoints[i][1]
                    extent = [...extent,...coords]
                })
                // extent = this.sourceVector.getExtent()
                this.rastrBindService.imageExtent.next(extent)
                this.imageExtent = this.rastrBindService.imageExtent.value
            }
        })

        snap = new Snap({ source: this.sourceVector });
        this.mapService.map.addInteraction(snap);

        this.rastrBindService.bindSelected.pipe(
            filter(bind => !!bind),
            tap(_ => {
                this.mapService.map.removeInteraction(snap);
                this.mapService.map.removeInteraction(draw);
                this.mapService.map.removeInteraction(modify);
            })
        )
        .subscribe()
        // }

        // /**
        //  * Handle change event.
        //  */
        // // typeSelect.onchange(() => {
        //     this.mapService.map.removeInteraction(draw);
        //     this.mapService.map.removeInteraction(snap);
        //   addInteractions();
        // // });

        // addInteractions();

        // this.initScaleline();

        // this.mapService.addInteractions(undefined, undefined, [
        //     InteractionType.selectAll,
        // ]);
    }

    testStyle() {

        let styles: Style[] = [];

        const stroke2 = new Stroke({
            color: '#ffa600',
            width: 3
        });

        styles.push(new Style({
            stroke: stroke2,
        }))

        return styles;

    }

    selectFeature() {
        console.log('selectFeature');
    }

    public onMapClick(event) {
        console.log('onMapClick', event);



        this.tabGroup.selectedIndex = 1;

        let map = this.mapService.getMap();

        // let coordinate = toLonLat(event.coordinate);
        let coordinate = toLonLat(map.getEventCoordinate(event));
        console.log('coordinate LonLat', coordinate);
        console.log('coordinate', map.getEventCoordinate(event));

        let pixel = //event.pixel
            map.getEventPixel({
                clientX: event.clientX,
                clientY: event.clientY,
            });

        console.log('pixel', pixel);



        // this.rastrBindService.updateSource({
        //     sourceX: pixel[0],
        //     sourceY: pixel[1],
        // });

        //TODO
        // this.rastrBindService.updateMap({
        //     mapX: pixel[0],
        //     mapY: pixel[1],
        //     mapLat: coordinate[0],
        //     mapLon: coordinate[1],
        // });



        // this.rastrBindService.imageExtent.next(null);

        // setTimeout(() => {
            // let centerTemp = fromLonLat(coordinate);
            // // this.imageExtent = [centerTemp[0], centerTemp[1] ,centerTemp[0] + this.imageSize[0] / 2, centerTemp[1] + this.imageSize[1] / 2];
            // // this.imageExtentCoords = fromExtent(this.imageExtent);
            // this.rastrBindService.imageExtent.next([centerTemp[0], centerTemp[1] ,centerTemp[0] + this.imageSize[0], centerTemp[1] + this.imageSize[1]]);
            // this.imageExtent = this.rastrBindService.imageExtent.value
            // this.rastrBindService.imageExtent.next([centerTemp[0], centerTemp[1] ,centerTemp[0] + this.imageSize[0] / 2, centerTemp[1] + this.imageSize[1] / 2]);
        // }, 1);

        console.log('this.rastrBindService.imageExtent', this.rastrBindService.imageExtent.value)
        console.log('getImageCenter', this.getImageCenter())
        console.log('imageScale', this.imageScale)
        console.log('imageAngle', this.imageAngle)
        console.log('imageSize', this.imageSize)
        console.log('imageCenter', this.imageCenter)
        console.log('imageExtent', this.imageExtent)
        // map.forEachFeatureAtPixel()

        // map.forEachLayerAtPixel(evt.pixel, function(layer) {
        //     console.log(evt.pixel);
        //     console.log(layer);
        //     var id = layer.get('title');
        //     console.log(id);
        //     var title = layer.get('title');
        //     console.log(title);
        //     var whatever = layer.get('whatever');
        //     console.log(whatever);
        // });            1


        // map
        //     .getFeaturesAtPixel(pixel, {
        //         hitTolerance: 1, //радиус в пикселях где ищутся features
        //     })
        //     .forEach((feature) => {
        //         console.log(feature);
        //     });

        // let featureSelected: FeatureLike;
        // let nodeSelected: FeatureLike;
        // let plotSelected: FeatureLike;
        // let appState = this.layersStateService.getData();

        // map.forEachFeatureAtPixel(pixel, ft => {
        //     console.log('ft', ft);
        //     // return ft.getGeometry().getType() == 'Polygon' ? ft : null
        // }, {
        //     hitTolerance: 100000
        //   });


        // this.rastrBindService.pushBind();


        // map.forEachFeatureAtPixel(
        //     pixel,
        //     (feature, layer, geometry) => {
        //         console.log('layer');
        //         // console.log(layer?.getProperties().id);
        //         console.log('feature', feature)
        //         console.log('layer', layer)
        //         console.log('geometry', geometry)
        //         // debugger
        //         // let featureType = feature?.getGeometry().getType()
        //         //сохраняем в vectorLayer и featureSelected самого верхнего слоя и самого верхней feature по клику
        //         // if (
        //         //     !vectorLayer ||
        //         //     vectorLayer.get('zIndex') < layer.get('zIndex')
        //         // ) {
        //         //     vectorLayer = layer;
        //         //     // featureSelected = feature;
        //         //     if (vectorLayer && geometry?.getType() == 'Point')
        //         //         nodeSelected = feature;
        //         //     if (vectorLayer && geometry?.getType() == 'LineString')
        //         //         plotSelected = feature;
        //         // }
        //     },
        //     {
        //         hitTolerance: 10000, //радиус в пикселях где ищутся features
        //         // checkWrapped: false
        //     }
        // );

    }

    onMouseUp(event) {
        // console.log('onMouseUp', event)
        // this.dragged = this.pointerDragging;
        // this.pointerDragging = false;
    }

    onImageClick(event: any) {
        console.log('onImageClick', event);

        this.rastrBindService.updateSource({
            sourceX: event.layerX,
            sourceY: event.layerY,
        });

        //         layerX
        // :
        // 8
        // layerY
        // :
        // 7
    }

    onAddImageClick() {
        // this.geoImgLayer = new ImageLayer<ImageSource>({//new ol.layer.GeoImage({
        //     // name: "Georef",
        //     opacity: .7,
        //     source: new GeoImage({
        //         url: '/assets/data/Moscow/Moscow.jpg',
        //         // image: this.firstImageURL,
        //         imageCenter: [37.6219993829727173, 55.7418555021286011],
        //         imageScale: [.001, .001],
        //         // imageCrop: [xmin,ymin,xmax,ymax],
        //         imageCrop: null,
        //         imageRotate: Number(15 * Math.PI / 180),
        //         // imageMask: [[273137.343,6242443.14],[273137.343,6245428.14],[276392.157,6245428.14],[276392.157,6242443.14],[273137.343,6242443.14]],
        //         imageMask: null,
        //         // projection: 'EPSG:3857',
        //         // attributions: [ "<a href='http://www.geoportail.gouv.fr/actualite/181/telechargez-les-cartes-et-photographies-aeriennes-historiques'>Photo historique &copy; IGN</a>" ]
        //     })
        // });

        // canvasLayer.on

        // this.mapService.map.addLayer(this.geoImgLayer);

    }

    angleToRadians (angle: number): number {
        return Number(angle * Math.PI / 180)
    }

    public transformCoordinates(center: Coordinate, from: string, to: string): Coordinate {
        return transform(center, from, to)
    }

    public getImageCenter(): Coordinate {
        if (!this.rastrBindService.imageExtent.value) return fromLonLat(this.center)
        // const x = this.rastrBindService.imageExtent.value.slice(0,2)
        // const y = this.rastrBindService.imageExtent.value.slice(-2)
        // return [(y[0]+x[0])/2, (y[1]+x[1])/2]
        return getCenter(this.rastrBindService.imageExtent.value)
    }

}
