import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService } from '@shared/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { RegisterModalComponent } from '../register-modal/register-modal.component';
import { catchError, first, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { EmailLinkModalComponent } from '@app/modules/account/components/email-link-modal/email-link-modal.component';
import { ImusDestroyService } from '@app/shared/services/destroy.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  public loginForm: FormGroup;

    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    passwordInputType = 'password';

    _loginError = new BehaviorSubject<boolean>(false);
    loginError$: Observable<boolean> = this._loginError//.asObservable();

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<LoginModalComponent>,
        private readonly _destroy$: ImusDestroyService,
    ) {
         // redirect to home if already logged in
        //  if (this.authenticationService.currentUserValue) {
        //     this.router.navigate(['/']);
        // }

          // Перенаправляет пользователя если уже залогинин и email верифицирован
        //   if (this.authenticationService.currentUserValue && this.authenticationService.currentUserEmailVerified) {
        //     this.router.navigate(['/']);
        // }


        // this.authenticationService.currentUser$.subscribe((user: any) => {
        //   // console.log('sss', user);
        // })

        this.loginForm = this.formBuilder.group({
          email: new FormControl(''),
          password: new FormControl(''),
        });

    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        // this.alertService.clear();

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this._loginError.next(false)

        // console.log('authenticationService.login', this.loginForm);

        this.authenticationService.login(this.f['email'].value, this.f['password'].value)
            .pipe(
              first(),
              catchError(error => {
                // this.alertService.error(error);
                // console.log(error)
                this.loading = false;
                this._loginError.next(true)
                return EMPTY
              }),
              takeUntil(this._destroy$)
              )
            .subscribe(

              data => {
                // console.log(data)
                if (data.user.email_verified_at !== null) {
                  this.router.navigate([this.returnUrl])
                }
                else this.router.navigate(['/email-confirmation'], { state: {user: data.user, from: 'login'} })
                // this.router.navigate(['/email-confirmation'], { state: {user: user} });
                this.dialogRef.close();
              },


              // data => {
              // },
              // error => {
              //   // this.alertService.error(error);
              //   this.loading = false;
              // }
              );
    }

    ngOnInit() {
        // this.loginForm = this.formBuilder.group({
        //     email   : ['', Validators.required],
        //     password: ['', Validators.required]
        // });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    closeModal() {
        this.dialogRef.close();
    }

    fromLoginRegister(event) {
        // alert(111);
        event.preventDefault();
        this.dialogRef.close();

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-register-component";

        this.matDialog.open(RegisterModalComponent, dialogConfig);
    }

    showPassword() {
        this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
    }

    fromLoginChangePass(event) {
      event.preventDefault();
      this.dialogRef.close();

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.id = "modal-change-password-component";

      this.matDialog.open(EmailLinkModalComponent, dialogConfig);

    }

}
