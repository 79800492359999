import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MeasurementRouteService } from 'src/app/routes/measurement-route.service';
import { MeasurementTypes } from 'src/app/shared/models/measurement';
import { DeviceTypes } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MeasurementService {

  private measurements = new BehaviorSubject<MeasurementTypes.IDeviceMeasurements>({ data: [] });
  public measurements$ = this.measurements.asObservable();

  private loadingMeasurementList = new BehaviorSubject<boolean>(false);
  public loadingMeasurementList$ = this.loadingMeasurementList.asObservable();

  private pageIndex = 20;
  private 

  constructor(private measurementRouteService: MeasurementRouteService) { }

  loadDeviceMeasurement(id: number, pageIndex? : number){

    if(pageIndex)
      this.pageIndex = pageIndex;

    const request: MeasurementTypes.IMeasurementRequest = {
      device: id,
      paginate: pageIndex
    }    
    this.loadingMeasurementList.next(true);
    this.measurementRouteService.loadDeviceMeasurement(request).subscribe((measures) => {
      this.measurements.next(measures);
      this.loadingMeasurementList.next(false);
    });
  }

  addMeasurement(device: DeviceTypes.IDevice, value: number){
    const measurement: MeasurementTypes.IAddMeasurement = {            
      token: device.token,      
      value: value,
      date: Date.toString()
  };
    this.measurementRouteService.addMesaurement(measurement).subscribe(() => this.loadDeviceMeasurement(device.ID, this.pageIndex));
  }

  deleteMeasurement(id: number, deviceId: number){
    this.measurementRouteService.deleteMesaurement(id).subscribe(() => this.loadDeviceMeasurement(deviceId, this.pageIndex));
  }
}
