import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DeviceTypes, NodeCollection, NodeFeature, NodeRef, NodeType } from '@app/shared/models';
import { node } from "@shared/models/node-ref";
import { Observable, catchError, map, of, shareReplay, switchMap, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImusDestroyService } from './destroy.service';
import { LayerService } from './layer.service';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

    featureCollection: any;
    nodeTypes: NodeType[] = [];

    //TODO
    // Временное решение, подменяем layer_type_id
    nodeTypeTrans = {
        1: 7,
        4: 10
    }

    public readonly nodeTypes$ = this.layerService.changed$.pipe(
        // startWith(1),
        switchMap(() => this.getAllTypes()),
        //TODO
        // Временное решение, подменяем layer_type_id
        map(value => {
            value.map(nodeType =>
                nodeType.layer_type_id = this.nodeTypeTrans[nodeType.layer_type_id] ?? nodeType.layer_type_id
            )
            return value
        }),
        shareReplay(1)
    )

    /**
     * Эмитер изменений состояния
     *
     * TODO: пока так
     * */
    public changed$ = new EventEmitter<number>();
    // public changedLayer$ = new EventEmitter<number>();

    constructor(
        // private authenticationService: AuthenticationService,
        private http: HttpClient,
        private readonly _destroy$: ImusDestroyService,
        private readonly layerService: LayerService,
    ) {
        this.nodeTypes$.pipe(takeUntil(this._destroy$)).subscribe((nodeTypes) => this.nodeTypes = nodeTypes)
    }

    // public updateLayerInfo(id: number) {
    //     this.changedLayer$.emit(id);
    // }

    public emitChanged(layerID: number) {
        this.changed$.emit(layerID);
    }
    // featureCollection = {
    //     "type": "FeatureCollection",
    //     "features": [
    //         {
    //             "type": "Feature",
    //             "properties": {
    //                 "ID": 727,
    //                 "user": 1,
    //                 "layer": 153,
    //                 "device": null
    //             },
    //             "geometry": {
    //                 "type": "Point",
    //                 "coordinates": [
    //                     37.60005235449218474741428508423268795013427734375,
    //                     54.7529080190138159878188162110745906829833984375
    //                 ]
    //             }
    //         },
    //         {
    //             "type": "Feature",
    //             "properties": {
    //                 "ID": 729,
    //                 "user": 1,
    //                 "layer": 153,
    //                 "device": null
    //             },
    //             "geometry": {
    //                 "type": "Point",
    //                 "coordinates": [
    //                     37.61073900000000236332198255695402622222900390625,
    //                     55.7519520000000028403519536368548870086669921875
    //                 ]
    //             }
    //         },
    //         {
    //             "type": "Feature",
    //             "properties": {
    //                 "ID": 728,
    //                 "user": 1,
    //                 "layer": 153,
    //                 "device": null
    //             },
    //             "geometry": {
    //                 "type": "Point",
    //                 "coordinates": [
    //                     37.5906746269830733808703371323645114898681640625,
    //                     55.75174627114824232876344467513263225555419921875
    //                 ]
    //             }
    //         },
    //         {
    //             "type": "Feature",
    //             "properties": {
    //                 "ID": 10000,
    //                 "user": 1,
    //                 "layer": 153,
    //                 "device": null
    //             },
    //             "geometry": {
    //                 "type": "Point",
    //                 "coordinates": [
    //                     37.60173900000000202226146939210593700408935546875,
    //                     55.7519520000000028403519536368548870086669921875
    //                 ]
    //             }
    //         }
    //     ]
    // }


    getGeoData (): Observable<any> {
        // return this.http.get(`${environment.apiUrl}/geojson/geo`);
        return this.http.get(`${environment.apiUrl}/geojson/layer`);
    }

    getGeoJSON() {
        // return this.http.get(`${environment.apiUrl}/geojson/geo`);
        // return {...this.featureCollection};
        // return this.http.get(`${environment.apiUrl}/geojson/geo`).pipe(map(res => {
        return this.http.get(`${environment.apiUrl}/geojson/layer`).pipe(map(res => {
            return res;
        }))
            // .subscribe((response) => {
            //     this.featureCollection = response;
            //     console.log(response);
            // });
    }

    getAll() {
        return this.http.get<NodeCollection>(`${environment.apiUrl}/node`);
    }

    getLast(layer: number){
        return this.http.post<NodeRef[]>(`${environment.apiUrl}/node/last`, {layer: layer});
    }

    getById(id) {
        return this.http.get<NodeFeature>(`${environment.apiUrl}/node/${id}`);
    }

/**
// left-panel

    add(node: Node) {
        return this.http.post(`${environment.apiUrl}/node`, {
                coords: node.coords, //'[' + node.coords.toString() + ']',
                layer : node.layer,
                node_type_id : node.nodeType.id,
                name: node.name
        });
    }

    edit(node: Node) {
        return this.http.put(`${environment.apiUrl}/node/${node.id}`, {
            coords: node.coords,
            name: node.name
        });

*/
    /**
     * Добавление новой точки
     * */
    add(node: node.NodeRequest): Observable<NodeFeature> {
        // return this.http.post<node.NodeData>(`${environment.apiUrl}/node`, node).pipe(
        return this.http.post<NodeFeature>(`${environment.apiUrl}/node`, node)
            // .pipe(
            //     tap(() => {
            //         //   this.changed$.emit();
            //         //   this.changedLayer$.emit(node.layer);
            //     })
            // );
    }

    editPut(nodeId: number, node: node.NodeRequest) {
        return this.http.put(`${environment.apiUrl}/node/${<number>nodeId}`, node).pipe(
            tap(() => {
                // this.changedLayer$.emit(node.layer);
            })
        );

    }

    editPatch(nodeId: number, node: Partial<node.NodeRequest>) {
        return this.http.put(`${environment.apiUrl}/node/${<number>nodeId}`, node).pipe(
            tap(() => {
                // this.changedLayer$.emit(node.layer);
            })
        );

    }

    union(toUnion: number, fromLayer: number) {
        return this.http.put(`${environment.apiUrl}/node/union`, {main: toUnion, layer: fromLayer});
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/node/${id}`);
    }

    device(id: number, device: DeviceTypes.IDevice) {
        return this.http.post(`${environment.apiUrl}/node/device/${id}`, {"device": device });
    }

    getAllTypes(): Observable<NodeType[]> {
        return this.http.get<NodeType[]>(`${environment.apiUrl}/node_type`);
    }

    /** Загрузка любого файла для точки */
    public uploadFile(file: File, nodeId: number, layerID: number): Observable<void> {
        if (!file) {
            return of(undefined);
        }
        const data = new FormData();
        data.append('file', file, file.name);
        return this.http.post<void>(`${environment.apiUrl}/node/${nodeId}/file`, data).pipe(
            tap(() => {
                this.changed$.emit(layerID);
                // this.changedLayer$.emit(layer);
            })
        );
    }

    /** Загрузка файла с точками */
    public uploadNodeFile(file: File, layerID: number): Observable<void> {
      const data = new FormData();
      data.append('csv_file', file, file.name);
      data.append('layer', layerID.toString())
      return this.http.post<void>(`${environment.apiUrl}/node/upload`, data).pipe(
          tap(() => {
              this.changed$.emit(layerID);
            //   this.changedLayer$.emit(layer);
          }),
        catchError(() => of(undefined))
      );
    }
}
