import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DevicesGridDialogComponent } from '@app/modules/devices/devices-grid-dialog/devices-grid-dialog.component';
import { ProjectsGridDialogComponent } from '@app/modules/projects/projects-grid-dialog/projects-grid-dialog.component';
import { BehaviorSubject, map, merge, Observable, of, shareReplay, switchMap, takeUntil } from 'rxjs';
import { LayersStateService } from "@shared/services";
import { ImusDestroyService } from "@services/destroy.service";
import { filter } from "rxjs/operators";
import { CopyObjectsService } from "@services/copy-objects.service";


export namespace PageServiceTypes {
    export enum PanelType {
        nodesEditPanel,
        inspectionPanel
    }
}

@Injectable({
    providedIn: 'root',
})
export class MainPageService extends ImusDestroyService {

    private _bottomClosed$ = new BehaviorSubject(true);
    private activePanel = new BehaviorSubject<PageServiceTypes.PanelType>(PageServiceTypes.PanelType.nodesEditPanel);
    private _leftClosed$ = new BehaviorSubject(true);
    public activePanel$ = this.activePanel.asObservable();
    public offsetDisable: boolean = true;
    public leftClosed$: Observable<boolean> = this._leftClosed$;
    public bottomClosed$: Observable<boolean> = this._bottomClosed$;
    // public bottomClosed$ = this.layerCopyState.isCopyLayerMode$.pipe(
    //     map(e => !e),
    //     switchMap(is => !is ? this.leftClosed$ : of(is)),
    //     shareReplay(1)
    // );
    public _leftClosedOffsetDisable: boolean = true;
    public _rightClosed: boolean = false;
    public _rightBlocked: boolean = false;

    public _layerAddClosed: boolean = true;

    public layerAddClosed$: BehaviorSubject<boolean> = new BehaviorSubject(
        this._layerAddClosed
    );

    private _rastrBindMode: boolean = false;

    public rastrBindMode$: BehaviorSubject<boolean> = new BehaviorSubject(
        this._rastrBindMode
    );


    public dialogs: MatDialogRef<any>[];
    public deviceGridDialog: MatDialogRef<DevicesGridDialogComponent>;
    public projectsGridDialog: MatDialogRef<ProjectsGridDialogComponent>;

    public rightClosed$: BehaviorSubject<boolean> = new BehaviorSubject(
        this._rightClosed
    );
    public rightBlocked$: BehaviorSubject<boolean> = new BehaviorSubject(
        this._rightBlocked
    );


    constructor(
        public layerCopyState: CopyObjectsService,
        public layerStateService: LayersStateService
    ) {
        super();
        this.layerStateService.state.selectedEntity$.pipe(
            filter(selected => !selected || this.activePanel.value !== PageServiceTypes.PanelType.inspectionPanel),
            takeUntil(this)
        ).subscribe(() => this.setActivePanel(PageServiceTypes.PanelType.nodesEditPanel));
    }

    public get leftClosed(): boolean {
        return this._leftClosed$.getValue();
    };

    public get bottomClosed(): boolean {
        return this._bottomClosed$.getValue();
    };

    public leftToggle() {
        this.setCloseToLeftPanel(!this._bottomClosed$.getValue() || !this._leftClosed$.getValue());
    }

    public setCloseBottomPanel(value: boolean): void {
        this._bottomClosed$.next(value);
        // при открытии нижней панели закрываем левую панель
        if (!value && !this.leftClosed) {
            this.setCloseToLeftPanel(!value);
        }
    }

    public setCloseToLeftPanel(value: boolean) {
        this._leftClosed$.next(value);
    }

    public rightToggle() {
        this.rightSetState(!this._rightClosed);
    }

    private rightSetState(value: boolean) {
        this._rightClosed = value;
        this.rightClosed$.next(this._rightClosed);
    }

    public rightSetBlocked(value: boolean) {
        this._rightBlocked = value;
        this.rightBlocked$.next(this._rightBlocked);
    }

    public setActivePanel(panel:PageServiceTypes.PanelType){
        this.activePanel.next(panel);
    }

    public layerAddToggle() {
        this.layerAddSetState(!this._layerAddClosed);
    }

    public layerAddSetState(value: boolean) {
        if(!value) {
            this.rastrBindModeSetState(false);
        }
        this._layerAddClosed = value;
        this.layerAddClosed$.next(this._layerAddClosed);
    }

    public rastrBindModeToggle() {
        this.rastrBindModeSetState(!this._rastrBindMode);
    }

    public rastrBindModeSetState(value: boolean) {
        this._rastrBindMode = value;
        this.rastrBindMode$.next(value);
        console.log('rastrBindModeSetState',this._rastrBindMode)
    }

}
