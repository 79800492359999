import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() pageIndex = 0;
  @Input() pageSize = 5;
  @Input() pageSizeOptions = [5, 10, 25];
  @Input() length = 50;
  @Input() showFirstLastButtons = true;
  @Input() disabled = false;
  @Input() hidePageSize = false;
  @Input() showPageSizeOptions = true;

  @Output() page: EventEmitter<PageEvent> = new EventEmitter();

  handlePageEvent(event: PageEvent){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.page.emit(event);
  }

}
