<p class="imus-row title">Дополнительные аккаунты с ролью Гость предоставляют компании или частные
    лица, зарегистрированные в системе ИМУС</p>
<div class="table">
    <mat-progress-bar *ngIf="isLoaded" mode="indeterminate"></mat-progress-bar>
    <mat-table
        matSort
        (matSortChange)="sort($event)"
        [dataSource]="accounts?.data">
        <ng-container *ngFor="let item of displayed$ | async" [matColumnDef]="item.name">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{item.title}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngSwitch]="item.name">
                <ng-container *ngSwitchCase="item.name === 'start_date' || item.name === 'end_date' ? item.name : ''">
                    <p [title]="element[item.name] | date" class="cell-data"
                       [class.close-account]="item.name === 'end_date'">
                        {{element[item.name] | date}}
                    </p>
                    <p class="close-icon">
                        <mat-icon *ngIf="item.name === 'end_date' && !element[item.name]" class="actions-icon" (click)="closeAccount(element)"
                                  title="Закрыть аккаунт (отписаться)">logout
                        </mat-icon>
                    </p>
                </ng-container>
                <p [title]="element[item.name]" class="cell-data" *ngSwitchDefault>
                    <a class="link" (click)="showInfo(element)">{{element[item.name]}}</a>
                </p>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns$ | async"></mat-header-row>
        <mat-row *matRowDef="let row; columns: (displayedColumns$ | async);"></mat-row>
    </mat-table>
    <div class="imus-row"></div>
    <app-paginator [pageSize]="(pageSettings$ | async)?.pageSize" [pageIndex]="(pageSettings$ | async)?.pageIndex"
                   [length]="accounts?.total" (page)="onPaginateData($event)"></app-paginator>
</div>
