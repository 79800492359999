import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output, PipeTransform, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { OptionItem } from '../../utilities/mat-select-search-utility';
import { ImusBaseInputComponent } from '../imus-base-input/imus-base-input.component';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { animationFrameScheduler, map, observeOn } from 'rxjs';


@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ImusDestroyService]
})
export class SelectSearchComponent<T> extends ImusBaseInputComponent<unknown> {


  @Input() options: OptionItem<T>[] = [];

  @Input() objectNameProperty = 'name';
  @Input() objectKeyProperty = 'ID';

  private _icon: string = undefined;
  get icon(): string {
    return this.getIcon();
  }
  @Input()
  set icon(value) {
    this._icon = value;
  };

  @Input() useDefaultIcon: boolean = true;
  @Input() required = false;

  @Output() selectChangeSelect = new EventEmitter<any>();

  constructor(
    @Optional() @Self() private controlDir: NgControl,
    private readonly destroy$: ImusDestroyService) {
    super(destroy$, controlDir)
  }


  hasError(): boolean {
    return !this.innerControl.valid && this.innerControl.dirty && this.innerControl.touched;
  }

  public useIcon$ = this.title$.pipe(
    observeOn(animationFrameScheduler),
    map(title => !title && this.getIcon() !== undefined)
  )

  private getIcon(): string | undefined {
      return this._icon;
  }

  public onSelectChangeSelect(event: MatSelectChange) {
    this.selectChangeSelect.emit(event);
  }

  public onSelectionChange(change: MatOptionSelectionChange): void {
    if (!change.isUserInput) {
      return;
    }
    this.innerControl.setValue(change.source.value);
  }
}
