<div mat-dialog-title>

    <table mat-table [dataSource]="projectDataSource">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Имя</th>
            <td mat-cell *matCellDef="let row">
                <p>{{row.properties.name}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="info">
            <th mat-header-cell *matHeaderCellDef class="icon-cell"></th>
            <td mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'info'"></app-button>
            </td>
        </ng-container>

        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef class="icon-cell"></th>
            <td mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'location_on'"></app-button>
            </td>
        </ng-container>

        <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef>Клиент</th>
            <td mat-cell *matCellDef="let row">
                <p>{{row.client.properties.company}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="contractor">
            <th mat-header-cell *matHeaderCellDef>Подрядчик</th>
            <td mat-cell *matCellDef="let row">
                <p>{{row.contractor.properties.company}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef>Последнее обновление</th>
            <td mat-cell *matCellDef="let row">
                {{row.properties.project_updated_at | date: 'dd.MM.yyyy'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef>Дата создания</th>
            <td mat-cell *matCellDef="let row">
                {{row.properties.project_created_at | date: 'hh:mm dd.MM.yyyy' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef class="icon-cell"></th>
            <td mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'edit'" #tooltipEdit="matTooltip" (click)="onEditProjectClick(row)" matTooltip="Изменить название проекта" matTooltipPosition="left" [matTooltipShowDelay]="200"></app-button>
            </td>
        </ng-container>

        <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef class="icon-cell"></th>
            <td mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'delete'" #tooltipRemove="matTooltip" (click)="onRemoveClick(row)" matTooltip="Удалить данные проекта" matTooltipPosition="right" [matTooltipShowDelay]="200"></app-button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="projectColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: projectColumns;"></tr>
    </table>

</div>

<mat-dialog-content>
    <div>
        <table mat-table [dataSource]="sectionDataSource">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Секция</th>
                <td mat-cell *matCellDef="let row">
                    <p>{{row.properties.name}}</p>
                </td>
            </ng-container>

            <ng-container matColumnDef="info">
                <th mat-header-cell *matHeaderCellDef class="icon-cell"></th>
                <td mat-cell *matCellDef="let row" class="icon-cell">
                    <app-button [type]="buttonType.icon" [icon]="'info'" (click)="onSectionInfoClick(row)" #tooltipInfo="matTooltip" matTooltip="Привязать секцию к участку на карте" matTooltipPosition="left" [matTooltipShowDelay]="200"></app-button>
                </td>
            </ng-container>

            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef class="icon-cell"></th>
                <td mat-cell *matCellDef="let row" class="icon-cell">
                    <app-button [type]="buttonType.icon" [icon]="'location_on'" [disabled]="!isLocationClickAllowed(row)" (click)="onLocationClick(row)" #tooltipLocation="matTooltip" matTooltip="Показать на карте" matTooltipPosition="right" [matTooltipShowDelay]="200"></app-button>
                </td>
            </ng-container>

            <ng-container matColumnDef="plot_id">
                <th mat-header-cell *matHeaderCellDef>id участка</th>
                <td mat-cell *matCellDef="let row">
                    <p>{{row.properties.plot_id}}</p>
                </td>
            </ng-container>

            <ng-container matColumnDef="use">
                <th mat-header-cell *matHeaderCellDef>назначение трубы</th>
                <td mat-cell *matCellDef="let row">
                    <p>{{row.properties.use}}</p>
                </td>
            </ng-container>

            <ng-container matColumnDef="length">
                <th mat-header-cell *matHeaderCellDef>длина</th>
                <td mat-cell *matCellDef="let row">
                    <p>{{row.properties.pipe.length}}</p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="sectionColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: sectionColumns;"></tr>
        </table>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="start">
    <app-paginator [length]="measurementsLenth" [pageSize]="pageSize" [pageIndex]="pageIndex" [showPageSizeOptions]="false" (page)="onPageEvent($event)">
    </app-paginator>
</mat-dialog-actions>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" size="xl" class="large" mat-dialog-close>Закрыть</button>
</mat-dialog-actions>
