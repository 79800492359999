import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, ReplaySubject, shareReplay, takeUntil, tap } from "rxjs";
import { FormControl } from "@angular/forms";
import { filter, map } from "rxjs/operators";
import {
    InspectionFileType,
    UploadedInspectionFileType
} from "@app/modules/main/modules/details-info-panel/uploaded-Inspection-file-type";
import { ImusDestroyService } from "@services/destroy.service";

@Component({
    selector: 'app-upload-inspection-card',
    templateUrl: './upload-inspection.card.component.html',
    styleUrls: ['./upload-inspection.card.component.scss'],
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadInspectionCardComponent {
    public readonly uploadControl = new FormControl<File[]>(null);
    public readonly data$ = new ReplaySubject(1);
    public readonly allowedType$ = new BehaviorSubject<InspectionFileType>('project');

    public readonly files = {
        projects$:  this.uploadControl.valueChanges.pipe(
            tap(() => this.allowedType$.next('project')),
            filter(Boolean),
            map(files => this.sortFiles(files, UploadedInspectionFileType.project)),
            shareReplay({refCount: true, bufferSize: 1})
        ),
        sections$:  this.uploadControl.valueChanges.pipe(
            filter(Boolean),
            map(files => this.sortFiles(files, UploadedInspectionFileType.section)),
            shareReplay({refCount: true, bufferSize: 1})
        ),
        observation$:  this.uploadControl.valueChanges.pipe(
            filter(Boolean),
            map(files => this.sortFiles(files, UploadedInspectionFileType.observation)),
            shareReplay({refCount: true, bufferSize: 1})
        ),
        rest$:  this.uploadControl.valueChanges.pipe(
            filter(Boolean),
            map(files => this.sortFiles<string[]>(files, [
                UploadedInspectionFileType.project,
                UploadedInspectionFileType.observation,
                UploadedInspectionFileType.section
            ], true)),
            shareReplay({refCount: true, bufferSize: 1})
        )
    }

    /**
     * Данные, по которым будет производиться загрузка файлов (точка или линия)
     *
     * @remarks не реализовано
     * */
    @Input('data')
    set data(value: unknown) {
        this.data$.next(value);
    }

    constructor(
        private _destroy$: ImusDestroyService
    ) {
        this.allowedType$.pipe(
            takeUntil(this._destroy$)
        ).subscribe((type) => {
            if (type === 'all') {
                this.uploadControl.patchValue([]);
            }
        });
    }


    public changeAllowedType(value: InspectionFileType): void {
        this.allowedType$.next(value);
    }
    private sortFiles<T extends string | string[]>(files: File[], searching: T, exclude = false): File[] {
        return Array.isArray(searching)
            ? files.reduce<File[]>((acc, f) => {
                if (exclude) {
                    if (!searching.join('').includes(f.name.toLowerCase())) {
                        acc.push(f);
                    }
                } else {
                    if (searching.join('').includes(f.name.toLowerCase())) {
                        acc.push(f);
                    }
                }
                return acc;
            }, [])
            : files.filter(f => exclude
                ? !f.name.toLowerCase().includes(searching)
                : f.name.toLowerCase().includes(searching))
    }
}
