import { NodeFeature } from "./node";
import { PlotFeature } from "./plot";

export class Device {
    id: string;
    name: string;
}

export namespace DeviceTypes {

    export interface IDeviceType{
        ID: number;
        name: string;
        units?: string;
        created_at?: Date;
        updated_at?: Date;
        deleted_at?: Date;
    }

    export interface IDevice{
        ID: number;
        coordinates?: string;
        user_id: number;
        name: string;
        created_at?: Date | string;
        updated_at?: Date | string;
        deleted_at?: Date | string;
        type?: DeviceTypes.IDeviceType;
        lon?: string;
        lat?: string;
        min?: string;
        max?: string;
        token?: string;
        notes?: string;
        node?: NodeFeature;
        plot?: PlotFeature;
    }

    export interface IDevicesResponse{
        // data: {
            devices: DeviceTypes.IDevice[];
            type: string;
        // }
    }

    export interface IAddEditDeviceRequest{
        type: number;
        name: string;
        coordinates?: string;
        lon: string;
        lat: string;
        min: string;
        max: string;
        notes: string;
        node?: any;
        plot?: any;
    }

    export interface IAfterEditProperties{

        properties?: any;

        id: number;
        coordinates?: string;
        user_id: number;
        name: string;
        created_at?: Date;
        updated_at?: Date;
        deleted_at?: Date;
        type_id?: number;
        lon?: string;
        lat?: string;
        min?: string;
        max?: string;
        token?: string;
        notes?: string;
    }

    // TODO: IEditDeviceResponse в реале имеет формат как ниже
    // node: {
    //     properties: {…}
    //     type: "Node"
    // }

    export interface IEditDeviceResponse {
        status: string;
        node: DeviceTypes.IAfterEditProperties;
    }

    export class EditDeviceResponse implements DeviceTypes.IEditDeviceResponse{
        status: string;
        node: DeviceTypes.IAfterEditProperties;
    }
}
