import { MapService } from './../../services/map.service';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NominatimResponse } from '../../models/nominatim-response.model';
import { GeocodingService } from '../../services/geocoding.service';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Icon, Stroke, Style } from 'ol/style';
import OSMXML from 'ol/format/OSMXML';
import { UserService } from '../../../../shared/services/user.service';
import { MapSearchResultComponent } from '../map-search-result/map-search-result.component';
import { MapSearchService } from '../../services/mapSearch.service';
import { takeUntil } from 'rxjs';
import { ImusDestroyService } from '@app/shared/services/destroy.service';

@Component({
    selector: 'app-map-search',
    templateUrl: './map-search.component.html',
    styleUrls: ['./map-search.component.scss'],
})
export class MapSearchComponent {
    @Output() onSearch = new EventEmitter();
    @Output() locationSelect = new EventEmitter();

    @ViewChild(MapSearchResultComponent)
    mapSearchResultComponent: MapSearchResultComponent;

    public firstSearchSuccessed: boolean = false;
    searchForm: FormGroup;
    searchQuery: string = '';
    searchInProgress: boolean = false;
    searchResults: NominatimResponse[];
    localSearchResults: any[];

    constructor(
        private formBuilder: FormBuilder,
        private mapService: MapService,
        public searchService: MapSearchService,
        private geocodingService: GeocodingService,
        private userService: UserService,
        private readonly _destroy$: ImusDestroyService,
    ) {
        this.searchForm = this.formBuilder.group({
            query: [null],
        });
    }


    onInputKeyup(event: any) {
        this.searchService.searchQuery.next(event.target.value);
        if (parseInt(event.target.value)) {
            this.searchService.searchTypeCoords.next('coords');
        } else {
            this.searchService.searchTypeCoords.next('base');
        }
        if(!event.target.value.length) {
            this.firstSearchSuccessed = false;
        }
    }

    doSearch() {
        this.firstSearchSuccessed = true;
        this.searchService.search(this.searchQuery);
    }

    setCenter(result: NominatimResponse) {
        // console.log('setCenter result', result);
        // this.locationSelect.emit(result);
        /** Добавление контура */
        if (result.osmId) {
            this.geocodingService
                .getContourMapArea(result)
                .subscribe((result) => {
                    const features = new OSMXML().readFeatures(result, {
                        featureProjection: this.mapService.map
                            .getView()
                            .getProjection(),
                    });
                    const vectorLayer = new VectorLayer({
                        source: new VectorSource({ features }),
                        style: function (feature) {
                            return new Style({
                                zIndex: 100,
                                stroke: new Stroke({
                                    color: 'rgb(246,82,62)',
                                    width: 4,
                                }),
                            });
                        },
                    });
                    this.mapService.addSearchableContour(vectorLayer);
                });
        }

        this.mapService.updateView(result.osmId ? 14 : 19, [result.longitude, result.latitude]);

        /** Добавление маркера */
        const marker = new VectorLayer({
            source: new VectorSource({
                features: [
                    new Feature({
                        geometry: new Point(
                            fromLonLat([result.longitude, result.latitude])
                        ),
                    }),
                ],
            }),
            style: new Style({
                image: new Icon({
                    src: '../assets/img/icons/marker-icon.png',
                    anchor: [0.5, 1],
                }),
                zIndex: 101,
            }),
        });
        this.mapService.addSearchableMarker(marker);

        // this.clearAll();
    }

    clearAll() {
        this.searchQuery = '';
        this.firstSearchSuccessed = false;
        this.searchService.clearResults();
    }
}
