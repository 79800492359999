<div class="imus-row header-container">
    <div class="imus-row-item user-image">
        <img src="/assets/images/account_preview.png">
    </div>
    <div class="imus-row-item user-name" *ngIf="accountService.account$ | async as account">
        <div class="user-name-cell">{{account?.user_name}}</div>
        <div *ngIf="account.contractor_type_id === 201 || account.contractor_type_id === 202" class="account-type-cell">
            <ng-container>{{account?.contractor_descr || '--'}}</ng-container>
        </div>
    </div>
    <div class="imus-row-item actions">
        <div class="imus-row">
            <app-button [label]="'Главная'" [large]="true" [fullWidth]="true" [type]="buttonType.primaryButton"
                        routerLink="/"></app-button>
            <app-button [label]="'Выход'" [large]="true" [fullWidth]="true" [type]="buttonType.primaryButton"
                        (click)="onLogoutClick()"></app-button>
        </div>
    </div>
</div>
