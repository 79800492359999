<!-- <section>
    <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let result of results" (click)="selectResult(result)">
            <div matListItemTitle>{{ result.displayName }}</div>
            <div matListItemLine>
                <mat-icon>location_on</mat-icon>{{result.latitude}}, {{result.longitude}}</div>
        </mat-list-item>
    </mat-list>
</section> -->

<mat-list role="list" *ngIf="(searchService.searchTypeCoords$ | async) == 'coords' && (searchService.coordsResults$ | async) as coordsResults">
    <mat-list-item role="listitem" *ngFor="let result of coordsResults" (click)="selectResult(result)">
        <div matListItemTitle>{{ result.displayName }}</div>
        <div matListItemLine>
            <mat-icon>location_on</mat-icon>{{result.latitude}}, {{result.longitude}}</div>
    </mat-list-item>
</mat-list>

<mat-tab-group #resultsTabs mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="getSelectedIndex()" (selectedTabChange)="onTabChange($event)" *ngIf="(searchService.searchTypeCoords$ | async) == 'base'">
    <mat-tab *ngIf="(searchService.localSearchResults$ | async) as localResults" [disabled]="!localResults || localResults.length == 0">
        <ng-template mat-tab-label>
            <span>База данных ({{localResults && localResults.length ? localResults.length : '0'}})</span>
            <!-- <div *ngIf="localResults && localResults.length" matBadge="{{localResults && localResults.length ? localResults.length : '0'}}" [position]="after" [size]="small" matBadgeOverlap="false">База данных</div> -->
            <!-- <div *ngIf="!localResults || localResults.length">База данных ({{localResults && localResults.length ? localResults.length : '0'}})</div> -->

        </ng-template>
        <ng-template matTabContent>
            <mat-list role="list">
                <mat-list-item role="listitem" *ngFor="let result of localResults" (click)="selectLocalResult(result.searchable)">
                    <div matListItemTitle>{{ result.title }}</div>
                    <div matListItemLine *ngIf="result.searchable && result.searchable.lat && result.searchable.lon">
                        <mat-icon>location_on</mat-icon>{{result.searchable?.lat}}, {{result.searchable?.lon}}
                    </div>
                    <section *ngIf="result.searchable && result.searchable.coords">
                        <div matListItemLine *ngFor="let coord of result.searchable.coords">
                            <mat-icon>location_on</mat-icon>{{coord[0]}}, {{coord[1]}}
                        </div>
                    </section>

                    <!-- <div matListItemLine *ngIf="result.searchable && result.searchable.coords">
                            <mat-icon>location_on</mat-icon>{{result.searchable?.coords[0]}}, {{result.searchable?.coords[1]}}</div> -->
                </mat-list-item>
            </mat-list>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="(searchService.searchResults$ | async) as results" [disabled]="!results || results.length == 0">
        <ng-template mat-tab-label>
            <span>OSM Nominatim ({{results && results.length ? results.length : '0'}})</span>
        </ng-template>
        <ng-template matTabContent>
            <mat-list role="list">
                <mat-list-item role="listitem" *ngFor="let result of results" (click)="selectResult(result)">
                    <div matListItemTitle>{{ result.displayName }}</div>
                    <div matListItemLine>
                        <mat-icon>location_on</mat-icon>{{result.latitude}}, {{result.longitude}}</div>
                </mat-list-item>
            </mat-list>
        </ng-template>
    </mat-tab>
</mat-tab-group>