import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThreeService {

  constructor() { }

  public searchModelInFiles(files: any[]) {
    console.log('ThreeService searchModelInFiles', files)
    return files.filter((item: any) => item.name.includes('.ply'));
  }
}
