import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DeviceTypes, PlotCollection, PlotFeature, PlotRef, PlotRequest, PlotType } from '@shared/models';
import { Observable, map, of, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { LayerService } from './layer.service';

@Injectable({
    providedIn: 'root'
})
export class PlotService {

    //TODO 
    // Временное решение, подменяем layer_type_id
    nodeTypeTrans = {
        1: 7,
        4: 10
    }

    public changed$ = new EventEmitter<number>();
    // public changedLayer$ = new EventEmitter<number>();
    public readonly plotTypes$ = this.layerService.changed$.pipe(
        startWith(1),
        switchMap(() => this.getAllTypes()),
        //TODO 
        // Временное решение, подменяем layer_type_id
        map(value => {
            value.map(nodeType => 
                nodeType.layer_type_id = this.nodeTypeTrans[nodeType.layer_type_id] ?? nodeType.layer_type_id
            )
            return value
        }),
        shareReplay(1)
    )

    constructor(
        private http: HttpClient,
        private layerService: LayerService
    ) {
    }

    getAll(): Observable<PlotCollection> {
        return this.http.get<PlotCollection>(`${environment.apiUrl}/plot/`);
    }

    getById(id): Observable<PlotFeature> {
        return this.http.get<PlotFeature>(`${environment.apiUrl}/plot/${id}`);
    }

    check(startNodeId, endNodeId) {
        return this.http.post(`${environment.apiUrl}/plot/check`, {startNode: startNodeId, endNode: endNodeId});
    }

    add(plot: PlotRef) {
        // console.log("add plot",plot)
        return this.http.post(`${environment.apiUrl}/plot`, plot.getPlotReq());
    }

    editPut(plot: PlotRequest, plotId: number) {
        return this.http.put(`${environment.apiUrl}/plot/${<number>plotId}`,  plot);
    }

    editPatch(plot: PlotRequest, plotId: number): Observable<PlotFeature> {
        return this.http.patch<PlotFeature>(`${environment.apiUrl}/plot/${<number>plotId}`,  plot);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/plot/${id}`);
    }

    node(id: number) {
        // вернет отрезки относящиеся (сопрекасающиеся) к данному узлу
        return this.http.get(`${environment.apiUrl}/plot/node/${id}`);
    }

    device(id: number, device: DeviceTypes.IDevice) {
        return this.http.post(`${environment.apiUrl}/plot/device/${id}`, {"device": device });
    }

    public uploadFile(file: File, plotId: number): Observable<void> {
        if (!file) {
            return of(undefined);
        }
        const data = new FormData();
        data.append('file', file, file.name);
        return this.http.post<void>(`${environment.apiUrl}/plot/${plotId}/file`, data).pipe(
            tap(() => {
                this.changed$.emit(plotId);
                // this.changedLayer$.emit(layer);
            })
        );
    }

    public getAllTypes(): Observable<PlotType[]> {
        return this.http.get<PlotType[]>(`${environment.apiUrl}/plot_type`)
    }
}
