import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ImusBaseInputComponent } from '../imus-base-input/imus-base-input.component';
import { animationFrameScheduler, BehaviorSubject, combineLatest, map, Observable, shareReplay } from 'rxjs';
import { ImusDestroyService } from '../../services/destroy.service';
import { AttachmentType } from '../../attachment.type';
import { NgControl } from '@angular/forms';

const DEFAULT_FILE_LINK = 'Выберите файл или перетяните его из проводника для загрузки';
const DEFAULT_MULTIPLE_FILE_LABEL = 'Выберите файл или несколько файлов или перетяните их из проводника для загрузки';

@Component({
    selector: 'imus-file-input',
    templateUrl: './imus-file-input.component.html',
    styleUrls: ['./imus-file-input.component.scss'],
    host: {
        '(click)': 'onClick()'
    },
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImusFileInputComponent extends ImusBaseInputComponent<File[]> {

    private _maxFileSize = 30000000;
    private _maxFilesLength = 20;

    protected readonly _accept$ = new BehaviorSubject<AttachmentType>(AttachmentType.all);
    protected readonly _multiple$ = new BehaviorSubject<boolean>(false);
    protected readonly _structure$ = new BehaviorSubject<boolean>(false);
    protected readonly _label$ = new BehaviorSubject<string>(DEFAULT_MULTIPLE_FILE_LABEL);

    @HostBinding('class.dragover')
    public dragover = false;

    @ViewChild('fileInput')
    public fileInput: ElementRef<HTMLInputElement>

    public readonly accept$: Observable<AttachmentType> = this._accept$.pipe(
        map(value => value ?? AttachmentType.all),
        shareReplay(1)
    );


    public readonly label$: Observable<string> = combineLatest([this._multiple$, this._label$]).pipe(
        map(([multiple, label]) => label
            ? label
            : `или перетащите ${multiple
                ? 'их'
                : 'его'} сюда`),
        shareReplay(1)
    );

    public readonly structure$: Observable<boolean> = this._structure$;

    public readonly multiple$: Observable<boolean> = combineLatest([this._multiple$, this._structure$]).pipe(
        map((multi) => multi.some(b => b)),
        shareReplay(1)
    );

    public hintText: string = null;

    /** title */
    @Input()
    public set label(value: string) {
        this._label$.next(value);
    }

    /**
     * Установка разрешенных форматов загружаемых файлов
     *
     * @see AttachmentType
     * */
    @Input()
    public set accept(value: AttachmentType) {
        this._accept$.next(value);
    }

    /**
     * Флаг установки мультизагрузки файлов (пока нет функционала)
     * */
    @Input()
    public set multiple(value: boolean | string) {
        this._multiple$.next(typeof value === 'string' ? true : value);
    }

    /**
     * Флаг установки мультизагрузки файлов из переданной папки
     * */
    @Input()
    public set structure(value: boolean | string) {
        this._structure$.next(typeof value === 'string' ? true : value);
    }

    /**
     * Вспомогательная информация для пользователя.
     * Отображаемая или в виде всплывающей подсказки при наведении курсора
     */
    @Input('hint')
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private set _hint(value: string) {
        this.hintText = value;
    }

    /** Максимальный размер загружаемого файла */
    @Input('maxSize')
    public set maxFileSize(value: string | number) {
        this._maxFileSize = +value ?? 30000000;
    }

    public get maxFileSize(): number {
        return this._maxFileSize;
    }

    @Input('maxFiles')
    public set maxFiles(value: number | string) {
        this._maxFilesLength = +value ?? 25;
    }

    @Input('disabled') public disabled: boolean = true;

    public get maxFiles(): number {
        return this._maxFilesLength || 25;
    }

    constructor(
        protected override readonly _destroy$: ImusDestroyService,
        protected override readonly _control: NgControl,
        private readonly _elementRef: ElementRef
    ) {
        super(_destroy$, _control);
    }

    public preload(evt: Event): void {
        try {
            let _files = Array
                .from((evt.target as HTMLInputElement).files);
            if (_files.length === 0) {
                return;
            }
            if (!this._multiple$.getValue()) {
                _files = [_files[0]];
                this.value = _files;
                return;
            }

            if (this._structure$.getValue() || this._multiple$.getValue()) {
                this.value = _files;
                return;
            }
        } finally {
            (evt.target as HTMLInputElement).value = '';
        }
    }

    public onClick() {
        if(this.disabled) return;
        animationFrameScheduler.schedule(() => {
            this.fileInput.nativeElement.click()
        })
    }
}
