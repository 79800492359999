import { AbstractControl, ValidatorFn } from '@angular/forms';
/** Регулярка для строки координат */
export const COORDINATES_MASK = /^[-+]?(90([.,]0+)?|((1[0-7]\d)|([1-9]?\d))([.,]\d+)?),\s*[-+]?([1-8]?\d([.,]\d+)?|180([.,]0+)?)$/;

/**
 * Объект опций для директивы imask для строки координат
 *
 * @see [imask]
 * */
export const COORDINATES_MASK_OPTIONS: IMask.AnyMaskedOptions = {
  mask: 's, f',
  blocks: {
    f:{
      mask: Number,
      thousandsSeparator: '',
      min: -180,
      max: 180,
      padFractionalZeros: false,
      normalizeZeros: true,
      radix: '.',
      mapToRadix: ['.', ','],
      scale: 13,
      signed: true
    },
    s:{
      mask: Number,
      thousandsSeparator: '',
      min: -90,
      max: 90,
      padFractionalZeros: false,
      normalizeZeros: true,
      radix: '.',
      mapToRadix: ['.', ','],
      scale: 12,
      signed: true
    }
  }
};

/**
 * Объект опций для директивы imask для строк координат (отдельно для широты и долготы)
 *
 * @see [imask]
 * */
export const COORDINATES_MASK_SEPARATED_OPTIONS: { lon: IMask.AnyMaskedOptions, lat: IMask.AnyMaskedOptions }  = {
    lon: {
        mask: Number,
        thousandsSeparator: '',
        min: -180,
        max: 180,
        padFractionalZeros: false,
        normalizeZeros: true,
        radix: '.',
        mapToRadix: ['.', ','],
        scale: 15,
        signed: true
    },
    lat: {
        mask: Number,
        thousandsSeparator: '',
        min: -90,
        max: 90,
        padFractionalZeros: false,
        normalizeZeros: true,
        radix: '.',
        mapToRadix: ['.', ','],
        scale: 15,
        signed: true
    }
};

/** Валидатор для поля ввода координат */
export function coordinatesValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const coordinatesRegex = COORDINATES_MASK;
    const coordinates = control.value;
    if (!coordinatesRegex.test(coordinates)) {
      return { 'invalidCoordinates': true };
    }
    return null;
  };
}
