import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountsService} from "@app/modules/account/services/accounts.service";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {AccountsViewData} from "@shared/models";
import {FormatStringPipe} from "@shared/pipes/format-string.pipe";
import {MatIconModule} from "@angular/material/icon";
import {SharedModule} from "@shared/shared.module";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {AlertDialogComponent} from "@app/modules/main/dialogs/alert.dialog/alert.dialog.component";
import {AlertService} from "@shared/services";
import {RouterLink} from "@angular/router";
import {MatSortModule, Sort} from "@angular/material/sort";
import {catchError, map, takeUntil, throwError} from "rxjs";
import { ImusDestroyService } from '@app/shared/services/destroy.service';

export function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

@Component({
    selector: 'additional-accounts',
    standalone: true,
    imports: [CommonModule, MatTableModule, FormatStringPipe, MatIconModule, SharedModule, RouterLink, MatSortModule],
    templateUrl: './additional-accounts.component.html',
    styleUrls: ['./additional-accounts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalAccountsComponent implements OnInit {
    private accountsService = inject(AccountsService);
    private dialog  = inject(MatDialog);
    private alertService = inject(AlertService);
    private readonly destroy = inject(ImusDestroyService);

    accountsViewData: MatTableDataSource<AccountsViewData> = new MatTableDataSource<AccountsViewData>();
    displayedColumns: string[] = [
        'contractor_id',
        'contractor_descr',
        'role_descr',
        'start_date',
        'end_date',
        'logout'
    ];

    @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() sortSettings: EventEmitter<any> = new EventEmitter<any>()
    @Input() userId: string;
    @Input()
    set accountsData(accountsData: AccountsViewData[]) {
        if (!accountsData) {
            return;
        }
        this.accountsViewData.data = accountsData
    };

    ngOnInit() {
        // this._sortSettings$.pipe(
        //     map(sortSettings => {
        //         if (!sortSettings) {
        //             return {}
        //         }
        //         const key = `orderBy[${sortSettings.active}]`
        //         return { [key]: sortSettings.direction }
        //     })
        // );
    }

    public sort(event: Sort) {
        this.sortSettings.next(event.direction ? event : null);
    }

    openDialogExit(row: AccountsViewData) {
        const dialog = new MatDialogConfig();
        dialog.width = '480px';
        dialog.data = {
            okButtonText: 'Закрыть аккаунт',
            cancelButtonText: 'Отменить',
            title: 'Внимание!?',
            message: `Вы действительно хотите закрыть гостевой аккаунт?`,
        }

        this.dialog.open(AlertDialogComponent, dialog)
            .afterClosed()
            .subscribe((remove?: boolean) => {
                if (remove) {
                    const data = {
                        id: row.id,
                        end_date: formatDate(new Date)
                    }
                    this.accountsService.exitAccounts(data)
                        .pipe(
                            catchError(err => {
                                if (err.status === 403) this.alertService.openSnackbar('Этот аккаунт отключить нельзя.');
                                return throwError(() => err)
                            }),
                            takeUntil(this.destroy)
                        )
                        .subscribe(() => {
                            this.alertService.openSnackbar('Аккаунт успешно закрыт');
                            this.changed.next(true);
                        })
                }
            })
    }

}
