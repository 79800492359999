<section class="filters-form">
    <mat-form-field *ngIf="sysSubjects$ | async">
        <mat-label>Объект (Таблица БД)</mat-label>
        <mat-select #sysSubjectSelect (opened)="true" [(ngModel)]="sysSubjectId" [value]="sysSubjectId$.value"  (valueChange)="changeSysSubjectId($event)">
            <mat-option *ngFor="let item of sysSubjects$ | async" [value]="item.id">{{ item.descr }} ({{ item.table_name }})</mat-option>
        </mat-select>
    </mat-form-field>
</section>

<section class="permission-settings__table">

    <mat-progress-bar *ngIf="isLoaded" mode="indeterminate"></mat-progress-bar>

    <mat-table [dataSource]="roleList$ | async">

        <ng-container matColumnDef="roleDescr">
            <mat-header-cell *matHeaderCellDef disableClear>Роль</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.descr}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="roleId">
            <mat-header-cell *matHeaderCellDef disableClear>roleId</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.role_id}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="accountTypeDescr">
            <mat-header-cell *matHeaderCellDef disableClear>accountTypeDescr</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.account_type_descr}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef disableClear>id</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.id}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="canCreate">
            <mat-header-cell *matHeaderCellDef disableClear>canCreate</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <!-- <mat-checkbox color="primary" (change)="showGrid($event.checked)">Показать сетку</mat-checkbox> -->
                <mat-checkbox
                    [checked]="isRolePermissionEnable(row, 'can_create') | async"
                    value=""
                    color="primary"
                    (change)="onChangeCheckbox($event, row, 'can_create')"
                ></mat-checkbox>
                <!-- <p>{{row.can_create}}</p> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="canRead">
            <mat-header-cell *matHeaderCellDef disableClear>canRead</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox
                [checked]="isRolePermissionEnable(row, 'can_read') | async"
                    value=""
                    color="primary"
                    (change)="onChangeCheckbox($event, row, 'can_read')"
                ></mat-checkbox>
                <!-- <p>{{row.can_read}}</p> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="canUpdate">
            <mat-header-cell *matHeaderCellDef disableClear>canUpdate</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox
                [checked]="isRolePermissionEnable(row, 'can_update') | async"
                value=""
                color="primary"
                (change)="onChangeCheckbox($event, row, 'can_update')"
            ></mat-checkbox>
                <!-- <p>{{row.can_update}}</p> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="canDelete">
            <mat-header-cell *matHeaderCellDef disableClear>canDelete</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox
                [checked]="isRolePermissionEnable(row, 'can_delete') | async"
                value=""
                color="primary"
                (change)="onChangeCheckbox($event, row, 'can_delete')"
            ></mat-checkbox>
                <!-- <p>{{row.can_delete}}</p> -->
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

</section>

<section class="mat-card-footer footer-actions">
    <app-button
    label="Сохранить"
    [icon]="'save'"
    [primary]="true"
    (onclick)="saveRolePermissions()" />
    <app-button
    label="Отменить"
    [icon]="'clear'"
    (onclick)="cancelRolePermissions" />
<!--

    <button mat-raised-button color="primary" (click)="saveRolePermissions()"></button>

    <button mat-raised-button (click)="cancelRolePermissions()"></button> -->

</section>


