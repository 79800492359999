import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Attachment } from "@shared/attachment";

const IMUS_REQUIRED_VALIDATOR_MESSAGE = 'Поле обязательное к заполнению';

export const imusRequiredValidator: () => ValidatorFn = () =>
	(control: AbstractControl): ValidationErrors | null => (
		(control.value === null || control.value === undefined)
		|| (control.value instanceof Attachment && !control.value)
		|| (Array.isArray(control.value) && !control.value.length)
		|| (typeof control.value === 'string' && control.value === '')
	)
		? { required: IMUS_REQUIRED_VALIDATOR_MESSAGE }
		: null;

/**
 * Обязательное поле
 */
@Directive({
	selector: '[imusRequired]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: ImusRequiredValidatorDirective,
		multi: true
	}]
})
export class ImusRequiredValidatorDirective implements Validator, OnChanges {

	private _required = true;

	@Input('imusRequired')
	set required(value: boolean | '') {
		this._required = value === '' || !!value;
		if (this._required) {
			this.element.nativeElement.classList.add('imus-required');
		} else {
			this.element.nativeElement.classList.remove('imus-required');
		}
	}

	constructor(private element: ElementRef) {
	}

	private _onChange: () => void;

	public validate(control: AbstractControl): ValidationErrors | null {
		return this._required
			? imusRequiredValidator()(control)
			: null;
	}

	registerOnValidatorChange(fn: () => void): void {
		this._onChange = fn;
	}

	ngOnChanges(_: SimpleChanges): void {
		if (this._onChange) {
			this._onChange();
		}
	}
}
