<!-- <app-spinner *ngIf="(loadingDeviceList$ | async)!!"></app-spinner>  -->

<!-- <app-spinner *ngIf="(loadingDeviceList$ | async)!!"></app-spinner> -->

<mat-dialog-header [title]="'Показания'">
    <!-- <section class="mat-dialog-header-buttons">
        <button mat-icon-button (click)="refreshData()">
            <mat-icon>refresh</mat-icon>
        </button>
    </section> -->
</mat-dialog-header>

<mat-dialog-content class="p-10-0">

    <div>
        <mat-table [dataSource]="deviceTableSource" *ngIf="this.deviceTableSource.data.length">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Имя</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p>{{row.name}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef>Тип</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p>{{row.type.name}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lat">
                <mat-header-cell *matHeaderCellDef>Широта</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p>{{row.lat}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lon">
                <mat-header-cell *matHeaderCellDef>Долгота</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p>{{row.lon}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="min">
                <mat-header-cell *matHeaderCellDef>Мин</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p>{{row.min}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="max">
                <mat-header-cell *matHeaderCellDef>макс</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p>{{row.max}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="notes">
                <mat-header-cell *matHeaderCellDef>Описание</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p>{{row.notes}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="token">
                <mat-header-cell *matHeaderCellDef>Токен</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <app-input [type]="inputType.password" [readonly]="true" [value]="row.token" [noBorder]="true" [useDefaultIcon]="false"></app-input>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="icon-cell">
                    <app-button [type]="buttonType.icon" [icon]="'edit'" [disabled]="!ifDialogOpeningAllowed" (onclick)="onEditDeviceClick(row)"></app-button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="icon-cell">
                    <app-button [type]="buttonType.icon" [icon]="'delete'" [disabled]="!ifDialogOpeningAllowed" (onclick)="onDeleteDeviceClick(row)"></app-button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="addMeasurement">
                <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="icon-cell">
                    <app-button [type]="buttonType.icon" [icon]="'add'" [disabled]="!ifDialogOpeningAllowed" (onclick)="onAddMeasurement(row)"></app-button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="devicesDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: devicesDisplayedColumns;"></mat-row>
        </mat-table>
    </div>

    <div>
        <table mat-table [dataSource]="measurementDataSource" *ngIf="measurementDataSource.data.length" [style.width.%]="100">
            <ng-container matColumnDef="value">
                <mat-header-cell *matHeaderCellDef>Показание</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p>{{row.value}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="updated_at">
                <mat-header-cell *matHeaderCellDef>Последнее обновление</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <!-- {{row.updated_at | date: 'dd.MM.yyyy HH:MM:SS'}} -->
                    {{row.collected_at | date: 'dd.MM.yyyy HH:mm:ss'}}
                    <!-- {{row.collected_at}} -->
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="measurementsDisplayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: measurementsDisplayedColumns;"></mat-row>
        </table>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="start">
    <app-paginator [length]="measurementsLenth" [pageSize]="pageSize" [pageIndex]="pageIndex" [showPageSizeOptions]="false" (page)="onPageEvent($event)">
    </app-paginator>
</mat-dialog-actions>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close>Закрыть</button>
</mat-dialog-actions>
