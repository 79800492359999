import {
    Component,
    Input,
    HostListener,
    ViewChild,
    ElementRef,
    OnInit,
    ChangeDetectionStrategy,
    Optional,
    Self
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ImusBaseInputComponent } from '@shared/components/imus-base-input/imus-base-input.component';
import { ImusDestroyService } from '@shared/services/destroy.service';
import { animationFrameScheduler, map, observeOn } from 'rxjs';
import { coordinatesValidator } from '@shared/validators/coordinates.validator';

export namespace InputComponentSettings {
    export enum InputType {
        color = 'color',
        date = 'date',
        datetimeLocal = 'datetime-local',
        email = 'email',
        month = 'month',
        number = 'number',
        password = 'password',
        search = 'search',
        tel = 'tel',
        text = 'text',
        time = 'time',
        url = 'url',
        week = 'week',
        coords = 'coords',
    }
}

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ImusDestroyService]
})
export class InputComponent extends ImusBaseInputComponent<unknown> implements OnInit {

    @Input()
    type: InputComponentSettings.InputType = InputComponentSettings.InputType.text;

    private _icon: string = undefined;
    get icon(): string {
        return this.getIcon();
    }

    @Input()
    set icon(value) {
        this._icon = value;
    };

    @Input()
    mask: IMask.AnyMaskedOptions;

    @Input()
    useDefaultIcon: boolean = true;

    @Input() required = false;

    @Input() outline = false; //TODO
    @Input() disabled: boolean = false; //TODO

    @Input() noBorder = false;

    @ViewChild('inputElement', { static: false })
    protected override _inputElement: ElementRef;

    public useIcon$ = this.title$.pipe(
        observeOn(animationFrameScheduler),
        map(title => !title && this.getIcon() !== undefined)
    )

    inputType = InputComponentSettings.InputType;

    private passwordVisible = false;

    get eye() {
        return this.passwordVisible ? 'visibility_off' : 'visibility';
    }

    constructor(
        protected override readonly _destroy$: ImusDestroyService,
        @Optional() @Self() protected override readonly _control: NgControl
    ) {
        super(_destroy$, _control);
        this.innerControl = new FormControl<unknown>(null);
        animationFrameScheduler.schedule(() => {
            if (this.type === InputComponentSettings.InputType.coords) {
                this.innerControl.addValidators(coordinatesValidator);
            }
        })
    }

    private getIcon(): string | undefined {
        if (this._icon) {
            return this._icon;
        } else {
            if (this.useDefaultIcon) {
                switch (this.type) {
                    case InputComponentSettings.InputType.email:
                        return 'email';
                    case InputComponentSettings.InputType.tel:
                        return 'call';
                    case InputComponentSettings.InputType.password:
                        return 'key';
                    case InputComponentSettings.InputType.date:
                        return 'today';
                    case InputComponentSettings.InputType.color:
                        return 'palette';
                    case InputComponentSettings.InputType.datetimeLocal:
                        return 'schedule';
                    case InputComponentSettings.InputType.month:
                        return 'calendar_month';
                    case InputComponentSettings.InputType.search:
                        return 'search';
                    case InputComponentSettings.InputType.text:
                        return 'description';
                    case InputComponentSettings.InputType.time:
                        return 'schedule';
                    case InputComponentSettings.InputType.url:
                        return 'link';
                    default:
                        return undefined;
                }
            } else {
                return undefined;
            }
        }
    }

    onMouseDown(event: MouseEvent) {
        if (event.button === 0) {
            this.passwordVisible = !this.passwordVisible;
            this._inputElement.nativeElement.setAttribute('type', this.passwordVisible ? this.inputType.text : this.inputType.password);
        }
    }
}
