import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginModalComponent } from '@app/modules/account/components/login-modal/login-modal.component';
import { RegisterModalComponent } from '@app/modules/account/components/register-modal/register-modal.component';
import { AuthenticationService } from '@app/shared/services';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-page-hello',
    templateUrl: './page-hello.component.html',
    styleUrls: ['./page-hello.component.scss'],
})
export class PageHelloComponent implements OnInit, AfterViewInit {
    public stepOneReady: boolean = false;
    public pageReady: boolean = false;
    public videoChanging: boolean = false;
    public displayDevLogin: boolean = false;

    @ViewChild('videoPlayer') videoPlayer: ElementRef;

    constructor(
        public matDialog: MatDialog,
        private authenticationService: AuthenticationService
    ) {
        if (!environment.production) {
            this.displayDevLogin = true;
        }
    }

    ngOnInit(): void {}

    ngAfterViewInit() {
        setTimeout(() => (this.pageReady = true), 1000);

        // this.videoPlayer.nativeElement.onloadeddata(() => {
        //     this.videoPlayer.nativeElement.play();
        // })
    }

    onVideoLoad(event) {
        this.videoPlayer.nativeElement.play();
    }

    // demoLogin() {
    //        this.authenticationService.demoLogin();
    // }

    openLoginModal() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.id = 'modal-login-component';

        let dialogRef = this.matDialog.open(LoginModalComponent, dialogConfig);
        this.stepOneReady = true;
        dialogRef.afterClosed().subscribe((result) => {
            this.stepOneReady = false;
        });
    }

    openRegisterModal() {
        let dialogRef = this.matDialog.open(RegisterModalComponent, {
            autoFocus: true,
            id: 'modal-register-component',
            // height: '100vh',
            // height: '90vh',
            // width: '100vw',
            maxHeight: '90vh',
            // maxWidth: '100vw',
        });
        this.stepOneReady = true;
        dialogRef.afterClosed().subscribe((result) => {
            this.stepOneReady = false;
        });
    }

    onVideoEnded(event) {
        // console.log('onVideoEnded', event);
        this.videoChanging = true;
        this.videoPlayer.nativeElement.play();
        setTimeout(() => {
            this.videoChanging = false;
        }, 1000);
    }
}
