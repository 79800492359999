<div class=WordSection1>

	<h2 style='text-indent:0cm'><a name="_Toc142485595"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Общая информация</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Система ИМУС
			предназначена для получения, хранения,
			обработки и отображения сведений о подземных коммуникациях (создания цифровых
			двойников реальных объектов) по архивным данным, данным дистанционного
			зондирования коммуникаций, телеметрическим данным роботизированных комплексов и
			данным датчиков постоянного базирования. </span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Система
			построена как веб-сервис для сбора, хранения,
			документирования, анализа и просмотра пространственных данных подземных
			коммуникаций и дополнительной информации о них с возможностью работы с любого
			устройства через браузер.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>ИМУС
			представляет из себя геоинформационную систему со
			слоями данных подземных коммуникаций, их документированию, анализу и контролю.
			В качестве базового слоя в качестве подложки используется тайловый сервис.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Система
			является многопользовательской. Пользователь,
			создавая аккаунт получает возможность ввода и вывода и хранения своих
			пространственных данных, медиафайлов, атрибутивных данных коммуникаций,
			документации и других данных. </span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Система
			позволяет загружать геометрию коммуникаций,
			привязывать к ним и хранить различные типы данных, в том числе семантические
			данные, динамические данные, данные телеметрии, полученные с датчиков или
			роботизированных комплексов обследования коммуникаций, обрабатывать, выгружать
			обработанные данные, осуществлять мониторинг различных параметров.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для
			пользователей продукт является облачным сервисом модели
			SaaS – программное обеспечение (ПО) как услуга. Пользователи получают готовое
			ПО и обслуживание провайдером.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485596"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Регистрация и вход в систему</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>При первом
			входе в систему пользователю необходимо
			зарегистрироваться. Для регистрации пользователю необходимо указать свой адрес
			электронной почты e-mail и пароль для доступа. Минимальная длина пароля должна
			составлять 8 символов. E-mail является логином для авторизации в системе. На
			указанный e-mail будет направлена ссылка для подтверждения регистрации. Для
			авторизации в системе в окне «вход» надо будет указывать только свой e-mail и
			пароль.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В случае, если
			пользователь указал ошибочный e-mail, то ему
			необходимо зарегистрироваться заново, указав корректный e-mail. </span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В случае, если
			пользователь забыл свой пароль, его можно
			восстановить по ссылке для восстановления пароля. В этом случае на
			зарегистрированный e-mail будет отправлена ссылка для формирования нового
			пароля.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485597"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Главная страница</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><a name="_heading=h.y6v2qmif1c2p"></a><a
			name="_heading=h.rz504zt167lt"></a><span style='color:black'>При входе в систему открывается главная
			страница. Главная
			страница состоит из основного блока с базовой картой-подложкой и отображаемыми
			слоями, панели работы со слоями и инструментами, панели атрибутов объектов,
			иконок вспомогательных элементов.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485598"></a><a name="_heading=h.84s24li456t8"></a><span style='font-size:14.0pt;font-family:
	"Calibri",sans-serif'>Базовая карта</span></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Основная часть
			интерфейса, где в процессе работы
			отображаются другие рабочие слои и графические материалы, различные
			инструменты. В качестве базовой карты-подложки используется ресурс
			Openstreetmap (некоммерческий веб-картографический проект по созданию силами
			сообщества участников - пользователей Интернета подробной свободной и
			бесплатной географической карты мира), по умолчанию используется система
			координат EPSG 4326 (основана на WGS84, в качестве координат – географические
			широта и долгота в градусах). Блок с картой построен на основе инструментов
			Openlayers (библиотека с открытым исходным кодом, предназначенная для создания
			карт на основе программного интерфейса). В качестве альтернативной подложки, в
			системе ИМУС можно включить снимки BING, одновременно может быть включена
			только одна подложка.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>На блоке с
			картой также присутствуют вспомогательные
			элементы:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Окно поиска
			объектов (используется сервис поиск в базе
			данных и географических названий OSM Nominatim), поиск места на карте по
			географическим координатам. При внесении запроса в окно поиска будет открыто
			окно с результатами запроса, базовая карта центрируется на найденном объекте,
			объект будет выделен цветом по контуру (для площадных объектов) или меткой на
			карте в течение нескольких секунд;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Указатель
			координат позиции курсора на карте и масштабная
			линейка для приблизительного понимания размеров на карте;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Иконка
			включение/выключение панели работы со слоями –
			активирует панель работы со слоями;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Инструмент
			«Измерение расстояний» для измерения
			расстояний на карте, при активации инструмента следует кликнуть на карте для
			указания начала измеряемой линии, последующие клики для промежуточных точек и
			двойной клик для завершения измерения;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Иконка
			списка устройств телеметрии;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Иконка
			списка отчетов телеинспекций;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Клавиши
			увеличения/уменьшения масштаба карты;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Иконка для
			перехода в личный кабинет;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>• Иконка для
			перехода на страницу информации.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485599"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Подложка</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В качестве
			картографической подложки (фонового изображения)
			сервиса ИМУС по умолчанию используются данные Openstreetmap. Альтернативным
			вариантом можно выбрать отображение спутниковых снимков Bing.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485600"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Слои</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В настоящий
			момент в системе ИМУС используются рабочие слои
			класса «Канализация» и растровые слои, загружаемые отдельно. </span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485601"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Слои «Канализация»</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Пользователь
			может создавать неограниченное количество
			слоев типа «Канализация». Данный тип слоев может состоять из точечных
			(канализационные колодцы и точки подключения) и линейных объектов (участков
			трубопроводов канализации):</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><b>Точка
			подключения</b> – место приема, подключенный абонент канализационной сети;</p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><b>Колодец</b>
		– узел между участками сети, различия по функциональному назначению
		предполагается отображать в имени объекта, например, КК-1, КГ-02, КНС;</p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><b>Участок
			сети</b> – участок сети между двумя колодцами или между отводом из дома и
		колодцем с визуальным обозначением направления стока (от начальной точки к
		конечной), названия начального и конечного узла присваиваются из смежных колодцев;</p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'>Участок
		представляет из себя направленный граф, где начальная и конечная точка
		соответствует колодцам или местам подключения, а направление - течению. В
		простейшем случае участок является отрезком прямой. Пользователь может, для
		уточнения прохождения участка, вручную из линии строить кривую дополняя её
		вершинами. Участок начинается и заканчивается колодцем или точкой подключения. Колодец
		может иметь несколько привязанных участков, точка подключения – один
		привязанный участок. Каждый участок должен иметь начальный и конечный колодец,
		который задает направление течения в нем. Множество связанных между собой
		колодцев с точками подключения посредством участков создают сеть. Колодцы или
		точки подключения могут быть не связаны участками. При удалении колодца или
		точки подключения с привязанными к ним участками, должны удаляться и эти
		участки.</p>

	<h2 style='text-indent:0cm'><a name="_Toc142485602"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Растровые слои</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Пользователь
			может самостоятельно загружать растровые слои
			с дополнительной информацией, например, схемы коммуникаций, снимки местности.
			Для отображения растрового слоя в системе он должен иметь файл для
			пространственной привязки. </span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Файл привязки
			может выглядеть примерно так:</span></p>

	<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:
	none'><span style='font-family:"Courier New"'>0.0000030995-A</span></p>

	<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:
	none'><span style='font-family:"Courier New"'>0-</span><span lang=EN-US style='font-family:"Courier New"'>B</span></p>

	<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:
	none'><span style='font-family:"Courier New"'>0-</span><span lang=EN-US style='font-family:"Courier New"'>C</span></p>

	<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:
	none'><span style='font-family:"Courier New"'>-0.0000017211-</span><span lang=EN-US
			style='font-family:"Courier New"'>D</span></p>

	<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:
	none'><span style='font-family:"Courier New"'>37.22772953-</span><span lang=EN-US
			style='font-family:"Courier New"'>E</span></p>

	<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:
	none'><span style='font-family:"Courier New"'>56.72740985-</span><span lang=EN-US
			style='font-family:"Courier New"'>F</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Где:
		</span><span lang=EN-US style='color:black'>A</span><span style='color:black'> - x-масштаб; размер пиксела в
			градусах долготы</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span lang=EN-US
			style='color:black'>B</span><span lang=EN-US style='color:black'> </span><span style='color:black'>и
		</span><span lang=EN-US style='color:black'>C</span><span lang=EN-US style='color:black'> </span><span
			style='color:black'>являются
			терминами поворота (в настоящий момент не используется)</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span lang=EN-US
			style='color:black'>D</span><span style='color:black'> -
			отрицательный y-масштаб; размер пиксела в градусах широты</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span lang=EN-US
			style='color:black'>E</span><span style='color:black'> – географическая
			долгота (</span><span lang=EN-US style='color:black'>WGS</span><span style='color:black'>84) центра верхнего
			левого пиксела в градусах</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span lang=EN-US
			style='color:black'>F</span><span style='color:black'> – географическая
			широта (</span><span lang=EN-US style='color:black'>WGS</span><span style='color:black'>84) центра верхнего
			левого пиксела в градусах</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для удобства и
			быстроты загрузки рекомендуется использовать
			растровые файлы не превышающие объемом 3 Мб.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485603"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Подготовка растрового изображения в </span></a><span lang=EN-US
			style='font-size:14.0pt;font-family:"Calibri",sans-serif'>SAS</span><span
			style='font-size:14.0pt;font-family:"Calibri",sans-serif'>.</span><span lang=EN-US
			style='font-size:14.0pt;font-family:"Calibri",sans-serif'>Planet</span></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Один из
			возможных способов подготовить растр с файлом
			привязки – воспользоваться программой </span><a href="http://www.sasgis.org/"><span
				lang=EN-US>SAS</span>.<span lang=EN-US>Planet</span></a><span style='color:
	black'>.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>После
			установки программы, надо выбрать источник для
			формирования растра. Далее следует использовать инструмент выделения, для
			обозначения границ нужного фрагмента. Во вкладке склеить фрагмент нужно выбрать
			путь для сохранения файлов, в качестве файла привязки выбрать формат *.</span><span lang=EN-US
			style='color:black'>w</span><span style='color:black'>, используемая
			проекция: </span><span lang=EN-US style='color:black'>WGS</span><span style='color:black'>84/</span><span
			lang=EN-US style='color:black'>EPSG</span><span style='color:black'>:4326. Созданная таким образом пара
			файлов (растр и файл
			привязки) может быть использована в системе ИМУС.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485604"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Добавление слоев</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для добавления
			любого типа слоев используется иконка
			«Добавить слой». Далее следует выбрать тип слоя: Базовый или Растровый. Для
			базового слоя выбираем тип «Канализация» и присваиваем название слоя. Для
			растрового необходимо указать расположение файла, загрузить его и указать
			расположение файла привязки, после обработки растр будет добавлен на карту.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485605"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Управление растровыми слоями</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Все растровые
			слои отображаются в категории «Растровые» на
			панели слоев. Справа от названия слоя есть панель с пиктограммами для
			управления: можно центрировать экран на выбранном слое, переместить его
			вверх/вниз в списке слоев (при этом измениться порядок его отображения в
			интерфейсе). Отдельно можно изменить название слоя, изменить прозрачность или
			удалить слой.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485606"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Управление базовыми слоями</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Все рабочие
			слои отображаются в категории «Канализация» на
			панели слоев. Справа от названия слоя есть панель с пиктограммами для
			управления: можно переместить его вверх/вниз в списке слоев (при этом
			измениться порядок его отображения в интерфейсе), можно изменить название слоя,
			включить надписи названий объектов или удалить слой. Для включения
			редактирования рабочего слоя надо нажать на его названии.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485607"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Редактирование слоев</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для включения
			редактирования рабочего слоя надо нажать на
			его названии, при этом название слоя изменит оттенок. В режиме редактирования
			активируется блок с инструментами, а также дополнительные иконки на базовой
			карте, слева от панели слоев.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'>Развернуть
		метрику<span style='color:black'> – используется для разворота направления
			указанного участка канализации;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'>Сохранить
		изменения<span style='color:black'> – для сохранения произведенных изменений,
			данные сохраняются в базу данных;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'>Завершить
		редактирование<span style='color:black'> – для выхода из режима редактирования.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485608"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Инструменты</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>При включенном
			режиме редактирования рабочего слоя снизу
			появляется панель Инструментов с объектами, которые доступны в данном типе слоя
			и методом их добавления. Для типа слоев «Канализация» вы можете
			создавать/редактировать точечные объекты (колодцы и точки подключения) и
			линейные объекты (участки).</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485609"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Создание точечных объектов</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>При включенном
			режиме редактирования рабочего слоя следует
			выбрать тип объекта, который вы хотите нанести на карту (колодец или точка
			подключения) в панели Инструменты, после этого каждый клик по карте будет
			создавать новую точку. Если известны координаты расположения точки, их можно ввести
			в строку ниже (используются географические координаты в системе </span><span lang=EN-US
			style='color:black'>WGS</span><span style='color:black'>84, широта и
			долгота в градусах). Что бы отключить функцию добавления кликом, надо снять
			выделение с типа объекта в панели Инструменты.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Если есть
			список координат точек, то их можно нанести
			массивом, загрузив список координат. Для загрузки точек списком, следует нажать
			«Загрузить из файла» и указать соответствующий файл.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>После
			добавления/редактирования данных сохраняем внесенные
			данные кнопкой «Сохранить изменения». Для обновления отображаемых данных
			следует воспользоваться клавишей </span><span lang=EN-US style='color:black'>F</span><span
			style='color:black'>5 на клавиатуре или обновить страницу в браузере.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485610"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Список координат точек для загрузки</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В настоящий
			момент возможно использовать для загрузки
			массива точек список в формате </span><span lang=EN-US style='color:black'>CSV</span><span
			style='color:black'>, в качестве разделителей полей - запятые, при
			использовании кириллицы в названиях точек должна использоваться кодировка </span><span lang=EN-US
			style='color:black'>UTF</span><span style='color:black'>-8. Список
			должен содержать первую строку из названий столбцов, столбцы могут быть
			записаны в произвольном порядке. Ниже представлен образец такого списка:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span lang=EN-US
			style='color:black'>name,lon,lat,node_type_id</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span
			style='color:black'>Новогодняя_1,37.223221,56.738334,1</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span
			style='color:black'>Новогодняя_2,37.220976,56.738392,1</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span
			style='color:black'>Новогодняя_дом8,37.220891,56.738159,2</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span
			style='color:black'>Новогодняя_дом7,37.220593,56.738608,2</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Где:
		</span><span lang=EN-US style='color:black'>name</span><span style='color:black'> – название точки, </span><span
			lang=EN-US style='color:black'>lon</span><span lang=EN-US style='color:black'> </span><span
			style='color:black'>и </span><span lang=EN-US style='color:black'>lat</span><span lang=EN-US
			style='color:black'> </span><span style='color:black'>–
			географические долгота и широта в градусах, </span><span lang=EN-US style='color:black'>node</span><span
			style='color:black'>_</span><span lang=EN-US style='color:black'>type</span><span
			style='color:black'>_</span><span lang=EN-US style='color:black'>id</span><span lang=EN-US
			style='color:black'> </span><span style='color:black'>– тип точки (1 - колодец, 2 – точка
			подключения).</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для примера,
			вы можете подготовить подобный список в </span><span lang=EN-US style='color:black'>Excel</span><span
			style='color:black'>, либо
			подобном редакторе. Текущий файл следует открыть в </span><span lang=EN-US
			style='color:black'>OpenOffice</span><span lang=EN-US style='color:black'> </span><span lang=EN-US
			style='color:black'>Calc</span><span style='color:black'>, сохранить
			файл как в формате .</span><span lang=EN-US style='color:black'>CSV</span><span style='color:black'>,
			сохранить как текущий формат, кодировку выбрать Юникод (</span><span lang=EN-US
			style='color:black'>UTF</span><span style='color:black'>-8), в
			качестве разделителя указать запятые. Полученный файл можно загружать в
			систему.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>После
			добавления/редактирования данных сохраняем внесенные
			данные кнопкой «Сохранить изменения». Для обновления отображаемых данных
			следует воспользоваться клавишей </span><span lang=EN-US style='color:black'>F</span><span
			style='color:black'>5 на клавиатуре или обновить страницу в браузере.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485611"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Редактирование точечных объектов</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>При включенном
			режиме редактирования точки можно
			перемещать, редактировать атрибутивную информацию или удалить.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для
			перемещения точки, ее достаточно зажать указателем и
			перетащить в новое место на карте, если известны координаты расположения, тогда
			их можно указать в панели атрибутов и точка перенесется на указанное место.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для изменения
			имени, типа точки, а также дополнительных
			сведений и характеристик объекта следует воспользоваться панелью атрибутов,
			расположенной слева.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для удаления
			точечного объекта его следует выделить на
			карте и нажать клавишу </span><span lang=EN-US style='color:black'>DEL</span><span style='color:black'> на
			клавиатуре.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>После
			добавления/редактирования данных сохраняем внесенные
			данные кнопкой «Сохранить изменения». Для обновления отображаемых данных
			следует воспользоваться клавишей </span><span lang=EN-US style='color:black'>F</span><span
			style='color:black'>5 на клавиатуре или обновить страницу в браузере.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485612"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Создание участков</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>При включенном
			режиме редактирования рабочего слоя (для
			типа «Канализация») следует выбрать тип объекта – участок. Затем следует
			кликнуть на начальной точке и конечной, между этими точками будет создан
			участок трубопровода, с направлением течения по нему соответственно от начальной
			к конечной точке. Участки можно наносить только, используя предварительно
			сохраненные в данном слое точечные объекты. Для этого требуется нанести точки,
			сохранить изменения и потом наносить участки.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>После
			добавления/редактирования данных сохраняем внесенные
			данные кнопкой «Сохранить изменения». Для обновления отображаемых данных
			следует воспользоваться клавишей </span><span lang=EN-US style='color:black'>F</span><span
			style='color:black'>5 на клавиатуре или обновить страницу в браузере.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485613"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Редактирование участков</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>При включенном
			режиме редактирования для участков можно
			добавлять/удалять дополнительные вершины, изменить направление течения,
			редактировать атрибутивную информацию или удалить участок.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для добавления
			вершин (точек изгиба) следует выделить
			нужный участок, зажать клавишу </span><span lang=EN-US style='color:black'>ALT</span><span lang=EN-US
			style='color:black'> </span><span style='color:black'>на клавиатуре
			и указателем мыши перетащить курсор для обозначения новой вершины. Для удаления
			вершины следует использовать зажатые клавиши </span><span lang=EN-US style='color:black'>SHIFT</span><span
			style='color:black'>+</span><span lang=EN-US style='color:black'>ALT</span><span lang=EN-US
			style='color:black'> </span><span style='color:black'>на клавиатуре и кликнуть на удаляемой вершине.</span>
	</p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для изменения
			направления течения надо воспользоваться
			иконкой «Разворот метрики».</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для изменения
			названия участка, дополнительных сведений и
			характеристик объекта следует воспользоваться панелью атрибутов, расположенной
			слева.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для удаления
			участка его следует выделить на карте и нажать
			клавишу </span><span lang=EN-US style='color:black'>DEL</span><span lang=EN-US style='color:black'>
		</span><span style='color:black'>на клавиатуре.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>После
			добавления/редактирования данных сохраняем внесенные
			данные кнопкой «Сохранить изменения». Для обновления отображаемых данных
			следует воспользоваться клавишей </span><span lang=EN-US style='color:black'>F</span><span
			style='color:black'>5 на клавиатуре или обновить страницу в браузере.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485614"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Измерение расстояний</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В качестве
			вспомогательного инструмента предусмотрена
			функция измерения расстояний на карте.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для этой
			функции следует нажать на иконке «Измерение
			расстояний», первый клик на карте для начала замера, второй с следующие клики –
			для промежуточных точек, двойной клик для завершения измерения.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485615"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Сохранить текущий фрейм</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Функция
			«Сохранить текущий фрейм» предназначена для
			сохранения текущего отображения карты на дисплее. Последующий сеанс работы в
			системе будет продолжен с сохраненного фрейма.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485616"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Поиск объектов</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В системе
			предусмотрен инструмент поиска. Окно поиска
			находится в верхнем левом углу. Возможен поиск объектов пользователя по
			названию, географических названий и поиск по координатам с помощью сервиса </span><span lang=EN-US
			style='color:black'>OSM</span><span lang=EN-US style='color:black'> </span><span lang=EN-US
			style='color:black'>Nominatim</span><span style='color:black'>. Для
			поиска необходимо ввести требуемое название/наименование в окне поиска,
			результаты поиска будут представлены в выпадающих двух выпадающих списках – в
			базе данных и </span><span lang=EN-US style='color:black'>OSM</span><span lang=EN-US style='color:black'>
		</span><span lang=EN-US style='color:black'>Nominatim</span><span style='color:black'>. Клик по позиции из
			списка поиска для центрирования и
			масштабирования карты на найденном объекте.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485617"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Панель атрибутов объектов</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Каждому
			объекту картографической базы данных соответствует
			атрибутивная таблица и ссылки на медиафайлы и документы.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В панели
			атрибутов объектов представлена информация об
			объектах рабочих слоев, которая храниться в системе. Для каждого типа слоев
			доступен свой набор атрибутов. Для слоев типа «Канализация» доступны следующие
			атрибуты:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для колодцев и
			точек подключения:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Название
			точки</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Тип
			объекта</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span
			style='color:black'>Координаты</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Материал
			(колодца) – доступен выбор из раскрывающегося
			списка</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Отметка
			поверхности (в метрах)</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Глубина
			колодца (в метрах)</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span
			style='color:black'>Примечание</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для
			участков:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Название
			участка</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Начальный и
			конечный узел – проставляются автоматически из
			названий начального и конечного колодцев (точки подключения)</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Длина (в
			метрах)</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Облицовка
			(трубопровода) – доступен выбор из
			раскрывающегося списка</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Диаметр
			(ширина/высота в миллиметрах)</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Уклон</span>
	</p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Год
			прокладки</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Материал
			(трубопровода) - доступен выбор из раскрывающегося
			списка</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Использование
			(назначение трубы) - доступен выбор из раскрывающегося
			списка</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Форма (форма
			поперечного сечения) - доступен выбор из
			раскрывающегося списка</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Код
			расположения (описание поверхности над участком) -
			доступен выбор из раскрывающегося списка</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span
			style='color:black'>Примечание</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Атрибуты
			объектов можно вносить и изменять без включения
			режима редактирования. Обязательными атрибутами являются название, координаты и
			начальный и конечный узел для участков).</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В панели
			атрибутов есть вкладка Файлы – к каждому объекту
			можно прикрепить различные файлы: фотографии и видео обследований, протоколы
			осмотров и прочие документы.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Файлы можно
			загрузить кнопкой «Добавить файл», для
			просмотра файл потребуется скачать, удаление файла происходит кликом на
			соответствующей пиктограмме.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485618"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Список устройств телеметрии</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Система ИМУС
			имеет возможность подключения и просмотра
			информации датчиков с возможностью дистанционной передачи показаний (приборы
			учета, счетчики и т.п.).</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В списке
			устройств телеметрии отображены все доступные
			пользователю устройства и информация о них, в списке можно просмотреть показания
			устройства, изменить информацию об устройстве или удалить устройство.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485619"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Добавление устройства телеметрии</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для добавления
			устройства телеметрии можно использовать два
			варианта:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>1. Открыть
			список устройств телеметрии, нажать добавить
			прибор, в появившейся слева панели указать имя устройства, выбрать его тип из
			списка, указать координаты его расположения или в окне привязка указать объект
			на карте, где расположено устройство. Дополнительные поля для заполнения:
			минимальное и максимальное пороговые значения, для которых возможны
			уведомления, а также описание прибора;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>2. Выбрать
			объект на карте, в панели атрибутов объекта
			выбрать блок «Телеметрия» и добавить прибор. В этом случае устройство уже будет
			привязано к выбранному объекту, необходимо будет задать ему имя и тип.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В настоящее
			время используется ограниченный список типов
			устройств телеметрии и единиц измерения, эти данные могут быть расширены по
			заявке пользователя.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>После
			добавления устройства телеметрии для него
			генерируется токен. Токен необходим для настройки передачи данных с устройства
			в систему, токен можно просмотреть в списке устройств телеметрии и при
			необходимости скопировать его.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Передача
			показаний с устройства в систему происходит при
			обращении к API: </span><a href="https://back.imus.city/api/v1/measurement">https://back.imus.city/api/<span
				lang=EN-US>v</span>1/measurement</a><span style='color:black'>. Для
			формирования запроса необходимо указать ранее сгенерированный токен прибора,
			значение и время. Пример запроса будет выглядеть так:</span></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>const</span></i><i><span lang=EN-US> </span></i><i><span
				lang=EN-US>url</span></i><i> = </i><i><span lang=EN-US>new</span></i><i><span lang=EN-US>
			</span></i><i><span lang=EN-US>URL</span></i><i>(</i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i>    &quot;</i><i><span lang=EN-US>https</span></i><i>://</i><i><span
				lang=EN-US>back</span></i><i>.</i><i><span lang=EN-US>imus</span></i><i>.</i><i><span
				lang=EN-US>city</span></i><i>/</i><i><span lang=EN-US>api</span></i><i>/</i><i><span
				lang=EN-US>v</span></i><i>1/</i><i><span lang=EN-US>measurement</span></i><i>&quot;</i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>);</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US ng-bind-html>const headers = {{ '{' }}</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US> &quot;Content-Type&quot;:
				&quot;application/json&quot;,</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US ng-bind-html> &quot;Accept&quot;:
	&quot;application/json&quot;,</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>{{ '}' }};</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>let body = {{ '{' }}</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US> &quot;token&quot;: &quot; Va6Bpw2HsRCh
				&quot;,</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>    &quot;value&quot;: &quot;</span></i><span lang=EN-US> </span><i><span
				lang=EN-US>40.266220589139&quot;,</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>    &quot;date&quot;: &quot;2023-05-04
				14:55:44&quot;</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US ng-bind-html>{{ '}' }};</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US ng-bind-html>fetch(url, {{ '{' }}</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>    method: &quot;POST&quot;,</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>    headers,</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>    body: JSON.stringify(body),</span></i></p>

	<p class=MsoNormal style='margin-bottom:12.0pt;line-height:115%;text-autospace:
	ideograph-other;vertical-align:baseline'><i><span lang=EN-US ng-bind-html>{{ '}' }}).then(response
				=&gt; response.json());</span></i></p>

	<p class=MsoNormal style='text-indent:35.45pt;line-height:115%;text-autospace:
	ideograph-other;vertical-align:baseline'>Ответ на запрос<span lang=EN-US>:</span></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>{{ '{' }}</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>    &quot;status&quot;: &quot;ok&quot;,</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>    &quot;value&quot;: &quot;</span></i><span lang=EN-US> </span><i><span
				lang=EN-US>40.266220589139&quot;,</span></i></p>

	<p class=MsoNormal style='line-height:115%;text-autospace:ideograph-other;
	vertical-align:baseline'><i><span lang=EN-US>    &quot;date&quot;:
				&quot;2023-05-04 14:55:44&quot;</span></i></p>

	<p class=MsoNormal style='margin-bottom:12.0pt;line-height:115%;text-autospace:
	ideograph-other;vertical-align:baseline'><i><span lang=EN-US ng-bind-html>{{ '}' }}</span></i></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485620"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Список</span></a>
			<span style='font-size:14.0pt;font-family:"Calibri",sans-serif'> отчетов</span>
			<span style='font-size:14.0pt;font-family:"Calibri",sans-serif'> телеинспекций</span></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Система ИМУС
			имеет возможность импорта файлов отчетов
			инспектирования трубопроводов из робототехнических комплексов производства
			ТАРИС.  Данные отчетов могут сопоставляться с нанесёнными на карту участками
			трубопроводов и использоваться для анализа или правки объектов. Система ИМУС
			совместима с ПО «ТЕЛЕСКАН» производства ТАРИС для упрощения и автоматизации
			внесения данных об участках канализации оператором.  Система позволяет
			пользователю оценить состояние трубопровода отображая схему участка с
			расположением дефектов. Повреждения соответствуют тому стандарту, что
			использовался в загруженном отчете. Стандарты отчетов, возможные к
			распознаванию системой будут расширяться. На данный момент используется
			стандарт NASSCO PACP, как наиболее оптимальный для целей инспектирования,
			используемый в различных системах инспекции трубопроводов.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В списке
			отчетов телеинспекций отображены загруженные
			результаты инспекций. Пользователь может редактировать информацию о проекте
			(изменяя название проекта, данные организаций заказчика и исполнителя работ).
			Каждый проект содержит список обследованных участков, которые можно привязать к
			участкам на карте, посмотреть расположение на карте привязанного участка. Через
			список отчетов телеинспекций можно удалить все загруженные данные по проекту.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485621"></a><a name="_heading=h.hwseukmo8xpf"></a><a
			name="_heading=h.30j0zll"></a><span style='font-size:14.0pt;font-family:"Calibri",sans-serif'>Загрузка
			отчета
			телеинспекции</span></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для добавления
			отчета телеинспекции существует два
			варианта:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>1. Открыть
			список отчетов телеинспекций, нажать добавить
			отчет, выделить папку, содержащую данные отчета и нажать кнопку загрузить.
			Процесс загрузки файлов автоматический, прогресс загрузки будет отображен ниже.
			Если появятся дополнительные файлы, относящиеся к этому отчету, то процедуру
			загрузки следует провести заново, записи о новых данных будут внесены в
			систему. Система распределяет файлы автоматически и загружает их в следующем
			порядке: данные проекта, данные о секциях (участках трубопровода), данные
			наблюдений в секциях и медиа файлы наблюдений. После загрузки отчет будет
			доступен в списке отчетов телеинспекций.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>2. Выделить
			участок любого слоя на карте, в панели
			атрибутов в блоке нажать добавить отчет, выделить папку, содержащую данные
			отчета и нажать кнопку загрузить. Процесс загрузки файлов автоматический,
			прогресс загрузки будет отображен ниже. Если появятся дополнительные файлы,
			относящиеся к этому отчету, то процедуру загрузки следует провести заново,
			записи о новых данных будут внесены в систему. Система распределяет файлы
			автоматически и загружает их в следующем порядке: данные проекта, данные о
			секциях (участках трубопровода), данные наблюдений в секциях и медиа файлы
			наблюдений. После загрузки отчет будет доступен в списке отчетов телеинспекций.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485622"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Работа с отчетами телеинспекций</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В списке
			отчетов телеинспекций используются следующие
			инструменты:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Список секций
			– выводит информацию о содержащихся в отчете
			секциях и позволяет выполнить их привязку к участкам на карте,</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Показать на
			карте – показывает на карте расположение первой
			секции в отчете,</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Редактировать
			– можно изменить название проекта, данные о
			компаниях заказчика и подрядчика,</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Удалить –
			удалить все данные проекта.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>После загрузки
			отчета, в списке секций следует связать
			данные секции с участком на карте, с помощью кнопки привязать. Для этого
			требуется нажать кнопку привязать, далее выбрать участок на карте и нажать на
			его середину, в панели слева отобразится информация о привязке. После
			выполнения привязки можно будет посмотреть расположение данных на карте
			(используя показать на карте), а также данные инспекции будут доступны для
			участков в панели атрибутов в блоке телеинпекция.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Для просмотра
			отчета надо выбрать участок на карте, в
			панели атрибутов выбрать блок телеинспекция, далее выбрать отчет для просмотра.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В режиме
			просмотра отчета становятся активны две панели:</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Левая панель –
			на ней изображена вертикальная схема участка
			и наблюдения дефектов на ней в виде выносок, где содержится информация о
			дефекте;</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Нижняя панель
			– содержит общую информацию о проекте
			(название, сведения о заказчике/подрядчике, дополнительную информацию),
			информацию об участке и условиях инспекции, график уклона для данного участка,
			галерею медиафайлов.</span></p>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В левой
			панели, под вертикальной схемой отображается
			перечень участков в отчете, между которыми можно переключаться.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485623"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Личный кабинет</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>В настоящий
			момент в личном кабинете можно просмотреть
			данные своей учетной записи и поменять пароль для входа в систему. Через личный
			кабинет также можно выйти из системы по клавише «Выход»</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485624"><span style='font-size:
	14.0pt;font-family:"Calibri",sans-serif'>Информация</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>На странице
			информация представлено краткое описание
			системы ИМУС и форма обратной связи с разработчиками системы.</span></p>

	<h2 style='text-indent:0cm'><a name="_Toc142485625"><span lang=EN-US
				style='font-size:14.0pt;font-family:"Calibri",sans-serif'>API</span></a></h2>

	<p class=MsoNormal style='text-align:justify;text-indent:1.0cm;border:none'><span style='color:black'>Система ИМУС
			имеет широкий набор точек входа </span><span lang=EN-US style='color:black'>API</span><span
			style='color:black'>, что
			позволяет использовать ее совместно с различным оборудованием и программными
			средствами. По вопросам интеграции ПО и расширения </span><span lang=EN-US
			style='color:black'>API</span><span style='color:black'> следует связаться с
			разработчиками системы.</span></p>

</div>