import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';

import { AlertService } from '@shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';


@Component({ selector: 'alert', templateUrl: 'alert.component.html' })
export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message: any;

  constructor(
    private alertService: AlertService,
    private readonly _destroy$: ImusDestroyService,
    ) { }

  ngOnInit() {
    // this.subscription = 
    this.alertService.getAlert()
      .pipe(takeUntil(this._destroy$))
      .subscribe(message => {
        switch (message && message.type) {
          case 'success':
            message.cssClass = 'alert alert-success';
            break;
          case 'error':
            message.cssClass = 'alert alert-danger';
            break;
        }

        this.message = message;
      });
    // setTimeout(()=>this.subscription.unsubscribe(),3000);
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }
}
