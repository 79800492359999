import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailConfirmationModalComponent } from '@app/modules/account/components/email-confirmation-modal/email-confirmation-modal.component';
import { AuthenticationService } from '@app/shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { takeUntil, tap } from 'rxjs';

@Component({
  templateUrl: './page-verify-registration.component.html',
  styleUrls: ['./page-verify-registration.component.scss']
})
export class PageVerifyRegistrationComponent implements OnInit {


  constructor(
    public matDialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private readonly _destroy$: ImusDestroyService,
) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
    .pipe(
        tap((queryParam) => {
            // console.log('queryParam', queryParam)
            if (queryParam['id'] && queryParam['token']) {
              // this.verifyEmail(queryParam['email_verify_url'])
              this.authenticationService.verifyEmail(Number.parseInt(queryParam['id']), queryParam['token'])
                .pipe(
                // tap(data => console.log('data', data))
                takeUntil(this._destroy$)
                )
                .subscribe(data => {
                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.disableClose = true;
                  dialogConfig.id = 'modal-email-confirmation-component';
                  // dialogConfig.data = ''
            
                  this.matDialog.open(EmailConfirmationModalComponent, dialogConfig);
            
                  this.router.navigate(['/'])
                })
            }
        }),
        takeUntil(this._destroy$)
    )
    .subscribe();
  }

  verifyEmail(emailVerifyUrl: string) {
    let i = emailVerifyUrl.indexOf('verify/') + 7
    let j = emailVerifyUrl.indexOf('/', i);

    let id = Number.parseInt(emailVerifyUrl.substring(i, j))
    let hash = emailVerifyUrl.substring(j+1)

    this.authenticationService.verifyEmail(id, hash).pipe(
      // tap(data => console.log('data', data))
      takeUntil(this._destroy$)
    )
    .subscribe(data => {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-email-confirmation-component';
      // dialogConfig.data = ''

      this.matDialog.open(EmailConfirmationModalComponent, dialogConfig);

      this.router.navigate(['/'])
        // console.log(data)
        // if ((<currentUser>data).user.email_verified_at !== null) this.router.navigate(['/'])
        // else this.router.navigate(['/email-confirmation'], { state: {user: (<currentUser>data).user, from: 'login'} })
        // this.router.navigate(['/email-confirmation'], { state: {user: user} });

    })

  }


}
