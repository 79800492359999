import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

/**
 * Проверка валидности Email
 *
 * Шаблон записи следующий: имя@субдомен.домен.
 * Допустимые символы для ввода "A-Z", "a-z", "а-я", "А-Я", "0-9", ".", "_", "@", "-"
 * Недопустимо одновременное использование русских и латинских букв в субдомене и домене.
 * Недопустимо одновременное использование русских и латинских букв в имени.
 * Ограничение количества символов «имя» от одного и более
 * Ограничение количества символов «субдомен» от одного и более
 * Ограничение количества символов «домен» от двух и более.
 * Обязательное наличие в адресе ровно одного символа «@»
 * Обязательное наличие хотя бы одного символа «.»
 * Символ «.» не может являться первым и последним символом имени/домена/субдомена.
 */
const emailReqExp = /^(([A-Z0-9_+-]+(\.[A-Z0-9_+-]+)*)|([А-Я0-9_+-]+(\.[А-Я0-9_+-]+)*))@((([A-Z0-9_-]+\.)+[A-Z0-9_-]{2,})|(([А-Я0-9_-]+\.)+[А-Я0-9_-]{2,}))$/i;

export const imusEmailValidator: () => ValidatorFn = () =>
	(control: AbstractControl): ValidationErrors | null =>
		control.value && !emailReqExp.test(control.value)
			? { email: 'Проверьте адрес e-mail' }
			: null;

/**
 * Валидатор Email
 */
@Directive({
	selector: '[imusEmail]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: ImusEmailValidatorDirective,
		multi: true
	}]
})
export class ImusEmailValidatorDirective implements Validator {

	@Input('imusEmail')
	private _email: boolean | '';

	validate(control: AbstractControl): ValidationErrors | null {
		return this._email === '' || !!this._email
			? imusEmailValidator()(control)
			: null;
	}
}
