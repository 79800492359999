import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Feature } from 'ol';
import { SourceVectorComponent } from './sources/vector.component';
import { Style } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';
import { Geometry } from 'ol/geom';
import { GeometryCollection } from 'ol/geom';
import { ColorLike } from 'ol/colorlike';



@Component({
  selector: 'aol-feature',
  template: ` <ng-content></ng-content> `,
})
export class FeatureComponent implements OnInit, OnDestroy, OnChanges {
  public componentType = 'feature';
  public instance: Feature;

  @Input()
  id: string | number | undefined;
  @Input()
  style: Style | Style[] | StyleFunction;
  @Input()
  geometry: Geometry; //| GeometryCollection
  @Input()
  color: ColorLike | undefined;


  constructor(private host: SourceVectorComponent) {}

  ngOnInit() {
    this.instance = new Feature();
    if (this.geometry !== undefined) this.instance.setGeometry(this.geometry);
    if (this.id !== undefined) {
      this.instance.setProperties({id : this.id})
      // this.instance.setId(this.id);
    }
    if (this.style !== undefined) {
      this.instance.setStyle(this.style)
    }
    if (this.color !== undefined) this.instance.setProperties({color : this.color})

    this.host.instance.addFeature(this.instance);
  }

  ngOnDestroy() {
    this.host.instance.removeFeature(this.instance);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.instance) {
      this.instance.setProperties({id : this.id})
      // this.instance.setStyle()
      if (this.style !== undefined) {
        this.instance.setStyle(this.style)
      }
      // this.instance.setId(this.id);
    }
  }
}
