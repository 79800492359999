import { Component } from '@angular/core';

@Component({
  selector: 'app-page-access-denied',
  templateUrl: './page-access-denied.component.html',
  styleUrls: ['./page-access-denied.component.scss']
})
export class PageAccessDeniedComponent {

}
