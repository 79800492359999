<ng-container [ngSwitch]="isEditing$ | async">
  <div [ngClass]="{'hide-buttons': !showButton, 'imus-row': true}">
    <ng-container *ngSwitchCase="false">
      <ng-content select=".content"></ng-content>
      <app-button *ngIf="showButton" (onclick)="openEditor()" icon="edit" [type]="buttonType.icon"></app-button>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-content select=".editor"></ng-content>
      <app-button title="Закрыть" (onclick)="closeEditor()" icon="close" [type]="buttonType.icon"></app-button>
    </ng-container>
  </div>
</ng-container>