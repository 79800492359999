<form [formGroup]="form">
    <div class="user-profile_content g30">
        <div class="user-profile_form">
            <mat-form-field>
                <mat-label>Роль</mat-label>
                <mat-select formControlName="account_role_id" required>
                    <mat-option *ngFor="let roles of (sysRolesList$ | async)" [value]="roles.id">{{roles.descr}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Должность</mat-label>
                <input formControlName="account_detail_job_post" matInput placeholder="Должность">
            </mat-form-field>

            <mat-checkbox formControlName="account_is_primary">
                Контактное лицо
            </mat-checkbox>
        </div>

        <div class="user-profile_form">
            <mat-form-field>
                <mat-label>Дата подключения</mat-label>
                <input readonly formControlName="account_start_date" matInput [matDatepicker]="picker">
                <mat-datepicker-toggle disabled matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Дата отключения</mat-label>
                <input formControlName="account_end_date" matInput [matDatepicker]="pickerEnd">
                <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <mat-divider></mat-divider>

    <h3 class="center">Право подписи</h3>

    <div class="user-profile_content g30">
        <div class="user-profile_form">
            <mat-checkbox formControlName="account_detail_is_signer">
                Имеет право подписи
            </mat-checkbox>

            <div class="radio">
                <label>Действует на основании</label>
                <mat-radio-group class="radio-group" formControlName="account_detail_is_proxy">
                    <mat-radio-button *ngFor="let signer of signer" [value]="signer.value">
                        {{ signer.title }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <app-button [type]="buttonType.primaryButton" (click)="submit()" [label]="'Сохранить'" [fullWidth]="true" />
        </div>

        <div class="user-profile_form">
            <mat-form-field>
                <mat-label>Номер доверенности</mat-label>
                <input formControlName="account_detail_proxy_doc_number" matInput placeholder="Номер доверенности">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Дата доверенности</mat-label>
                <input formControlName="account_detail_proxy_doc_date" matInput [matDatepicker]="pickerDoc">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDoc"></mat-datepicker-toggle>
                <mat-datepicker #pickerDoc></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Должность в родительном падеже</mat-label>
                <input formControlName="account_detail_job_post_gen" matInput placeholder="Должность в родительном падеже">
            </mat-form-field>
        </div>
    </div>
</form>
