import { HttpParams } from "@angular/common/http"
import { environment } from "@environments/environment";

export namespace HttpParamsUtility {
    export function objectToParams(obj): HttpParams {
        let params = new HttpParams({ fromObject: obj});        
        return params;
    }
}

export namespace HttpSettings{   
    // export const apiHost = "https://backend.imus.city/api/v2/";    
    export const apiHost = `${environment.apiUrl}`;    
}