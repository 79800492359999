<div class="component-area">
    <app-account-header [userName]="'UserName'" [userType]="'Профессиональный аккаунт'" [buttonLabel]="'Дочерний аккаунт'" (accountButtonClick)="onChildAccountClick()"></app-account-header>

    <div class="inputs-area">

        <div class="left-buttons">
            <app-button [label]="'Главная'" [fullWidth]="true" (onclick)="onMainPageClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Настройки'" [fullWidth]="true" (onclick)="onAccountSettingsClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Информация'" [fullWidth]="true" (onclick)="onAccountInformationClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Выход'" [fullWidth]="true" (click)="authenticationService.logout()"></app-button>
            <div class="separator"></div>
        </div>

        <div class="account-inputs">

            <form [formGroup]="account.form">
                <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.email" imusRequired imusEmail [placeholder]="'E-mail'" formControlName="email"></app-input>
                    <field-errors formFieldName="email"></field-errors>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div>
                <div class="separator"></div>

                <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.tel" [placeholder]="'Phone'" formControlName="phone"></app-input>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div>
                <div class="separator"></div>

                <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.password" imusRequired [placeholder]="'Password'" formControlName="password"></app-input>
                    <field-errors formFieldName="password"></field-errors>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div>
                <div class="separator"></div>

                <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.number" [icon]="'edit_note'" formControlName="inn"></app-input>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div>
                <div class="separator"></div>
            </form>

            <app-text-separator [text]="'Дочерние аккаунты'"></app-text-separator>

            <div class="inputs-grid2">
                <app-input class="input-cell" [type]="inputType.text" [placeholder]="'Login'" [useDefaultIcon]="false"></app-input>
                <app-input class="input-cell2" [type]="inputType.text" [placeholder]="'Password'" [useDefaultIcon]="false"></app-input>
                <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                <app-button class="button-cell2" [type]="buttonType.icon" [icon]="'settings'"></app-button>
            </div>
            <div class="separator"></div>

            <app-text-separator [text]="'Подписки'"></app-text-separator>
            <div class="inputs-grid">
                <app-input class="input-cell" [label]="'Глобальный слой'" [placeholder]="'Глобальный слой'"></app-input>
                <app-button class="button-cell" [type]="buttonType.icon" [icon]="'settings'"></app-button>
            </div>

        </div>

    </div>
</div>
