import { Component } from '@angular/core';

@Component({
    selector: 'app-property-title',
    template: `<span class="imus-row-item"><ng-content></ng-content></span>`,
    host: {
        class: 'imus-row title'
    },
    styles: [`
      :host {
        span {
            white-space: nowrap;
            width: auto;
        }
        &:after {
          content: " ";
          width: 100%;
          margin: 0 15px;
          border: 1px solid #4A4A4A;
          opacity: 0.3;
        }
      }
    `]
})
export class PropertyTitleComponent {}
