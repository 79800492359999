import { ChangeDetectionStrategy, Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { AccountService } from "@app/modules/account/services/account.service";
import { map, takeUntil, tap } from "rxjs";
import { PROFILE_ROUTES } from "@app/modules/account/pages/profile/models/profile";
import { MatExpansionModule } from "@angular/material/expansion";
import { PortalModule } from "@angular/cdk/portal";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { ContractorService } from '@app/modules/account/services/contractor';

@Component({
    selector: 'app-profile',
    standalone: true,
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    imports: [
        AsyncPipe,
        NgForOf,
        MatExpansionModule,
        PortalModule,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
        NgIf
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit{

    private readonly destroy$ = inject(ImusDestroyService);
    private readonly accountService= inject(AccountService); 
    private readonly contractorService= inject(ContractorService); 
    public readonly routes$ = this.accountService.account$.pipe(
        map(account => PROFILE_ROUTES.filter(route => route.complianceContractor
            .includes(account.contractor_type_id))
            .sort((a, b) => a.order - b.order))
    )

    constructor(
    ) {
    }
    ngOnInit(): void {
        this.accountService.account$
            .pipe(
                tap(account => this.contractorService.contractorForGuests$.next({
                        id: account.contractor_id,
                        component: 'profile'
                    })),
                takeUntil(this.destroy$)
            )
            .subscribe()
    }

}
