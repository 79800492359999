import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaFilesUtility } from '@app/shared/utilities/media-files-utility';

@Component({
  selector: 'app-v-player',
  templateUrl: './v-player.component.html',
  styleUrls: ['./v-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VPlayerComponent {

  @Input() src: string;
  @Input() poster: string; 


  get mediaType() {
    return MediaFilesUtility.getMediaType(this.src);
  }  
}
