// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    LOG_LEVEL: 'DEBUG',
    // apiUrl: 'http://127.0.0.1:8000',
    // apiUrl: 'https://imus.city:8080',
    // apiUrl: 'https://backend.imus.city/api',
    apiUrl: 'https://backend.imus.city/api/v2',
    // apiUrl: 'https://imus.back/api',
    // mapbox: {
    //     accessToken: 'pk.eyJ1IjoiZGltYWd1ZGtvdiIsImEiOiJjanAwNXVvN2gyc2JoM21ucnNhdmFobm9hIn0.fxJ2-ihm6ij4slQCh7Nymg'
    // },
    storage: {
        s3: {
            endpoint: 'https://storage.yandexcloud.net',
            accessKeyId: 'AztK5rGCgXRbqKPnTLnF',
            secretAccessKey: '_QWkz4HxsMhlw98ZV3CYiUfhY6Tp2qWOfuuNHfW0',
            region: 'us-east-1'
        },
        bucket: 'imus-dev'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
