import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfessionalAccountComponent } from './pages/professional-account/professional-account.component';
import { AccountComponent } from './pages/account/account.component';
import { ChildAccountComponent } from './pages/child-account/child-account.component';
import { AccountInformationComponent } from './pages/account-information/account-information.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';

//TODO REMOVE
const titlePrefix: string = 'Тарис';

const routes: Routes = [
  {
    path: '',
    // pathMatch: 'full',
    // title: `${titlePrefix}-Простой аккаунт`,
    component: AccountComponent,
  },
  {
    path: 'professional',
    component: ProfessionalAccountComponent,
    // title: `${titlePrefix}-Профессиональный аккаунт`,
  },
  {
    path: 'child',
    // title: `${titlePrefix}-Дочерний аккаунт`,
    component: ChildAccountComponent,
  },
  {
    path: 'information',
    // title: `${titlePrefix}-Информация о пользователе`,
    component: AccountInformationComponent,
  },
  // {
  //   path: 'settings',
  //   // title: `${titlePrefix}-Настройка пользователя`,
  //   component: AccountSettingsComponent,
  // },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
