<!-- <button mat-button [matMenuTriggerFor]="devMenu">Menu</button> -->

<!-- <button mat-mini-fab color="primary" [matMenuTriggerFor]="devMenu" aria-label="Developers menu">
    <mat-icon>menu</mat-icon>
  </button> -->

<!-- <mat-menu #devMenu="matMenu" panelClass="dev-menu">
    <button mat-menu-item>Item 1</button>
    <button mat-menu-item>Item 2</button>
</mat-menu> -->

<button mat-flat-button color="warn" [routerLink]="['/', 'dev']">DEV MENU</button>