import { Injectable } from '@angular/core';
import { filter, map, Observable, shareReplay, switchMap } from "rxjs";
import {UserAccountsInfo, UserAccountsPage} from "@shared/models";
import { AuthenticationService, UserService } from "@shared/services";

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    public readonly account$: Observable<UserAccountsInfo> = this.authenticationService.currentUser$.pipe(
        filter(currUser => !!currUser),
        switchMap(res => this.userService.getUserAccountInfoByUserId(res.user.id).pipe(
            map((userAccount) => (userAccount.data as UserAccountsPage).data.find(a => a.user_id === res.user.id))
        )),
        shareReplay({ refCount: true, bufferSize: 1 })
    )
    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UserService
    ) {
    }
}
