import { Injectable, signal } from '@angular/core';
import { LayerService, LayersStateService } from '@app/shared/services';
import { MainPageService } from '@app/modules/main/services/main-page.service';
import {
    Observable,
    catchError,
    of,
    tap,
    Subscriber,
    EMPTY,
} from 'rxjs';
import { LayerTypeIDs, NewLayerType } from '@shared/models/layer-types';
import { Layer, NewLayerResponse } from '@app/shared/models';
import { fromLonLat } from 'ol/proj';
import { Extent } from 'ol/extent';
import { RasterImageData } from './rastr-bind.service';

@Injectable({
    providedIn: 'root',
})
export class LayerAddFormService {
    constructor(
        private layerService: LayerService,
        public layerStateService: LayersStateService,
        // private layerEditService: LayerEditService,
        private pageService: MainPageService
    ) {
        // this.layerService.allLayerTypes$.subscribe(types => {
        //     this.layerTypes = Array.from(types, ([_, type]) => type).filter(
        //         type => !!type.parent_id && [LayerTypeIDs.CANALISATION_CHILD, LayerTypeIDs.WATERPIPES_CHILD].includes(type.id)
        //     );
        //     this.newLayerType = this.layerTypes[0];
        //     // this.newLayerTypeId.set(this.layerTypes[0]?.id ?? LayerTypeIDs.CANALISATION_CHILD);
        // })
    }

    public newLayerName: string = '';
    public newLayerCc: string = '';

    public newLayerType: NewLayerType;
    // public readonly newLayerTypeId = signal(LayerTypeIDs.CANALISATION_CHILD);
    public geoJSON: string = '';

    public imageFile: File;
    public posFile: File;
    public newLayer: any;
    public w: number = 0;
    public h: number = 0;
    public format: string | any = 'jpgw';
    public data: any[] | Extent = [0, 0, 0, 0];

    //TODO вечерний быдлокод
    public layerTypes: NewLayerType[] = [];

    clearData() {
        this.newLayerName = '';
        this.data = [0, 0, 0, 0];
        this.imageFile = undefined;
        this.posFile = undefined;
        this.newLayer = undefined;
        this.w = 0;
        this.h = 0;
    }

    public addBaseLayer (layerType = this.newLayerType, layerName: string = this.newLayerName, geoJSON: string = this.geoJSON): Observable<NewLayerResponse | never> {
        if (!layerName) return EMPTY;
        return this.addNewLayer(layerType.id, layerType.name, layerName, undefined, layerType.id === LayerTypeIDs.GEOJSON && this.geoJSON ? this.geoJSON : undefined);
    }

    public addNewLayer(
        layerTypeId: number,
        layerTypeName: string,
        layerName: string,
        order?: number,
        geoJSON?: string
    ): Observable<NewLayerResponse> {

        return this.layerService.add(
            {
                name: layerName,
                layer_type: {
                    id: layerTypeId,
                    name: layerTypeName,
                },
                order: order ?? Math.floor(Math.random() * 99) + 1,
                geojson: geoJSON ?? undefined,
                format: geoJSON ? 'geojson' : null,
            } as Layer
        ).pipe(
            /** TODO: зачем смотрим на свойство status при статусе 200?? */
            tap((response) => {
                if (response.status !== 'ok') {
                    throw new Error(
                        `Ошибка добавления слоя, статус:${response.status}`
                    );
                }
            })
        )

        //Реализация добавления слоев через массив newLayers
        // this.changeObjects.newLayers.push(layer)
        // this.save()
    }

    /**  */
    public uploadRastrImageFile(file: File): Observable<any> | any {
        if (!file) {
            return of(undefined);
        }

        this.imageFile = file;

        return this.layerService.addRastr(file.name, file).pipe(
            tap((newLayer) => {
                this.newLayer = newLayer;
                this.newLayer.name = file.name
                this.layerService.updateLayerInfo(newLayer.layer_id) //.changed$.emit();
            }),
            catchError(() => of(undefined))
        );
    }

    public uploadRastrPosFile(file: File): Observable<File> {
        if (!file) {
            return of(undefined);
        }
        this.posFile = file;
        return of(file);

        const formData = new FormData();
        formData.append('rastr', file, file.name);
        return this.layerService.addFile(this.newLayer.layer_id, formData).pipe(
            tap((newLayer) => {
                // this.updateLayerData();
                // this.newLayer.layer_id
                this.layerService.updateLayerInfo(this.newLayer.layer_id)//.changed$.emit();
                this.pageService.layerAddSetState(true);
                // this.newLayer = newLayer
                // this.layerService.changed$.emit();
            }),
            catchError(() => of(undefined))
        );
    }

    readPos(files: File[]): Observable<Extent> {
        const fileReader = new FileReader();
        fileReader.readAsText(files[0]);
        return new Observable((observer: Subscriber<Extent>): void => {
            // if success
            fileReader.onload = (event: ProgressEvent): void => {

                let lines = (fileReader.result as string).split('\n').map((line) => {
                    return parseFloat(line.replace(/(?:\\[rn]|[\r\n]+)+/g, ''));
                })

                this.data = this.jpgwToExtent(lines);
                observer.next(this.data);
                observer.complete();
            };
            fileReader.onerror = (error: ProgressEvent<FileReader>) => observer.error(error);
        });
    }

    readJSON(files: File[]): Observable<string> {
        const fileReader = new FileReader();
        fileReader.readAsText(files[0]);
        return new Observable((observer: Subscriber<string>): void => {
            // if success
            fileReader.onload = (event: ProgressEvent): void => {
                // this.data = JSON.parse(fileReader.result as string);
                observer.next(fileReader.result as string);
                observer.complete();
            };
            fileReader.onerror = (error: any): void => {
                observer.error(error);
            };
        });
    }

    public updateLayerData(layer?: NewLayerResponse, data = this.data) {
        // let newLayer = new Layer
        // let tempLayer: NewLayerResponse


        if (!layer) layer = this.newLayer
        if (!layer.name) {
            layer.name = this.newLayer.name ? this.newLayer.name : this.newLayerName
        }
        // if (!layer) {
        //     tempLayer = this.newLayer;
        // } else {
        //     tempLayer = layer;
        // }

        // newLayer.ID = tempLayer.layer_id
        // newLayer.user_id = tempLayer.user_id
        // newLayer.name = this.newLayerName
        // newLayer.color= this.newLayer.color

        // const newLayerTypeID = parseInt(this.newLayer.layer_type_id)
        // const layerTypeID = Object.keys(LayerTypeIDs).filter(value => LayerTypeIDs[value] == newLayerTypeID)[0]
        // const layerType = Object.keys(LayerTypes).filter(value => LayerTypes[value] == layerTypeID)[0]
        // newLayer.layer_type= {
        //     id: newLayerTypeID,
        //     name: LayerTypes[layerType]
        // }

        // newLayer.order = layer.order ? layer.order : Math.floor(Math.random() * 99) + 1
        // newLayer.data = this.data
        // newLayer.format = 'jpgw'
        // newLayer.w = this.w;
        // newLayer.h = this.h;


        // console.log('updateLayerData',layer)


        return this.layerService.editPut(
            {
                ...layer,
                w: this.w,
                h: this.h,
                layer_type_id: layer.layer_type_id,
                // data: this.data.map(item => item.toString()),
                data: data,
                // order = layer.order ? layer.order : Math.floor(Math.random() * 99) + 1;
                format: 'jpgw',
                status: undefined,
                // layer_id: undefined
            })
            .subscribe((newLayer) => {
                const layerId = this.newLayer.layer_id
                this.newLayer = newLayer;
                this.layerService.updateLayerInfo(layerId)//.changed$.emit();
                this.pageService.layerAddSetState(true);
            });
    }

    public jpgwToExtent(data: any[]) {
        // console.log('data', data);
        let l1 = [data[4], data[5]];
        // console.log('l1', l1);
        let ll1 = fromLonLat(l1);
        // console.log('ll1', ll1);
        // let l2 = [data[4] + data[0] * this.w, data[5] - Math.abs(data[3]) * this.h];
        let l2 = [data[4] + data[0] * this.w, data[5] + data[3] * this.h];
        // let l2 = [data[4] + data[0] * this.w, data[5] - data[0] * this.h];
        // console.log('l2', l2);
        let ll2 = fromLonLat(l2);
        // console.log('ll2', ll2);
        let extent: Extent = [ll1[0], ll2[1], ll2[0], ll1[1]];
        return extent;
    }

    readImage(files: HTMLInputElement | File[]): Observable<RasterImageData> {
        let imageData: RasterImageData;
        const fileReader = new FileReader();
        fileReader.readAsDataURL((files as HTMLInputElement)[0]);
        // return Observable.create((observer: Subscriber<any[]>): void => {
        return new Observable((observer: Subscriber<RasterImageData>): void => {
            // if success
            fileReader.onload = (event: ProgressEvent): void => {
                const img = new Image();
                img.onload = () => {
                    this.w = img.width;
                    this.h = img.height;
                    imageData = {
                        w: this.w,
                        h: this.h,
                        src: img.src,
                    };
                    observer.next(imageData);
                    observer.complete();
                };
                const result = (<any>event.target).result;
                img.src = result as string;
            };
            fileReader.onerror = (error: any): void => {
                observer.error(error);
            };
        });
    }
}
