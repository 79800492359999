import { Injectable } from '@angular/core';
import { UserService } from '@app/shared/services';
import { GeocodingService } from './geocoding.service';
import {
    BehaviorSubject,
    Observable,
    Subscription,
    map,
    timer,
    switchMap,
    of,
    takeWhile,
    shareReplay,
    takeUntil,
    Subject,
} from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { NominatimResponse } from '../models/nominatim-response.model';
import { MapService } from './map.service';

@Injectable({
    providedIn: 'root',
})
export class MapSearchService {
    private inProgress = new BehaviorSubject<boolean>(false);
    public inProgress$ = this.inProgress.asObservable();

    private coordsResults = new BehaviorSubject<any[]>([]);
    public coordsResults$ = this.coordsResults.asObservable();

    private searchResults = new BehaviorSubject<NominatimResponse[]>([]);
    public searchResults$ = this.searchResults.asObservable();

    private localSearchResults = new BehaviorSubject<any[]>([]);
    public localSearchResults$ = this.localSearchResults.asObservable();

    // public searchTypeCoords: boolean = false;
    public searchTypeCoords = new BehaviorSubject<string>('base');
    public searchTypeCoords$ = this.searchTypeCoords.asObservable();

    public searchQuery = new BehaviorSubject<string>('');
    public searchQuery$ = this.searchQuery.asObservable();

    public highlightedPlot = new BehaviorSubject<any>(undefined);
    public highlightedPlot$ = this.highlightedPlot.asObservable();

    public highlightedNode = new BehaviorSubject<any>(undefined);
    public highlightedNode$ = this.highlightedNode.asObservable();

    constructor(
        private geocodingService: GeocodingService,
        private userService: UserService
    ) {}

    public checkIfValidlatitudeAndlongitude(str: string) {
        // Regular expression to check if string is a latitude and longitude
        const regexExp =
            /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
        return regexExp.test(str);
    }

    public search(query: string) {
        if (this.checkIfValidlatitudeAndlongitude(query)) {
            this.searchTypeCoords.next('coords');
            this.geocodingService.latLonResult(query).subscribe((results) => {
                this.coordsResults.next(results);
            });
        } else {
            this.searchTypeCoords.next('base');
            // this.searchInProgress = true;
            this.inProgress.next(true);
            this.addressLookup(query);
            this.localSearch(query);
        }
    }

    addressLookup(address: string) {
        this.geocodingService.addressLookup(address).subscribe((results) => {
            // console.log('addressLookup', results);
            this.searchResults.next(results);
            this.inProgress.next(false);
        });
    }

    localSearch(query: string) {
        this.userService.search(query).subscribe((results: any[]) => {
            // console.log('localSearch results', results);
            this.localSearchResults.next(results);
            this.inProgress.next(false);
        });
    }

    public clearResults() {
        this.coordsResults.next([]);
        this.searchResults.next([]);
        this.localSearchResults.next([]);
    }
}
