import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule, Sort } from "@angular/material/sort";
import { SharedModule } from "@shared/shared.module";
import { PageEvent } from "@angular/material/paginator";
import { AccountsService } from "@app/modules/account/services/accounts.service";
import { AccountService } from "@app/modules/account/services/account.service";
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    map,
    of,
    ReplaySubject,
    shareReplay,
    startWith,
    switchMap,
    tap
} from "rxjs";
import { UserService } from "@shared/services";
import { ButtonComponentSettings } from "@shared/components/button/button.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {
    EmployeeEditDialogComponent
} from "@app/modules/account/pages/profile/components/employees/components/employee-edit.dialog/employee-edit.dialog.component";
import { EmployeeAccount } from "@shared/models/contractors";
import {
    EmployeeCreateDialogComponent
} from "@app/modules/account/pages/profile/components/employees/components/employee-create.dialog/employee-create.dialog.component";
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';

/** Сотрудники */
@Component({
    selector: 'app-employees',
    standalone: true,
    imports: [CommonModule, MatProgressBarModule, MatTableModule, MatSortModule, SharedModule, RouterOutlet, RouterLink],
    templateUrl: './contractor-employees.component.html',
    styleUrls: ['./contractor-employees.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractorEmployeesComponent implements OnInit {
    private readonly _dialog = inject(MatDialog);
    private readonly _accountService = inject(AccountService);
    private readonly _accountsService = inject(AccountsService);
    private readonly _refresh = new EventEmitter<void>();
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private readonly _sortSettings$ = new BehaviorSubject<Sort | null>(null);
    public readonly pageSettings$ = new ReplaySubject<{ pageIndex: number, pageSize: number }>(1);
    public isLoaded = false;
    public totalItems = 0;
    public pageSize = 15;
    public pageIndex = 0;
    public displayed$ = new BehaviorSubject([
        { name: 'is_primary', title: 'Контактное лицо' },
        { name: 'user_name', title: 'ФИО' },
        { name: 'job_post ', title: 'Должность' },
        { name: 'user_phone', title: 'Телефон' },
        { name: 'user_email', title: 'Email' },
        { name: 'is_signer', title: 'Право подписи' },
        { name: 'role_descr', title: 'Роль в системе' },
        { name: 'start_date', title: 'Дата подключения' },
        { name: 'end_date', title: 'Дата отключения' },
        { name: 'actions', title: 'Действия' }
    ]);
    public displayedColumns$ = this.displayed$.pipe(
        map(items => items.map(o => o.name))
    );
    
    public readonly contractorId = this.route.parent.snapshot.params['id']

    public readonly employees$ = this._accountService.account$.pipe(
        switchMap(acc => combineLatest([
            this.pageSettings$,
            this._sortSettings$.pipe(
                map(sortSettings => {
                    if (!sortSettings) {
                        return {}
                    }
                    const key = `orderBy[${sortSettings.active}]`
                    return { [key]: sortSettings.direction }
                })
            ),
            this._refresh.pipe(
                startWith(undefined)
            )]).pipe(
            switchMap(([pageSettings, sortSettings]) => this._accountsService.getAccounts(Object.assign(
                {},
                {
                    // 'where[contractor_id]': acc.contractor_id,
                    'where[contractor_id]': this.contractorId,
                    'where[is_main]': true,
                    'per_page': pageSettings.pageSize,
                    page: pageSettings.pageIndex
                },
                sortSettings
            ))),
            catchError(() => of(null))
        )),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public readonly contractorsAccess$ = inject(UserService).contractorsAccess$;
    public readonly onlyRead$ = this.contractorsAccess$.pipe(
        map(res => res.data[0].can_read && !res.data[0].can_update),
        tap(bol => {
            if (bol) {
                this.displayed$.next(this.displayed$.getValue().filter(item => item.name !== 'actions'))
            }
        })
    );

    ngOnInit(): void {

        console.log('contractor-employees.component ngOnInit');

        this.pageSettings$.next({
            pageSize: 15,
            pageIndex: 1
        });
        this._refresh.emit();
    }

    public sort(event: Sort) {
        this._sortSettings$.next(event.direction ? event : null);
    }

    public onPaginateData(event: PageEvent) {
        this.pageSettings$.next({
            pageSize: event.pageSize,
            pageIndex: event.pageIndex + 1
        })
    }

    public addEmployee() {
        const dialog = new MatDialogConfig();
        dialog.width = '480px';

        this._dialog
            .open(EmployeeCreateDialogComponent, dialog)
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this._refresh.emit()
                }
            })
    }

    public actionEdit(item: EmployeeAccount) {
        const dialog = new MatDialogConfig();
        dialog.width = '700px';
        dialog.data = item;

        this._dialog
            .open(EmployeeEditDialogComponent, dialog)
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this._refresh.emit()
                }
            })
    }
}
