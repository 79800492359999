import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { HttpSettings } from '@app/shared/utilities/http-utility';
import { takeUntil } from 'rxjs';
import { MeasurementTypes } from 'src/app/shared/models/measurement';

@Component({
  selector: 'app-device-measurement-latest',
  templateUrl: './device-measurement-latest.component.html',
  styleUrls: ['./device-measurement-latest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ImusDestroyService]
})
export class DeviceMeasurementLatestComponent implements OnInit {

  @Input() id: number;

  measurement: MeasurementTypes.IMeasurementProperties;

  loading = false;

  get value(){
    return this.measurement ? this.measurement.value : ''; 
  }

  constructor(private httpClient: HttpClient,
    private detectChangeRef: ChangeDetectorRef,
    private readonly _destroy$: ImusDestroyService){ }

  ngOnInit(): void {
    this.loading = true;
    this.httpClient.get<MeasurementTypes.IMeasurementProperties>(`${HttpSettings.apiHost}/device/${this.id}/latest`)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: data => {
        this.measurement = data;      
        this.loading = false;
        this.detectChangeRef.markForCheck();
        },
        error: err => {
          this.loading = false;
          this.detectChangeRef.markForCheck();
        }
      });
  }
}
