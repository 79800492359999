import { Component, ElementRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { ImusBaseInputComponent } from '../imus-base-input/imus-base-input.component';
import { animationFrameScheduler, map, observeOn } from 'rxjs';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [ImusDestroyService]
})
export class TextAreaComponent extends ImusBaseInputComponent<unknown> {

  private _icon: string = undefined;
  get icon(): string {
      return this.getIcon();
  }
  @Input()
  set icon(value) {
      this._icon = value;
  };

  @Input() rows: 3;

  @Input() maxLength?: number;
  
  @Input() resizeNone?: boolean;

  @Input()
  useDefaultIcon: boolean = true;

  @ViewChild('inputELement', { static: false })
  inputElement: ElementRef;

  constructor(
      @Optional() @Self() private controlDir: NgControl,
      private readonly destroy$: ImusDestroyService) {
        super(destroy$, controlDir);
  }

  public useIcon$ = this.title$.pipe(
    observeOn(animationFrameScheduler),
    map(title => !title && this.getIcon() !== undefined)
  )

  private getIcon(): string | undefined {
      return this._icon;
  }
}
