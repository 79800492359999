import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { AuthenticationService } from '@app/shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { SharedModule } from "@shared/shared.module";
import { MenuCode, MenuItem } from "@shared/models/menu";
import { NgForOf } from "@angular/common";

@Component({
    selector: 'app-account-left-sidebar',
    templateUrl: './account-left-sidebar.component.html',
    styleUrls: ['./account-left-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SharedModule,
        NgForOf,
        RouterLinkActive,
        RouterLink
    ],
    standalone: true
})
export class AccountLeftSidebarComponent implements OnInit {
    public items: MenuItem[] = [];
    public readonly menuCode = MenuCode;
    public readonly buttonType = ButtonComponentSettings.ButtonType;

    constructor(
        public authenticationService: AuthenticationService,
        private router: Router,
        private readonly _destroy$: ImusDestroyService,
    ) {
    }

    @Input()
    set menuItems(items: MenuItem[]) {
        if (items) {
            // this.items.push(...items);
            this.items = [...items];
        }
    }

    ngOnInit() {

    }

    onMainPageClick() {
        this.router.navigate(['app-taris']);
    }

    onProfileClick() {
        this.router.navigate(['account']);
    }

    onAccountSettingsClick() {
        this.router.navigate(['account', 'settings']);
    }

    onAccountInformationClick() {
        this.router.navigate(['account', 'information']);
    }
}
