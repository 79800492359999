import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { imus } from '@app/shared/imus';
import { AuthenticationService } from '@app/shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { takeUntil, tap } from 'rxjs';

interface FormModel {
  name: string;
  email: string;
  phone: string;
  // password: string
}

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent {
  public readonly account = {
    form: null as imus.form.IFormGroup<FormModel>,
    model: {
        name: null,
        email: null,
        phone: null,
        // password: null
    } as FormModel,
};

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private readonly _fb: FormBuilder,
    private readonly _destroy$: ImusDestroyService,
    ) {
      this.account.form = _fb.fromTypedModel<FormModel>(this.account.model);
      this.authenticationService.currentUser$
          .pipe(
            // tap(currentUser => console.log('tap currentUser', currentUser)),
            // switchMap(async (currentUser) => currentUser?.user),
            tap((currentUser) => {
                // console.log('tap currentUser', currentUser)
                if (currentUser) {
                    this.account.model = currentUser.user;
                } else {
                    this.account.model = {
                        name: null,
                        email: null,
                        phone: null,
                    };
                }
                this.account.form = _fb.fromTypedModel<FormModel>(
                    this.account.model
                );
            }),
            takeUntil(this._destroy$)
          )
          .subscribe();
     }

  onChildAccountClick() {
    this.router.navigate(['account', 'child']);
  }

  onAccountInformationClick() {
    this.router.navigate(['account', 'information']);
  }

  onMainPageClick(){
    this.router.navigate(["app-taris"]);
  }
}
