import { DeviceTypes, NodeRef, NodeFeature } from './';
import { codeCategories } from "@shared/models/code-categories";
import { ProjectTypes } from "@shared/models/project";
import {
    editPanelForm
} from "@app/modules/main/modules/details-info-panel/components/details-edit-panel/details-edit-panel-form.models";

export type ItemProperty = { properties: codeCategories.CollectionItemCodesProperties | null };
export class PlotRef {
    /** Идентификатор */
    id: number | symbol;
    /** Слой, иногда приходит в данных null */
    layer: number;
    /** Начальный узел (не приходит с бэка) */
    startNode: NodeRef;
    /** Конечный узел (не приходит с бэка) */
    endNode: NodeRef;
    /** Начальный узел (приходит с бэка) */
    start_node: NodeFeature;
    /** Конечный узел (приходит с бэка) */
    end_node: NodeFeature;
    /** Название */
    name: string;
    /** Массив координат помежуточных точек */
    coords: Array<[number, number]>;

    /** Массив координат всех точек линии включая startNode и endNode */
    // coordinates: Array<[number, number]>;
    get coordinates(): Array<[number, number]>{
        // return this.coords
        let coordinates: Array<[number, number]> = []
        // if (this.startNode) coordinates = [[this.startNode.lon,this.startNode.lat]]
        if (this.startNode) coordinates = [this.startNode.coords as [number, number]]
        else if (this.start_node) coordinates = [[+this.start_node.properties.lon,+this.start_node.properties.lat]]
        else return []
        coordinates = [...coordinates, ...this.coords]

        // if (this.endNode) coordinates = [...coordinates,...[this.endNode.lon,this.endNode.lat]]
        if (this.endNode) coordinates = [...coordinates,(this.endNode.coords as [number, number])]
        // else if (this.end_node) coordinates = [...coordinates,...[this.end_node.properties.lon,this.end_node.properties.lat]]
        else if (this.end_node) coordinates = [...coordinates,[+this.end_node.properties.lon,+this.end_node.properties.lat]]

        return coordinates
}
    set coordinates(coords: Array<[number, number]>){
        // this.coords = coords

        // this.startNode.lon = coords[0][0].toString()
        // this.startNode.lat = coords[0][1].toString()
        this.startNode.coords = coords[0]
        // this.endNode.lon = coords.at(-1)[0].toString()
        // this.endNode.lat = coords.at(-1)[1].toString()
        this.endNode.coords = coords.at(-1)
        this.coords = coords.slice(1,-1)
    }


    /** Длина линии*/
    length: number;
    /** Длина секции */
    section_length: number;
    /** Диаметр */
    diameter: number;
    /** Уклон */
    slope: number;
    /** Форма */
    shape: ItemProperty;
    /** Материал */
    material: ItemProperty;
    /** Использование */
    use: ItemProperty;
    /** Облицовка */
    coating: ItemProperty;
    /** Код расположения */
    location: ItemProperty;
    /** Назначение */
    purpose: ItemProperty;
    /**
     * TODO: Неизвестно, выяснить, что это
     * @deprecated
     * */
    cladding: string; // list
    /** Год прокладки */
    laying_year: number;
    /** Режим работы */
    on: boolean;
    /**
     * TODO: неизвестно
     * @deprecated
     * */
    appointment: string; // list
    /**
     * TODO: неизвестно
     * @deprecated
     * */
    dynamicData: string; // array
    /** Файлы */
    files: Array<{
        id: number;
        name: string;
        src: string;
    }>;
    /** Название */
    device: string;
    notes: string;
    /** Секции */
    sections: {
        type: string;
        Sections: ProjectTypes.ISectionResponseItem[];
    };

    plot_type: PlotType;
    plot_type_id: number | undefined;

    constructor(src?: IPlot) {
        Object.assign(this, src ?? {}, {coords: src?.coords ?? []});
        if (src) {
            this.section_length = src.section_length;
            /**
             * TODO
             * В БД в полях start_node и возможно в end_node встречается null, чего быть не должно
             * Поэтому пока src.start_node? и src.end_node?
             */
            this.startNode = new NodeRef(src.start_node?.properties);
            this.endNode = new NodeRef(src.end_node?.properties);
            this.on = src ? !!src.on : true;
            if (!src.layer) {
                this.layer = src.start_node?.properties.layer_id;
            }
        }
    }

    /**
     * @param value
     * @param layerId - опционально, пока в БД не наведётся порядок, передаем вручную
     * */
    getPlotReq(value?: editPanelForm.FormPlotModel, layerId?: number): PlotRequest {
        return <PlotRequest>Object.assign({}, value ?? {
            start_node: this.startNode?.id,
            end_node: this.endNode?.id,
            section_length: this.section_length,
            laying_year: this.laying_year,
            name: this.name,
            length: this.length,
            diameter: this.diameter,
            slope: this.slope,
            shape_id: this.shape?.properties?.id,
            material_id: this.material?.properties?.id,
            coating_id: this.coating?.properties?.id,
            location_id: this.location?.properties?.id,
            use_id: this.use?.properties?.id,
            purpose_id: this.purpose?.properties?.id,
            notes: this.notes,
            on: true
        }, {
            layer_id: layerId ?? this.layer,
            coords: this.coords,
            plot_type_id: this.plot_type_id ?? this.plot_type.id
    })
    }
}

export class PlotCollection implements IPlotCollection {
    type: string;
    plots: PlotFeature[]
}

export class PlotFeature implements IPlotFeature {
    type: string;
    properties: IPlot
}

export interface IPlotCollection {
    type: string;
    plots: IPlotFeature[]
}

export class IPlotFeature {
    type: string;
    properties: IPlot
}


export interface IPlot {
    id: number | symbol;
    layer: number;
    name: string;
    start_node: NodeFeature;
    end_node: NodeFeature;
    length: number;
    section_length: number;
    diameter: ItemProperty;
    slope: number;
    laying_year: number;
    coords: number[][];
    notes: string;
    use: ItemProperty;
    shape: ItemProperty;
    material: ItemProperty;
    coating: ItemProperty
    purpose: ItemProperty;
    location: ItemProperty;
    /** Режим работы */
    on: boolean;
    devices: {
        type: string;
        devices: DeviceTypes.IDevice[];
    },
    sections: {
        type: string;
        Sections: ProjectTypes.ISectionResponseItem[];
    },
    files: Array<{
        id: number;
        name: string;
        src: string;
    }>;

}
export interface PlotRequest extends Partial<editPanelForm.FormPlotModel> {
    coords?: Array<[number, number]>;
    layer_id?: number;
    plot_type_id?: number;
}
export class PlotType {
    id: number;
    name: string;
    icon?: string;
    russian?: string;
    layer_type_id?: number;
}
export interface GlobalPlot {
    coords: number[][];
    plot_id: number;
    layer_id: number;
    start_node: number,
    end_node: number,
    plot_type_id: number,
    name: string
}
