import { ContractorType } from "@app/modules/account/pages/profile/models/profile";
import { InputType } from "@shared/input-type";

export interface TemplateField {
    name: string;
    type: InputType;
    label: string
    hidden: boolean;
    required?: boolean;
}

export interface BankFormModel {
    bank_descr: string;
    bank_bik: string;
    bank_account: string;
    bank_contractor_account: string;
    bank_inn: string;
    bank_kpp: string;
    bank_okpo: string;
    bank_swift: string;
    id: number;
    type_id: number;
}

export interface LegalInfoFormModel {
    /**Юридическое наименование*/
    requisite_short_name: string;
    requisite_full_name: string;
    address: string;
    phone: string;
    email: string;
    site: string;
    requisite_inn: string;
    requisite_kpp: string;
    requisite_ogrn: string;
    requisite_director_full_name: string;
    requisite_director_post: string;
    requisite_director_phone: string;
    id: number;
    type_id: number;
    status_id: number;
}

export interface IndividualInfoFormModel {
    requisite_short_name: string;
    requisite_full_name: string;
    person_name_last: string;
    person_name_first: string;
    person_name_second: string;
    address: string;
    phone: string;
    email: string;
    requisite_inn: string;
    // requisite_kpp: string;
    requisite_ogrn: string;
    site: string;
    id: number;
    type_id: number;
    status_id: number;
}
export const INDIVIDUAL_MAIN_FIELDS: TemplateField[][] = [
    [
        {
            name: 'person_name_last',
            type: InputType.text,
            label: 'Фамилия',
            hidden: false,
            required: true
        },
        {
            name: 'person_name_first',
            type: InputType.text,
            label: 'Имя',
            hidden: false,
            required: true
        },
        {
            name: 'person_name_second',
            type: InputType.text,
            label: 'Отчество',
            hidden: false
        },
        {
            name: 'address',
            type: InputType.area,
            label: 'Юридический адрес',
            hidden: false
        },
        {   
            name: 'status_id', 
            type: InputType.select, 
            label: 'Статус проверки', 
            hidden: false 
        },
        {
            name: 'id',
            type: InputType.text,
            label: '',
            hidden: true
        },
        {
            name: 'type_id',
            type: InputType.text,
            label: '',
            hidden: true
        },
        {
            name: 'requisite_short_name',
            type: InputType.text,
            label: 'Юридическое наименование',
            hidden: true
        },
        {
            name: 'requisite_full_name',
            type: InputType.text,
            label: 'Полное юридическое наименование',
            hidden: true
        },
        {
            name: 'requisite_ogrn',
            type: InputType.text,
            label: 'ОГРН',
            hidden: true
        },
    ],
    [
        {
            name: 'requisite_inn',
            type: InputType.text,
            label: 'ИНН',
            hidden: false
        },
        // {
        //     name: 'requisite_kpp',
        //     type: InputType.text,
        //     label: 'КПП',
        //     hidden: false
        // },
        {
            name: 'site',
            type: InputType.text,
            label: 'Официальный сайт',
            hidden: false
        },
        {
            name: 'email',
            type: InputType.email,
            label: 'email организации',
            hidden: false
        },
        {
            name: 'phone',
            type: InputType.text,
            label: 'Телефон организации',
            hidden: false
        },
    ]
]

export const LEGAL_MAIN_FIELDS: TemplateField[][] = [
    [
        {
            name: 'requisite_short_name',
            type: InputType.text,
            label: 'Юридическое наименование',
            hidden: false
        },
        {
            name: 'requisite_full_name',
            type: InputType.text,
            label: 'Полное юридическое наименование',
            hidden: false
        },
        {
            name: 'address',
            type: InputType.area,
            label: 'Юридический адрес',
            hidden: false
        },
        {
            name: 'phone',
            type: InputType.text,
            label: 'Телефон организации',
            hidden: false
        },
        {
            name: 'email',
            type: InputType.email,
            label: 'email организации',
            hidden: false
        },
        {
            name: 'site',
            type: InputType.text,
            label: 'Официальный сайт',
            hidden: false
        },
        {
            name: 'id', type: InputType.text, label: '', hidden: true
        },
        {
            name: 'type_id', type: InputType.text, label: '', hidden: true
        },
        { name: 'status_id', type: InputType.select, label: 'Статус проверки', hidden: false },
    ],
    [
        {
            name: 'requisite_inn',
            type: InputType.text,
            label: 'ИНН',
            hidden: false
        },
        {
            name: 'requisite_kpp',
            type: InputType.text,
            label: 'КПП',
            hidden: false
        },
        {
            name: 'requisite_ogrn',
            type: InputType.text,
            label: 'ОГРН',
            hidden: false
        },
        {
            name: 'requisite_director_full_name',
            type: InputType.text,
            label: 'ФИО руководителя',
            hidden: false
        },
        {
            name: 'requisite_director_post',
            type: InputType.text,
            label: 'Должность руководителя',
            hidden: false
        },
        {
            name: 'requisite_director_phone',
            type: InputType.text,
            label: 'Телефон руководителя',
            hidden: false
        }]
]

export const BANK_FIELDS: TemplateField[][] = [
    [{ name: 'bank_descr', type: InputType.text, label: 'Наименование банка', hidden: false },
        { name: 'bank_bik', type: InputType.text, label: 'БИК', hidden: false },
        { name: 'bank_account', type: InputType.text, label: 'Корреспондирующий счёт', hidden: false },
        { name: 'bank_contractor_account', type: InputType.text, label: 'Расчётный счёт', hidden: false },
        { name: 'id', type: InputType.text, label: '', hidden: true },
        { name: 'type_id', type: InputType.text, label: '', hidden: true }],
    [{ name: 'bank_inn', type: InputType.text, label: 'ИНН банка', hidden: false },
        { name: 'bank_kpp', type: InputType.text, label: 'КПП банка', hidden: false },
        { name: 'bank_okpo', type: InputType.text, label: 'ОКПО банка', hidden: false },
        { name: 'bank_swift', type: InputType.text, label: 'SWIFT', hidden: false }]
]

export const MAIN_LEGAL_MODEL: LegalInfoFormModel =  {
    requisite_short_name: null,
    requisite_full_name: null,
    address: null,
    phone: null,
    email: null,
    site: null,
    requisite_inn: null,
    requisite_kpp: null,
    requisite_ogrn: null,
    requisite_director_full_name: null,
    requisite_director_post: null,
    requisite_director_phone: null,
    id: null,
    type_id: null,
    status_id: null
}

export const MAIN_INDIVIDUAL_MODEL: IndividualInfoFormModel =  {
    requisite_short_name: null,
    requisite_full_name: null,
    person_name_last: null,
    person_name_first: null,
    person_name_second: null,
    address: null,
    phone: null,
    email: null,
    requisite_inn: null,
    // requisite_kpp: null,
    requisite_ogrn: null,
    site: null,
    id: null,
    type_id: null,
    status_id: null
}

export class CompanyDetails {

    public contractorType: ContractorType;
    public mainFields: TemplateField[][];
    public bankFields: TemplateField[][];
    public contractorTypeTitle: string;
    public formModel: { [key: string]: any };
    constructor(contractorType: ContractorType) {
        console.log('CompanyDetails contractorType', contractorType);
        this.contractorType = contractorType;
        this.formModel = contractorType === ContractorType.LEGAL ? MAIN_LEGAL_MODEL : MAIN_INDIVIDUAL_MODEL;
        this.contractorTypeTitle = contractorType === ContractorType.LEGAL ? 'ЮЛ' : 'ИП';
        this.mainFields = contractorType === ContractorType.LEGAL ? LEGAL_MAIN_FIELDS : INDIVIDUAL_MAIN_FIELDS;
        this.bankFields = BANK_FIELDS;
    }

}
