import { NodeRef } from './node';
import { NewLayerType } from "@shared/models/layer-types";
import { BehaviorSubject } from "rxjs";
import { PlotRef } from './plot';
import { Attachments } from "@shared/attachment";
import { TreeNode } from '@app/modules/main/modules/layer-edit-panel/models/tree-node.model';
import { RightsType } from './layer';
import { AccessLevelType } from './access-level-type';

export class Item {
    /** цвет слоя */
    color?: string;
    /**
     * тип слоя
     * @deprecated используем айдишники
     * */
    layerType: string;
    /** ID типа системного слоя */
    parent_id: number;
    /** ID типа подслоя */
    id: number | null;
    /** имя */
    name: string;
    russian: string;
    /** имеет фильтры - прозрачность, яркость, контраст */
    hasFilters: boolean;
    // filters?: {
    //     /** прозрачность */
    //     transparency: number,
    //     /** яркость */
    //     brightness: number,
    //     /** контраст */
    //     contrast: number
    // };

    /** возможно несколько слоев true/false */
    multipleLayers!: boolean;
    /** отмечен (отображается на карте) */
    checked = true;
    /** редактируемый */
    editable!: boolean;
    /** можно изменить имя */
    changeName!: boolean;
    /** развернут */
    expanded!: boolean;
    /** удаляемый */
    removable!: boolean;
    /** имеет возможность блокировки редактирования */
    hasBlockDataEdit!: boolean;
    /** имеет возможность объединять данные */
    combineData!: boolean;
    /** может иметь подслои
     * @deprecated
     * определяем имеет ли слой подслои по принадлежности к классу ItemLayer
     * instanceof ItemLayer
    */
    hasChildren!: boolean;
    /** Включена/выкдючена подпись точек и линий */
    objectLabels?: boolean;
    /** отображается панель редактирования 
     * @deprecated
     * Проверяем редактируется ли слой через равенство id и editableLayerId
    */
    editVisible?: boolean;

    constructor(src: NewLayerType & UnitedItem) {
        // Object.assign(this, src);
        // Необходимо глубокое клонирование объекта
        Object.assign(this, structuredClone(src));
    }
}

export class ItemLayer extends Item {
    /** @deprecated */
    children?: ItemSubLayer[];
    children$: BehaviorSubject<Map<number, ItemSubLayer>> = new BehaviorSubject(new Map());

    constructor(src: NewLayerType & ItemLayer) {
        super(src)
    }
}

export class ItemSubLayer extends Item {
    /** id слоя в БД */
    layerId: number;
    /** id пользователя в БД */
    userId: number;
    /** Имя пользователя */
    userName: string;
    /** ссылка на родительский объект (слой) */
    parent?: ItemLayer;
    parentNode?: TreeNode;
    ///** @deprecated */
    // nodes?: Node[];
    /** стрим точек */
    nodes$: BehaviorSubject<NodeRef[]> = new BehaviorSubject([]);
    /** стрим линий */
    plots$: BehaviorSubject<PlotRef[]> = new BehaviorSubject([]);
    /** порядок сортировки */
    order?: number;
    layerTypeId: number;

    files$? = new BehaviorSubject<any[]>([]);
    files: Attachments;

    /** ID типа слоя
     * 801 - Собственный
     * 802 - Сотрудник компании
     * 803 - Сотрудник, предоставившей гостевой доступ
     * 804 – Чужой (на фронт не приходит)
     */
    creator_type?: LayerOwnerType;
    /** Является ли слой глобальным */
    global: boolean;
    /** номер уровня доступа к слою 
     *  701 - стандартный, 
     *  702 - расширенный, 
     *  703 - скрытый, 
     *  704 - персональный).
    */
    accessLevelTypeId: AccessLevelType;
    rights: RightsType;
    // rights: Map<number, string>;
    filters?: {
        /** прозрачность */
        transparency: number,
        /** яркость */
        brightness: number,
        /** контраст */
        contrast: number
    };

    rastr: Attachments;

    /** Extent для изображения */
    data: number[] | null;
    /** Ширина изображения */
    w: number;
    /** Высота изображения */
    h: number;
    /** Формат изображения или слоя
     * 'jpgw' - изображение задается через Extent(data), ширину(w) и высоту(h)
     * 'geoimage' - изображение задается через координаты центра (center), масштаб(scale) и угол поворота(rotate)
     * 'geojson' - слой задается через GeoJSON
    */
    format?: string;

    /** GeoJSON */
    geojson?: string | null;

    /** Координаты центра изображения */
    center?: number[];
    /** Масштаб изображения */
    scale?: number[] | number;
    /** Угол поворота изображения в радианах*/
    rotate?: number;
    /** Extent по которому обрезается изображение */
    imageCrop?: number[];
    /** Маска по которой обрезается изображение */
    imageMask?: number[][] = null;

    constructor(src: NewLayerType & ItemSubLayer) {
        super(src)
    }
}
/** 
 *  Тип владельца слоя:   
 *  801 - Собственный,  
 *  802 - Сотрудник компании,  
 *  803 - Сотрудник, предоставившей гостевой доступ,  
 *  804 – Чужой (на фронт не приходит)
*/
export enum LayerOwnerType {
    USER = 801,
    COMPANY = 802,
    GUEST = 803,
    OTHER = 804
}

/**
 * Нужен ли??
 * @remarks Пока deprecated
 * @deprecated
 * */
export class ItemRaster extends ItemSubLayer {
    // filters?: {
    //     /** прозрачность */
    //     transparency: number,
    //     /** яркость */
    //     brightness: number,
    //     /** контраст */
    //     contrast: number
    // };
    url?: string;

    constructor(src: NewLayerType & ItemRaster) {
        super(src)
    }
}

export type UnitedItem = Item | ItemLayer | ItemSubLayer | ItemRaster;
