import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
    selector: 'app-email-confirmation-modal',
    templateUrl: './email-confirmation-modal.component.html',
    styleUrls: ['./email-confirmation-modal.component.scss'],
})
export class EmailConfirmationModalComponent implements OnInit {
    loading = false;
    // matDialog: any;

    constructor(
      public dialogRef: MatDialogRef<EmailConfirmationModalComponent>,
      public matDialog: MatDialog,
    ) {}

    ngOnInit() {}
    closeModal() {
        this.dialogRef.close();
    }
    fromEmailToLogin(event) {

      event.preventDefault();
      this.dialogRef.close();

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-login-component';

      this.matDialog.open(LoginModalComponent, dialogConfig);
  }


}
