import { Injectable } from '@angular/core';
import { ProjectRouteService } from '@app/routes/project-route.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ProjectTypes } from '../models/project';
import { MainPageService } from '@app/modules/main/services/main-page.service';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsGridDialogComponent } from '@app/modules/projects/projects-grid-dialog/projects-grid-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogSettings } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    private projects = new BehaviorSubject<ProjectTypes.IProjectListResponse>(
        null
    );
    public projects$ = this.projects.asObservable();

    public editableSection = new BehaviorSubject(null);
    public editableSection$ = this.editableSection.asObservable();

    private _editableProject: BehaviorSubject<ProjectTypes.IProjectResponseItem> = new BehaviorSubject<ProjectTypes.IProjectResponseItem>(null);
    public editableProject$ = this._editableProject.asObservable();
    set editableProject(value: ProjectTypes.IProjectResponseItem) {
        this._editableProject.next(value);
    }
    get editableProject(): ProjectTypes.IProjectResponseItem {
        return this._editableProject.value;
    }

    public plotSelected = new BehaviorSubject<number>(null);
    public plotSelected$ = this.plotSelected.asObservable();

    private loadingProjects = new BehaviorSubject<boolean>(false);
    public loadingProjects$ = this.loadingProjects.asObservable();

    constructor(
        private projectRoute: ProjectRouteService,
        public pageService: MainPageService,
        public matDialog: MatDialog,

    ) { }

    loadInspections() {
        this.loadingProjects.next(true);
        this.projectRoute.loadProjects().subscribe((projects) => {
            this.projects.next(projects);
            this.loadingProjects.next(false);
        });
    }

    loadProjects() {
        this.loadingProjects.next(true);
        this.projectRoute.loadProjects().subscribe((projects) => {
            this.projects.next(projects);
            this.loadingProjects.next(false);
        });
    }

    deleteProject(project: ProjectTypes.IProjectResponseItem) {
        let deleteResult = new BehaviorSubject<boolean | undefined>(undefined);

        this.matDialog
            .open(ConfirmDialogComponent, {
                data: {
                    title: 'Удаление отчёта телеинспекции',
                    text: `Удалить отчёт ${project.properties.name}?`,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (
                    result === ConfirmDialogSettings.ConfirmDialogResult.ok
                ) {
                    this.projectRoute
                        .deleteProject(project.properties.id)
                        .subscribe(() => deleteResult.next(true));
                }
                else deleteResult.next(false);
            });

        return deleteResult.asObservable();
    }

    editSection(sectionId: any, plotId: number) {

        console.log('editSection', sectionId, plotId);

        this.loadingProjects.next(true);
        this.projectRoute.editSection(sectionId, plotId).subscribe((section) => {
            this.loadInspections();
            this.loadProjects();
            this.formsHelperDataClear();
        });
    }

    public formsHelperDataClear() {
        this.editableSection.next(null);
        this.editableProject = null;
        this.plotSelected.next(null);
        this.gridDialogRolledUpCheck();
    }

    public gridDialogRolledUp: boolean = false;

    // Проверка опции для повторного открытия окна списка устройств, закрытого при переходе в редактирование
    public gridDialogRolledUpCheck() {
        if (this.gridDialogRolledUp) {
            this.gridDialogOpen();
        }
    }

    public gridDialogOpen() {
        this.pageService.projectsGridDialog = this.matDialog.open(ProjectsGridDialogComponent);
    }

    public updateProject(projectId: number, contractorId: number, clientId: number, values: ProjectTypes.IProjectEditForm) {
        return forkJoin([
            this.projectRoute.updateProject(projectId, { name: values.name }),
            this.projectRoute.updateCompany(contractorId,
                {
                    company: values.contractorCompany,
                    representative: values.contractorRepresentative,
                    phone: values.contractorPhone,
                    email: values.contractorEmail,
                    city: values.contractorCity,
                    zip: values.contractorZip,
                    street: values.contractorStreet,
                    web: values.contractorWeb,
                    notes: values.contractorNotes
                }),
            this.projectRoute.updateCompany(clientId,
                {
                    company: values.clientCompany,
                    representative: values.clientRepresentative,
                    phone: values.clientPhone,
                    email: values.clientEmail,
                    city: values.clientCity,
                    zip: values.clientZip,
                    street: values.clientStreet,
                    web: values.clientWeb,
                    notes: values.clientNotes
                })
        ]);
    }
}
