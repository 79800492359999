<main class="login"
    [ngClass]="{'page-ready': pageReady, 'step-one-ready': stepOneReady, 'video-changing': videoChanging}">
    <div class="login-content">
        <header class="login-header">
            <div class="login-header-logo">
                <img src="/assets/images/imus.png" alt="">
            </div>
        </header>
        <section class="login-section">
            <div class="login-section_wrapper">
                <div class="login-section_wrapper--cell">
                    <h1>Геоинформационная система IMUS</h1>
                    <h5>подземные инженерные коммуникации</h5>
                    <div class="login-section_wrapper--buttons">
                        <!-- <button class="btn" *ngIf="displayDevLogin" (click)="demoLogin()" [style.margin.right.px]="10">Demo</button> -->
                        <button class="btn" (click)="openLoginModal()">Войти</button>
                        <button class="btn btn-transparent" (click)="openRegisterModal()">Регистрация</button>
                    </div>
                </div>
            </div>
        </section>
        <footer class="login-footer">
            <div class="footer-fond">
                <a href="https://fasie.ru/" target="_blank">
                    <img src="/assets/img/footer_logo_fasie.png" alt="">
                </a>
                <span>
                </span>
            </div>
            <div class="footer-fond-text">
                При поддержке Фонда содействия инновациям <br> в рамках реализации Федерального проекта<br>«Цифровые
                технологии»
            </div>
            <div class="login-footer-wrapper">
                <div class="login-footer-logo">
                    <a href="https://taris.ru/" target="_blank">
                        <!--                    <img src="https://storage.yandexcloud.net/imus/assets/images/taris_logo_arrow.svg" alt="">-->
                        <img src="/assets/img/taris_logo_arrow.svg" alt="">
                    </a>
                </div>
                <div class="login-footer-info">
                    <a href="/information">
                        информация о системе
                    </a>
                </div>
            </div>
        </footer>
    </div>
    <div class="shadow-overlay"></div>

    <div class="main-screen__video main-video">
        <div class="main-screen__video slider-video">
            <!-- <div class="login-video_bg video1">
                <video class="js-main-video" muted loop playsinline preload poster="">
                <source src="/assets/videos/720.mp4" type="video/mp4">
            </video>
            </div> -->
            <div class="login-video_bg video2">
                <video #videoPlayer (loadeddata)="onVideoLoad($event)" (ended)="onVideoEnded($event)"
                    class="js-main-video active" [autoplay]="true" [loop]="true" [muted]="true" playsinline preload
                    poster="">
                    <source src="/assets/videos/1080.mp4" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
</main>