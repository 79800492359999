<div class="inputs-area">
  <div>
    <h1>Подтверждение адреса электронной почты</h1>
    <ng-container *ngIf="(state$ | async) as state ">

      <ng-container *ngIf="state.from == 'register' && state.user; else login">
        <p>
          На адрес {{state.user["email"]}} отправлена ссылка для подтверждения адреса электронной почты.
          Пожалуйста, проверьте свою электронную почту и нажмите на ссылку для подтверждения, чтобы завершить
          регистрацию.
        </p>
      </ng-container>
      <ng-template #login>
        <p>
          Ваш адрес {{currentUser.email}} не был подтвержден. Для завершения регистрации, пожалуйста,
          проверьте свою электронную почту и нажмите на ссылку для подтверждения.
        </p>
      </ng-template>
    </ng-container>
    <app-button class="button-cell" [label]="'Отправить ссылку повторно'" [fullWidth]="false"
      (onclick)="sendLinkAgain()"></app-button>
    <p>
      Не получили ссылку для подтверждения?<br>
      - Возможно, письмо попало в спам. Проверьте папку «Спам» вашего ящика электронной почты.<br>
      - Иногда письма приходят с задержкой. Подождите, возможно, вы получите письмо позже.<br>
      - Если вы ошиблись при написании своего адреса электронной почты, зарегистрируйтесь снова с корректным адресом электронной почты.
      <!-- - Возможно, вы написали адрес с ошибкой. Уточните написание своего адреса электронной почты.<br> -->
    </p>
  </div>
  <div class="separator"></div>


  <p *ngIf="resent$ | async">
    Письмо отправлено!
  </p>

  <div class="separator"></div>
</div>