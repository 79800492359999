import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
// import 'rxjs/add/operator/finally';
import { environment } from '@environments/environment';
import {AuthenticationService} from './authentication.service';
import {PlotService} from './plot.service';
import { NodeService } from './node.service';
import { EntityType } from "@shared/models/entity-type";
import { HttpClient } from "@angular/common/http";

// import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    constructor (
        private authenticationService: AuthenticationService,
        private plotService: PlotService,
        private nodeService: NodeService,
        private http: HttpClient
    ) {}

    uploadFile(files: any, type: string, id: number): any {
        // console.log('start upload');

        return of({});

        // const s3Observable = new Observable(observer => {
        //     const thisNodeService = this.nodeService;
        //     const thisPlotService = this.plotService;
        //     let newNode = new Node;
        //     let newPlot = new Plot;

        //     this.authenticationService.me().subscribe(user => {
        //         const destFolder = `uploads/${user.id}/${type}/${id}/`;
        //         const bucket = new S3(environment.storage.s3);

        //         let lastIndex = files.length;
        //         files.map((file, i) => {
        //             const params = {
        //                 Bucket: environment.storage.bucket,
        //                 Key: destFolder + file.name,
        //                 Body: file,
        //                 ACL: 'public-read',
        //                 ContentType: file.type
        //             };

        //             bucket.upload(params, function (err, data) {
        //                 if (err) {
        //                     // console.log('There was an error uploading your file: ', err);
        //                     // return false;
        //                     observer.error(`There was an error uploading your file: ${err}`);
        //                 }
        //                 if (type === 'plot') {
        //                     newPlot.id = id;
        //                     newPlot.files = [data.Location];
        //                     thisPlotService.edit(newPlot).subscribe(info => {
        //                         observer.next(info);
        //                         lastIndex--;
        //                         if (lastIndex === 0) {
        //                             observer.complete();
        //                         }
        //                     });
        //                 }
        //                 if (type === 'node') {
        //                     newNode.id = id;
        //                     newNode.files = [data.Location];
        //                     thisNodeService.edit(newNode).subscribe(info => {
        //                         observer.next(info);
        //                         lastIndex--;
        //                         if (lastIndex === 0) {
        //                             observer.complete();
        //                         }
        //                     });
        //                 }
        //                 // return true;
        //             });
        //         });
        //     });
        // });
        // return s3Observable;
    }
    public deleteFile(fileId: number, entity: EntityType, id?: number): Observable<void> {
        return this.http.delete<void>(`${environment.apiUrl}/file/${fileId}`).pipe(
            tap(() => {
                if (entity === EntityType.NODE) {
                    this.nodeService.changed$.emit();
                } else {
                    this.plotService.changed$.next(id);
                }
            }),
            catchError(() => of(undefined))
        );
    }

}
