import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    BehaviorSubject,
    catchError,
    combineLatest, distinctUntilChanged,
    EMPTY,
    finalize,
    from,
    mergeMap,
    Observable,
    of,
    ReplaySubject,
    switchMap,
    takeUntil,
    tap
} from "rxjs";
import { InspectionFileType } from "@app/modules/main/modules/details-info-panel/uploaded-Inspection-file-type";
import { filter, map } from "rxjs/operators";
import { TelevisionInspectionService } from "@services/television-inspection.service";
import { ImusDestroyService } from "@services/destroy.service";

interface UploadedInspectionStatus {
    [key: string]: {
        /** Состояние загрузки */
        loading$: BehaviorSubject<boolean>,
        /** Удачная загрузка */
        success$: BehaviorSubject<boolean>,
        /** Ошибка при загрузке */
        fail$: BehaviorSubject<boolean>
    }
}

@Component({
    selector: 'app-uploaded-inspection-files-list',
    templateUrl: './uploaded-inspection-files.list.component.html',
    styleUrls: ['./uploaded-inspection-files.list.component.scss'],
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadedInspectionFilesListComponent implements OnInit {
    private readonly reloadedInspectionFile$ = new BehaviorSubject<File>(null);
    private _type: InspectionFileType;
    private _allFiles: File[];

    public readonly files$ = new ReplaySubject<File[]>(1);
    public readonly allowed$ = new BehaviorSubject(false);
    public uploadedInspectionStatus: UploadedInspectionStatus = {};

    /** Файлы для загрузки */
    @Input('files')
    set files(files: File[]) {
        if (files) {
            this.files$.next(files);
            this._allFiles = files;
            this.reloadedInspectionFile$.next(null);
        }
    }

    /** Разрешение инициирования загрузки */
    @Input('type')
    set type(type: InspectionFileType) {
        this._type = type;
    }

    /** Разрешение инициирования загрузки */
    @Input('allowed')
    set allowed(bol: boolean) {
        this.allowed$.next(bol);
    }

    /** Оповещение о удачной загрузке всех файлов */
    @Output('resolve')
    public readonly resolve = new EventEmitter<InspectionFileType>();

    constructor(
        private readonly _destroy$: ImusDestroyService,
        private readonly _televisionInspectionService: TelevisionInspectionService
    ) {
    }

    ngOnInit(): void {
        this.files$.pipe(
            tap((files) => {
                this.uploadedInspectionStatus = {};
                files.forEach(file => {
                    this.uploadedInspectionStatus[file.name + file.webkitRelativePath] = {
                        loading$: new BehaviorSubject(true),
                        success$: new BehaviorSubject(false),
                        fail$: new BehaviorSubject(false)
                    }
                })
            }),
            switchMap(files => combineLatest([of(files), this.allowed$.pipe(
                distinctUntilChanged()
            ), this.reloadedInspectionFile$.pipe(
                filter(f => f !== undefined),
                distinctUntilChanged((x, y) => x === y && (y === null || y === undefined))
            )]).pipe(
                filter(([_, allowed]) => allowed),
                map(([files, _, reloaded]) => reloaded ? [reloaded] : files)
            )),
            // пропускаем только незагруженные файлы
            map(files => files.filter(f => !this.uploadedInspectionStatus[f.name + f.webkitRelativePath].success$.value)),
            this.uploadFiles,
            takeUntil(this._destroy$)
        ).subscribe(() => {
            this.resolve.emit(this._type === 'project' ? 'section' : this._type === 'section' ? 'observation' : this._type === 'observation' ? 'rest' : 'all');
        });
    }

    private uploadFiles = switchMap<File[], Observable<unknown>>(files => !files.length
        ? of(undefined)
        : from(files).pipe(
            tap(file => {
                if (this.uploadedInspectionStatus[file.name + file.webkitRelativePath] && this.uploadedInspectionStatus[file.name + file.webkitRelativePath].fail$.value) {
                    // если был сбой загрузки, то активируем лоадер
                    this.uploadedInspectionStatus[file.name + file.webkitRelativePath].loading$.next(true);
                    this.uploadedInspectionStatus[file.name + file.webkitRelativePath].fail$.next(false);
                }
            }),
            mergeMap(file => this._televisionInspectionService.uploadFile(file).pipe(
                tap(() => {
                    this.uploadedInspectionStatus[file.name + file.webkitRelativePath].success$.next(true);
                }),
                catchError(error => {
                    this.uploadedInspectionStatus[file.name + file.webkitRelativePath].fail$.next(true);
                    // не прерываем поток при ошибке
                    return EMPTY;
                }),
                finalize(() => {
                    this.uploadedInspectionStatus[file.name + file.webkitRelativePath].loading$.next(false);
                })
            )),
            finalize(() => {
                this.reloadedInspectionFile$.next(undefined);
            }),
            filter(() => Object.keys(this.uploadedInspectionStatus).filter(key =>
                this._allFiles.map(f =>
                    f.name + f.webkitRelativePath).includes(key)).reduce<boolean[]>((acc, key) => {
                if (this.uploadedInspectionStatus[key].success$.value) {
                    acc.push(true)
                }
                return acc;
            }, []).length === this._allFiles.length)
        ))

    // повторная загрузка файла
    public reload(file: File) {
        if (this.reloadedInspectionFile$.value || Object.keys(this.uploadedInspectionStatus).filter(key => this.uploadedInspectionStatus[key].loading$.value).length) {
            return;
        }
        this.reloadedInspectionFile$.next(file);
    }
}
