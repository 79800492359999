/** Перечисление поддерживаемых типов вложений к mime форматам загружаемых файлов */
export enum AttachmentType {
  all = '',
  csv = '.csv',
  png = 'image/png',
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpeg = 'image/jpeg',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml = 'text/xml',
  axml = 'application/xml',
  plain = 'text/plain,',
  sig = '.sig',
  // типы файлов для сертификатов
  cer = 'application/pkix-cert',
  der = 'application/x-x509-ca-cert',
  crt = 'application/x-x509-user-cert'
}
