import { LayerTypeIDs, LayerTypes, NewLayerType } from '@shared/models/layer-types';
import { NodeCollection } from '@shared/models/node';
import { AttachmentModel } from "@shared/attachment";
import { node } from './node-ref';
import { GlobalPlot, PlotCollection } from './plot';
import { LayerOwnerType } from './layers-typings';

export class LayerCollection {
    type: string;
    features: LayerFeature[];
    data: LayerFeature[];
}

export class LayerFeature {
    files: any[]; //TODO проверить типы
    rastr: any[]; //TODO проверить типы
    // points: NodeCollection;
    nodes: NodeCollection;
    plots?: PlotCollection;
    type: string;
    properties: Layer;
    // data: any;
    // w: any;
    // h: any;
}

export class GlobalLayer {
    layer_id: number; // | string; 
    layer_type_id: number;
    layerType?: LayerType;
    can_read?: boolean;
    // points: NodeCollection;
    nodes: node.NodeGlobalResponse[];
    plots: GlobalPlot[]
}

export interface GlobalResponce {
    nodes: {
        1?: node.NodeGlobalResponse[],
        2?: node.NodeGlobalResponse[],
        3?: node.NodeGlobalResponse[],
        4?: node.NodeGlobalResponse[],
    }[],
    plots: {
        1?: GlobalPlot[],
        2?: GlobalPlot[],
        3?: GlobalPlot[],
        4?: GlobalPlot[],
    }[]
}
/**
 * Типы прав:  
 * can_read - Есть права на чтение,   
 * can_update - Есть права на обновление,  
 * can_delete - Есть права на удаление,  
 * can_change_level - Есть права на изменение уровня доступа,  
 * can_set_global - Есть права на изменения уровня на глобальный и обратно
 */
export interface RightsType {
    /** can_read - Есть права на чтение*/
    301?: string; //"can_read",
    /** can_update - Есть права на обновление*/
    303?: string; //"can_update",
    /** can_delete - Есть права на удаление*/
    304?: string; //"can_delete",
    /** can_change_level - Есть права на изменение уровня доступа*/
    305?: string; //"can_change_level",
    /** can_set_global - Есть права на изменения уровня на глобальный и обратно*/
    306?: string; //"can_set_global"
}
export class Layer {
    ID: number;
    user_id: number;
    user_name: string;
    // can_rights?: string;
    own_layer?: boolean;
    global?: boolean;
    /** 
     * 
     * 801 - Собственный,
     * 802 - Сотрудник компании,
     * 803 - Сотрудник, предоставившей гостевой доступ,
     * 804 – Чужой (на фронт не приходит)
    */
    creator_type?: LayerOwnerType;
    /* Права объекта*/
    rights?: RightsType;
    name: string;
    color: string;
    layer_type: NewLayerType;
    order: number;
    // data: string | null; 
    data: number[] | null;
    geojson?: string | null;
    format: string;
    w: number;
    h: number;
    files: AttachmentModel[];
    rastr: AttachmentModel[];
    scale: number[];
    rotate: number;
    center: number[];
    objectLabels?: boolean;
    access_level_type_id: number;
}

export class LayerType {
    id: LayerTypeIDs;
    name: LayerTypes;
}

export interface NewLayerResponse {
    name: string;
    status: string;
    color: string;
    layer_id: number;
    user_id: number;
    order: number;
    /** TODO: Почему стринга?? */
    layer_type_id: number;
}

export interface CopyItemsResponse {
    layer_id: number;
    nodes: number[];
    plots: number[];
}

export interface LayerAccess {
    type: string;
    user_id: number;
    can_create_new_layer?: boolean;
    can_read?: boolean;
    can_update?: boolean;
    can_delete?: boolean;
    can_change_level?: boolean;
    can_set_global?: boolean;

    layer_id?: number;
    rights?: RightsType;
}