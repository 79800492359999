import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Лейбл отображения нарушения правила для элемента формы.
 * Привязка к компоненту формы считывается автоматически из родительского элемента @see field-errors
 *
 * @param rule имя \ идентификатор правила нарушение которого отображает элемент
 * @param message задает пользовательское сообщение отображаемое в случе нарушения правила
 * @content задает шаблон который будет отображаться в качестве сообщения в случе нарушения правила,
 * если не задан @param message
 * @empty если не задан @param message и @content то для отображения будет использовано сообщение
 * из @see ITranslator > warnings > @param rule,
 * если таковая отсутствует то сообщение будет отображать имя правила @param rule
 * @use
 * 	<field-error-label rule="required"></field-error-label> <-- Показ предупреждения с текстом по умолчанию
 * 	<field-error-label rule="required" message="Обязательно к заполнению!"></field-error-label>
 *  ^- Показ предупреждения с пользовательским сообщением
 * 	<field-error-label rule="required"> <-- Показ предупреждения в виде пользовательского шаблона
 * 		<span class="icons icon-warning"></span>
 * 		<span>Заполните!</span>
 * 	</field-error-label>
 * @remarks
 * - опирается на css стиль form-field-error-label
 * - к значению @param message применяются стиль form-field-error-label, к @content стандартных стилей
 * не применяется и односложное сообщение рекомендуется передавать в @param message
 */
@Component({
	selector: 'field-error-label',
	templateUrl: './form-field-error-label.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldErrorLabelComponent {

	private _isActive = new BehaviorSubject<boolean>(false);

	/** Имя \ идентификатор правила нарушение которого отображает элемент */
	@Input('rule')
	public rule: string;

	/** Пользовательское сообщение отображаемое в случе нарушения правила, если установлено, то @content игнорируется */
	@Input('message')
	public customMessage: string | null = null;

	/** Сообщение по умолчанию о нарушении правила назначенного элементу */
	public get defaultMessage(): string {
		return this.rule;
	}

	public get isActive(): Observable<boolean> {
		return this._isActive;
	}

	constructor() {}

	public markAsShown(): void {
		this._isActive.next(true);
	}

	public markAsHidden(): void {
		this._isActive.next(false);
	}
}
