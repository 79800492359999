import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'app-show-image-dialog',
  templateUrl: './show-image-dialog.component.html',
  styleUrls: ['./show-image-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowImageDialogComponent {
  public readonly src: string = inject(MAT_DIALOG_DATA);
  public dialogRef = inject(MatDialogRef<ShowImageDialogComponent>);
  
  onClose() {
    this.dialogRef.close();
  }
}
