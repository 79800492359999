import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Report } from '@shared/models';
import { PlotRef } from '@shared/models';

@Injectable()
export class ReportService {
    constructor(
        private http: HttpClient
    ) {
    }

    getById(id: number) {
        return this.http.get<Report>(`${environment.apiUrl}/report/item/${id}`);
    }

    getByPlot(plot: PlotRef) {
        return this.http.get<Report>(`${environment.apiUrl}/report/list/${<number>plot.id}`);
    }

    add(report: Report) {
        return this.http.post(`${environment.apiUrl}/report/add`, report);
    }

    upload(formModel) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        return this.http.post(`${environment.apiUrl}/report/add`, formModel, {
            headers: headers
        });
    }
}
