import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { imus } from '@app/shared/imus';
import { AlertService, UserService } from '@app/shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { passwordMatchValidator } from '@app/shared/validators/password.validator';
import { animationFrameScheduler, catchError, takeUntil, throwError } from 'rxjs'
import { SharedModule } from "@shared/shared.module";
import { InputType } from "@shared/input-type";

interface FormModel {
    currentPassword: string,
    passGroup: {
        password: string;
        confirmPassword: string;
    };
}

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss'],
    standalone: true,
    imports: [
        SharedModule,
        ReactiveFormsModule
    ]
})
export class ChangePasswordModalComponent {
    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public readonly inputType = InputType;
    public readonly buttonComponentSettings = ButtonComponentSettings;

    public readonly password = {
        form: null as imus.form.IFormGroup<FormModel>,
        formGroup: null as FormGroup,
        model: {
            currentPassword: null,
            passGroup: {
                password: null,
                confirmPassword: null
            }
        } as FormModel,
    };


    constructor(
        public readonly matDialog: MatDialog,
        public readonly dialogRef: MatDialogRef<ChangePasswordModalComponent>,
        private readonly _fb: FormBuilder,
        private readonly userService: UserService,
        private readonly alertService: AlertService,
        private readonly _destroy$: ImusDestroyService,
    ) {
        this.password.formGroup = _fb.group(
            {
                currentPassword: new FormControl('', [Validators.required]),
                passGroup:
                    new FormGroup({
                            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
                            confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
                        }, passwordMatchValidator
                    )

            }
        )
    }

    closeModal() {
        this.dialogRef.close();
    }

    onSubmit() {
        if (this.password.formGroup.invalid) return
        this.userService.resetUserPassword(this.password.formGroup.value.currentPassword, this.password.formGroup.value.passGroup.password)
            .pipe(
                catchError(err => {
                    if (err.status == 422) {
                        animationFrameScheduler.schedule(() => {
                            this.password.formGroup.controls['currentPassword'].setErrors({ wrongPassword: true })
                            setTimeout(() => this.password.formGroup.controls['currentPassword'].setErrors(null), 2000)
                        })
                    }
                    return throwError(() => err)
                }),
                takeUntil(this._destroy$)
            )
            .subscribe(() => {
                this.alertService.openSnackbar('Пароль успешно изменен!');
                this.closeModal();
            })
    }
}
