import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { NominatimResponse } from '../../models/nominatim-response.model';
import { MapService } from '../../services/map.service';
import { MapSearchService } from '../../services/mapSearch.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
    selector: 'app-map-search-result',
    templateUrl: './map-search-result.component.html',
    styleUrls: ['./map-search-result.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class MapSearchResultComponent {

    @Input() public results: NominatimResponse[];

    @Input() public localResults: any[]; //TODO: Response type
    // localResultsCount

    @Input() public coordsResults: any[]; //TODO: Response type

    @Output() locationSelected = new EventEmitter();

    // @ViewChild('resultsTabs', {static: false}) tabGroup: MatTabGroup;
    @ViewChild('resultsTabs') tabGroup: MatTabGroup;

    constructor(public searchService: MapSearchService) {}

    ngAfterContentInit(): void {
        setTimeout(() => {
            if(this.tabGroup) {
                this.searchService.localSearchResults$.subscribe(data => {
                    console.log('localSearchResults', data);
                    if(data.length > 0) {
                        this.tabGroup.selectedIndex = 0;
                    }
                })
                this.searchService.searchResults$.subscribe(data => {
                    console.log('searchResults', data);
                    if(data.length > 0) {
                        this.tabGroup.selectedIndex = 1;
                    }
                })
            }
        });
    }

    public selectedIndex: number = 0;

    getSelectedIndex() {
        this.selectedIndex;
    }

    onTabChange(event) {}

    selectResult(result: NominatimResponse) {
        this.locationSelected.emit(result);
    }

    selectLocalResult(result: any) {
        console.log('selectLocalResult', result);

        if (result.lat && result.lon) {
            this.locationSelected.emit({
                displayName: result.name,
                latitude: result.lat,
                longitude: result.lon,
            });
        } else if (result.coords) {
            this.locationSelected.emit({
                displayName: result.name,
                latitude: result.coords[0][1],
                longitude: result.coords[0][0],
            });
        }
    }
}
