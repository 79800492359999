@for (item of items; track item.number) {
@if (item.visible){
<div class="imus-row">
  @if (item.expanded !== undefined) {
  <mat-icon class="icon-chevron" (click)="clickChevron(item)" [class]="{'expanded': item.expanded}">
    chevron_right
  </mat-icon>
  }
  <div class="menu-item" [class]="{ 'selected': selectedItem().number === item.number }"
    [style]="{'text-indent': item?.level ? (item.level + 1) + 'em': item.level === 0 && item.children.length === 0 ? '2em' : undefined}"
    (click)="onItemClick(item)">
    {{item.number !== '0' ? item.number + ' ' :''}}{{item.name}}
  </div>
</div>
}
}