import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuService} from "@services/menu.service";
import {MaterialModule} from "@shared/modules/material/material.module";
import {take} from "rxjs";
import {MenuItem} from "@shared/models/menu";
import {FormatStringPipe} from "@shared/pipes/format-string.pipe";
import {MatTableDataSource} from "@angular/material/table";
import {AlertService} from "@shared/services";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {AlertDialogComponent} from "@app/modules/main/dialogs/alert.dialog/alert.dialog.component";
import {
    AddEditMenuSettingsComponent
} from "@app/modules/account/pages/menu-settings/add-edit-menu-settings-modal/add-edit-menu-settings.component";
import {SharedModule} from "@shared/shared.module";
// import {ButtonComponentSettings} from "@shared/components/button/button.component";

@Component({
    selector: 'app-menu-settings',
    standalone: true,
    imports: [CommonModule, MaterialModule, FormatStringPipe, SharedModule],
    templateUrl: './menu-settings.component.html',
    styleUrls: ['./menu-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MenuSettingsComponent implements OnInit {
    private menuService = inject(MenuService);
    private cdr = inject(ChangeDetectorRef);
    private alertService = inject(AlertService);
    private dialog  = inject(MatDialog);

    //TODO убрать во всех контроллерах
    // protected readonly ButtonComponentSettings = ButtonComponentSettings;
    isLoaded = false;
    menuSettings: MatTableDataSource<MenuItem> = new MatTableDataSource<MenuItem>();
    displayedColumns: string[] = [
        'icon',
        'descr',
        'subject_table_name',
        'front_code',
        'concat_role_descr',
        'actions'
    ];


    ngOnInit(): void {
        this.loadSettingsMenu();
    }

    loadSettingsMenu(): void {
        this.isLoaded = true;
        this.menuService.getMenuSettings()
            .pipe(take(1))
            .subscribe(menuItems => {
                this.menuSettings.data = menuItems;
                this.isLoaded = false;
                this.cdr.markForCheck();
            })
    }

    actionDown(row: MenuItem, idx: number): void {
        const nextMenu = this.menuSettings.data[idx + 1];

        if (!nextMenu) {
            this.alertService.error('Невозможно выполнить перемещение вниз.');
            return;
        }

        const data = {
            self_menu_id: row.id.toString(),
            previous_menu_id: nextMenu.id.toString()
        }
        this.isLoaded = true;
        this.menuService.changeOfPosition(data)
            .pipe(take(1))
            .subscribe(() => this.loadSettingsMenu())
    }

    actionUp(row: MenuItem, idx: number): void {
        const previousMenu = this.menuSettings.data[idx - 1];

        if (!previousMenu) {
            this.alertService.error('Невозможно выполнить перемещение вверх.');
            return;
        }

        const data = {
            self_menu_id: row.id.toString(),
            next_menu_id: previousMenu.id.toString()
        }

        this.isLoaded = true;
        this.menuService.changeOfPosition(data)
            .pipe(take(1))
            .subscribe(() => this.loadSettingsMenu())
    }

    actionEdit(row: MenuItem): void {
        this.openDialog(row.id, row);
    }

    actionDelete(row: MenuItem): void {
        this.openDialogDelete(row)
    }

    actionAdd(): void {
        this.openDialog(null, null);
    }

    reload(): void {
        window.location.reload();
    }

    openDialogDelete(row: MenuItem): void {
        const dialog = new MatDialogConfig();
        dialog.width = '480px';
        dialog.data = {
            okButtonText: 'Удалить',
            cancelButtonText: 'Отменить',
            title: 'Внимание!',
            message: `Вы действительно хотите удалить ${row.descr}?`,
        }

        this.dialog
            .open(AlertDialogComponent, dialog)
            .afterClosed()
            .subscribe((remove?: boolean) => {
                if (remove) {
                    this.menuService.deleteMenu(row.id.toString())
                        .pipe(take(1))
                        .subscribe(() => {
                            this.alertService.success(`Вы успешно удалили ${row.descr}`)
                            this.loadSettingsMenu()
                        })
                }
            })

    }

    openDialog(id: number, row: MenuItem): void {
        const dialog = new MatDialogConfig();
        dialog.width = '480px';
        dialog.id = id ? id.toString() : 'add';
        dialog.data = row;

        this.dialog
            .open(AddEditMenuSettingsComponent, dialog)
            .afterClosed()
            .subscribe(() => this.loadSettingsMenu())
    }
}
