import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonComponentSettings } from "@app/shared/components/button/button.component";
import { InputComponentSettings } from "@app/shared/components/input/input.component";
import { AuthenticationService } from '@app/shared/services';
import { imus } from "@shared/imus";

interface FormModel {
    email: string,
    phone: string,
    password: string
}

@Component({
    selector: 'app-child-account',
    templateUrl: './child-account.component.html',
    styleUrls: ['./child-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChildAccountComponent {
    inputType = InputComponentSettings.InputType;
    buttonType = ButtonComponentSettings.ButtonType;
    public readonly account = {
        form: null as imus.form.IFormGroup<FormModel>,
        model: {
            email: null,
            phone: null,
            password: null
        } as FormModel
    }

    constructor(
        public authenticationService: AuthenticationService,
        private router: Router,
        private readonly _fb: FormBuilder
    ) {
        this.account.form = _fb.fromTypedModel<FormModel>(this.account.model);
    }

    onAccountSettingsClick() {
        this.router.navigate(['account', 'settings']);
    }

    onAccountInformationClick() {
        this.router.navigate(['account', 'information']);
    }

    onMainPageClick() {
        this.router.navigate(["app-taris"]);
    }
}
