<ng-container *ngIf="type === buttonType.icon; else baseBtn">
    <button mat-icon-button
            [type]="isSubmitted ? 'submit' : 'button'"
            (click)="onButtonClick($event)"
            [ngStyle]="basicColor"
            [class.iconbuttondisabled]="disabled === true && color === undefined"
            [class.iconbuttonenabled]="disabled === false && color === undefined"
            [color]="primary ? 'primary' : 'default'"
            [disabled]="disabled">
        <mat-icon class="icon-button">{{icon}}</mat-icon>
    </button>
</ng-container>

<ng-template #baseBtn>
    <!-- [class.blue-button]="type === buttonType.blueButton"
            [class.deep-blue-button]="type === buttonType.deepBlueButton"
            [class.primary-button]="type === buttonType.primaryButton" -->
    <button mat-raised-button
            [class.fit-btn]="fit"
            [color]="type === buttonType.primaryButton || primary || active ? 'primary' : 'default'"
            [type]="isSubmitted ? 'submit' : 'button'"
            [ngStyle]="basicColor"
            [class.large]="large"
            [ngClass]="{fullwidth: fullWidth}"
            (click)="onButtonClick($event)"
            [disabled]="disabled">
        <mat-icon class="btn-icon" *ngIf="icon">{{icon}}</mat-icon>
        {{label}}
        <ng-content></ng-content>
    </button>
</ng-template>

