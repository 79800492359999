import { Pipe, PipeTransform } from '@angular/core';
import { ItemProperty, NodeFeature, NodeRef, NodeType, PlotRef, PlotType } from "@shared/models";
import { OPERATIONS_STATE } from "@app/modules/main/modules/details-info-panel/operations-states";
import { codeCategories } from "@shared/models/code-categories";
import { TableConfigColumn } from "@shared/models/layer-attribute";

@Pipe({
    name: 'attributeValueByType',
    standalone: true
})
export class AttributeValueByTypePipe implements PipeTransform {
    transform(item: NodeRef | PlotRef, column: TableConfigColumn, categories: Map<number, codeCategories.CollectionItemCodesProperties[]>, trigger: unknown): unknown {
        return AttributeValueByTypePipe.transformData(item, column, categories);
    }

    static transformData(item: NodeRef | PlotRef, column: TableConfigColumn, categories: Map<number, codeCategories.CollectionItemCodesProperties[]>): unknown {
        if (column.isHidden) {
            return ''
        }
        const plotOperations = {
            off: 'Отключен',
            on: 'Включен'
        };
        const value = column.name === 'coords' ? [(item as NodeRef).lat, (item as NodeRef).lon] : item[column.name];
        switch (column.type) {
            case 'coords':
                return (value as Array<number>).join();
            case 'NodeFeature':
                return (value as NodeFeature).properties.name;
            case "NodeType":
                return (value as NodeType)?.russian || '--';
            case "operations":
                return (item as PlotRef)?.plot_type ? plotOperations[value ? 'on' : 'off'] : OPERATIONS_STATE.get((item as NodeRef).node_type.id)[value ? 'on' : 'off'];
            case "PlotType":
                return (value as PlotType)?.russian || '--';
            case "CodeCategory":
                return categories.get(column.codeCategory).find(cat => cat.id === (value as ItemProperty)?.properties?.id)?.russian || '--'
            case 'number':
            case 'string':
                return value ?? '--';
            default:
                return value ?? '--';
        }
    }

}
