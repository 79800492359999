<div class="component-area">
    <app-account-header 
        [userName]="account.form?.controls.name?.value" 
        [userType]="'Простой аккаунт'" 
        [buttonLabel]="'Дочерний аккаунт'" 
        (accountButtonClick)="onChildAccountClick()" 
        [editable]="false">
    </app-account-header>

    <div class="inputs-area">

        <!-- <div class="left-buttons">
            <app-button [label]="'Главная'" [fullWidth]="true" (onclick)="onMainPageClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Настройки'" [fullWidth]="true"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Информация'" [fullWidth]="true" (onclick)="onAccountInformationClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Выход'" [fullWidth]="true" (click)="authenticationService.logout()"></app-button>
            <div class="separator"></div>
        </div> -->

        <app-account-left-sidebar></app-account-left-sidebar>

        <div class="account-inputs">
            <app-text-separator [text]="'Глобальный слой'"></app-text-separator>

            <app-text-separator [text]="'Домашняя локация'"></app-text-separator>

        </div>

    </div>
</div>