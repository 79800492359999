import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { DevicesRouteService } from 'src/app/routes/device-route.service';
import { DeviceTypes } from '@app/shared/models/device';
import {
    ConfirmDialogComponent,
    ConfirmDialogSettings,
} from '../components/confirm-dialog/confirm-dialog.component';
import { MainPageService } from '@app/modules/main/services/main-page.service';
import { DevicesGridDialogComponent } from '@app/modules/devices/devices-grid-dialog/devices-grid-dialog.component';
import { LayersStateService } from './layers-state.service';
import { ImusDestroyService } from './destroy.service';
import { EntityType } from '../models/entity-type';

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    private deviceList = new BehaviorSubject<DeviceTypes.IDevicesResponse>({
        devices: [],
        type: ''
    });
    public deviceList$ = this.devicesRouteService.devicesList$;
    public loadingDeviceList$ = this.devicesRouteService.loadingList$;

    private deviceTypeList = new BehaviorSubject<DeviceTypes.IDeviceType[]>([]);
    public deviceTypeList$ = this.deviceTypeList.asObservable();
    private loadingDeviceTypeList = new BehaviorSubject<boolean>(false);
    public loadingDeviceTypeList$ = this.loadingDeviceTypeList.asObservable();

    private device = new BehaviorSubject<DeviceTypes.IDevice>(null);
    public device$ = this.device.asObservable();
    private loadingDevice = new BehaviorSubject<boolean>(false);
    public loadingDevice$ = this.loadingDevice.asObservable();

    private dialog;
    public gridDialogRolledUp: boolean = false;

    constructor(
        private devicesRouteService: DevicesRouteService,
        private matDialog: MatDialog,
        private pageService: MainPageService,
        private readonly layerStateService: LayersStateService,
        private readonly _destroy$: ImusDestroyService
    ) {
        this.layerStateService.state.selectedEntity$.pipe(
            distinctUntilChanged(),
            tap(selectedEntity => {
                if (selectedEntity) {
                    if (selectedEntity.type === EntityType.NODE) {
                        this.nodeSelected.next(selectedEntity.id);
                        this.plotSelected.next(null);
                    }
                    if (selectedEntity.type === EntityType.PLOT) {
                        this.plotSelected.next(selectedEntity.id);
                        this.nodeSelected.next(null);
                    }
                } else {
                    this.nodeSelected.next(null);
                    this.plotSelected.next(null);
                }
            }),
            takeUntil(this._destroy$)
        ).subscribe()
    }

    loadDeviceList() {
        this.devicesRouteService.updateDeviceList();
    }

    loadDeviceTypeList() {
        this.loadingDeviceTypeList.next(true);
        this.devicesRouteService
            // .loadDeviceTypeList()
            .deviceTypeList$
            .pipe(takeUntil(this._destroy$))
            .subscribe((deviceTypes) => {
                this.deviceTypeList.next(deviceTypes);
                this.loadingDeviceTypeList.next(false);
            });
    }

    addDevice(pars: DeviceTypes.IAddEditDeviceRequest) {
        this.devicesRouteService
            .addDevice(pars)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.formsHelperDataClear();
            });
    }

    editDevice(pars: DeviceTypes.IAddEditDeviceRequest, id: number) {
        this.loadingDevice.next(true);
        this.devicesRouteService.editDevice(pars, id).pipe(takeUntil(this._destroy$)).subscribe((device) => {
            // TODO: Запрос перестал возвращать новый объект
            if (device && device.node && device.node.id) {
                this.loadDeviceById(device.node.id);
            }
            this.formsHelperDataClear();
        });
    }

    loadDeviceById(id: number) {
        this.loadingDevice.next(true);
        this.devicesRouteService.loadDeviceById(id).pipe(takeUntil(this._destroy$)).subscribe((device) => {
            this.device.next(device);
            this.loadingDevice.next(false);
        });
    }

    deleteDevice(device: DeviceTypes.IDevice) {
        let deleteResult = new BehaviorSubject<boolean | undefined>(undefined);

        if (this.dialog === undefined || this.dialog.closed) {
            this.dialog = this.matDialog
                .open(ConfirmDialogComponent, {
                    data: {
                        title: 'Удаление устройства телеметрии',
                        text: `Удалить устройство ${device.name}?`,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (
                        result === ConfirmDialogSettings.ConfirmDialogResult.ok
                    ) {
                        this.devicesRouteService
                            .deleteDevice(device.ID)
                            .pipe(takeUntil(this._destroy$))
                            .subscribe(() => deleteResult.next(true));
                    } else deleteResult.next(false);
                });
        }

        return deleteResult.asObservable();
    }

    tokenCheck() {
        this.devicesRouteService.checkAuth();
    }

    // Проверка опции для повторного открытия окна списка устройств, закрытого при переходе в редактирование
    public gridDialogRolledUpCheck() {
        if (this.gridDialogRolledUp) {
            this.gridDialogOpen();
        }
    }

    public gridDialogOpen() {
        this.pageService.deviceGridDialog = this.matDialog.open(
            DevicesGridDialogComponent
        );
    }

    // TODO перенести в deviceStateService
    // Редактируемое устройство для инициализации панели редактирования и проверки статуса для режима клика по карте
    public editableDevice = new BehaviorSubject<DeviceTypes.IDevice>(null);
    public editableDevice$ = this.editableDevice.asObservable();

    public nodeSelected = new BehaviorSubject<number>(null);
    public nodeSelected$ = this.nodeSelected.asObservable();

    public plotSelected = new BehaviorSubject<number>(null);
    public plotSelected$ = this.plotSelected.asObservable();

    public formsHelperDataClear() {
        this.editableDevice.next(null);
        this.nodeSelected.next(null);
        this.plotSelected.next(null);
        this.gridDialogRolledUpCheck();
    }

}
