import { PROFILE_ROUTE } from '@shared/route';

export interface NewMainAccount {
    user_name: string;
    user_email: string;
    user_password: string;
    user_phone: string;
    account_contractor_id: number;
    account_role_id: number;
}

export interface ProfileRoute {
    route: string;
    name: string;
    complianceContractor: ContractorType[];
    order: number;
}

export enum ContractorType {
    /** ЮЛ */
    LEGAL = 201,
    /** ИП */
    INDIVIDUAL = 202,
    /** ФЛ */
    PERSONAL = 203,
}

export const PROFILE_ROUTES: ProfileRoute[] = [
    {
        route: PROFILE_ROUTE.credentials,
        name: 'Учетные данные',
        complianceContractor: [
            ContractorType.LEGAL,
            ContractorType.INDIVIDUAL,
            ContractorType.PERSONAL,
        ],
        order: 1,
    },
    {
        route: PROFILE_ROUTE.additionalAccounts,
        name: 'Дополнительные аккаунты',
        complianceContractor: [
            ContractorType.LEGAL,
            ContractorType.INDIVIDUAL,
            ContractorType.PERSONAL,
        ],
        order: 4,
    },
    {
        route: PROFILE_ROUTE.guests,
        name: 'Гости',
        complianceContractor: [
            ContractorType.LEGAL,
            ContractorType.INDIVIDUAL,
            ContractorType.PERSONAL,
        ],
        order: 5,
    },
    {
        route: PROFILE_ROUTE.legalDetails,
        name: 'Реквизиты компании',
        complianceContractor: [ContractorType.LEGAL],
        order: 2,
    },
    {
        route: PROFILE_ROUTE.individualDetails,
        name: 'Реквизиты ИП',
        complianceContractor: [ContractorType.INDIVIDUAL],
        order: 2,
    },
    {
        route: PROFILE_ROUTE.personal,
        name: 'Персональные данные',
        complianceContractor: [ContractorType.PERSONAL],
        order: 2,
    },
    {
        route: PROFILE_ROUTE.employees,
        name: 'Сотрудники',
        complianceContractor: [ContractorType.LEGAL, ContractorType.INDIVIDUAL],
        order: 3,
    },
];
