<app-spinner *ngIf="(loadingProjects$ | async)!!"></app-spinner>

<!-- <div matDialogTitle>
  <h4>Отчеты телеинспекций</h4>
  <app-text-separator [text]="'Список проектов'"></app-text-separator>
</div> -->

<mat-dialog-header [title]="'Отчеты телеинспекций'">
    <section class="mat-dialog-header-buttons">
        <button mat-icon-button (click)="tooltipsShowAll()" #tooltipHelp="matTooltip" matTooltip="Помощь" [ngClass]="{active: helpActive}">
            <mat-icon>help</mat-icon>
        </button>
        <button mat-icon-button (click)="refreshData()" #tooltipRefresh="matTooltip" matTooltip="Обновить список">
            <mat-icon>refresh</mat-icon>
        </button>
    </section>
</mat-dialog-header>

<mat-dialog-content class="p-10-0">

    <mat-table [dataSource]="projectList$ | async" matSort matSortActive="created_at" (matSortChange)="sortingPagingProjects()" matSortDirection="asc" matSortDisableClear>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Имя</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.properties.name}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controlInfo">
            <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'info'" [disabled]="!ifDialogOpeningAllowed" (click)="onSectionsDialogClick(row)" #tooltipInfo="matTooltip" matTooltip="Список секций" matTooltipPosition="left" [matTooltipShowDelay]="200"></app-button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controlLocation">
            <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'location_on'" [disabled]="!isLocationClickAllowed(row)" (click)="onLocationClick(row)" #tooltipLocation="matTooltip" matTooltip="Показать на карте" matTooltipPosition="right" [matTooltipShowDelay]="200"></app-button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="client">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Клиент</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.client.properties.company}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="contractor">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Подрядчик</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.contractor.properties.company}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Последнее обновление</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.properties.project_updated_at | date: 'dd.MM.yyyy'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Дата создания</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.properties.project_created_at | date: 'hh:mm dd.MM.yyyy' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controlEdit">
            <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'edit'" [disabled]="!ifDialogOpeningAllowed" (click)="onEditProjectClick(row)" #tooltipEdit="matTooltip" matTooltip="Изменить данные проекта" matTooltipPosition="left" [matTooltipShowDelay]="200"></app-button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controlRemove">
            <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'delete'" [disabled]="!ifDialogOpeningAllowed" (click)="onRemoveClick(row)" #tooltipRemove="matTooltip" matTooltip="Удалить данные проекта" matTooltipPosition="right" [matTooltipShowDelay]="200"></app-button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

</mat-dialog-content>

<mat-dialog-actions class="tools">
    <!-- <button mat-raised-button color="primary" size="xl" class="large">
        + Добавить отчет
    </button> -->

    <section>
        <app-upload-inspection-card [data]="undefined"></app-upload-inspection-card>
    </section>

    <app-paginator align="end" [length]="projects?.features?.length" [pageSize]="pageSize" (page)="onPageEvent()" [pageSizeOptions]="pageSizeOptions" [disabled]="loadingProjects$ | async">
    </app-paginator>
</mat-dialog-actions>

<!-- <mat-dialog-actions align="end ">
    <button mat-button mat-dialog-close>Закрыть</button>
</mat-dialog-actions> -->