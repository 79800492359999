<div class="table" *ngIf="(access$ | async)?.data[0].can_read || (access$ | async)?.data[0].can_update; else accessDeniedTpl">
    <mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate"></mat-progress-bar>
    <mat-table
        matSort
        (matSortChange)="sort($event)"
        [dataSource]="(accounts$ | async)?.data">
        <!-- [dataSource]="accounts?.data"> -->
        <ng-container *ngFor="let item of displayed$ | async" [matColumnDef]="item.name">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{item.title}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngSwitch]="item.name" style="justify-content: space-between; word-break: break-all; width: max-content;" >
                <ng-container *ngSwitchCase="item.name === 'start_date' || item.name === 'end_date' ? item.name : ''">
                    <p [title]="element[item.name] | date" class="cell-data"
                       [class.close-account]="item.name === 'end_date'">
                        {{element[item.name] | date}}
                    </p>
                    <p  *ngIf="item.name === 'end_date' && (component$ | async) === 'profile'" class="cell-data close-account">
                        <mat-icon class="actions-icon" (click)="closeAccount(element)"
                                  title="Закрыть аккаунт гостя">logout
                        </mat-icon>
                    </p>
                </ng-container>

                <ng-container *ngSwitchCase="(item.name === 'user_name' || item.name === 'user_id') && component === 'contractor' ? item.name : ''">
                    <p [title]="element[item.name]" *ngIf="item.name === 'user_name'"  class="cell-data">
                        <a [routerLink]="['/', 'account', 'accounts', 'user', element.user_id]">{{element[item.name]}}</a>
                    </p>
                    <p [title]="element[item.name]" *ngIf="item.name === 'user_id'"   class="cell-data">
                        <a [routerLink]="['/', 'account', 'accounts', 'user', element.user_id]">{{element[item.name]}}</a>
                    </p>
                </ng-container>
                <p [title]="element[item.name]" class="cell-data" *ngSwitchDefault>{{element[item.name]}}</p>
            </mat-cell>
        </ng-container>
        <mat-header-row  *matHeaderRowDef="displayedColumns$ | async"></mat-header-row>
        <mat-row *matRowDef="let row; columns: (displayedColumns$ | async);"></mat-row>
    </mat-table>
    <div class="imus-row"></div>
    <div class="imus-row start" *ngIf="(access$ | async)?.data[0].can_create && (component$ | async) === 'profile'">
        <div>
            <app-imus-input
                #control
                [type]="inputType.email"
                imusEmail
                [formControl]="searchEmail"
                [placeholder]="'Электронная почта'"
                label="Введите email гостя"
            ></app-imus-input>
            <field-errors [formFieldErrors]="control.errors"></field-errors>
        </div>
        <app-button
            [disabled]="control.invalid"
            (click)="addGuest()"
            [type]="buttonType.primaryButton"
            label="Подключить гостя"></app-button>
    </div>
    <app-paginator [pageSize]="(pageSettings$ | async)?.pageSize" [pageIndex]="(pageSettings$ | async)?.pageIndex" [length]="(accounts$ | async)?.total" (page)="onPaginateData($event)"></app-paginator>
</div>

<ng-template #accessDeniedTpl>
    <div class="imus-row">Информация не доступна</div>
</ng-template>
