import { DeviceTypes } from "./device";

export namespace MeasurementTypes {
    export interface IMeasurementProperties{
        id: number;
        device_id: number;
        value: string;
        collected_at: Date;
        created_at: Date;
        updated_at: Date;
        deleted_at: Date;
    }

    export interface IMeasurementRequest{
        device: number;
        paginate: number;
    }

    export interface IDeviceMeasurements{
        current_page?: number;
        data: MeasurementTypes.IMeasurementProperties[];
        from?: number;
        last_page?: number;
        per_page?: number;
        to?: number;
        total?: number;
    }

    export interface  IAddMeasurement {        
        token: string;        
        value: number;
        date: string
    }
}