import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-nodes-files-view',
    templateUrl: './nodes-files.view.component.html',
    styleUrls: ['./nodes-files.view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NodesFilesViewComponent {

}
