import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserService} from "@shared/services";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {map, Observable, startWith, switchMap, takeUntil, tap} from "rxjs";

import {ImusDestroyService} from "@services/destroy.service";
import {AccountsViewData, Credentials, UserAccountsInfo} from "@shared/models";
import {MatExpansionModule} from "@angular/material/expansion";
import {PortalModule} from "@angular/cdk/portal";
import {SharedModule} from "@shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {CredentialsComponent} from "@app/modules/account/pages/user-profile/credentials/credentials.component";
import {MainAccountComponent} from "@app/modules/account/pages/user-profile/main-account/main-account.component";
import {
    AdditionalAccountsComponent
} from "@app/modules/account/pages/user-profile/additional-accounts/additional-accounts.component";
import {Accounts, DataAccounts} from "@shared/models/contractors";
import {AccountsService} from "@app/modules/account/services/accounts.service";
import {Sort, SortDirection} from "@angular/material/sort";

@Component({
    selector: 'user-profile',
    standalone: true,
    imports: [CommonModule, MatExpansionModule, PortalModule, RouterLinkActive, SharedModule, ReactiveFormsModule, CredentialsComponent, MainAccountComponent, AdditionalAccountsComponent, RouterLink],
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {
    private userService = inject(UserService);
    private destroy = inject(ImusDestroyService);
    private cdr = inject(ChangeDetectorRef);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private accountsService = inject(AccountsService);

    private readonly _refresh = new EventEmitter()
    userId: string;
    sortSettings: { [x: string]: SortDirection; } = {};
    userAccountsInfo: UserAccountsInfo;
    accounts$: Observable<Accounts>;
    accounts: Accounts;
    dataAccounts$: Observable<AccountsViewData[]>
    credentials: Credentials;
    userAccountSections = [
        {
            fields: [
                {label: 'ID', property: 'user_id'},
                {label: 'Пользователь', property: 'user_name'},
                {label: 'Дата подключения', property: 'start_date'},
                {label: 'Дата отключения', property: 'end_date'},
            ]
        },
        {
            fields: [
                {label: 'Тип аккаунта', property: 'account_type_descr'},
                {label: 'Тип контрагента', property: 'contractor_type_descr'},
                {label: 'ID Контрагента', property: 'contractor_id'},
                {label: 'Контрагент', property: 'contractor_descr'},
            ]
        }
    ];

    ngOnInit() {
        this.userId = this.route.snapshot.params['id']
        // this.route.params
        //     .pipe(takeUntil(this.destroy))
        //     .subscribe(params => this.userId = params['id'])
        this.dataAccounts$ = this._refresh.pipe(
            startWith(undefined),
            switchMap(() => this.userService.getUserAccountsView({
                    'where[user_id]': this.userId,
                    'where[is_main]': false,
                    'paginate': false,
                    ...this.sortSettings
                }).pipe(map(data => data as AccountsViewData[]))
            ))
    
        this.reload();
    }

    public sortChanges(sortSettings: Sort) {
        if (!sortSettings) {
            this.sortSettings = {};
        } else {
            const key = `orderBy[${sortSettings.active}]`
            this.sortSettings = { [key]: sortSettings.direction }
        }
        this._refresh.emit();
    }

    get access(): string {
        if (!this.credentials) {
            return null;
        }

        if (this.credentials.can_update) {
            return 'update';
        }

        if (this.credentials.can_read) {
            return 'read';
        }

        return null;
    }

    getCredentials(): void {
        this.userService.getCredentials()
            .pipe(takeUntil(this.destroy))
            .subscribe(credentials => {
                this.credentials = credentials;
                this.cdr.markForCheck();
            });
    }


    isDateField(property: string): boolean {
        return property.toLowerCase().includes('date');
    }

    getUserId(): void {
        this.getUserAccountInfo();
        this.getData();
        // this.getDataAccounts()
    }

    getData() {
        // this.accounts$ = this.accountsService.getAccounts(params)
        //     .pipe(
        //         takeUntil(this.destroy),
        //         map((dataAccounts: DataAccounts) => (dataAccounts.data as Accounts[])[0])
        //     )
        const paramsAccounts = {
            'where[user_id]': this.userId,
            'where[is_main]': true
        };
        this.accountsService.getAccounts(paramsAccounts)
            .pipe(
                map((dataAccounts: DataAccounts) => (dataAccounts.data as Accounts[])[0]),
                takeUntil(this.destroy),
            )
            .subscribe(accounts => this.accounts = accounts)

    }

    // getDataAccounts() {
    //     let paramsDataAccounts: any
    //     // = {
    //     //     'where[user_id]': this.userId,
    //     //     'where[is_main]': false,
    //     //     'paginate': false
    //     // };

    //     this.dataAccounts$ = this.sortRefresh.pipe(
    //         startWith(undefined),
    //         tap(() => paramsDataAccounts = {
    //             'where[user_id]': this.userId,
    //             'where[is_main]': false,
    //             'paginate': false,
    //             ...this.sortSettings
    //         }),
    //         switchMap(() => this.userService.getUserAccountsView(paramsDataAccounts)
    //                     .pipe(map(data => data as AccountsViewData[]))
    //                     ))
    // }

    getUserAccountInfo(): void {
        const params = {
            'where[user_id]': this.userId,
            'paginate': false,
            // ...this.sortSettings
        }
        this.userService.getUserAccountInfo(params)
            .pipe(takeUntil(this.destroy))
            .subscribe(userAccountsFeature => {
                this.userAccountsInfo = userAccountsFeature[0] as UserAccountsInfo;
                this.cdr.markForCheck();
            });
    }

    backToList() {
        this.router.navigate(['/', 'account', 'accounts']);
    }

    reload() {
        this.getCredentials();
        this.getUserId();
        this._refresh.emit()
    }
}
