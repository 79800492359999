import { Signal } from "@angular/core";
import { codeCategories } from "@shared/models/code-categories";
import { CodeCategory } from "@shared/models/code-category";

export type LayerId = number;

export interface TableConfig {
    columns: Record<'plots' | 'nodes', Array<TableConfigColumn>>;
    tabs: Array<{ name: keyof TableConfig['columns']; displayedName: string }>;
    categories: Signal<Map<number, codeCategories.CollectionItemCodesProperties[]>>;
}

export type ColumnType = 'string' | 'number' | 'boolean' | 'NodeType' | 'operations' | 'NodeFeature' | 'CodeCategory' | 'PlotType' | 'coords';

export interface TableConfigColumn {
    type: ColumnType;
    name: string;
    codeCategory?: CodeCategory;
    isHidden: boolean;
    minWidth: string;
}

export const TABLE_CONFIG: TableConfig = {
    categories: null,
    columns: {
        nodes: [
            {
                name: 'id',
                type: 'string',
                isHidden: true,
                minWidth: '30px'
            },
            {
                name: 'name',
                type: 'string',
                isHidden: false,
                minWidth: '90px'
            },
            {
                name: 'node_type',
                type: 'NodeType',
                isHidden: false,
                minWidth: '90px'
            }, {
                name: 'coords',
                type: 'coords',
                isHidden: false,
                minWidth: '120px'
            }, {
                name: 'height_mark',
                type: 'number',
                isHidden: false,
                minWidth: '120px'
            }, {
                name: 'well_depth',
                type: 'number',
                isHidden: false,
                minWidth: '100px'
            }, {
                name: 'material',
                type: 'CodeCategory',
                codeCategory: CodeCategory.material,
                isHidden: false,
                minWidth: '110px'
            },
            {
                name: 'notes',
                type: 'string',
                isHidden: false,
                minWidth: '120px'
            }, {
                name: 'on',
                type: 'operations',
                isHidden: false,
                minWidth: '90px'
            }],
        plots: [
            {
                name: 'id',
                type: 'string',
                isHidden: true,
                minWidth: '30px'
            },
            {
                name: 'name',
                type: 'string',
                isHidden: false,
                minWidth: '90px'
            }, {
                name: 'start_node',
                type: 'NodeFeature',
                isHidden: false,
                minWidth: '120px'
            }, {
                name: 'end_node',
                type: 'NodeFeature',
                isHidden: false,
                minWidth: '120px'
            }, {
                name: 'length',
                type: 'number',
                isHidden: false,
                minWidth: '90px'
            }, {
                name: 'section_length',
                type: 'number',
                isHidden: false,
                minWidth: '110px'
            }, {
                name: 'laying_year',
                type: 'string',
                isHidden: false,
                minWidth: '120px'
            }, {
                name: 'diameter',
                type: 'number',
                isHidden: false,
                minWidth: '100px'
            }, {
                name: 'slope',
                type: 'number',
                isHidden: false,
                minWidth: '100px'
            }, {
                name: 'shape',
                type: 'CodeCategory',
                codeCategory: CodeCategory.shape,
                isHidden: false,
                minWidth: '100px'
            }, {
                name: 'material',
                type: 'CodeCategory',
                codeCategory: CodeCategory.material,
                isHidden: false,
                minWidth: '120px'
            }, {
                name: 'coating',
                type: 'CodeCategory',
                codeCategory: CodeCategory.coating,
                isHidden: false,
                minWidth: '130px'
            }, {
                name: 'location',
                type: 'CodeCategory',
                codeCategory: CodeCategory.location,
                isHidden: false,
                minWidth: '130px'
            }, {
                name: 'use',
                type: 'CodeCategory',
                codeCategory: CodeCategory.use,
                isHidden: false,
                minWidth: '140px'
            }, {
                name: 'notes',
                type: 'string',
                isHidden: false,
                minWidth: '120px'
            }, {
                name: 'on',
                type: 'operations',
                isHidden: false,
                minWidth: '100px'
            }, {
                name: 'plot_type',
                type: 'PlotType',
                isHidden: false,
                minWidth: '100px'
            }]
    },
    tabs: [{ name: 'nodes', displayedName: 'Точки' }, { name: 'plots', displayedName: 'Линии' }]
}
