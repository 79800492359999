import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";

import {environment} from "@environments/environment";
import {
    AccountsFeature,
    DataAccounts,
    SysStatus,
    SysTypes
} from "@shared/models/contractors";

type SysParam = string | number | boolean;

@Injectable({
  providedIn: 'root'
})
export class ContractorsService {

    private http = inject(HttpClient);

    getSysTypes(paramsArray: [string, SysParam][]): Observable<SysTypes[]> {
        return this.http.get<AccountsFeature>(`${environment.apiUrl}/sys_types?${paramsArray.reduce((strParams, param, i) => strParams += `${i === 0 ? '' : '&'}${param[0]}=${param[1]}`, '')}`)
            .pipe(map(accountsFeature => accountsFeature.data as SysTypes[]));
    }

    getSysStatus(): Observable<SysStatus[]> {
        return this.http.get<AccountsFeature>(`${environment.apiUrl}/sys_statuses?where[subject_id]=2&paginate=false`)
            .pipe(map(accountsFeature => accountsFeature.data as SysStatus[]));
    }

    getContractors(params?: any, responseType: 'blob' | 'json' = 'json'): Observable<Blob | DataAccounts> {
        const options = { params } as any;

        return this.http.get<ArrayBuffer | AccountsFeature>(`${environment.apiUrl}/view/contractors`, { ...options, responseType })
            .pipe(map((response: any) => {
                if (responseType === 'json') {
                    return (response as AccountsFeature).data as DataAccounts;
                } else {
                    return response as Blob;
                }
            }));
    }
}
