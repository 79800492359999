import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RastrEditorPageComponent } from './pages/rastr-editor-page/rastr-editor-page.component';
import { LayerPageComponent } from './pages/layer-page/layer-page.component';
import { RastrPageComponent } from './pages/rastr-page/rastr-page.component';

//TODO REMOVE
const titlePrefix: string = 'Тарис';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    title: `${titlePrefix} - Слои`,
    component: LayerPageComponent,
  },
  {
    path: 'rastr',
    component: RastrPageComponent,
    title: `${titlePrefix} - Растровые слои`,
  },
  {
    path: 'rastr/editor',
    component: RastrEditorPageComponent,
    title: `${titlePrefix} - Редактор растровых слоев`,
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayerRoutingModule {}
