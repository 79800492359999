<div class="container" *ngIf="mediaType == 'stream'">
    <vg-player>
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls>
            <vg-play-pause></vg-play-pause>
            <vg-playback-button></vg-playback-button>

            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

            <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

            <vg-track-selector></vg-track-selector>
            <vg-mute></vg-mute>
            <vg-volume></vg-volume>

            <vg-fullscreen></vg-fullscreen>
        </vg-controls>

        <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls style="max-width: 100%;">
            <source src={{src}} type="video/mp4">
        </video>
    </vg-player>
</div>

<div *ngIf="mediaType == 'video'">
    <object id="ie_plugin" classid="clsid:67DABFBF-D0AB-41fa-9C46-CC0F21721616" width="660" height="300"
        codebase="http://go.divx.com/plugin/DivXBrowserPlugin.cab">
        <param name="custommode" value="stage6" />
        <param name="autoPlay" value="false" />
        <param name="src" value={{src}} />
        <param name="bannerEnabled" value="false" />

        <embed id="np_plugin" type="video/divx" src={{src}} custommode="stage6" width="660" height="300"
            autoPlay="false" bannerEnabled="false" pluginspage="http://go.divx.com/plugin/download/" />
    </object>
</div>

<ng-container *ngIf="mediaType == 'image'">
    <img id="img" src={{src}}>
</ng-container>

<ng-container *ngIf="!mediaType">
    <p>Данный формат не поддерживается</p>
</ng-container>