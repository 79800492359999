@if({
    info: info$ | async,
    isLayerGlobal: isGlobalLayerSlelected$ | async
}; as data) {

<ng-container *ngIf="data.info?.id; else emptyTpl">
    <section *ngIf="data.info.type as type" [formGroup]="entity[type].form" [ngSwitch]="type">
        <app-details-plot-view 
            *ngSwitchCase="'plot'"
            [form]="entity[type].form" 
            [item]="data.info.plot" 
            [editingField]="currentEditPlotField$ | async" 
            (fieldValueChange)="editPlot($event)" 
            [isLayerGlobal]="data.isLayerGlobal"
            >
        </app-details-plot-view>
        <app-details-node-view 
            *ngSwitchCase="'node'"
            [form]="entity[type].form" 
            [item]="data.info.node" 
            (fieldValueChange)="editNode($event)" 
            [isLayerGlobal]="data.isLayerGlobal"
            >
        </app-details-node-view>
    </section>
</ng-container>

<ng-container *ngIf="data.info && !data.isLayerGlobal">
    <!-- Файлы -->
    <section>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p class="imus-row title">Файлы</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <section *ngIf="data.info.type as type">
                <div class="imus-row-item" *ngIf="data.info[type]?.files?.length; else emptyFilesTpl">
                    <div class="imus-row file-container" *ngFor="let file of data.info[type].files">
                        <a color="primary" [href]="file.src" target="_blank" mat-icon-button>
                            <mat-icon>note</mat-icon>
                        </a>
                        <span [title]="file.name" class="title">{{file.name}}</span>
                        <button color="warn" (click)="deleteFile(file.id)" mat-icon-button>
                                <mat-icon>delete_forever</mat-icon>
                            </button>
                    </div>
                </div>
                <div class="imus-row">
                    <imus-file-input title="Добавить файл" [formControl]="uploadFileControl" *ngIf="!(uploaded$ | async); else uploadTpl">
                        <button mat-stroked-button color="primary">
                                Добавить файл
                                <mat-icon>add</mat-icon>
                            </button>
                    </imus-file-input>
                </div>
            </section>
        </mat-expansion-panel>
    </section>

    <!--Телеинспекция-->
    <section *ngIf="data.info?.type === 'plot'">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p class="imus-row title">Телеинспекция</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <section>
                <div class="imus-row">
                    <div class="imus-row-item" *ngIf="(inspectionList$ | async) as inspections">
                        <ng-container *ngIf="inspections.length; else emptyInspectionsTpl">
                            <div class="imus-row file-container" *ngFor="let inspection of inspections">
                                <button [title]="'Отчет от ' + (inspection.section?.project?.properties?.project_created_at | date: 'dd.MM.yyyy')"
                                        mat-button class="inspect-btn" (click)="onInspectClick(inspection)"><span
                                    class="title"
                                    >Отчет от {{inspection.section?.project?.properties?.project_created_at | date: 'dd.MM.yyyy'}}</span>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </section>
            <section>
                <app-upload-inspection-card [data]="undefined"></app-upload-inspection-card>
            </section>
        </mat-expansion-panel>
    </section>

    <!-- Телеметрия -->
    <section>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p class="imus-row title">Телеметрия</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="imus-row">
                <div class="imus-row-item">
                    <div class="imus-row file-container" *ngFor="let item of (devices$ | async)">
                        <button (click)="editDevice(item)" [title]="'Отредактировать ' + item.name" mat-button class="inspect-btn"><span
                                class="title">{{item.name}}</span></button>
                    </div>
                </div>
            </div>
            <div class="imus-row">
                <div class="imus-row-item">
                    <button title="Добавить прибор" (click)="addDevice()" mat-stroked-button color="primary">
                            Добавить прибор
                            <mat-icon>add</mat-icon>
                        </button>
                </div>
            </div>
        </mat-expansion-panel>
    </section>
</ng-container>

<ng-template #emptyTpl>
    <mat-card>
        <mat-card-header>
            <mat-card-title>На карте нет выбранных элементов</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Для просмотра информации выберите точку или участок на карте</p>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #emptyFilesTpl>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Нет загруженных файлов</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Загрузите файлы</p>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #emptyInspectionsTpl>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Нет загруженных отчетов</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Загрузите отчеты</p>
        </mat-card-content>
    </mat-card>
</ng-template>
<ng-template #uploadTpl>
    <button (click)="abort()" class="upload-btn" mat-stroked-button color="warn" title="Прервать загрузку">
            <mat-spinner
                diameter="26">
            </mat-spinner>
            <mat-icon>close</mat-icon>
        </button>
</ng-template>
}
