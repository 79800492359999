import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routes, LayerRoutingModule } from './layer-routing.module';
import { LayerPageComponent } from './pages/layer-page/layer-page.component';
import { RastrPageComponent } from './pages/rastr-page/rastr-page.component';
import { RastrEditorPageComponent } from './pages/rastr-editor-page/rastr-editor-page.component';
// import { RastrBindListComponent } from './components/rastr-bind-list/rastr-bind-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MaterialModule } from '@app/shared/modules/material/material.module';
// import { RastrBindService } from './services/rastr-bind.service';
import { MapModule } from '../map/map.module';
// import { MatTabsModule } from '@angular/material/tabs';




@NgModule({
  declarations: [
    LayerPageComponent,
    RastrPageComponent,
    RastrEditorPageComponent,
    // RastrBindListComponent,
  ],
  imports: [
    CommonModule,
    // RouterModule.forRoot(routes, {
    //     // initialNavigation: 'enabled',
    // }),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    LayerRoutingModule,
    // LayerAddModule,
    // MainModule,
    MapModule,
  ],
  exports: [
    // RastrBindListComponent,
  ],
  providers: [
    // RastrBindService,
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class LayerModule { }
