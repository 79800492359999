import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export namespace ConfirmDialogSettings{
  export enum ConfirmDialogResult{
    ok,
    cancel
  }
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, text: string},
  private dialiogRef: MatDialogRef<ConfirmDialogComponent>){}

  closeOk(){
    this.dialiogRef.close(ConfirmDialogSettings.ConfirmDialogResult.ok);
  }

  closeCancel(){
    this.dialiogRef.close(ConfirmDialogSettings.ConfirmDialogResult.cancel);
  }

}
