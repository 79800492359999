// import { CONTRACTROR_ROUTE } from '@shared/route';

// export interface NewMainAccount {
//     user_name: string;
//     user_email: string;
//     user_password: string;
//     user_phone: string;
//     account_contractor_id: number;
//     account_role_id: number;
// }

export const CONTRACTROR_ROUTE = {
    legalDetails: 'legal-details',
    // individualDetails: 'individual-details',
    employees: 'employees',
    guests: 'guests',
    personal: 'personal'
}


export interface ContactorRoute {
    route: string;
    name: string;
    complianceContractor: ContractorType[];
    // complianceContractor: any[];
    order: number;
}

export enum ContractorType {
    /** ЮЛ */
    LEGAL = 201,
    /** ИП */
    INDIVIDUAL = 202,
    /** ФЛ */
    PERSONAL = 203,
}

export const CONTRACTROR_ROUTES: ContactorRoute[] = [
    {
        route: CONTRACTROR_ROUTE.personal,
        name: 'Персональные данные',
        complianceContractor: [
            ContractorType.PERSONAL
        ],
        order: 1,
    },
    {
        route: CONTRACTROR_ROUTE.legalDetails,
        name: 'Реквизиты компании',
        complianceContractor: [
            ContractorType.LEGAL,
            ContractorType.INDIVIDUAL
        ],
        order: 1,
    },
    {
        route: CONTRACTROR_ROUTE.employees,
        name: 'Сотрудники',
        complianceContractor: [
            ContractorType.LEGAL,
            ContractorType.INDIVIDUAL
        ],
        order: 2,
    },
    {
        route: CONTRACTROR_ROUTE.guests,
        name: 'Гости',
        complianceContractor: [
            ContractorType.LEGAL,
            ContractorType.INDIVIDUAL,
            ContractorType.PERSONAL,
        ],
        order: 3,
    },
];
