import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ButtonComponentSettings } from '@shared/components/button/button.component';

/**
 * Компонент, переключающий представление двух типов, например, для редактирования контента
 *
 * В селектор необходимо поставить два элемента с классами `.content` и `.editor`
 * */
@Component({
    selector: 'app-switch-editor',
    templateUrl: './switch-editor.component.html',
    styleUrls: ['./switch-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchEditorComponent {

    /** Признак отключения действий на кнопках */
    @Input()
    disable: boolean = false;

    /** Отображать кнопку редактирования поля, false для глобальных слоев */
    @Input()
    showButton: boolean = true;

    @Input()
    set closeTrigger(trigger: boolean) {
        if (trigger) {
            this.closeEditor();
        }
    }

    private readonly _isEditing$ = new BehaviorSubject(false);
    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public readonly isEditing$: Observable<boolean> = this._isEditing$;

    public closeEditor(): void {
        if (this.disable) {
            return;
        }
        this._isEditing$.next(false);
    }

    public openEditor(): void {
        if (this.disable) {
            return;
        }
        this.closeTrigger = false;
        this._isEditing$.next(true);
    }
}
