<mat-form-field appearance="fill" *ngIf="specification$ | async as specification; else noSpecificationTpl">
  <mat-label>{{title$ | async}}</mat-label>
  <mat-select (selectionChange)="matSelectionChange($event)" [formControl]="innerControl" [multiple]="multiple$ | async" [setFocus]="setInitFocus">
    <mat-option *ngFor="let item of items$ | async" [value]="item[specification.key]">{{item[specification.value]}}</mat-option>
  </mat-select>
</mat-form-field>

<ng-template #noSpecificationTpl>
  <p>В компонент селектора не передана спецификация</p>
</ng-template>
