import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { MatTabsModule } from "@angular/material/tabs";
import { imus } from "@shared/imus";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "@shared/shared.module";
import {
    BehaviorSubject,
    catchError,
    map,
    Observable,
    of,
    ReplaySubject,
    shareReplay,
    switchMap,
    takeUntil,
    tap
} from "rxjs";
import { AlertService, UserService } from "@shared/services";
import { ButtonComponentSettings } from "@shared/components/button/button.component";
import { AccountService } from "@app/modules/account/services/account.service";
import { ImusDestroyService } from "@services/destroy.service";
import { ContractorsView, ContractorsViewData, UserContractorsReq } from "@shared/models";
import { finalize } from "rxjs/operators";
import {
    BankFormModel,
    CompanyDetails,
    TemplateField
} from "@app/modules/account/pages/profile/components/company-details/company-details";
import { ContractorService } from '@app/modules/account/services/contractor';
import { ActivatedRoute } from '@angular/router';
import { ContractorsService } from '@app/modules/account/services/contractors.service';
import { DadataBank, DadataPerson, DadataService } from '@app/modules/account/services/dadata.service';
import { DadataListDialogComponent } from '../../../profile/components/company-details/components/dadata-list.dialog/dadata-list.dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataAccounts } from '@app/shared/models/contractors';

interface Details<T extends { [key: string]: any }> {
    main: {
        form: imus.form.IFormGroup<T>,
        model: T,
        fields$: ReplaySubject<TemplateField[][]>
    };
    bank: {
        form: imus.form.IFormGroup<BankFormModel>,
        model: BankFormModel,
        fields$: ReplaySubject<TemplateField[][]>
    };
}

/** Реквизиты компании */
@Component({
    selector: 'app-company-details',
    standalone: true,
    imports: [MatTabsModule, ReactiveFormsModule, SharedModule, NgIf, NgForOf, AsyncPipe],
    templateUrl: './contractor-company-details.component.html',
    styleUrls: ['./contractor-company-details.component.scss'],
    providers: [ImusDestroyService],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractorCompanyDetailsComponent implements OnInit {
    private readonly _destroy$ = inject(ImusDestroyService);
    private readonly _alertService = inject(AlertService);
    private readonly _fb = inject(FormBuilder);
    private readonly _dialog = inject(MatDialog);
    private readonly _dadataService = inject(DadataService);
    public companyDetails: CompanyDetails;
    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public readonly refresh = new EventEmitter<void>();
    public readonly userService = inject(UserService);
    public readonly contractorsService = inject(ContractorsService);
  
    public readonly account$ = inject(AccountService).account$;
    public readonly contractor$ = inject(ContractorService).contractor$;

    private route = inject(ActivatedRoute);

    public readonly contractorsView$: Observable<ContractorsViewData | null> = this.refresh.pipe(
        tap(() => this.isLoaded$.next(true)),
        switchMap(() => this.contractor$),
        tap(acc => {
            // console.log('ContractorCompanyDetailsComponent contractorsView$', acc);
            this.isLoaded$.next(false)
            if(acc?.type_id) {
                this.companyDetails = new CompanyDetails(+acc.type_id);
                this.details.main.model = this.companyDetails.formModel;
                // console.log('contractorsView companyDetails', this, this.companyDetails);
            }
        }),
        catchError(_ => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    public isLoaded$ = new BehaviorSubject(false);
    public readonly onlyRead$: Observable<boolean>;
    public readonly details: Details<{ [key: string]: any; }> = {
        bank: {
            form: null,
            model: {
                bank_descr: null,
                bank_bik: null,
                bank_account: null,
                bank_contractor_account: null,
                bank_inn: null,
                bank_kpp: null,
                bank_okpo: null,
                bank_swift: null,
                type_id: null,
                id: null,
            },
            fields$: new ReplaySubject<TemplateField[][]>(1)
        },
        main: {
            form: null,
            model: {},
            fields$: new ReplaySubject<TemplateField[][]>(1)
        }
    }

    public readonly sysStatusTypes$ = this.contractorsService.getSysStatus().pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    )

    constructor() {
        this.onlyRead$ = this.userService.contractorsAccess$.pipe(
            map(res => res.data[0].can_read && !res.data[0].can_update),
            tap(onlyRead => {
                if (onlyRead) {
                    this.details.main.form.disable();
                }
            })
        );
    }

    ngOnInit(): void {
        this.init();
    }

    public onSubmit(name: string): void {
        if (this.details[name].form.invalid) {
            return;
        }
        const res: UserContractorsReq = Object.assign({ is_rights_granted: true }, this.details[name].form.value) as unknown as UserContractorsReq;
        of(this.isLoaded$.next(true)).pipe(
            switchMap(() => this.userService.setUserContractors(res)),
            switchMap(_ => this.contractorsService.getContractors({['where[id]']: res.id})),
            map((res: any) =>  res.data[0]),
            // tap(value => console.log('Submit contractor', value)),
            tap(contractor => this.contractor$.next(contractor)),
            finalize(() => this.isLoaded$.next(false)),
            takeUntil(this._destroy$)
        ).subscribe(() => this.refresh.next());
    }

    public resetChangeInForm(name: string): void {
        this.details[name].form.patchValue(this.details[name].model);
        this.setPristineFormByName(name);
    }

    private setPristineFormByName(name: string): void {
        this.details[name].form.markAsPristine();
        this.details[name].form.markAsUntouched();
    }

    private init(): void {
        this.contractorsView$.pipe(
            takeUntil(this._destroy$)
        ).subscribe(info => {

            // console.log('info', info);
            if (!info) {return};
            if (info) {
                this.details.main.model = Object.keys(this.details.main.model).reduce((acc, key) => {
                    acc[key] = info[key];
                    return acc;
                }, {});
                this.details.bank.model = <BankFormModel>Object.keys(this.details.bank.model).reduce((acc, key) => {
                    acc[key] = info[key];
                    return acc;
                }, {});
            }
            if (!this.details.main.form && !this.details.bank.form) {
                this.details.main.form = this._fb.fromTypedModel(this.details.main.model);
                this.details.bank.form = this._fb.fromTypedModel(this.details.bank.model);
            } else {
                this.details.main.form.patchValue(this.details.main.model);
                this.details.bank.form.patchValue(this.details.bank.model);
            }
            this.details.main.fields$.next(this.companyDetails.mainFields);
            this.details.bank.fields$.next(this.companyDetails.bankFields);
            this.setPristineFormByName('main');
            this.setPristineFormByName('bank');
        });
        this.refresh.next();
    }

    public getInfo<T>(param: 'inn' | 'short_name' | 'bik', value: T) {
        this._dadataService.getDadata({ [param]: value }).pipe(
            tap(data => {
                if (!data.length) {
                    this._alertService.openSnackbar('Ничего не найдено по переданному значению, попробуйте изменить значение и повторить поиск');
                }
            }),
            switchMap(data => data.length
                ? this._dialog.open(DadataListDialogComponent, {
                    width: '700px',
                    data: {
                        list: data,
                        type: param
                    }
                }).afterClosed()
                : of(null)
            ),
            catchError(err => {
                this._alertService.openSnackbar('Произошла ошибка в работе сервиса. Попробуйте позже');
                return of(err);
            }),
            takeUntil(this._destroy$)
        ).subscribe((result: (DadataPerson | DadataBank) | null) => {
            // console.log('modal result', result)
            if (result) {
                if (param === 'bik') {
                    const form = this.details.bank.form;
                    Object.keys(result).forEach(key => {
                        form.controls[key].patchValue(result[key])
                    })
                    form.markAsDirty()
                } else {
                    const form = this.details.main.form;
                    // console.log('form controls', form.controls)
                    Object.keys(result).forEach(key => {
                        form.controls[key]?.patchValue(result[key])
                    })
                    form.controls['person_name_last']?.patchValue(result['persons_name_last'])
                    form.controls['person_name_first']?.patchValue(result['persons_name_first'])
                    form.controls['person_name_second']?.patchValue(result['persons_name_second'])
                
                    form.markAsDirty()
                }
            }
        });
    }

}
