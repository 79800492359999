import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup
} from '@angular/forms';
import { imus } from "@shared/imus";
import { Attachment } from "@shared/attachment";

export {};

interface ToModelConvertOptions {
	[selector: string]: (value: null | boolean | number | string | Date ) =>
	null | boolean | number | string;
}

/**
 * Обогатитель внешнего класса @see @angular/forms/FormGroup
 */
declare module '@angular/forms' {

	interface FormGroup {
		/** Флаг, что в данный момент идет передача данных формы */
		submitting: boolean;

		/**
         * Метод обновления данных модели из представления.
         * Учитывает конвертирование иерархии вложенных объектов.
         *
         * @param model
         * @param convertors - Дополнительные правила преобразования данных меду моделью и представлением.
         * @use
         *   form.toModel<IModel | TModel>(model$.getValue(), {
         *     field: (value: Date | string | ...) => ... return string,
         *     ...
         *   }
         * @example FormGroup({
         *   b: FormControl('new-value-1'),
         *   c: FormGroup({
         *     d: FormControl('new-value-3')
         *   })
         * }) >> {
         *   b: 'new-value-1',
         *   e: 'old-value-2',
         *   c: {
         *     d: 'new-value-3'
         *   }
         * }
         */
		toModel<TModel>(model: TModel, convertors?: ToModelConvertOptions): TModel;
	}

}

FormGroup.prototype.submitting = false;

const convertObjectFields = <TModel>(obj: TModel, _convertors: ToModelConvertOptions): void => {
	const field = Array.isArray(obj)
		? obj
		: Object.keys(obj);
	field.forEach(key => {
		if (!_convertors[key]) {
			if (obj[key] !== null
				&& obj[key] instanceof Object
				&& !(obj[key] instanceof Date)) {
				convertObjectFields(obj[key], _convertors);
			}
			return;
		}
		const valueType = typeof obj[key];
		if (obj[key] === null
			|| valueType === 'boolean'
			|| valueType === 'number'
			|| valueType === 'string'
			|| obj[key] instanceof Date) {
			obj[key] = _convertors[key](obj[key]);
		}
	});
};

FormGroup.prototype.toModel = function <TModel> (model: TModel, convertors?: ToModelConvertOptions): TModel {
	const value = this.value;
	if (convertors) {
		convertObjectFields(value, convertors);
	}
	Object.assign(model, value);
	return model;
};

interface FromModelConvertOptions {
	[selector: string]: (value: null | boolean | number | string) =>
	null | boolean | number | string | Date;
}

type FromModelConvertOptionsTyped<TModel> = {
	[P in keyof TModel]?: (value: null | boolean | number | string) => null | boolean | number | string | Date;
};

/** Обогатитель внешнего класса @see FormBuilder */
declare module '@angular/forms' {

	interface FormBuilder {
		/**
         * Метод конвертирования модели в представление.
         * Учитывает конвертирование иерархии вложенных объектов.
         *
         * @param value
         * @param convertors - Дополнительные правила преобразования данных меду моделью и представлением.
         * @use
         *   formBuilder.fromModel<IModel | TModel>(model, {
         *     field: (value: string | number | ...) => ... return Date,
         *     ...
         *   })
         * @example {
         *   b: '',
         *   c: {
         *     d: ''
         *   }
         * } >> FormGroup({
         *   b: FormControl(''),
         *   c: FormGroup({
         *     d: FormControl('')
         *   })
         * })
         */
		fromTypedModel<TModel extends Partial<{ [K in keyof TModel]: TModel[K] }>>(
			value: TModel,
			convertors?: FromModelConvertOptionsTyped<TModel>
		): imus.form.IFormGroup<TModel>;
		/** @deprecated */
		fromModel<TModel>(value: TModel, convertors?: FromModelConvertOptions): FormGroup;
	}

}

/**
 * @deprecated
 */
FormBuilder.prototype.fromModel = function <TModel>(value: TModel, convertors: FromModelConvertOptions): UntypedFormGroup {
	const controls: { [key: string]: FormGroup | FormArray } = {};
	Object.keys(value).forEach(key => {
		if (value[key] === null
			|| value[key] instanceof Date
			|| value[key] instanceof Attachment
			|| !(value[key] instanceof Object)) {
			controls[key] = convertors?.[key]
				? convertors[key](value[key])
				: value[key];
		} else if (Array.isArray(value[key])) {
			const formArray = value[key].map(item => this.fromModel(item, convertors));
			controls[key] = this.array(formArray);
		} else {
			controls[key] = this.fromModel(value[key], convertors);
		}
	});
	const result = new UntypedFormGroup({});
	Object.keys(controls).forEach(key =>
		result.addControl(key, controls[key] instanceof UntypedFormGroup || controls[key] instanceof UntypedFormArray
			? controls[key]
			: new UntypedFormControl(controls[key])));
	return result;
};

FormBuilder.prototype.fromTypedModel = function <TModel extends Partial<{ [K in keyof TModel]: TModel[K] }>>(
	value: TModel,
	convertors?: FromModelConvertOptionsTyped<TModel>
): imus.form.IFormGroup<TModel> {
	const controls:
	{ [key: string]: FormGroup | FormArray } = {};
	Object.keys(value).forEach(key => {
		if (value[key] === null
			|| value[key] instanceof Date
			|| value[key] instanceof Attachment
			|| !(value[key] instanceof Object)) {
			controls[key] = convertors?.[key]
				? convertors[key](value[key])
				: value[key];
		} else if (Array.isArray(value[key])) {
			const formArray = value[key].map(item => this.fromTypedModel(item, convertors));
			controls[key] = this.array(formArray);
		} else {
			controls[key] = this.fromTypedModel(value[key], convertors);
		}
	});
	const result = new FormGroup({});
	Object.keys(controls).forEach(key =>
		result.addControl(key,
			(controls[key] instanceof FormGroup || controls[key] instanceof FormArray)
				? controls[key]
				: new FormControl(controls[key])));
	return result as imus.form.IFormGroup<TModel>;
};
