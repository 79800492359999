import { ChangeDetectorRef, ContentChildren, Directive, DoCheck, Input, Optional, QueryList, ViewContainerRef } from '@angular/core';
import { FormGroupDirective, ValidationErrors, FormGroup, FormControl } from '@angular/forms';
import { FormFieldErrorLabelComponent } from '../form-field-error-label/form-field-error-label.component';
import { ImusDestroyService } from "@shared/services/destroy.service";

/**
 * Инструкция отображения нарушения правил для элемента формы.
 * Привязка к форме считывается автоматически из родительского элемента с аттрибутом @see FormGroup, если
 * указывается @param formFieldName
 * Правила отображения ошибок указываются элементами @see FormFieldErrorLabelComponent
 * Если паттерн поведения при нарушении правила не был указан, то лейбл отображения ошибки будет сгенерирован
 * с настройками по умолчанию @see FormFieldErrorLabelComponent
 *
 * @param formFieldName имя \ идентификатор элемента привязки из формы
 * @param formFieldErrors альтернативное (!) подключение, через ссылку на объект ошибок
 * @use с привязкой к @see FormControl
 * @example
 *  <mat-form-field>
 *    <input formControlName="address"/>
 *  </mat-form-field>
 * 	<field-errors formFieldName="address">
 * 		<field-error-label rule="required"></field-error-label> <-- Показ предупреждения с текстом по умолчанию
 * 		empty <--	В случае наличия нарушения правила 'rule_2' и отсутствие инструкции, лейбл будет сгенерирован
 * 					в runtime с сообщением по умолчанию @see FormFieldErrorLabelComponent
 * 	</field-errors>
 * @use c привязкой к модели @see ValidationErrors (не является основным и рекомендованным способом подключения)
 * 	<field-errors [formFieldErrors]="errors">
 * 		<field-error-label rule="required"></field-error-label>
 * 		empty
 * 	</field-errors>
 */
@Directive({
	selector: 'field-errors',
	providers: [ImusDestroyService]
})
export class FormFieldErrorContainerDirective implements DoCheck {

	// private readonly _control$ = new BehaviorSubject<FormControl<unknown>>(null);
    private _control: FormControl = null;
	private _form: FormGroup<unknown> = null;

	@ContentChildren(FormFieldErrorLabelComponent, { descendants: true })
	private _labels!: QueryList<FormFieldErrorLabelComponent>;

	/**
	 * Имя \ идентификатор элемента формы
	 *
	 * @remarks
	 * - селектор formFieldName по тому что formControlName уже является селектором другой атрибутной директивы
	 */
	@Input('formFieldName')
	private _formFieldName: string;

	/**
	 * Ссылка на объект владелец ошибок элемента формы
	 * Используется только если не указан @param formFieldName так как не является основным и рекомендованным
	 * способом подключения
	 */
	@Input('formFieldErrors')
	public _formFieldErrors: ValidationErrors;

	/**
     * @param _parentFormRef родительский элемент содержащий ссылку на форму
     * @param _vcRef
     * @param _cdRef
     * @param _destroy$
     */
	constructor(
		@Optional()
		private readonly _parentFormRef: FormGroupDirective,
		private readonly _vcRef: ViewContainerRef,
		private readonly _cdRef: ChangeDetectorRef,
		private readonly _destroy$: ImusDestroyService
	) {}

	ngDoCheck(): void {
        if (!this._formFieldName) {
            this.refresh(this._formFieldErrors);
            return;
        }
        // Если у родителя сменилась привязка к форме
        if (this._form !== this._parentFormRef.form) {
            this._form = this._parentFormRef.form;
            this._control = null;
        }
        if (!this._form) {
            return;
        }
        const control = this._parentFormRef?.directives.find(o => o.name === this._formFieldName)?.control
            || this._parentFormRef?.form?.controls[this._formFieldName];
        // Если сменился наблюдаемы control
        if (this._control !== control) {
            this._control = control || control instanceof FormControl
                ? control as FormControl
                : null;
        }
        if (!this._control) {
            return;
        }
        if (!this._control.touched && !this._parentFormRef.submitted) {
            return;
        }
        this.refresh(this._control.errors);
	}

	/** Подключить label описывающий отображение нарушения правила */
	public attach(item: FormFieldErrorLabelComponent): void {
		this._labels.reset(this._labels.toArray().include(item));
	}

	/** Отключить label описывающий отображение нарушения правила */
	public detach(item: FormFieldErrorLabelComponent): void {
		this._labels.reset(this._labels.toArray().exclude(item));
	}

	/** Добавляет label с поведением по умолчанию для отображения нарушения правила */
	private add(rule: string, message: string): void {
		const componentRef = this._vcRef.createComponent(FormFieldErrorLabelComponent);
		this._vcRef.element.nativeElement.appendChild(componentRef.location.nativeElement);
		const instance = componentRef.instance;
		instance.rule = rule;
		instance.customMessage = message;
		this.attach(instance);
	}

	/** Обновить состояние дочерних label`ов */
	private refresh(errors: ValidationErrors): void {
        if (!this._labels || (!this._control && !this._formFieldErrors)) {
            return;
        }
        // Если ошибки есть и поле изменено
        if (errors) {
            const violations = Object.keys(errors);
            const uncharted = violations
                .filter(violation => this._labels.toArray().every(label => label.rule !== violation));
            // В ошибках есть те для которых пользователь не назначил специфического поведения
            if (uncharted.length) {
                // Добавляем обработчики с дефолтным поведением
                uncharted.forEach(rule => this.add(rule, errors[rule]));
            }
            // Обновляем метки лейблов в соответствии со статусом правила
            this._labels.forEach(label => {
                if (errors[label.rule]) {
                    label.markAsShown();
                } else {
                    label.markAsHidden();
                }
            });
        } else {
            // Помечаем все лейблы ошибок как скрытые
            this._labels.forEach(item => item.markAsHidden());
        }
	}

}
