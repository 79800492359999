import { imus } from "@shared/imus";
import { ReplaySubject } from "rxjs";
import {
    TemplateField
} from "@app/modules/account/pages/profile/components/company-details/company-details";
import { InputType } from "@shared/input-type";

export interface ContractorPersonal {
    form: imus.form.IFormGroup<ContractorPersonalData>,
    model: ContractorPersonalData,
    fields$: ReplaySubject<TemplateField[][]>
}

export interface ContractorPersonalData {
    person_name_last: string;
    person_name_first: string;
    person_name_second: string;
    person_sex_type_id: number;
    person_birthday: string;
    address: string;
    idcard_type_id: number;
    idcard_doc_num: string;
    idcard_doc_date: string;
    person_birthplace: number;
    idcard_doc_org: string;
    id: number;
    type_id: number;
    is_rights_granted: boolean;
    status_id: number;
}

export const CONTRACTOR_PERSONAL_FIELDS: TemplateField[][] = [
    [
        { name: 'person_name_last', type: InputType.text, label: 'Фамилия', hidden: false, required: true },
        { name: 'person_name_first', type: InputType.text, label: 'Имя', hidden: false, required: true },
        { name: 'person_name_second', type: InputType.text, label: 'Отчество', hidden: false },
        { name: 'person_sex_type_id', type: InputType.select, label: 'Пол', hidden: false, required: true },
        { name: 'person_birthday', type: InputType.date, label: 'Дата рождения', hidden: false },
        { name: 'address', type: InputType.area, label: 'Адрес регистрации', hidden: false },
        { name: 'status_id', type: InputType.select, label: 'Статус проверки', hidden: false },
        { name: 'is_rights_granted', type: InputType.checkbox, label: 'Получено согласие на обработку персональных данных', hidden: false},
    ],
    [
        { name: 'idcard_type_id', type: InputType.select, label: 'Документ, удостоверяющий личность', hidden: false },
        { name: 'idcard_doc_num', type: InputType.text, label: 'Номер документа', hidden: false },
        { name: 'idcard_doc_date', type: InputType.date, label: 'Дата выдачи', hidden: false },
        { name: 'person_birthplace', type: InputType.text, label: 'Место рождения', hidden: false },
        { name: 'idcard_doc_org', type: InputType.text, label: 'Организация, выдавшая документ', hidden: false },
    ]
];
