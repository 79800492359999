import { Injectable } from '@angular/core';
import { BehaviorSubject, shareReplay, withLatestFrom } from "rxjs";
import { ItemSubLayer } from "@shared/models";
import { LayersStateService } from "@services/layers-state.service";
import { CircledFeature } from "@app/modules/map/services/map-polygon.service";

@Injectable({
    providedIn: 'root'
})
export class CopyObjectsService {
    private readonly copyLayerState = {
        mode$: new BehaviorSubject(false),
        selectedLayers$: new BehaviorSubject<{ donor: ItemSubLayer, acceptor: ItemSubLayer } | null>(null),
        selectedNodesIds$: new BehaviorSubject<number[]>([]),
        selectedPlotsIds$: new BehaviorSubject<number[]>([]),
        selectedLayersHash: null as Record<number, boolean> | null
    }

    public readonly isCopyLayerMode$ = this.copyLayerState.mode$.pipe(
        shareReplay(1)
    );
    public readonly selectedForCopyLayer$ = this.copyLayerState.selectedLayers$.pipe(
        shareReplay(1)
    );
    public readonly selectedNodes$ = this.copyLayerState.selectedNodesIds$.pipe(
        shareReplay(1)
    );
    public readonly selectedPlots$ = this.copyLayerState.selectedPlotsIds$.pipe(
        shareReplay(1)
    );

    constructor(
        private layerState: LayersStateService
    ) {
        // подписка на состояние выбранных слоёв при копировании объектов
        this.copyLayerState.selectedLayers$.pipe(
            withLatestFrom(this.layerState.state.items$)
        ).subscribe(([state, layers]) => {
            if (!state && this.copyLayerState.selectedLayersHash) {
                layers.forEach(layer => {
                    layer.children$.getValue().forEach(subLayer => {
                        subLayer.checked = !!this.copyLayerState.selectedLayersHash[subLayer.layerId];
                    })
                });
                this.copyLayerState.selectedLayersHash = null;
            } else {
                this.copyLayerState.selectedLayersHash = {};
                layers.forEach(layer => {
                    layer.children$.getValue().forEach(subLayer => {
                        this.copyLayerState.selectedLayersHash[subLayer.layerId] = subLayer.checked;
                        subLayer.checked = subLayer.layerId === state.donor.layerId;
                    })
                })
            }
        })
    }

    public get isCopyLayerMode(): boolean {
        return this.copyLayerState.mode$.getValue();
    }

    public get selectedNodes(): Array<number> {
        return this.copyLayerState.selectedNodesIds$.getValue();
    }

    public get selectedPlots(): Array<number> {
        return this.copyLayerState.selectedPlotsIds$.getValue();
    }

    public get selectedLayers(): { donor: ItemSubLayer, acceptor: ItemSubLayer } {
        return this.copyLayerState.selectedLayers$.getValue();
    }

    public addSelectedNode(nodeId: number, reject = true): void {
        this.select(nodeId, 'selectedNodesIds$', reject);
    }

    public addSelectedPlot(plotId: number, reject = true): void {
        this.select(plotId, 'selectedPlotsIds$', reject);
    }

    public setCopyLayerMode(state: boolean) {
        this.copyLayerState.mode$.next(state);
        if (!state) {
            this.clear();
        }
    }

    public setCopiedLayerState(state: { donor: ItemSubLayer, acceptor: ItemSubLayer } | null) {
        this.copyLayerState.selectedLayers$.next(state);
    }

    public clear(): void {
        this.copyLayerState.mode$.next(false);
        this.copyLayerState.selectedLayers$.next(null);
        this.copyLayerState.selectedNodesIds$.next([]);
        this.copyLayerState.selectedPlotsIds$.next([]);
        this.copyLayerState.selectedLayersHash = null as Record<number, boolean> | null;
    }

    public selectedHandler(list: CircledFeature[]): void {
        list?.forEach(feature => {
            if (feature.type === 'Point') {
                this.addSelectedNode(feature.id, false);
            } else {
                this.addSelectedPlot(feature.id, false);
            }
        })
    }

    private select(id: number, entity: 'selectedNodesIds$' | 'selectedPlotsIds$', reject: boolean): void {
        const list = this.copyLayerState[entity].getValue();
        const index = list.indexOf(id);
        if (index === -1) {
            this.copyLayerState[entity].next(list.concat(id))
        } else if (reject) {
            list.splice(index, 1);
            this.copyLayerState[entity].next(list);
        }
    }
}
