<h1 mat-dialog-title class="imus-row title">Изменить служебную информацию {{data.user_name}}</h1>
<div mat-dialog-content>
    <mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate"></mat-progress-bar>
    <form [formGroup]="employee.form" (ngSubmit)="onSubmit()">
        <div class="imus-row form-container">
            <div class="imus-row-item">
                <div class="imus-row">
                    <div class="imus-row-item imus-form-item">
                        <app-imus-select
                            placeholder="Роль"
                            imusRequired
                            [items]="roles$ | async"
                            [specification]="{ key: 'id', value: 'descr'}"
                            formControlName="role_id"
                        ></app-imus-select>
                        <field-errors formFieldName="role_id">
                            <field-error-label rule="required"
                                               message="Выберите роль"></field-error-label>
                        </field-errors>
                    </div>
                </div>
                <div class="imus-row">
                    <div class="imus-row-item">
                        <app-imus-input
                            placeholder="Должность"
                            formControlName="job_post"
                        ></app-imus-input>
                    </div>
                </div>
                <div class="imus-row">
                    <div class="imus-row-item">
                        <mat-checkbox color="primary" formControlName="is_primary">Контактное лицо</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="imus-row-item date-fields">
                <div class="imus-row">
                    <div class="imus-row-item">
                        <app-imus-input
                            [type]="inputType.date"
                            placeholder="Дата подключения"
                            formControlName="start_date"
                        ></app-imus-input>
                    </div>
                </div>
                <div class="imus-row">
                    <div class="imus-row-item">
                        <app-imus-input
                            [type]="inputType.date"
                            placeholder="Дата отключения"
                            formControlName="end_date"
                        ></app-imus-input>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div class="imus-row">
            <div class="imus-row-item">
                <h2 mat-dialog-title class="imus-row title">Право подписи</h2>
                <div class="imus-row form-container">
                    <div class="imus-row-item">
                        <div class="imus-row">
                            <mat-checkbox color="primary" formControlName="is_signer">Имеет право подписи</mat-checkbox>
                        </div>
                        <div class="imus-row">
                            <div class="imus-row-item">
                                <label>Действует на основании</label>
                                <mat-radio-group formControlName="is_proxy">
                                    <mat-radio-button [value]="true">Доверенности</mat-radio-button>
                                    <mat-radio-button [value]="false">Устава</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="imus-row">
                            <div class="imus-row-item">
                                <app-imus-input
                                    placeholder="Должность в родительном падеже"
                                    formControlName="job_post_gen"
                                ></app-imus-input>
                            </div>
                        </div>
                    </div>
                    <div class="imus-row-item">
                        <div class="imus-row">
                            <div class="imus-row-item">
                                <app-imus-input
                                    placeholder="Номер доверенности"
                                    formControlName="proxy_doc_number"
                                ></app-imus-input>
                            </div>
                        </div>
                        <div class="imus-row">
                            <div class="imus-row-item">
                                <app-imus-input
                                    [type]="inputType.date"
                                    placeholder="Дата доверенности"
                                    formControlName="proxy_doc_date"
                                ></app-imus-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="imus-row">
            <app-button [disabled]="employee.form.invalid"
                        [isSubmitted]="true"
                        [type]="buttonType.primaryButton"
                        [fullWidth]="true"
                        [label]="'Сохранить'"></app-button>
            <app-button [fullWidth]="true"
                        (click)="dialogRef.close(null)"
                        [label]="'Отменить'"></app-button>
        </div>
    </form>
</div>
