import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'formatString'
})
export class FormatStringPipe implements PipeTransform {
    transform(value: string): string {
        if (value.includes('{') && value.includes('}')) {
            value = value.replace(/{|}/g, '');
        }
        value = value.replace(/,/g, ', ');

        return value;
    }
}
