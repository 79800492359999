export class NewLayerType {
    layerType: string;
    id: number;
    parent_id: number;
    name: LayerTypes;
    russian: string;
    hasFilters: boolean;
    multipleLayers: boolean;
    checked: boolean;
    editable: boolean;
    changeName: boolean;
    expanded: boolean;
    removable: boolean;
    hasBlockDataEdit: boolean;
    combineData: boolean;
    hasColor?: boolean;
    /** Отображать у слоев этого типа меню - надписи объектов */
    objectLabels: boolean;
    filters?: {
        transparency: number,
        brightness: number,
        contrast: number
    };
    constructor(source: NewLayerType) {
        Object.assign(this, source);
    }
}

export interface LayerTypesCollection {
    layer_types: NewLayerType[];
    type: string;
}

export enum LayerTypes {
    base = 'base', // 0
    canalization = 'CANALISATION', // 1
    electricity = 'ELECTRICITY', // 2
    gaspipes = 'GASPIPES', // 3
    waterpipes = 'WATERPIPES', // 4
    other = 'OTHER', // 5
    rastr = 'RASTR', // 6
    wmswmts = 'wmswmts', //wms/wmts
    vector = 'vector',
    geojsonParent = 'GEOJSON_PARENT', // 12
    geojson = 'GEOJSON', // 13

    globalCanalization = 'GLOBAL_CANALISATION', // 101
    globalElectricity = 'GLOBAL_ELECTRICITY', // 102
    globalGaspipes = 'GLOBAL_GASPIPES', // 103
    globalWaterpipes = 'GLOBAL_WATERPIPES', // 104
}

export enum LayerTypeIDs {
    base = 0, //'base', // 0
    // CANALISATION_PARENT = 1, //'CANALISATION', // 1
    // ELECTRICITY_PARENT = 2, //'ELECTRICITY', // 2
    // GASPIPES_PARENT = 3, //'GASPIPES', // 3
    // WATERPIPES_PARENT = 4, //'WATERPIPES', // 4
    OTHER = 5, //'OTHER', // 5
    // RASTR_PARENT = 6, //'RASTR', // 6
    // wmswmts = 7, //'wms/wmts',
    // vector = 8, //'vector',
    CANALISATION = 1, //'CANALISATION', // 7
    ELECTRICITY = 2, //'ELECTRICITY', // 8
    GASPIPES = 3, //'GASPIPES', // 9
    WATERPIPES = 4, //'WATERPIPES', // 10
    RASTR = 6, //'RASTR', // 11
    CANALISATION_CHILD = 7, //'CANALISATION', // 7
    ELECTRICITY_CHILD = 8, //'ELECTRICITY', // 8
    GASPIPES_CHILD = 9, //'GASPIPES', // 9
    WATERPIPES_CHILD = 10, //'WATERPIPES', // 10
    RASTR_CHILD = 11, //'RASTR', // 11


    
    GLOBAL_CANALISATION = 101, // 101
    GLOBAL_ELECTRICITY = 102, // 102
    GLOBAL_GASPIPES = 103, // 103
    GLOBAL_WATERPIPES = 104, // 104
    
    GEOJSON_PARENT = 12, //'GEOJSON',
    GEOJSON = 13, //'GEOJSON',

}


export const LAYER_TYPE_INIT_DATA: NewLayerType[] = [
    {
        layerType: null,
        id: LayerTypeIDs.GLOBAL_CANALISATION, // 101
        parent_id: LayerTypeIDs.CANALISATION, // 1
        name: LayerTypes.globalCanalization,
        russian: 'Канализация',
        hasFilters: false,
        multipleLayers: false,
        checked: false,
        editable: false,
        changeName: false,
        expanded: false,
        removable: false,
        hasBlockDataEdit: false,
        combineData: false,
        objectLabels: true,
        hasColor: false,
    },
    {
        layerType: null,
        id: LayerTypeIDs.GLOBAL_ELECTRICITY, // 102,
        parent_id: LayerTypeIDs.ELECTRICITY, // 2,
        name: LayerTypes.globalElectricity,
        russian: 'Электроэнергия',
        hasFilters: false,
        multipleLayers: false,
        checked: false,
        editable: false,
        changeName: false,
        expanded: false,
        removable: false,
        hasBlockDataEdit: false,
        combineData: false,
        objectLabels: true,
        hasColor: false,
    },
    {
        layerType: null,
        id: LayerTypeIDs.GLOBAL_GASPIPES,// 103,
        parent_id: LayerTypeIDs.GASPIPES, // 3,
        name: LayerTypes.globalGaspipes,
        russian: 'Газопровод',
        hasFilters: false,
        multipleLayers: false,
        checked: false,
        editable: false,
        changeName: false,
        expanded: false,
        removable: false,
        hasBlockDataEdit: false,
        combineData: false,
        objectLabels: true,
        hasColor: false,
    },
    {
        layerType: null,
        id: LayerTypeIDs.GLOBAL_WATERPIPES,// 104,
        parent_id: LayerTypeIDs.WATERPIPES, // 4,
        name: LayerTypes.globalWaterpipes,
        russian: 'Водопровод',
        hasFilters: false,
        multipleLayers: false,
        checked: false,
        editable: false,
        changeName: false,
        expanded: false,
        removable: false,
        hasBlockDataEdit: false,
        combineData: false,
        objectLabels: true,
        hasColor: false,
    },
    // {
    //     layerType: null,
    //     id: LayerTypeIDs.GEOJSON_PARENT,
    //     parent_id: null,
    //     name: LayerTypes.geojsonParent,
    //     russian: 'GeoJSON',
    //     hasFilters: false,
    //     multipleLayers: false,
    //     checked: false,
    //     editable: false,
    //     changeName: false,
    //     expanded: false,
    //     removable: false,
    //     hasBlockDataEdit: false,
    //     combineData: false,
    //     objectLabels: false,
    //     hasColor: false,
    // },
    // {
    //     layerType: null,
    //     id: LayerTypeIDs.GEOJSON,
    //     parent_id: LayerTypeIDs.GEOJSON_PARENT,
    //     name: LayerTypes.geojson,
    //     russian: 'GeoJSON',
    //     hasFilters: false,
    //     multipleLayers: true,
    //     checked: false,
    //     editable: true,
    //     changeName: true,
    //     expanded: false,
    //     removable: true,
    //     hasBlockDataEdit: false,
    //     combineData: false,
    //     objectLabels: true,
    //     hasColor: true,
    // },
    
]