import {
  Component,
  Host,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { ImageStatic } from 'ol/source';
import { SourceComponent } from './source.component';
import { LayerImageComponent } from '../layers/layerimage.component';
import { ProjectionLike } from 'ol/proj';
import { Extent } from 'ol/extent';
import { AttributionLike } from 'ol/source/Source';
import { LoadFunction } from 'ol/Image';
import { Size } from 'ol/size';
import { ImageSourceEvent } from 'ol/source/Image';
import { LoggerService as Logger } from '@app/shared/services';
// import GeoImage from 'ol-ext/source/GeoImage';
import { Coordinate } from 'ol/coordinate';
// import { promises } from 'dns';
// import GeoImage from '@app/shared/modules/openlayers/models/GeoImage';
import GeoImage from 'ol-ext/source/GeoImage';


@Component({
  selector: 'aol-source-geoimage',
  template: ` <ng-content></ng-content> `,
  providers: [{ provide: SourceComponent, useExisting: forwardRef(() => SourceGeoImageComponent) }],
})
export class SourceGeoImageComponent extends SourceComponent implements OnInit, OnChanges {
  // override instance: ImageStatic;
  override instance: GeoImage;

  @Input()
  url?: string;
  @Input()
  image?: any; // TODO: image type
  @Input()
  imageCenter?: Coordinate;
  @Input()
  imageScale?: Size | number = [1,1];
  @Input()
  imageRotate?: number;
  @Input()
  imageCrop?: Extent;
  @Input()
  imageMask: Coordinate[] = null;
  @Input()
  projection?: ProjectionLike;
  


  @Output()
  imageLoadStart = new EventEmitter<ImageSourceEvent>();
  @Output()
  imageLoadEnd = new EventEmitter<ImageSourceEvent>();
  @Output()
  imageLoadError = new EventEmitter<ImageSourceEvent>();


  constructor(@Host() layer: LayerImageComponent) {
    super(layer);
  }

  setLayerSource(): void {
    // Logger.debug('setLayerSource', this);
    // console.log('setLayerSource', this)
    this.instance = new GeoImage(this);
    this.host.instance.setSource(this.instance);
    this.instance.on('imageloadstart', (event: ImageSourceEvent) => this.imageLoadStart.emit(event));
    this.instance.on('imageloadend', (event: ImageSourceEvent) => this.imageLoadEnd.emit(event));
    this.instance.on('imageloaderror', (event: ImageSourceEvent) => this.imageLoadError.emit(event));
  }

  ngOnInit() {
    this.setLayerSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Logger.debug('ngOnChanges', this);
    const properties: { [index: string]: any } = {};
    if (!this.instance) {
      return;
    }
    for (const key in changes) {
      if (changes.hasOwnProperty(key)) {
        switch (key) {
          case 'url':
            this.url = changes[key].currentValue;
            this.setLayerSource();
            break;
          case 'imageCenter':
            if ( !(changes[key].previousValue.length === changes[key].currentValue.length 
                && changes[key].previousValue.every((item,index) => item === changes[key].currentValue[index]))) {
              this.imageCenter = changes[key].currentValue;
              this.setLayerSource();
            }
            break;
          case 'imageRotate':
            if (changes[key].previousValue !== changes[key].currentValue) {
              this.imageRotate = changes[key].currentValue;
              this.setLayerSource();
            }
            break;
      default:
            break;
        }
        properties[key] = changes[key].currentValue;
      }
    }
    this.instance.setProperties(properties, false);
  }
}
