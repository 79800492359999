import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { SharedModule } from "@shared/shared.module";
import { InputType } from "@shared/input-type";
import { MenuService } from "@services/menu.service";
import { ButtonComponentSettings } from "@shared/components/button/button.component";
import { AccountService } from "@app/modules/account/services/account.service";
import { shareReplay, switchMap } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { UserAccountsInfo } from "@shared/models";
import { AccountsService } from "@app/modules/account/services/accounts.service";
import { NewMainAccount } from "@app/modules/account/pages/profile/models/profile";
import { passwordMatchValidator } from "@shared/validators/password.validator";

interface Employee {
    form: FormGroup
}

@Component({
    selector: 'app-employee-create.dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, ReactiveFormsModule, SharedModule],
    templateUrl: './employee-create.dialog.component.html',
    styleUrls: ['./employee-create.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeCreateDialogComponent implements OnInit {
    private readonly menuService = inject(MenuService);
    private readonly accountsService = inject(AccountsService);
    private readonly fb = inject(FormBuilder);
    private account: UserAccountsInfo;
    public readonly dialogRef = inject(MatDialogRef<EmployeeCreateDialogComponent>)
    public readonly inputType = InputType;
    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public readonly employee: Employee = {
        form: this.fb.group(
                {
                    user_name: new FormControl('', [Validators.required]),
                    user_email: new FormControl('', [Validators.required]),
                    user_phone: new FormControl(''),
                    account_role_id: new FormControl('', [Validators.required]),
                    passGroup:
                        new FormGroup({
                                password: new FormControl('', [Validators.required, Validators.minLength(8)]),
                                confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
                            }, passwordMatchValidator
                        )

                }
            )
    };
    public readonly roles$ = inject(AccountService).account$.pipe(
        tap(acc => this.account = acc),
        switchMap(acc => this.menuService.getRolesList({
            'where[account_type_id]': acc.account_type_id,
            paginate: false
        })),
        shareReplay({ refCount: true, bufferSize: 1 })
    )
    public isLoaded = false;

    ngOnInit(): void {
        this.employee.form = new FormGroup({
                user_name: new FormControl(''),
                user_email: new FormControl(''),
                user_phone: new FormControl(''),
                account_role_id: new FormControl(''),
                passGroup: new FormGroup({
                    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
                    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
                }, passwordMatchValidator)
            }
        );
    }

    public onSubmit() {
        if (this.employee.form.invalid) {
            return;
        }
        const { user_name, user_email, user_phone, account_role_id, passGroup } = this.employee.form.value;
        const employee: NewMainAccount = {
            user_name,
            user_email,
            user_password: passGroup.password,
            user_phone,
            account_contractor_id: this.account.contractor_id,
            account_role_id,
        };
        this.isLoaded = true;
        this.accountsService.createMainAccount(employee).pipe(
            finalize(() => this.isLoaded = false)
        ).subscribe((res) => this.dialogRef.close(res));
    }
}
