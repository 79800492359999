import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateDemoPageComponent } from './pages/template-demo-page/template-demo-page.component';
import { DevIndexPageComponent } from './pages/dev-index-page/dev-index-page.component';
import { DevMenuButtonComponent } from './components/dev-menu-button/dev-menu-button.component';
import { DevRoutingModule } from './dev-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';



@NgModule({
  declarations: [
    TemplateDemoPageComponent,
    DevIndexPageComponent,
    DevMenuButtonComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DevRoutingModule
  ],
  exports: [
    DevMenuButtonComponent
  ]
})
export class DevModule { }
