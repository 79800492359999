import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { InputComponentSettings } from '@app/shared/components/input/input.component';
import { imus } from '@app/shared/imus';
import { ProjectTypes } from '@app/shared/models/project';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { ProjectService } from '@app/shared/services/project.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-project-panel',
  templateUrl: './edit-project-panel.component.html',
  styleUrls: ['./edit-project-panel.component.scss'],
  providers: [ImusDestroyService]
})
export class EditProjectPanelComponent implements OnInit {
  inputType = InputComponentSettings.InputType;
  public readonly edit = {
    form: null as imus.form.IFormGroup<ProjectTypes.IProjectEditForm>,
    model: {
      name: null,
      contractorCompany: null,
      contractorRepresentative: null,
      contractorEmail: null,
      contractorPhone: null,
      contractorCity: null,
      contractorZip: null,
      contractorStreet: null,
      contractorWeb: null,
      contractorNotes: null,
      clientCompany: null,
      clientRepresentative: null,
      clientEmail: null,
      clientPhone: null,
      clientCity: null,
      clientZip: null,
      clientStreet: null,
      clientWeb: null,
      clientNotes: null,   
    } as ProjectTypes.IProjectEditForm,
  };

  private project: ProjectTypes.IProjectResponseItem;

  get isValid(): boolean {
    return this.edit.form.valid;
  }

  constructor(private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private destroy$: ImusDestroyService) { }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  private initForm() {
    this.edit.form = this.formBuilder.fromTypedModel(this.edit.model);
  }

  private initData() {
    this.projectService.editableProject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((project) => {
        this.project = project;
        if (project) {
          const pars: ProjectTypes.IProjectEditForm = {
            name: project.properties.name,
            contractorCompany: project?.contractor?.properties?.company,
            contractorRepresentative: project?.contractor?.properties?.representative,
            contractorEmail: project?.contractor?.properties?.email,
            contractorPhone: project?.contractor?.properties?.phone,
            contractorCity: project?.contractor?.properties?.city,
            contractorZip: project?.contractor?.properties?.zip,
            contractorStreet: project?.contractor?.properties?.street,
            contractorWeb: project?.contractor?.properties?.web,
            contractorNotes: project?.contractor?.properties?.notes,
            clientCompany: project?.client?.properties?.company,
            clientRepresentative: project?.client?.properties?.representative,
            clientEmail: project?.client?.properties?.email,
            clientPhone: project?.client?.properties?.phone,
            clientCity: project?.client?.properties?.city,
            clientZip: project?.client?.properties?.zip,
            clientStreet: project?.client?.properties?.street,
            clientWeb: project?.client?.properties?.web,
            clientNotes: project?.client?.properties?.notes
          };
          this.edit.form.setValue(pars);
        }
        else {
          const pars: ProjectTypes.IProjectEditForm = {
            name: null,
            contractorCompany: null,
            contractorRepresentative: null,
            contractorEmail: null,
            contractorPhone: null,
            contractorCity: null,
            contractorZip: null,
            contractorStreet: null,
            contractorWeb: null,
            contractorNotes: null,
            clientCompany: null,
            clientRepresentative: null,
            clientEmail: null,
            clientPhone: null,
            clientCity: null,
            clientZip: null,
            clientStreet: null,
            clientWeb: null,
            clientNotes: null
          };
          this.edit.form.setValue(pars);
        }
      })
  }

  onEditProjectClick() {
    if (this.project && this.project.contractor && this.project.client) {
      const values: ProjectTypes.IProjectEditForm = this.edit.form.toModel(this.edit.model);
      this.projectService.updateProject(
        this.project.properties.id,
        this.project.contractor.properties.id,
        this.project.client.properties.id,
        values).subscribe(() => {
          this.projectService.formsHelperDataClear();
        });
    }
  }

  onCancelClick() {
    //TODO destroy
    this.projectService.formsHelperDataClear();
  }
}

