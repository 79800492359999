import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, shareReplay } from "rxjs";

@Injectable()
export class EditingFieldNameProvider {
    private readonly _current$ = new BehaviorSubject<string | null>(null);
    public set current(value: string | null) {
        this._current$.next(value);
    }

    public get current(): string | null {
        return this._current$.getValue();
    }
    public readonly current$: Observable<string | null> = this._current$.pipe(
        shareReplay(1)
    );
}
