import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InputComponentSettings } from '@app/shared/components/input/input.component';
import { ButtonComponentSettings } from 'src/app/shared/components/button/button.component';
import { SharedModule } from "@shared/shared.module";
import { takeUntil } from "rxjs";
import { AuthenticationService, UserService } from "@shared/services";
import { ImusDestroyService } from "@services/destroy.service";
import { RouterLink } from "@angular/router";
import { AsyncPipe, NgIf } from "@angular/common";
import { AccountService } from "@app/modules/account/services/account.service";
/** TODO: убрать лишний код после полного перехода на новое меню */
@Component({
    selector: 'app-account-header',
    templateUrl: './account-header.component.html',
    styleUrls: ['./account-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SharedModule,
        RouterLink,
        AsyncPipe,
        NgIf
    ],
    providers: [ImusDestroyService],
    standalone: true
})
export class AccountHeaderComponent {
    buttonType = ButtonComponentSettings.ButtonType;
    inputType = InputComponentSettings.InputType;


    @Input()
    userName: string;

    @Input()
    userType: string;

    @Input()
    editable: boolean = true;

    @Input()
    hasRelatedAccount: boolean = true;

    @Input()
    buttonLabel: string;

    @Output()
    accountButtonClick: EventEmitter<PointerEvent> = new EventEmitter();

    nameReadonly: boolean = true

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        public readonly accountService: AccountService,
        private readonly _destroy$: ImusDestroyService
    ) {
    }

    onAccountButtonClick(event: PointerEvent) {
        this.accountButtonClick.emit(event);
    }

    onNameEditClick() {
        this.nameReadonly = !this.nameReadonly
    }

    onLogoutClick(): void {
        this.authenticationService.logout().pipe(takeUntil(this._destroy$)).subscribe();
    }
}
