import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ImusBaseInputComponent } from '@shared/components/imus-base-input/imus-base-input.component';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ImusDestroyService } from '@shared/services/destroy.service';
import { FormControl, NgControl } from '@angular/forms';
import { MatSelectChange } from "@angular/material/select";

interface SelectSpecification {
  key: string | number;
  value: string | number;
}
@Component({
  selector: 'app-imus-select',
  templateUrl: './imus-select.component.html',
  styleUrls: ['./imus-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImusSelectComponent extends ImusBaseInputComponent<string | number> {

  private readonly _multiple$ = new BehaviorSubject(false);
  private readonly _items$ = new ReplaySubject<Record<string, unknown>[]>(1);
  private readonly _specification$ = new ReplaySubject<SelectSpecification>(1);

  @Output()
  public selectionChange = new EventEmitter<string | number>();

  /**
   * Мультивыбор
   *
   * @remarks пока не реализована логика
   * */
  @Input()
  public set multiple(bol: boolean) {
    this._multiple$.next(bol);
  }

  /**
   * Описание целевых полей
   *
   * Необходимо передать объект с описанием полей идентификатора и значения
   *
   * @example
   * ```
   * {
   * 		key: 'название_поля_идентификатора',
   * 		value: 'название_поля_отображаемого_значения'
   * 	}
   * 	```
   * */
  @Input('specification')
  public set _specification(_value: SelectSpecification) {
    this._specification$.next(_value);
  }

  /**
   * Список значений для селектора
   *
   * @remarks пока не реализована логика
   * */
  @Input()
  public set items(items: Record<string, unknown>[]) {
    this._items$.next(items);
  }

  public readonly items$: Observable<Record<string, unknown>[]> = this._items$;
  public readonly specification$: Observable<SelectSpecification> = this._specification$;
  public readonly multiple$: Observable<boolean> = this._multiple$;

  constructor(
    protected override readonly _destroy$: ImusDestroyService,
    protected override readonly _control: NgControl
  ) {
    super(_destroy$, _control);
    this.innerControl = new FormControl<string | number>(null);
    this.changed = new EventEmitter<string | number>()
  }

    matSelectionChange(ev: MatSelectChange): void {
      this.selectionChange.emit(ev.value);
    }

}
