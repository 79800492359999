import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
    MatDialog,
    MatDialogRef,
    MatDialogConfig,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '@app/shared/models';
import {
    AuthenticationService,
    UserService,
    AlertService,
} from '@app/shared/services';
import {
    BehaviorSubject,
    Observable,
    catchError,
    EMPTY,
    throwError,
    tap,
    takeUntil,
} from 'rxjs';
import { LoginModalComponent } from '@app/modules/account/components/login-modal/login-modal.component';
import { RegisterModalComponent } from '@app/modules/account/components/register-modal/register-modal.component';
import { ImusDestroyService } from '@app/shared/services/destroy.service';

@Component({
    selector: 'app-email-link-modal',
    templateUrl: './email-link-modal.component.html',
    styleUrls: ['./email-link-modal.component.scss'],
})
export class EmailLinkModalComponent implements OnInit {
    changePassForm: FormGroup;
    emailForm: FormGroup;
    loading = false;
    emailSubmitted = false;
    changePassSubmitted = false;
    passwordInputType = 'password';
    passwordRepeatInputType = 'password';
    _emailExist = new BehaviorSubject<boolean>(false);
    emailExist$: Observable<boolean> = this._emailExist.asObservable();

    _modalState = new BehaviorSubject<ModalState>(ModalState.newPass);
    modalState$: Observable<ModalState> = this._modalState.asObservable();

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<RegisterModalComponent>,
        private readonly _destroy$: ImusDestroyService,
    ) {
        // redirect to home if already logged in
        // Перенаправляет пользователя если уже залогинин и email верифицирован
        // if (
        //     this.authenticationService.currentUserValue &&
        //     this.authenticationService.currentUserEmailVerified
        // ) {
        //     this.router.navigate(['/']);
        // }
    }

    ngOnInit() {
        this.emailForm = this.formBuilder.group({
            email: ['', Validators.required],
            // password: ['', [Validators.required, Validators.minLength(8)]],
            // password_confirmation: [
            //     '',
            //     [Validators.required, Validators.minLength(8)],
            // ],
            // login: [],
            // phone: [],
        });
        this.changePassForm = this.formBuilder.group({
            // email: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(8)]],
            password_confirmation: [
                '',
                [Validators.required, Validators.minLength(8)],
            ],
            // login: [],
            // phone: [],
        });
        this._modalState.next(ModalState.email);
    }

    // convenience getter for easy access to form fields
    get ef() {
        return this.emailForm.controls;
    }
    get f() {
      return this.changePassForm.controls;
  }

    onSubmit() {


        // reset alerts on submit
        this.alertService.clear();

        this.loading = true;

        switch (this._modalState.value) {
            case ModalState.email:
              this.emailSubmitted = true;
                if (this.emailForm.invalid) {
                    return;
                }
                this.userService
                    .forgotPass(this.emailForm.value.email)
                    .pipe(
                        // tap((data) => console.log(data)),
                        catchError((err) => {
                            // console.log('error', 'cause' in err);
                            // if (err?.cause?.status === 409) {
                            //     // Такой email уже зарегистрирован
                            //     this._emailExist.next(true);
                            //     // console.log(err)
                            //     // if (err.error.status == 'Email already registered') {
                            //     //     // this.authenticationService.logout();
                            //     //     // setTimeout((() => location.reload()), 2000)
                            //     // }
                            //     return EMPTY;
                            // }
                            this.alertService.error(
                                'Упс! Что-то пошло не так. Проверьте данные которые вводите'
                            );
                            this.loading = false;
                            return throwError(() => err);
                        }),
                        takeUntil(this._destroy$)
                    )
                    .subscribe(() => {
                        this._modalState.next(ModalState.newPass);
                        this.loading = false;
                    });
                break;
            case ModalState.newPass:
              this.changePassSubmitted = true
                if (this.changePassForm.invalid) {
                    return;
                }
                break;
            case ModalState.savePass:
                break;
            default:
                break;
        }

        // // stop here if form is invalid
        // if (this.changePassForm.invalid) {
        //     return;
        // }
    }

    closeModal() {
        this.dialogRef.close();
    }
    showPassword() {
        this.passwordInputType =
            this.passwordInputType === 'password' ? 'text' : 'password';
    }

    showRepeatPassword() {
        this.passwordRepeatInputType =
            this.passwordRepeatInputType === 'password' ? 'text' : 'password';
    }

    recoverPassword() {}

    toLoginFromRegister(event) {
        // alert(111);
        event.preventDefault();
        this.dialogRef.close();

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-login-component';

        this.matDialog.open(LoginModalComponent, dialogConfig);
    }
}

export enum ModalState {
    email = 1,
    newPass = 2,
    savePass = 3,
}
