<div class="modal">
  <div (click)="closeModal()" class="modal-close">CLOSE</div>
  <div class="modal-form_group form_group form_group--center">
    <p>
      Ваша регистрация подтверждена!
    </p>
  </div>
  <div class="modal-form_group form_group form_group--center">
    <button [disabled]="loading" class="btn" (click)="closeModal()">ОК</button>
  </div>

</div>