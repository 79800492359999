<h1 mat-dialog-title class="imus-row title">Добавить сотрудника</h1>
<div mat-dialog-content>
    <form [formGroup]="employee.form" (ngSubmit)="onSubmit()">
        <div class="imus-row">
            <div class="imus-row-item imus-form-item">
                <app-imus-input
                    placeholder="Фамилия, Имя, Отчество"
                    formControlName="user_name"
                    imusRequired
                    [required]="true"
                ></app-imus-input>
                <field-errors formFieldName="user_name"></field-errors>
            </div>
        </div>
        <div class="imus-row">
            <div class="imus-row-item imus-form-item">
                <app-imus-input
                    placeholder="Email"
                    formControlName="user_email"
                    imusRequired
                    [type]="inputType.email"
                    [required]="true"
                ></app-imus-input>
                <field-errors formFieldName="user_email"></field-errors>
            </div>
        </div>
        <div class="imus-row">
            <div class="imus-row-item imus-form-item">
                <app-imus-input
                    placeholder="Контактный телефон"
                    formControlName="user_phone"
                ></app-imus-input>
            </div>
        </div>
        <ng-container formGroupName="passGroup">
            <div class="imus-row">
                <div class="imus-row-item imus-form-item">
                    <app-imus-input required="true"
                                    [type]="inputType.password"
                                    imusRequired
                                    placeholder='Пароль'
                                    formControlName="password"></app-imus-input>
                    <field-errors formFieldName="password">
                        <field-error-label rule="minlength"
                                           message="Минимальная длина 8 символов"></field-error-label>
                        <field-error-label rule="required"
                                           message="Необходимо ввести новый пароль"></field-error-label>
                    </field-errors>
                </div>
            </div>
            <div class="imus-row">
                <div class="imus-row-item imus-form-item">
                    <app-imus-input required="true"
                                    [type]="inputType.password"
                                    imusRequired
                                    placeholder='Повторите пароль'
                                    formControlName="confirmPassword"></app-imus-input>
                    <field-errors formFieldName="confirmPassword">
                        <field-error-label rule="minlength"
                                           message="Минимальная длина 8 символов"></field-error-label>
                        <field-error-label rule="required"
                                           message="Необходимо повторить новый пароль"></field-error-label>
                    </field-errors>
                    <field-errors formFieldName="passGroup">
                        <field-error-label rule="mismatch" message="Пароли не совпадают"></field-error-label>
                    </field-errors>
                </div>
            </div>
        </ng-container>
        <div class="imus-row">
            <div class="imus-row-item imus-form-item">
                <app-imus-select
                    label="Роль"
                    imusRequired
                    [items]="roles$ | async"
                    [specification]="{ key: 'id', value: 'name'}"
                    formControlName="account_role_id"
                ></app-imus-select>
                <field-errors formFieldName="account_role_id">
                    <field-error-label rule="required"
                                       message="Выберите роль"></field-error-label>
                </field-errors>
            </div>
        </div>
        <div class="imus-row">
            <app-button [disabled]="employee.form.invalid"
                        [isSubmitted]="true"
                        [type]="buttonType.primaryButton"
                        [fullWidth]="true"
                        label='Добавить'></app-button>
            <app-button [fullWidth]="true"
                        (click)="dialogRef.close(null)"
                        label='Отменить'></app-button>
        </div>
    </form>
    <mat-progress-bar *ngIf="isLoaded" mode="indeterminate"></mat-progress-bar>
</div>
