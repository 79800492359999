import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { InputComponentSettings } from '@app/shared/components/input/input.component';
import { imus } from '@app/shared/imus';
import { UserService } from '@app/shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { tap, catchError, throwError, Observable, BehaviorSubject, takeUntil } from 'rxjs';

interface FormModel {
    subject: string;
    message: string;
}

@Component({
    selector: 'app-account-feedback-modal',
    templateUrl: './account-feedback-modal.component.html',
    styleUrls: ['./account-feedback-modal.component.scss'],
})
export class AccountFeedbackModalComponent {
    buttonType = ButtonComponentSettings.ButtonType;
    inputType = InputComponentSettings.InputType;

    email: string;

    public readonly account = {
        form: null as imus.form.IFormGroup<FormModel>,
        // formGroup: null as FormGroup,
        model: {
            subject: null,
            message: null,
        } as FormModel,
    };

    _messageSent = new BehaviorSubject<boolean>(false);
    messageSent$: Observable<boolean> = this._messageSent.asObservable();


    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { email: string },
        private _fb: FormBuilder,
        private userService: UserService,
        // public matDialog: MatDialog,
        public dialogRef: MatDialogRef<AccountFeedbackModalComponent>,
        private readonly _destroy$: ImusDestroyService,
    ) {
        this.account.form = _fb.fromTypedModel<FormModel>(this.account.model);
        this.email = data.email
    }

    closeModal() {
        this.dialogRef.close();
    }

    onSubmit() {
        if (this.account.form.invalid) return;
        // console.log(this.account.form.value)
        this.userService
            .sendFeedback(
                this.email,
                this.account.form.value.subject,
                this.account.form.value.message
            )
            .pipe(
                tap((data) => console.log('tap', data)),
                // catchError(err => {
                //   if (err.status == 422) {
                //     this.account.form.controls['currentPassword'].setErrors({wrongPassword: true})
                //     setTimeout(() => this.account.form.controls['currentPassword'].setErrors(null), 2000)
                //   }
                //   return throwError(err)
                // })
                takeUntil(this._destroy$)
            )
            .subscribe(() => {
                this.account.form.reset()
                this._messageSent.next(true)
                setTimeout(() => this._messageSent.next(false), 5000)
            });
    }
}
