<div class="component-area">
    <app-account-header [userName]="'UserName'" [userType]="'Дочерний аккаунт'" [editable]="false" [hasRelatedAccount]="false"></app-account-header>

    <div class="inputs-area">

        <div class="left-buttons">
            <app-button [label]="'Главная'" [fullWidth]="true" (onclick)="onMainPageClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Настройки'" [fullWidth]="true" (onclick)="onAccountSettingsClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Информация'" [fullWidth]="true" (onclick)="onAccountInformationClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Выход'" [fullWidth]="true" (click)="authenticationService.logout()"></app-button>
            <div class="separator"></div>
        </div>

        <div class="account-inputs">

            <form [formGroup]="account.form">
                <div class="inputs-grid">
                    <app-input class="nput-cell" [type]="inputType.email" imusRequired imusEmail [placeholder]="'E-mail'" formControlName="email"></app-input>
                    <field-errors formFieldName="email"></field-errors>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div>
                <div class="separator"></div>

                <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.tel" [placeholder]="'Phone'" formControlName="phone"></app-input>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div>
                <div class="separator"></div>

                <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.password" imusRequired [placeholder]="'Password'" formControlName="password"></app-input>
                    <field-errors formFieldName="password"></field-errors>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div>
                <div class="separator"></div>
            </form>

            <app-text-separator [text]="'Родительский аккаунт'"></app-text-separator>

            <app-input class="input-cell" [placeholder]="'E-mail'" [label]="'MasterUserName'"></app-input>

            <div class="separator"></div>

            <app-text-separator [text]="'Подписки'"></app-text-separator>
            <app-input class="input-cell" [label]="'Глобальный слой'" [placeholder]="'Глобальный слой'"></app-input>
        </div>
    </div>
</div>
