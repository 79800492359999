<!-- <app-main-map></app-main-map> -->

<!-- <aol-map #map (click)="onMapClick($event)" (mouseup)="onMouseUp($event)"></aol-map> -->
<aol-map #map (click)="onMapClick($event)" (mouseup)="onMouseUp($event)">

    <aol-interaction-default></aol-interaction-default>
    <aol-view [zoom]="zoom">
        <aol-coordinate [x]="center[0] " [y]="center[1] " [srid]=" 'EPSG:4326' "></aol-coordinate>
        <!-- <aol-coordinate [x]="center[0] " [y]="center[1] " [srid]=" 'EPSG:3857' "></aol-coordinate> -->

    </aol-view>
    <aol-control-scaleline></aol-control-scaleline>
    <aol-control>
        <aol-content style="position:absolute; top: 24px; right: 24px; ">
        </aol-content>
    </aol-control>

    <!-- <aol-layer-image *ngIf="childrenItem.value.checked && childrenItem.value.rastr?.length > 0 && childrenItem.value.data" [opacity]="childrenItem.value.hasFilters && childrenItem.value.filters ? childrenItem.value.filters.transparency / 100 : 1" [extent]="imageExtent">
        <aol-source-imagestatic [url]="childrenItem.value.rastr[0].src" [imageExtent]="childrenItem.value.data" [imageSize]="[childrenItem.value.w, childrenItem.value.h]"></aol-source-imagestatic>
    </aol-layer-image> -->

    <aol-layer-vector>
        <aol-source-vector>

            <aol-feature [style]="testStyle()" (click)="onImageClick('')">
                <aol-geometry-point>
                    <aol-coordinate [x]="+center[0]" [y]="+center[1]"></aol-coordinate>
                </aol-geometry-point>

                <!-- <aol-geometry-linestring>
                    <aol-collection-coordinates [coordinates]="[[center[0], center[1]], [center[1], center[0]]]" [srid]=" 'EPSG:4326' ">
                    </aol-collection-coordinates>
                </aol-geometry-linestring> -->

            </aol-feature>
        </aol-source-vector>
    </aol-layer-vector>

    <!-- <aol-layer-image *ngIf="(rastrBindService.imageExtent$ | async)" [opacity]=".8"> -->
    <!-- [url]="'https://backend.imus.city/storage/media/223/Kremlin.jpg'"  -->
    <!-- <aol-layer-image [opacity]=".8">
        <aol-source-imagestatic 
            [url]="rastrLayers[0].src" 
            [imageExtent]="imageExtent" 
            [imageSize]="imageSize"
            [zIndex]="100"
            >
        </aol-source-imagestatic>
    </aol-layer-image> -->

    <aol-layer-image *ngIf="firstImageURL"
        [opacity]="0.5"
        [zIndex]="110"
        >
        <aol-source-geoimage *ngIf="!rastrBindService.imageExtent?.value"
            [url]="firstImageURL"
            [imageCenter]="transformCoordinates(center, 'EPSG:4326', 'EPSG:3857')"
            >
        <!-- [imageRotate]="angleToRadians(35)" -->
        <!-- [url]="rastrLayers[1].src" -->
        <!-- [imageRotate]="angleToRadians(15)" -->
        <!-- [imageScale]="1" -->
        <!-- [imageScale]="[1, 1]" -->
        <!-- [projection]="'EPSG:3857'" -->
        <!-- [imageCenter]="transformCoordinates(center, 'EPSG:4326', 'EPSG:3857')" -->
        <!-- [url]="'/assets/data/Moscow/Moscow.jpg'"  -->
        <!-- [imageCrop]="null" -->
        <!-- [imageExtent]="imageExtent" 
            [imageSize]="imageSize" -->
        </aol-source-geoimage>
        <aol-source-geoimage *ngIf="rastrBindService.imageExtent?.value"
            [url]="firstImageURL"
            [imageCenter]="imageCenter"
            [imageScale]="imageScale"
            [imageRotate]="imageAngle"
            
            >
            <!-- [imageRotate]="angleToRadians(360)" -->
        </aol-source-geoimage>
    </aol-layer-image>

</aol-map>

<section class="layers-list" style="display: none;">

    <mat-card>
        <mat-card-title>Слои</mat-card-title>
        <mat-card-content>
            <!-- <mat-list>
                <mat-list-item>sdasd</mat-list-item>
            </mat-list> -->

            <mat-list>
                <div mat-subheader>Растровые</div>
                <mat-selection-list #shoes [multiple]="false">
                    <mat-list-option *ngFor="let layer of rastrLayers">

                        <mat-icon matListItemIcon><img matListAvatar [src]="layer.src"></mat-icon>
                        <div matListItemTitle> {{layer.name}}</div>
                        <!-- <div matListItemLine>{{folder.updated | date}}</div> -->
                    </mat-list-option>
                </mat-selection-list>
                <mat-list-item>-</mat-list-item>

                <!-- <mat-list-item *ngFor="let folder of folders">
                    <mat-icon matListItemIcon>folder</mat-icon>
                    <div matListItemTitle>{{folder.name}}</div>
                    <div matListItemLine>{{folder.updated | date}}</div>
                </mat-list-item> -->
                <mat-divider></mat-divider>
                <div mat-subheader>Подложка</div>
                <mat-list-item>-</mat-list-item>
                <!-- <mat-list-item *ngFor="let note of notes">
                    <mat-icon matListItemIcon>note</mat-icon>
                    <div matListItemTitle>{{note.name}}</div>
                    <div matListItemLine>{{note.updated | date}}</div>
                </mat-list-item> -->
            </mat-list>

        </mat-card-content>



    </mat-card>


</section>

<section class="bottom-panel">



    <mat-tab-group #tabs>
        <mat-tab label="Растровые данные">


            <!-- <app-layer-add-rastr></app-layer-add-rastr> -->

            <div class="imagePreview" *ngIf="firstImageURL">
                <!-- <button mat-icon-button color="warn" (click)="imageUploadFileControl.reset()"><mat-icon>close</mat-icon></button> -->
                <img [src]="firstImageURL" />
            </div>

            <div class="imus-row">
                <!-- <imus-file-input title="Добавить файл" [formControl]="uploadFileControl" *ngIf="!(uploaded$ | async); else uploadTpl"> -->
                <imus-file-input title="Добавить файл" accept="image/*" [formControl]="imageUploadFileControl" *ngIf="!(imageUploaded$ | async); else uploadTpl">
                    <button mat-stroked-button color="primary">
                                {{ firstImageURL ? 'Изменить файл' : 'Добавить файл' }}
                                <!-- Добавить файл -->
                                <mat-icon>add</mat-icon>
                            </button>
                </imus-file-input>
                <!-- <button mat-stroked-button color="primary" *ngIf="firstImageURL" (click)="onAddImageClick()">
                    Добавить на карту
                    <mat-icon>add</mat-icon>
                </button> -->
            </div>

        </mat-tab>
        <mat-tab label="Таблица связей">

            <!-- <mat-card>
                <mat-card-title>Таблица связей</mat-card-title>
                <mat-card-content>

                </mat-card-content>
            </mat-card> -->



            <!-- <app-rastr-bind-list></app-rastr-bind-list> -->

        </mat-tab>
        <mat-tab label="Привязка">

        </mat-tab>
    </mat-tab-group>


    <!-- <section class="image-editor" (click)="onImageClick($event)">
        <img [src]="'https://backend.imus.city/storage/media/223/Kremlin.jpg'">
    </section> -->


    <!-- <section class="app-rastr-bind-list-card-wrap">


        <mat-card style="    margin-bottom: 15px;">
            <mat-card-title>Контент</mat-card-title>
            <mat-card-content>
                <app-layer-add-rastr></app-layer-add-rastr>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-title>Список связей</mat-card-title>
            <mat-card-content>
                <app-rastr-bind-list></app-rastr-bind-list>
            </mat-card-content>
        </mat-card>

    </section> -->

</section>

<ng-template #uploadTpl>
    <button (click)="abort()" class="upload-btn" mat-stroked-button color="warn" title="Прервать загрузку">
        <mat-spinner diameter="26"> </mat-spinner>
        <mat-icon>close</mat-icon>
    </button>
</ng-template>