import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn, Router
} from '@angular/router';
import { map, tap } from 'rxjs';
import { AccountService } from "@app/modules/account/services/account.service";
import { PROFILE_ROUTES } from "@app/modules/account/pages/profile/models/profile";

export const canActivateChildAccountProfileGuardFn: CanActivateChildFn = (
    activatedRoute: ActivatedRouteSnapshot
) => {
    return  inject(AccountService).account$.pipe(
        map(account => PROFILE_ROUTES.filter(route => route.complianceContractor.includes(account.contractor_type_id))),
        map(complianceContractorRoutes => !!complianceContractorRoutes.find(route => route.route === activatedRoute.routeConfig.path)),
        tap(is => {
            if (!is) {
                inject(Router).navigateByUrl('/account/profile');
            }
        })
    );
}
