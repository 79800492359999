<div class="accounts">
    <form [formGroup]="searchForm" class="accounts__body">

        <div class="accounts__block sb w_full">
            <div class="accounts__block fd-row">
                <mat-form-field>
                    <mat-label>Юридическая форма</mat-label>
                    <mat-select formControlName="sysTypesId">
                        <mat-option [value]="null">Сбросить</mat-option>
                        <mat-option *ngFor="let sysTypes of sysTypes" [value]="sysTypes.id">{{ sysTypes.descr }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Статус</mat-label>
                    <mat-select formControlName="sysStatusId">
                        <mat-option [value]="null">Сбросить статус</mat-option>
                        <mat-option *ngFor="let sysStatus of sysStatus" [value]="sysStatus.id">{{ sysStatus.descr }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="accounts__block">
                <mat-form-field>
                    <mat-label>Наименование / ФИО</mat-label>
                    <input formControlName="descr" matInput type="text" placeholder="Введите текст для поиска наименования компании или ФИО">
                </mat-form-field>
            </div>
        </div>


        <div class="accounts__block sb buttons">
            <app-button
                label="Очистить фильтр"
                [type]="buttonType.primaryButton"
                [icon]="'clear'"
                (onclick)="searchForm.reset()" />

            <app-button
                label="Скачать Excel"
                [type]="buttonType.primaryButton"
                [icon]="'save'"
                (onclick)="downloadExel()" />
        </div>
    </form>

    <div class="accounts__table">
        <mat-progress-bar *ngIf="isLoaded" mode="indeterminate"></mat-progress-bar>
        <mat-table
            matSort
            (matSortChange)="fetchData()"
            [dataSource]="dataContractors">

            <ng-container matColumnDef="id">
                <mat-header-cell mat-sort-header  *matHeaderCellDef> id </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="descr">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Наименование /ФИО  </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <a [routerLink]="[element.id]">{{ element.descr }}</a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type_descr">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Юр. Форма </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.type_descr }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="requisite_inn">
                <mat-header-cell mat-sort-header *matHeaderCellDef> ИНН </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.requisite_inn ? element.requisite_inn : '&mdash;' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="requisite_kpp">
                <mat-header-cell mat-sort-header *matHeaderCellDef> КПП </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.requisite_kpp ? element.requisite_kpp : '&mdash;' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="address">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Адрес </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.address ? element.address : '&mdash;' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status_descr">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Статус </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.status_descr }}</mat-cell>
            </ng-container>

            <mat-header-row  *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <app-paginator [pageSize]="pageSize" [pageIndex]="pageIndex" [length]="totalItems" (page)="onPaginateData($event)"></app-paginator>
    </div>

</div>
