<ng-container *ngIf="(isActive | async)">
	<ng-container *ngIf="customMessage; else template">
        <mat-error>{{customMessage}}</mat-error>
<!--		<p class="error-label">{{customMessage}}</p>-->
	</ng-container>
	<ng-template #template>
		<div #contentContainer>
			<ng-content></ng-content>
		</div>
		<ng-container *ngIf="!contentContainer.innerHTML.trim()">
<!--			<p class="error-label">{{defaultMessage}}</p>-->
            <mat-error>{{defaultMessage}}</mat-error>
		</ng-container>
	</ng-template>
</ng-container>
