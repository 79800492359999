<div class="user-profile_content g30">
    <form class="user-profile_form" [formGroup]="userForm">
        <mat-form-field>
            <mat-label>Фамилия, Имя, Отчество</mat-label>
            <input [readonly]="access === 'read'" formControlName="name" matInput placeholder="Фамилия, Имя, Отчество" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Email</mat-label>
            <input [readonly]="access === 'read'" formControlName="email" matInput type="email" placeholder="Email" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Контактный телефон</mat-label>
            <input [readonly]="access === 'read'" formControlName="phone" mask="+0 (000) 000 00 00" matInput placeholder="Контактный телефон">
        </mat-form-field>

        <app-button [type]="buttonType.primaryButton"  *ngIf="access === 'update'" (click)="submitUserForm()" [disabled]="userForm.invalid" [label]="'Сохранить'" [fullWidth]="true" />
    </form>

    <form *ngIf="access === 'update'" class="user-profile_form" [formGroup]="passwordForm">
        <h3 class="user-profile_title-pass">Изменить пароль</h3>
        <mat-form-field>
            <mat-label>Введите новый пароль </mat-label>
            <input [type]="hideNew ? 'password' : 'text'" formControlName="password" matInput placeholder="Введите новый пароль " required>
            <button mat-icon-button matSuffix (click)="hideNew = !hideNew" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNew">
                <mat-icon>{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Повторите пароль</mat-label>
            <input [type]="hideNew ? 'password' : 'text'" formControlName="confirmPassword" matInput placeholder="Повторите пароль" required>
            <button mat-icon-button matSuffix (click)="hideNew = !hideNew" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNew"></button>
        </mat-form-field>

        <app-button [type]="buttonType.primaryButton" (click)="submitPasswordForm()" [disabled]="passwordForm.invalid" [label]="'Изменить пароль'" [fullWidth]="true" />
    </form>
</div>
