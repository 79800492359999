import { ChangeDetectionStrategy, Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../shared/services/user.service';
import { BehaviorSubject, map, of } from 'rxjs';
import { MaterialModule } from '@app/shared/modules/material/material.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../../shared/shared.module';
import { MatSelect } from '@angular/material/select';
import { RolePermissionData, RolesViewData } from '@app/shared/models';
import { SysSubjects } from '@app/shared/models/menu';

@Component({
    selector: 'app-permission-settings',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        SharedModule,
    ],
    templateUrl: './permission-settings.component.html',
    styleUrls: ['./permission-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionSettingsComponent {

    private userService = inject(UserService);

    roleList$ = new BehaviorSubject<RolesViewData>([]);

    @ViewChild('sysSubjectSelect', {static: false}) sysSubjectSelect: MatSelect;

    rolePermissions: RolePermissionData[];
    rolePermissionsMatrix$ = new BehaviorSubject<RolePermissionData[]>([]);
    rolePermissions$ = new BehaviorSubject<RolePermissionData[]>([]);

    public sysSubjects$ = new BehaviorSubject<SysSubjects[]>([]);
    public sysSubjectId: number;
    public sysSubjectId$ = new BehaviorSubject<number>(null);

    isLoaded = true;
    displayedColumns: string[] = [
        'accountTypeDescr',
        'id',
        'roleDescr',
        'canCreate',
        'canRead',
        'canUpdate',
        'canDelete',
    ];

    ngOnInit() {
        this.userService.viewRoles().subscribe((roles: RolesViewData[]) => {
            this.roleList$.next(roles);
        });
        this.userService.sysSubjects().subscribe((subjects: SysSubjects[]) => {
            this.sysSubjects$.next(subjects);
            this.changeSysSubjectId(this.sysSubjects$.value[0].id);
        });
    }

    ngAfterViewInit() {
        // Welcome animation
        setTimeout(() => {
            this.sysSubjectSelect.open();
        }, 600);
        setTimeout(() => {
            this.sysSubjectSelect.close();
        }, 1600);
    }


    onChangeCheckbox(event, role, permName) {
        let rolePermission = this.rolePermissions.find(rolePermission => rolePermission.role_id === role.id && rolePermission.subject_id === this.sysSubjectId$.value);
        if(!rolePermission) {
            const index = this.rolePermissions.push({
                    "can_create": false,
                    "can_read": false,
                    "can_update": false,
                    "can_delete": false,
                    "role_id": role.id,
                    "subject_id": this.sysSubjectId$.value,
            });
            this.rolePermissions[index - 1][permName] = event.checked;
        } else {
            rolePermission[permName]  = event.checked;
        }
    }

    changeSysSubjectId(id: number) {
        this.isLoaded = true;
        this.rolePermissions = [];
        this.sysSubjectId$.next(id);
        this.userService.rolePermissions(id).subscribe((res: any) => {
            this.rolePermissions$.next(res.data);
                this.rolePermissions = res.data.map((item: any) => {
                    return {
                        "id": item.id,
                        "can_create": item.can_create,
                        "can_read": item.can_read,
                        "can_update": item.can_update,
                        "can_delete": item.can_delete,
                        "role_id": item.role_id,
                        "subject_id": item.subject_id,
                    }
                });
            // TODO change detection force
            this.roleList$.next(this.roleList$.value);
            this.isLoaded = false;
        });
    }

    findPermissionForRole(role) {
        return this.rolePermissions$.pipe(
            map(rolePermissions => rolePermissions.find(rolePermission => rolePermission.role_id === role.id)))
    }


    isRolePermissionEnable(role: any, perm: any) {
    if(this.rolePermissions) {
        let rolePermissionItem = this.rolePermissions.find(rolePermission => rolePermission.role_id === role.id && rolePermission.subject_id === this.sysSubjectId$.value);
        if(rolePermissionItem && rolePermissionItem[perm]) {
            return of(rolePermissionItem[perm]);
        }
    }
    return of(null);
    }

    saveRolePermissions() {
        for(let rolePermission of this.rolePermissions) {
            this.userService.sysRolePermissions(rolePermission).subscribe();
        }
    }

    cancelRolePermissions() {
        this.changeSysSubjectId(this.sysSubjectId$.value);
    }

    compareCategoryObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id == object2.id;
    }

}
