<h1 mat-dialog-title class="imus-row title">Гостевой аккаунт предоставил</h1>
<div mat-dialog-content>
    <div class="imus-row title">Наименование/ ФИО контрагента</div>
    <div class="imus-row">{{data.contractor_descr || '--'}}</div>
    <div class="imus-row title">Контактное лицо</div>
    <div class="imus-row">{{data.user_name || '--'}}</div>
    <div class="imus-row title">Телефон контактного лица</div>
    <div class="imus-row">{{data.user_phone || '--'}}</div>
    <div class="imus-row title">ИНН</div>
    <div class="imus-row">{{data.contractor_inn || '--'}}</div>
    <div class="imus-row title">КПП</div>
    <div class="imus-row">{{data.contractor_kpp || '--'}}</div>
    <div class="imus-row title">email контактного лица</div>
    <div class="imus-row">{{data.user_email || '--'}}</div>
</div>

