import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './components/registration/registration.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/modules/material/material.module';
import { AccountRoutingModule } from './account-routing.module';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { RegisterModalComponent } from './components/register-modal/register-modal.component';
import { SharedModule } from '@app/shared/shared.module';
import { MeasurementComponent } from './components/settings-modal/measurement/measurement.component';
import { RegisterComponent } from './components/register';
import { httpInterceptorProviders } from '@app/shared/interceptors';
import { ProfessionalAccountComponent } from './pages/professional-account/professional-account.component';
import { ChildAccountComponent } from './pages/child-account/child-account.component';
import { AccountInformationComponent } from './pages/account-information/account-information.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { AccountComponent } from './pages/account/account.component';
import { EmailLinkModalComponent } from './components/email-link-modal/email-link-modal.component';
import { EmailConfirmationModalComponent } from './components/email-confirmation-modal/email-confirmation-modal.component';
import { AccountLeftSidebarComponent } from './components/account-left-sidebar/account-left-sidebar.component';
import { AccountFeedbackModalComponent } from './components/account-feedback-modal/account-feedback-modal.component';
import { InfoComponentComponent } from './components/info-component/info-component.component';
import { AccountHeaderComponent } from "@app/modules/account/components/account-header/account-header.component";


@NgModule({
  declarations: [
    RegistrationComponent,
    LoginModalComponent,
    RegisterComponent,
    RegisterModalComponent,
    EmailLinkModalComponent,
    EmailConfirmationModalComponent,
    MeasurementComponent,
    AccountComponent,
    ProfessionalAccountComponent,
    ChildAccountComponent,
    AccountInformationComponent,
    AccountSettingsComponent,
    AccountFeedbackModalComponent,
    InfoComponentComponent,
  ],
    imports: [
      CommonModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
        CommonModule,
        MaterialModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        AccountRoutingModule,
        AccountHeaderComponent,
        AccountLeftSidebarComponent
    ],
  exports:[
    LoginModalComponent,
    RegisterModalComponent,
    EmailLinkModalComponent,
    EmailConfirmationModalComponent,
    ProfessionalAccountComponent,
    AccountInformationComponent,
    AccountSettingsComponent
  ],
  // entryComponents: [
  //   LoginModalComponent,
  //   RegisterModalComponent
  // ],
  providers: [
    httpInterceptorProviders,
    // AuthenticationService,
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AccountModule { }
