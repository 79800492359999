import { Component } from '@angular/core';

@Component({
  selector: 'app-rastr-page',
  templateUrl: './rastr-page.component.html',
  styleUrls: ['./rastr-page.component.scss']
})
export class RastrPageComponent {

}
