import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MapService } from '@app/modules/map/services';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { PlotFeature } from '@app/shared/models';
import { ProjectTypes } from '@app/shared/models/project';
import { PlotService } from '@app/shared/services';
import { ProjectService } from '@app/shared/services/project.service';

@Component({
    selector: 'app-project-sections-dialog',
    templateUrl: './project-sections-dialog.component.html',
    styleUrls: ['./project-sections-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSectionsDialogComponent {
    projectDataSource: ProjectTypes.IProjectResponseItem[];
    sectionDataSource: ProjectTypes.ISectionResponseItem[];

    buttonType = ButtonComponentSettings.ButtonType;

    projectColumns: string[] = [
        'name',
        'client',
        'contractor',
        'updated_at',
        'created_at',
        'edit',
        'remove',
    ];
    sectionColumns: string[] = [
        'name',
        'info',
        'location',
        'plot_id',
        'use',
        'length',
    ];

    measurementsLenth = 0;
    pageSize = 20;
    pageIndex = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: { project: ProjectTypes.IProjectResponseItem },
        public projectService: ProjectService,
        private matDialog: MatDialog,
        private plotService: PlotService,
        private mapService: MapService,
        public dialogRef: MatDialogRef<ProjectSectionsDialogComponent>
    ) {
        this.projectDataSource = [data.project];
        this.pagingData();
    }

    onPageEvent(pageEvent: PageEvent) {
        this.pageIndex = pageEvent.pageIndex;
        this.pagingData();
    }

    private pagingData() {
        const start = this.pageIndex * this.pageSize;
        this.sectionDataSource = this.data.project.sections.Sections.slice(
            start,
            start + this.pageSize
        );
    }

    onSectionInfoClick(row: any) {
        this.projectService.editableSection.next(row.properties);
        this.projectService.gridDialogRolledUp = true;
        this.matDialog.closeAll();
    }

    isLocationClickAllowed(sections) {
        console.log('isLocationClickAllowed', sections);
        return sections.properties.plot_id;
    }

    onLocationClick(section?: any) {
        console.log('onLocationClick', section);
        if(section.properties.plot_id) {
            this.plotService.getById(section.properties.plot_id)
            .subscribe((plot: PlotFeature) => {
                if(plot 
                    && plot.properties 
                    // && plot.properties.coords 
                    // && plot.properties.coords[0]
                    ) {
                    this.mapService.zoomToPlot(plot);
                }
            });
            this.matDialog.closeAll();
        }
    }

    onRemoveClick(project: ProjectTypes.IProjectResponseItem){                
        this.projectService.deleteProject(project).subscribe((result) => {
            if (result === true) 
                this.dialogRef.close();            
        });   
    }

    onEditProjectClick(project: ProjectTypes.IProjectResponseItem) {        
        this.projectService.editableProject = project;
        this.projectService.gridDialogRolledUp = true;
        this.dialogRef.close();
        this.matDialog.closeAll();
    }

}
