import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { imus } from '@app/shared/imus';
import { AuthenticationService } from '@app/shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { takeUntil, tap } from 'rxjs';
import { AccountFeedbackModalComponent } from '../../components/account-feedback-modal/account-feedback-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

interface FormModel {
    name: string;
    email: string;
    phone: string;
    // password: string
}

@Component({
    selector: 'app-account-information',
    templateUrl: './account-information.component.html',
    styleUrls: ['./account-information.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInformationComponent {
    public readonly account = {
        form: null as imus.form.IFormGroup<FormModel>,
        model: {
            name: null,
            email: null,
            phone: null,
            // password: null
        } as FormModel,
    };

    public panelExpanded = ""
    public panels: any[] = [
        // {
        //   title: `...`
        // },
        // {
        //     id: 'contacts',
        //     title: `Контакты`,
        //     expanded: false,
        // },
        // {
        //     id: 'feedback',
        //     title: `Обратная связь`,
        //     expanded: false,
        //     // counter: 4,
        // },
        // {
        //     id: 'faq',
        //     title: `Вопросы-ответы`,
        //     expanded: false,
        //     // items: this.staticMenuTools,
        // },
        {
          id: 'manual',
          title: `Руководство пользователя`,
          expanded: false,
        //   items: this.staticMenuSettings,
        },
        // {
        //     id: 'eula',
        //     title: `Условия пользовательского соглашения`,
        //     expanded: false,
        //   },
  
        
    ];


    constructor(
        public authenticationService: AuthenticationService,
        private router: Router,
        public matDialog: MatDialog,
        private readonly _fb: FormBuilder,
        private readonly _destroy$: ImusDestroyService,
    ) {
        this.account.form = _fb.fromTypedModel<FormModel>(this.account.model);
        this.authenticationService.currentUser$
            .pipe(
                // tap(currentUser => console.log('tap currentUser', currentUser)),
                // switchMap(async (currentUser) => currentUser?.user),
                tap((currentUser) => {
                    // console.log('tap currentUser', currentUser)
                    if (currentUser) {
                        this.account.model = currentUser.user;
                    } else {
                        this.account.model = {
                            name: null,
                            email: null,
                            phone: null,
                        };
                    }
                    this.account.form = _fb.fromTypedModel<FormModel>(
                        this.account.model
                    );
                }),
                takeUntil(this._destroy$)
            )
            .subscribe();
    }

    onChildAccountClick() {
        this.router.navigate(['account', 'child']);
    }

    onAccountSettingsClick() {
        this.router.navigate(['account', 'settings']);
    }

    onMainPageClick() {
        this.router.navigate(['app-taris']);
    }

    onFeedbackClick() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.data = { email : this.account.model.email}
        dialogConfig.id = "account-feedback-component";

        this.matDialog.open(AccountFeedbackModalComponent, dialogConfig);

    }
}
