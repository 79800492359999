<div class="modal">
  <div (click)="closeModal()" class="modal-close">CLOSE</div>
  <div class="modal-form form">
    <!-- <alert></alert> -->

    <!-- <form [formGroup]="registerForm" (ngSubmit)="onSubmit()"></form> -->
    <!-- <div class="modal-form_group form_group form_group--icon login-icon"> -->
    <!-- <input type="text" placeholder="Логин" formControlName="login"> -->
    <!-- <input type="text" placeholder="Логин" formControlName="login" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" /> -->
    <!-- </div> -->
    <!-- <ng-container *ngIf="(modalState$ | async) "></ng-container> -->

    <!-- <ng-container [ngSwitch]="(modalState$ | async)"> -->
      <!-- <ng-container *ngSwitchCase="1"> -->
        <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
          <div class="modal-form_group form_group form_group--center">
            Введите email, на который будет отправлена<br>ссылка для изменения пароля
          </div>
          <div class="modal-form_group form_group form_group--icon email-icon">
            <input type="text" placeholder="Email" formControlName="email">
            <!-- <input type="text" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" /> -->
            <div *ngIf="emailSubmitted && ef['email'].errors" class="invalid-feedback">
              <div *ngIf="ef['email'].errors.required">Email is required</div>
            </div>
          </div>
          <div class="modal-form_group form_group form_group--center">
            <button [disabled]="loading" class="btn">Отправить</button>
          </div>

        </form>
      <!-- </ng-container> -->
      <ng-container *ngIf="(modalState$ | async) == 2">
        <div class="modal-form_group form_group form_group--center">
          Письмо на указанный Вами email отправлено,<br>для изменения пароля перейдите по ссылке указанной в письме.
        </div>
      </ng-container>
      <!-- <div *ngSwitchCase="3">3</div> -->
      <!--default case when there are no matches -->
      <!-- <div *ngSwitchDefault>...</div> -->
    <!-- </ng-container> -->

    <!-- <div class="modal-form_group form_group form_group--icon email-icon"> -->
    <!-- <input type="text" placeholder="Email" formControlName="email"> -->
    <!-- <input type="text" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" /> -->
    <!-- <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
              </div> -->
    <!-- </div> -->
    <!-- <div class="modal-form_group form_group form_group--icon phone-icon"> -->
    <!-- <input type="text" placeholder="Телефон" formControlName="phone" prefix="+7 " mask="(000) 000-0000"> -->
    <!-- <input type="text" placeholder="Телефон" formControlName="phone" prefix="+7 " mask="(000) 000-0000" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" /> -->
    <!-- </div> -->
    <!-- <ng-container *ngIf="submitted && (emailExist$ | async)">
        <div class="modal-form_group form_group form_group--center">
          Такой email уже зарегестрирован
        </div>
        <div class="modal-form_group form_group form_group--center">
          <a href="" (click)="recoverPassword()">Восстановить пароль</a>
          <a href="" (click)="toLoginFromRegister($event)">Войти</a>
        </div>
      </ng-container> -->

  </div>
</div>