import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSelectModule} from "@angular/material/select";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {debounceTime, take, takeUntil} from "rxjs";
import { saveAs } from 'file-saver';

import {SharedModule} from "@shared/shared.module";
import {ImusDestroyService} from "@services/destroy.service";
import {ButtonComponentSettings} from "@shared/components/button/button.component";
import {ContractorsService} from "@app/modules/account/services/contractors.service";
import {DataAccounts, SysContractors, SysStatus, SysTypes} from "@shared/models/contractors";
import {PageEvent} from "@angular/material/paginator";
import {SearchParamsService} from "@app/modules/account/services/search-params.service";
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-contractors',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        ReactiveFormsModule,
        SharedModule,
        RouterOutlet,
        RouterLink
    ],
    templateUrl: './contractors.component.html',
    styleUrls: ['./contractors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorsComponent implements OnInit {
    private fb = inject(FormBuilder);
    private destroyRef = inject(ImusDestroyService);
    private contractorsService = inject(ContractorsService);
    private searchParamsService = inject(SearchParamsService);
    private cdr = inject(ChangeDetectorRef);

    @ViewChild(MatSort) sort: MatSort;

    totalItems: number = 0;
    pageSize: number = 15;
    pageIndex: number = 0;
    isLoaded = true;
    searchForm: FormGroup;
    sysTypes: SysTypes[];
    sysStatus: SysStatus[];
    buttonType = ButtonComponentSettings.ButtonType;
    dataContractors: MatTableDataSource<SysContractors> = new MatTableDataSource<SysContractors>();
    displayedColumns: string[] = [
        'id',
        'descr',
        'type_descr',
        'requisite_inn',
        'requisite_kpp',
        'address',
        'status_descr',
    ];

    ngOnInit(): void {
        this.initForm();
        this.loadDataSelects();
        this.changesForm();
    }

    ngAfterViewInit() {
        this.dataContractors.sort = this.sort;
    }

    loadDataSelects(): void {
        this.getContractors();

        this.contractorsService.getSysTypes([['where[catalog_id]', 2], ['paginate', false]])
            .pipe(take(1))
            .subscribe(sysTypes => this.sysTypes = sysTypes);

        this.contractorsService.getSysStatus()
            .pipe(take(1))
            .subscribe(sysStatus => this.sysStatus = sysStatus);
    }

    initForm(): void {
        this.searchForm = this.fb.group({
            sysTypesId: [null],
            descr: [null],
            sysStatusId: [null]
        });
    }

    changesForm(): void {
        this.searchForm.valueChanges
            .pipe(debounceTime(700), takeUntil(this.destroyRef))
            .subscribe(() => this.fetchData());
    }

    getContractors(params?: any): void {
        this.isLoaded = true;
        this.cdr.markForCheck();
        this.contractorsService.getContractors(params)
            .pipe(take(1))
            .subscribe((data: DataAccounts) => {
                this.totalItems = data.total;
                this.pageSize = data.per_page;
                this.pageIndex = data.current_page - 1;
                this.dataContractors.data = data.data as SysContractors[];
                this.isLoaded = false;
                this.cdr.markForCheck();
            });
    }

    downloadExel(): void {
        const params = this.getSearchParams();
        delete params.page;
        delete params.per_page;
        params.format = 'excel'

        this.contractorsService.getContractors(params, 'blob')
            .pipe(take(1))
            .subscribe((dataAccounts: Blob)  => {
                const blob = new Blob([dataAccounts]);

                saveAs(blob, 'contractors.xlsx');
                this.cdr.markForCheck();
            });
    }

    onPaginateData($event: PageEvent): void {
        this.pageSize = $event.pageSize;
        this.pageIndex = $event.pageIndex;
        this.fetchData();
    }

    fetchData(): void {
        this.getContractors(this.getSearchParams());
    }

    getSearchParams(): any {
        const sortField = this.dataContractors.sort.active;
        const sortDirection = this.dataContractors.sort.direction;

        const paramMappings = [
            { formField: 'sysTypesId', paramName: 'where[type_id]' },
            { formField: 'sysStatusId', paramName: 'where[status_id]' },
            { formField: 'descr', paramName: 'where[descr]', operator: 'like' },
        ];

        return this.searchParamsService.getSearchParams(
            paramMappings,
            this.searchForm,
            sortField,
            sortDirection,
            this.pageIndex,
            this.pageSize
        );
    }
}
