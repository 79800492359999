import { ChangeDetectionStrategy, Component, HostListener, Input, inject } from '@angular/core';
import { GetHtmlService } from '../../services/get-html.service';
import { EMPTY, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ShowImageDialogComponent } from '../show-image-dialog/show-image-dialog.component';

@Component({
  selector: 'app-info-content',
  templateUrl: './info-content.component.html',
  styleUrls: ['./info-content.component.scss'],
  imports: [AsyncPipe],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoContentComponent {
  protected readonly getHtmlService = inject(GetHtmlService);
  private readonly matDialog = inject(MatDialog);
  protected content = new Observable<string>();

  @Input()
  public set html(url: string) {
    url 
      ? this.content = this.getHtmlService.getHtml(url)
      : this.content = EMPTY;
  }

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(targetElement: HTMLElement): void {
    if (targetElement.tagName !== 'IMG') { return; }
    console.log('targetElement', targetElement)
    this.matDialog.open(ShowImageDialogComponent, {
      data: (targetElement as HTMLImageElement).src
    })
  }

}
