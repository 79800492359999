import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Optional,
    Self,
    ViewEncapsulation
} from '@angular/core';
import { ImusBaseInputComponent } from "@shared/components/imus-base-input/imus-base-input.component";
import { ImusDestroyService } from "@shared/services/destroy.service";
import { FormControl, NgControl } from "@angular/forms";
import { animationFrameScheduler } from "rxjs";
import { coordinatesValidator } from "@shared/validators/coordinates.validator";
import { InputType } from "@shared/input-type";

@Component({
    selector: 'app-imus-input',
    templateUrl: './imus-input.component.html',
    styleUrls: ['./imus-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImusInputComponent extends ImusBaseInputComponent<string | number | Date>{

    public inputType = InputType;

    /**
     * Тип поля
     *
     * @see InputType
     * @default text
     * */
    @Input()
    public type: InputType = InputType.text;

    /**
     * Установка флага обязательного заполнения для визуализации в виде *
     * */
    @Input()
    public required = false;

    @Input()
    public autocomplete = 'on';

    @Input('outline') public outline: boolean = false;

    /**
     * Маска
     * */
    @Input()
    mask: IMask.AnyMaskedOptions;
    constructor(
        protected override readonly _destroy$: ImusDestroyService,
        @Optional() @Self() protected override readonly _control: NgControl
    ) {
        super(_destroy$, _control);
        this.innerControl = new FormControl<string | number>(null);
        this.changed = new EventEmitter<string | number>()
        animationFrameScheduler.schedule(() => {
            if (this.type === InputType.coords) {
                this.innerControl.addValidators(coordinatesValidator);
            }
        });
    }

    override beforeSetOuterValue(value: string | number) {
        if (value && this.type === InputType.date) {
            return new Date(value);
        }
        return value;
    }

}
