import { Component, OnInit } from '@angular/core';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    catchError,
    concatMap,
    first,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    AlertService,
    AuthenticationService,
    UserService,
} from '@shared/services';
import { User } from '@app/shared/models';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { EmailLinkModalComponent } from '../email-link-modal/email-link-modal.component';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { InputComponentSettings } from '@app/shared/components/input/input.component';
import { passwordMatchValidator } from '@app/shared/validators/password.validator';
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'app-register-modal',
    templateUrl: './register-modal.component.html',
    styleUrls: ['./register-modal.component.scss'],
})
export class RegisterModalComponent implements OnInit {
    transitionEnd = false;
    buttonType = ButtonComponentSettings.ButtonType;
    inputType = InputComponentSettings.InputType;

    public accountType: number = 0;
    public accountProType: number = 1;

    public displayPassword: boolean = false;
    public displayTwoPassword: boolean = false;

    public accountProTypes: any[] = [
        {
            name: 'Юридическое лицо',
            value: 1,
        },
        {
            name: 'Индивидуальный предприниматель',
            value: 2,
        },
    ];

    accountProTypeSelectionChange(item: MatRadioChange) {
        // console.log('accountProTypeSelectionChange', item);
        this.accountProType = item.value;
        const form = this.registerForm
        const formAdvance = this.registerFormAdvance
        if (this.accountProType == 2){
            let contractorDescription = 'ИП ' 
                + form.controls['contractor_persons_name_first'].value
                + ' ' + form.controls['contractor_persons_name_last'].value
            if (form.controls['contractor_persons_name_second'].value) {
                contractorDescription += ' ' + form.controls['contractor_persons_name_second'].value
            }
            formAdvance.patchValue({ contractor_descr: contractorDescription });
            this.newUser.contractor_descr = contractorDescription
            formAdvance.controls['contractor_descr'].removeValidators(Validators.required)
            // formAdvance.updateValueAndValidity()
        } else if (this.accountProType == 1) {
            // formAdvance.patchValue({ contractor_descr: '' });
            this.newUser.contractor_descr = ''
            if (!formAdvance.controls['contractor_descr'].hasValidator(Validators.required)) {
                formAdvance.controls['contractor_descr'].addValidators(Validators.required)
            }
            // formAdvance.updateValueAndValidity()
        }
        formAdvance.updateValueAndValidity()
        // console.log('this.registerFormAdvance.controls',formAdvance.controls['contractor_descr'])
    }

    public newUser: any = {
        contractor_persons_name_last: '',
        contractor_persons_name_first: '',
        contractor_persons_name_second: undefined,
        user_email: '',
        user_phone: undefined,
        user_password: '',
        contractor_descr: undefined,
        contractor_requisites_inn: undefined,
        contractor_requisites_kpp: undefined,
        contractor_address: undefined,
        accountProType: undefined,
        contractors_is_rights_granted: false,
    };

    registerForm: FormGroup;
    registerFormAdvance: FormGroup;

    _loading = new BehaviorSubject<boolean>(false);
    loading$: Observable<boolean> = this._loading;

    submitted = false;
    passwordInputType = 'password';
    passwordRepeatInputType = 'password';
    _emailExist = new BehaviorSubject<boolean>(false);
    emailExist$: Observable<boolean> = this._emailExist.asObservable();

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<RegisterModalComponent>,
        private readonly _destroy$: ImusDestroyService
    ) {
        // redirect to home if already logged in
        // Перенаправляет пользователя если уже залогинин и email верифицирован
        // if (this.authenticationService.currentUserValue && this.authenticationService.currentUserEmailVerified) {
        //     this.router.navigate(['/']);
        // }
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            contractor_persons_name_last: new FormControl('', [Validators.required]),
            contractor_persons_name_first: new FormControl('', [Validators.required]),
            contractor_persons_name_second: new FormControl(''),
            user_email: new FormControl('', [Validators.required]),
            user_phone: new FormControl(''),
            // user_password: ['', Validators.required],
            passGroup: new FormGroup(
                {
                    password: new FormControl('', [
                        Validators.required,
                        Validators.minLength(8),
                    ]),
                    confirmPassword: new FormControl('', [
                        Validators.required,
                        Validators.minLength(8),
                    ]),
                },
                // passwordMatchValidator
                // {
                //     validators: (control) => {
                //         if (control.value.passGroup.password !== control.value.passGroup.confirmPassword) {
                //             // control.get('passGroup').get('confirmPassword').setErrors({ notSame: true });
                //             return { notSame: true }
                //             // control.get('passGroup').setErrors({ notSame: true });
                //         }
                //         return null;
                //     },
                // }
            ),
        },
        {
            validators: (control) => {
                if (control.value.passGroup.password !== control.value.passGroup.confirmPassword) {
                    control.get('passGroup').get('confirmPassword').setErrors({ notSame: true });
                    return { notSame: true }
                    // control.get('passGroup').setErrors({ notSame: true });
                }
                control.get('passGroup').get('confirmPassword').setErrors(null);
                return null;
            },
          }
        );

        this.registerFormAdvance = this.formBuilder.group({
            contractor_descr: new FormControl('', [Validators.required]),
            // contractor_requisites_inn: ['', this.accountProType == 1 ? [Validators.minLength(10), Validators.maxLength(10)] : [Validators.minLength(12), Validators.maxLength(12)]],
            contractor_requisites_inn:  new FormControl('', [Validators.minLength(10), Validators.maxLength(12)]),
            contractor_requisites_kpp:  new FormControl('', [Validators.minLength(9), Validators.maxLength(9)]),
            contractor_address:  new FormControl(''),
        });

        // console.log('registerForm.value.passGroup.confirmPassword',this.registerForm.value.passGroup.confirmPassword)
    }

    // convenience getter for easy access to form fields
    get f() {
        // console.log(this.registerForm.controls)
        return this.registerForm.controls;
    }

    get g() {
        return (this.registerForm.controls['passGroup'] as FormGroup).controls;
    }

    onSubmit() {
        // console.log('onSubmit', this.registerForm, this.registerFormAdvance);
        // console.log('newUser', this.newUser);
        // console.log('forms valid', this.registerForm.valid, this.registerFormAdvance.valid)

        if(this.accountType == 1){
            this.newUser.contractor_type_id = 203; //203
        } else if (this.accountType == 2) {
            if(this.accountProType == 1) {
                this.newUser.contractor_type_id = 201; //201
            } else if(this.accountProType == 2) {
                this.newUser.contractor_type_id = 202;
            }
        }

        // debugger
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // this.userService.registerPro(this.newUser)
        // .subscribe((result: any) => {
        //     console.log('result', result);
        // });

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            // console.log(this.registerForm)
            return;
        }

        if (this.accountType == 2 && this.registerFormAdvance.invalid) {
            // if (this.registerFormAdvance.invalid) {
                return;
            // }
        }

        this._loading.next(true);
        this._emailExist.next(false);
        // let user = Object.assign({}, new User(), {
        //     email: this.registerForm.value.email,
        //     password: this.registerForm.value.passGroup.password,
        //     password_confirmation:
        //         this.registerForm.value.passGroup.confirmPassword,
        // });

        this.userService.registerPro(this.newUser)
            .pipe(
                concatMap(value => this.authenticationService.login(this.newUser.user_email, this.newUser.user_password)),
                tap(data => {
                    if (data.user.email_verified_at !== null) {
                        this.router.navigate(['/'])
                    } 
                    else this.router.navigate(['/email-confirmation'], { state: {user: data.user, from: 'register'} })
                    // this.router.navigate(['/email-confirmation'], { state: {user: user} });
                    this.dialogRef.close();
                }),
                catchError(err => {
                    if (err.status == 422) {
                        // console.log('err',err)
                        if (err.error?.data?.user_email == 'user_email already in use') {
                            this.registerForm.setErrors({
                                notUniqueEmail: true
                              })
                            // console.log(err.error.data.user_email)
                        }
                    //   setTimeout(() => this.registerForm.setErrors(null), 2000)
                    }
                    return throwError(() => err)
                  }),
                  takeUntil(this._destroy$)
          
            )
            .subscribe(
                // result => console.log('result', result)
            );



    }

    closeModal() {
        this.dialogRef.close();
    }
    showPassword() {
        this.passwordInputType =
            this.passwordInputType === 'password' ? 'text' : 'password';
    }

    showRepeatPassword() {
        this.passwordRepeatInputType =
            this.passwordRepeatInputType === 'password' ? 'text' : 'password';
    }

    recoverPassword(event) {
        event.preventDefault();
        this.dialogRef.close();

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-change-password-component';

        this.matDialog.open(EmailLinkModalComponent, dialogConfig);
    }

    toLoginFromRegister(event) {
        // alert(111);
        event.preventDefault();
        this.dialogRef.close();

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-login-component';

        this.matDialog.open(LoginModalComponent, dialogConfig);
    }

    transitionEnded() {
        if (this.accountType) setTimeout(() => {this.transitionEnd = true}, 500)
    }
}
