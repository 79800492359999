import { Pipe, PipeTransform } from '@angular/core';
import { NodeTypeId } from "@shared/models";

@Pipe({
  name: 'sortByLayerType'
})
export class SortByLayerTypePipe<T extends NodeTypeId> implements PipeTransform {

  transform(list: Array<T>, layerTypeId: number): T[] {
    return list?.filter(e => e.layer_type_id === layerTypeId) ?? list;
  }

}
