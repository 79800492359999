export enum UploadedInspectionFileType {
    /** project.json */
    project = 'project.json',
    /** section.json */
    section = 'section.json',
    /** observation.json */
    observation = 'observation.json'
}

export type InspectionFileType = 'project' | 'section' | 'observation' | 'rest' | 'all';
