
import {
  Component,
  OnInit,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { MapService } from '@app/modules/map/services';
// import { privateDecrypt } from 'crypto';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import MapEvent from 'ol/MapEvent';
import ObjectEvent from 'ol/Object';
// import RenderEvent from 'ol/render/Event';
import { Control } from 'ol/control';
import { Interaction } from 'ol/interaction';
import RenderEvent from 'ol/render/Event';

@Component({
  selector: 'aol-map',
  template: `
    <div [style.width]="width" [style.height]="height"></div>
    <ng-content></ng-content>
  `,
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public instance: Map;
  public componentType = 'map';

  @Input()
  width = '100%';
  @Input()
  height = '100%';
  @Input()
  pixelRatio?: number;
  @Input()
  keyboardEventTarget: Element | string | any;
  @Input()
  loadTilesWhileAnimating: boolean;
  @Input()
  loadTilesWhileInteracting: boolean;
  @Input()
  logo: string | boolean;
  @Input()
  renderer: 'canvas' | 'webgl';

  @Output()
  olClick: EventEmitter<MapBrowserEvent<any>>;
  @Output()
  dblClick: EventEmitter<MapBrowserEvent<any>>;
  @Output()
  moveStart: EventEmitter<MapEvent>;
  @Output()
  moveEnd: EventEmitter<MapEvent>;
  @Output()
  pointerDrag: EventEmitter<MapBrowserEvent<any>>;
  @Output()
  pointerMove: EventEmitter<MapBrowserEvent<any>>;
  @Output()
  onpostrender: EventEmitter<RenderEvent>;
  @Output()
  postRender: EventEmitter<MapEvent>;
  @Output()
  onprerender: EventEmitter<RenderEvent>;
  @Output()
  propertyChange: EventEmitter<ObjectEvent>;
  @Output()
  singleClick: EventEmitter<MapBrowserEvent<any>>;

  // we pass empty arrays to not get default controls/interactions because we have our own directives
  controls: Control[] = [];
  interactions: Interaction[] = [];

  constructor(
    private host: ElementRef,
    private mapService: MapService,
    ) {
    this.olClick = new EventEmitter<MapBrowserEvent<any>>();
    this.dblClick = new EventEmitter<MapBrowserEvent<any>>();
    this.moveStart = new EventEmitter<MapEvent>();
    this.moveEnd = new EventEmitter<MapEvent>();
    this.pointerDrag = new EventEmitter<MapBrowserEvent<any>>();
    this.pointerMove = new EventEmitter<MapBrowserEvent<any>>();
    this.onpostrender = new EventEmitter<RenderEvent>();
    this.postRender = new EventEmitter<MapEvent>();
    this.onprerender = new EventEmitter<RenderEvent>();
    this.propertyChange = new EventEmitter<ObjectEvent>();
    this.singleClick = new EventEmitter<MapBrowserEvent<any>>();
  }
  
  ngOnDestroy(): void {
    this.instance = undefined;
  }

  ngOnInit() {
    // console.log('creating ol.Map instance with:', this);
    // console.log('this.host.nativeElement', this.host.nativeElement);

    // TODO: 
    // Если this.instance = new Map(this);
    // то при выходе в профиль и обратно - карта начинает мигать при ее перемещении
    // Если this.instance = this.mapService.map;
    // то выдает AssertionError

    // this.instance = new Map();
    // console.log('MapComponent', this.mapService.map)
    if (!this.mapService.map) this.mapService.initMap()
    this.instance = this.mapService.map;// ?? new Map(this);
    // this.instance = new Map(this);
    this.instance.setTarget(this.host.nativeElement.firstElementChild);
    // this.instance.setTarget(this.host.nativeElement);
    this.instance.on('click', (event: MapBrowserEvent<any>) => this.olClick.emit(event));
    this.instance.on('dblclick', (event: MapBrowserEvent<any>) => this.dblClick.emit(event));
    this.instance.on('movestart', (event: MapEvent) => this.moveStart.emit(event));
    this.instance.on('moveend', (event: MapEvent) => this.moveEnd.emit(event));
    this.instance.on('pointerdrag', (event: MapBrowserEvent<any>) => this.pointerDrag.emit(event));
    this.instance.on('pointermove', (event: MapBrowserEvent<any>) => this.pointerMove.emit(event));
    this.instance.on('postrender', (event: RenderEvent) => this.onpostrender.emit(event));
    this.instance.on('postrender', (event: MapEvent) => this.postRender.emit(event));
    // @ts-ignore
    this.instance.on('prerender', (event: RenderEvent) => this.onprerender.emit(event));
    // @ts-ignore
    this.instance.on('propertychange', (event: ObjectEvent) => this.propertyChange.emit(event));
    this.instance.on('singleclick', (event: MapBrowserEvent<any>) => this.singleClick.emit(event));
  }

  ngOnChanges(changes: SimpleChanges) {
    const properties: { [index: string]: any } = {};
    if (!this.instance) {
      return;
    }
    for (const key in changes) {
      if (changes.hasOwnProperty(key)) {
        properties[key] = changes[key].currentValue;
      }
    }
    console.log('changes detected in aol-map, setting new properties: ', properties);
    this.instance.setProperties(properties, false);
  }

  ngAfterViewInit() {
    this.instance.updateSize();
  }
}
