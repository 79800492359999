import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    AccountsView,
    AccountsViewData,
    ContractorsAccess,
    ContractorsView,
    Credentials,
    RolePermissionView,
    RolesView,
    RolesViewData,
    User,
    UserAccountsFeature,
    UserAccountsPage,
    UserContractorsReq,
} from '../models';
import { environment } from '@environments/environment';
import { iif, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { AuthenticationService } from '@services/authentication.service';
import { Accounts } from '@shared/models/contractors';
import { SysSubjects } from '../models/menu';

@Injectable({ providedIn: 'root' })
export class UserService {
    // private readonly refresh = new EventEmitter<User>(null);
    private readonly emptyContractorAccess: ContractorsAccess = {
        success: false,
        data: [{
            id: 0,
            role_id: 0,
            subject_id: 0,
            can_create: false,
            can_read: false,
            can_update: false,
            can_delete: false,
        }]
    }

    public contractorsAccess$ = this.authenticationService.currentUser$.pipe(
        switchMap((currUser) => iif(() => (!currUser?.user), of(this.emptyContractorAccess), this.getContractorsAccess())),
        shareReplay({ refCount: true, bufferSize: 1 })
    )
    public accountsAccess$ = this.authenticationService.currentUser$.pipe(
        switchMap((currUser) => iif(() => (!currUser?.user), of({ success: false, data: [] }), this.getAccountsAccess())),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    constructor(
        private authenticationService: AuthenticationService,
        private http: HttpClient
    ) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/auth/register`, user);
    }

    registerBase(user: User) {
        return this.http.post(`${environment.apiUrl}/users/registration`, user);
    }

    registerPro(user: User) {
        return this.http.post(`${environment.apiUrl}/users/registration`, user);
    }

    resendEmail(email: string) {
        return this.http.post(
            `${environment.apiUrl}/auth/email/verify/resend`,
            { email: email }
        );
        // return this.http.post(`https://backend.imus.city/email/verify/resend`, {});
    }

    edit(user: User) {
        return this.http.post(`${environment.apiUrl}/auth/edit`, user);
    }

    forgotPass(email: string) {
        return this.http.post(`${environment.apiUrl}/auth/forgot-password`, {
            email: email,
        });
    }

    resetPassword(token: string, password: string, email: string) {
        return this.http.post(`${environment.apiUrl}/auth/reset-password`, {
            token: token,
            password: password,
            email: email,
        });
    }

    sendFeedback(email: string, subject: string, message: string) {
        return this.http.post(`${environment.apiUrl}/feedback`, {
            email: email,
            subject: subject,
            message: message,
        });
    }

    getContractorsAccess(): Observable<ContractorsAccess> {
        return this.http.get<ContractorsAccess>(
            `${environment.apiUrl}/contractors/access`
        );
    }

    getAccountsAccess(): Observable<UserAccountsFeature> {
        return this.http.get<UserAccountsFeature>(
            `${environment.apiUrl}/accounts/access`
        );
    }

    // verifyEmail(id: number, hash: string) {
    //     return this.http.get(`${environment.apiUrl}/auth/email/verify/${id}/${hash}`)
    //         .pipe(
    //             tap((user) => {
    //                 // console.log('login',user)
    //                 localStorage.setItem('currentUser', JSON.stringify(user));
    //                 this.currentUserSubject.next(user);
    //             })
    //         );
    // }

    resetUserPassword(passwordCurrent: string, password: string) {
        return this.http.post(`${environment.apiUrl}/users/reset-password`, {
            old_password: passwordCurrent,
            password: password,
        });
    }

    updateUser(body: User, id: number): Observable<User> {
        delete body.email
        return this.http
            .patch<User>(`${environment.apiUrl}/users/update/${id}`, body)
            .pipe(
                tap((user) => {
                    this.authenticationService.currentUserValue = user;
                    // this.refresh.emit(user);
                })
            );
    }

    getStartData() {
        return this.http.get(`${environment.apiUrl}/users/start`);
    }

    setStartData(user: User) {
        // startLon?: number, startLat?: number, startZoom?: number, uData?: any
        return this.http.patch(`${environment.apiUrl}/users/update-start`, {
            start_lon: user.start_lon ?? undefined, //"37.166483",
            start_lat: user.start_lat ?? undefined, //"56.753054",
            start_zoom: user.start_zoom ?? undefined, //"16"
            u_data: user.u_data ?? undefined,
        });
    }

    search(query: string) {
        return this.http.get(
            `${environment.apiUrl}/users/search?query=${query}`
        );
    }

    getUserAccountInfo(
        params: Record<string, any>
    ): Observable<UserAccountsPage | Credentials[]> {
        return this.http
            .get<UserAccountsFeature>(
                `${environment.apiUrl}/view/main_user_accounts`,
                { params }
            )
            .pipe(map((res) => res.data));
    }

    getCredentials(): Observable<Credentials> {
        return this.http
            .get<UserAccountsFeature>(`${environment.apiUrl}/users/access`)
            .pipe(map((data) => (data.data as Credentials[])[0]));
    }

    getUserAccountInfoByUserId(id: number): Observable<UserAccountsFeature> {
        return this.http.get<UserAccountsFeature>(
            `${environment.apiUrl}/view/main_user_accounts?where[user_id]=${id}`
        );
    }

    getUserAccountsView(
        params: Record<string, any>
    ): Observable<UserAccountsPage | AccountsViewData[]> {
        return this.http
            .get<AccountsView>(`${environment.apiUrl}/view/accounts`, {
                params,
            })
            .pipe(map((res) => res.data));
    }

    getUserContractorsView(
        params: Record<string, any>
    ): Observable<ContractorsView> {
        return this.http.get<ContractorsView>(
            `${environment.apiUrl}/view/contractors`,
            { params }
        );
    }

    setUserContractors(res: UserContractorsReq): Observable<unknown> {
        if (res.site && res.site.slice(0, 4) != 'http')
            res.site = 'http://' + res.site;
        return this.http.post<unknown>(
            `${environment.apiUrl}/contractors/save`,
            res
        );
    }

    getUserInfo(id: string): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    // putUserInfo(id: string, data?: any): Observable<User> {
    //     return this.http.patch<User>(
    //         `${environment.apiUrl}/users/update/${id}`,
    //         data
    //     );
    // }

    viewRoles(): Observable<RolesViewData[]> {
        return this.http.get<RolesView>(`${environment.apiUrl}/view/roles?orderBy[account_type_id]=asc&orderBy[id]=asc`)
            .pipe(map(res => res.data.data));
    }

    rolePermissions(selectedSubjectId?: number): Observable<RolePermissionView> {
        return this.http.get<RolePermissionView>(`${environment.apiUrl}/sys_role_permissions?where[subject_id]=${selectedSubjectId}&paginate=false`)
    }

    sysSubjects(): Observable<SysSubjects[]> {
        return this.http.get<any>(`${environment.apiUrl}/sys_subjects?paginate=false`)
            .pipe(map(res => res.data));
    }

    accountSave(data: Accounts): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/accounts/save`, data);
    }

    sysRolePermissions(data): Observable<any> {
        if (data.id) {
            return this.http.put<any>(`${environment.apiUrl}/sys_role_permissions/${data.id}`, data);
        } else {
            return this.http.post<any>(
                `${environment.apiUrl}/sys_role_permissions`,
                data
            );
        }
    }

}
