<div *ngIf="data.title" class="imus-row title">
    {{ data.title }}
</div>
<img *ngIf="data.imgSrc" class="alert-image" [src]="data.imgSrc">
<p *ngIf="data.message" [innerHTML]="data.message" class="imus-row"></p>
<div class="imus-row">
	<button *ngIf="data.cancelButtonText" mat-stroked-button color="primary" (click)="close(false)">
		{{ data.cancelButtonText }}
	</button>
	<button *ngIf="data.okButtonText" mat-raised-button color="primary" (click)="close(true)">
		{{ data.okButtonText }}
	</button>
</div>
