import { Injectable } from '@angular/core';
import { MapBrowserEvent, Overlay } from 'ol';
// import { getArea } from 'ol/extent';
// import { LineString, Polygon } from 'ol/geom';
import { getLength} from 'ol/sphere';
import { Draw } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { MapService } from './map.service';
import { unByKey } from 'ol/Observable';
import { EventsKey } from 'ol/events';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {

  public measureMode: boolean = false;
  private sketch: any;
  private draw: Draw;

  private pointermoveListener: EventsKey;
  private drawstartListener: EventsKey;
  private mouseoutListener: any;
  private drawendListener: EventsKey;
  private sketchChangelistener: EventsKey;

  source: VectorSource = new VectorSource();

  vector: VectorLayer<any> = new VectorLayer<any>({
    source: this.source,
    style: {
      'stroke-color': '#009EE3',
      'stroke-width': 2,
      'circle-radius': 7,
      'circle-fill-color': '#009EE3',
    },
  });

  measureTooltip: Overlay;
  measureTooltipElement: HTMLElement;


  helpTooltip: Overlay;
  helpTooltipElement: HTMLElement;
  continueLineMsg: string = 'Клик для промежуточной точки.<br> Двойной клик для завершения';

  constructor(private mapService: MapService) {}

  public toggleMode() {

    // console.log('MeasureService toggleMode');

    this.measureMode = !this.measureMode;
    if(this.measureMode) {
      this.enable();
    } else {
      this.disable();
    }
  }

  public enable() {
    this.addInteraction();
    this.addEventListeners();
    this.mapService.getMap().addLayer(this.vector);
  }

  public disable() {
    this.mapService.getMap().removeInteraction(this.draw);

    //TODO
    this.mapService.getMap().removeOverlay(this.measureTooltip);
    this.mapService.getMap().removeOverlay(this.helpTooltip);

    this.source.clear();
    this.mapService.getMap().getOverlays().clear();

    this.removeEventListeners();
    this.mapService.getMap().removeLayer(this.vector);
  }

  addEventListeners() {

    this.pointermoveListener = this.mapService.getMap().on('pointermove', (event: MapBrowserEvent<any>) => {
      if (event.dragging) {
        return;
      }
      let helpMsg = 'Клик для начала замера';

      if (this.sketch) {
          helpMsg = this.continueLineMsg;
      }

      this.helpTooltipElement.innerHTML = helpMsg;
      this.helpTooltip.setPosition(event.coordinate);
      this.helpTooltipElement.classList.remove('hidden');

    });

    this.mouseoutListener = this.mapService.getMap().getViewport().addEventListener('mouseout', () => {
      this.helpTooltipElement.classList.add('hidden');
    });

  }

  removeEventListeners() {
    unByKey(this.pointermoveListener);
    unByKey(this.drawstartListener);
    unByKey(this.mouseoutListener);
    unByKey(this.drawendListener);
    unByKey(this.sketchChangelistener);
  }

  initSource() {
    this.source = new VectorSource();
  }

  createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
    });
    this.mapService.getMap().addOverlay(this.measureTooltip);
  }

  createHelpTooltip() {
    if (this.helpTooltipElement) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
    }
    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'ol-tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: 'center-left',
    });
    this.mapService.getMap().addOverlay(this.helpTooltip);
  }

  formatLength(line) {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'км';
    } else {
      output = Math.round(length * 100) / 100 + ' ' + 'м';
    }
    return output;
  };

  addInteraction() {

    this.draw = new Draw({
      source: this.source,
      type: 'LineString',
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
        }),
      }),
    });

    this.mapService.getMap().addInteraction(this.draw);

    this.createMeasureTooltip();
    this.createHelpTooltip();

    this.drawstartListener = this.draw.on('drawstart', (event: any) => {
      this.sketch = event.feature;
      let tooltipCoord = event.coordinate;
      this.sketchChangelistener = this.sketch.getGeometry().on('change', (event: any) => {
        const geom = event.target;
        let output;
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        this.measureTooltipElement.innerHTML = output;
        this.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.drawendListener = this.draw.on('drawend', () => {
      this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      this.measureTooltip.setOffset([0, -7]);
      this.sketch = null;
      this.measureTooltipElement = null;
      this.createMeasureTooltip();
      unByKey(this.sketchChangelistener);
    });

  }

}
