import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ProjectsGridDialogComponent } from './projects-grid-dialog/projects-grid-dialog.component';
import { ProjectSectionsDialogComponent } from './project-sections-dialog/project-sections-dialog.component';
import { MaterialModule } from '@app/shared/modules/material/material.module';
import { DetailsInfoPanelModule } from '../main/modules/details-info-panel/details-info-panel.module';
import { ProjectSectionEditPanelComponent } from './project-section-edit-panel/project-section-edit-panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditProjectPanelComponent } from './edit-project-panel/edit-project-panel.component';
// import { NodeEditPanelModule } from '../main/modules/node-edit-panel/node-edit-panel.module';



@NgModule({
  declarations: [
    ProjectsGridDialogComponent,
    ProjectSectionsDialogComponent,
    ProjectSectionEditPanelComponent,
    EditProjectPanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    // NodeEditPanelModule,
    DetailsInfoPanelModule,
  ],
  exports: [
    ProjectsGridDialogComponent,
    ProjectSectionEditPanelComponent,
    EditProjectPanelComponent
  ]
})
export class ProjectsModule { }
