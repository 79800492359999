export enum CodeCategory {
    /** Поперечная форма */
    shape = 21,
    /** Материал */
    material = 22,
    /** Метод покрытия */
    coating = 23,
    /** Код места */
    location = 19,
    /** Использование */
    use = 20,
    /** Назначение */
    purpose = 17
}
