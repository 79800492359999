import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetHtmlService {
  private http = inject(HttpClient);
  private sanitizer = inject(DomSanitizer);
constructor() { }

  getHtml(url: string): Observable<any> {
    // return this.http.get('/assets/user-manual/' + url)
    // { observe: 'body', responseType: 'text' }
    return this.http.get('/assets/user-manual/' + url, { responseType: 'text' }).pipe(
      map((data) => this.sanitizer.bypassSecurityTrustHtml(data))
    );
  }

}
