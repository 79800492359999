<ng-container [ngSwitch]="type">
    <mat-form-field *ngSwitchCase="'area'" class="input-form" [title]="placeholder$ | async">
        <mat-label>{{placeholder$ | async}}</mat-label>
        <textarea matInput
                  [setFocus]="setInitFocus"
                  [required]="required"
                  [placeholder]="placeholder$ | async"
                  [formControl]="innerControl"
                  [readonly]="readonly$ | async"></textarea>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'date'">
        <mat-label>{{placeholder$ | async}}</mat-label>
        <input [formControl]="innerControl" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngSwitchDefault class="input-form" [title]="placeholder$ | async">
        <mat-label>{{placeholder$ | async}}</mat-label>
        <input matInput
               #inputElement
               [autocomplete]="autocomplete"
               [setFocus]="setInitFocus"
               [required]="required"
               [placeholder]="placeholder$ | async"
               [formControl]="innerControl"
               [type]="type"
               [imask]="mask"
               [readonly]="readonly$ | async">
    </mat-form-field>
</ng-container>
