import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";

import {environment} from "@environments/environment";
import {Accounts, AccountsFeature, Contractors, DataAccounts} from "@shared/models/contractors";
import { NewMainAccount } from "@app/modules/account/pages/profile/models/profile";
import { AccountsViewData } from "@shared/models";

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

    private http = inject(HttpClient);

    getAccounts(params?: any, responseType: 'blob' | 'json' = 'json'): Observable<Blob | DataAccounts> {
        const options = { params } as any;

        return this.http.get<ArrayBuffer | AccountsFeature>(`${environment.apiUrl}/view/accounts`, { ...options, responseType })
            .pipe(map((accountsFeature: any) => {
                if (responseType === 'json') {
                    return (accountsFeature as AccountsFeature).data as DataAccounts;
                } else {
                    return accountsFeature as Blob;
                }
            }));
    }

    getAccountsBase(params?: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/accounts`, { params })
            .pipe(map(accountsFeature => accountsFeature.data.data as DataAccounts));
    }

    exitAccounts(data: { id: number, end_date: string }): Observable<Accounts> {
        return this.http.post<AccountsFeature>(`${environment.apiUrl}/accounts/guest_exit`, data)
            .pipe(map(accountsFeature => accountsFeature.data as Accounts));
    }

    getContractors(): Observable<Contractors[]> {
        return this.http.get<AccountsFeature>(`${environment.apiUrl}/contractors?paginate=false`)
            .pipe(map(accountsFeature => accountsFeature.data as Contractors[]));
    }

    createMainAccount(account: NewMainAccount): Observable<AccountsFeature> {
        return this.http.post<AccountsFeature>(`${environment.apiUrl}/users/create_main_account`, account)
    }

    editMainAccount(account: AccountsViewData): Observable<AccountsFeature> {
        return this.http.post<AccountsFeature>(`${environment.apiUrl}/accounts/save`, account)
    }

    exitGuest(id: number): Observable<unknown> {
        return this.http.post<AccountsFeature>(`${environment.apiUrl}/accounts/guest_exit`, {
            id,
            end_date: new Date().toLocaleDateString()
        })
    }

    addGuest(req: Record<string, any>) {
        return this.http.post(`${environment.apiUrl}/accounts/guest_add`, req)
    }
}
