import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { InputComponentSettings } from '@app/shared/components/input/input.component';
import { imus } from '@app/shared/imus';
import { User } from '@app/shared/models';
import { AuthenticationService, UserService } from '@app/shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { Observable, map, switchMap, tap, of, BehaviorSubject, catchError, throwError, takeUntil } from 'rxjs';

@Component({
  selector: 'app-page-email-confirmations',
  templateUrl: './page-email-confirmations.component.html',
  styleUrls: ['./page-email-confirmations.component.scss']
})
export class PageEmailConfirmationComponent implements OnInit {
  inputType = InputComponentSettings.InputType;
  buttonType = ButtonComponentSettings.ButtonType;

  private currentUser: User;

  state$: Observable<any>;
  _resent = new BehaviorSubject<boolean>(false)
  resent$: Observable<boolean> = this._resent.asObservable();

  constructor(
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private readonly _fb: FormBuilder,
    private readonly _destroy$: ImusDestroyService,
  ) {
  }

  ngOnInit() {
    this.state$ = this.activatedRoute.paramMap
      .pipe(
        map(() => window.history.state),
        // tap(state => console.log('state', state)),
        // tap(state => this.email = state.user["email"]),
      )
    this.currentUser = this.authenticationService.currentUserValue
  }

  sendLinkAgain() {
    this.state$.pipe(
      switchMap(state => { return this.userService.resendEmail(state.user["email"]) }),
      catchError(err => throwError(() => err)),
      takeUntil(this._destroy$),
      // tap(data => console.log('data', data)),
    )
      .subscribe(data => {
        // console.log('data', data)
        this._resent.next(true)
      })
  }

}
