import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {debounceTime, take, takeUntil} from "rxjs";
import {MatInputModule} from "@angular/material/input";
import {MatSort} from "@angular/material/sort";
import {PageEvent} from "@angular/material/paginator";
import { saveAs } from 'file-saver';

import {SysRoles} from "@shared/models/menu";
import {AccountsService} from "@app/modules/account/services/accounts.service";
import {MenuService} from "@services/menu.service";
import {Accounts, Contractors, DataAccounts} from "@shared/models/contractors";
import {FormatStringPipe} from "@shared/pipes/format-string.pipe";
import {MatIconModule} from "@angular/material/icon";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {ImusDestroyService} from "@services/destroy.service";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {SharedModule} from "@shared/shared.module";
import {SearchParams, SearchParamsService} from "@app/modules/account/services/search-params.service";
import {RouterLink, RouterOutlet} from "@angular/router";

@Component({
    selector: 'app-accounts',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        FormatStringPipe,
        MatIconModule,
        MatTableModule,
        MatProgressBarModule,
        SharedModule,
        RouterOutlet,
        RouterLink
    ],
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountsComponent implements OnInit, AfterViewInit {
    private accountsService = inject(AccountsService);
    private searchParamsService = inject(SearchParamsService);
    private menuService = inject(MenuService);
    private fb = inject(FormBuilder);
    private destroyRef = inject(ImusDestroyService);
    private cdr = inject(ChangeDetectorRef);

    @ViewChild(MatSort) sort: MatSort;

    totalItems: number = 0;
    pageSize: number = 15;
    pageIndex: number = 0;
    isLoaded = true;
    // buttonType = ButtonComponentSettings.ButtonType;
    searchForm: FormGroup;
    contractors: Contractors[];
    sysRoles: SysRoles[];
    dataAccounts: MatTableDataSource<Accounts> = new MatTableDataSource<Accounts>();
    displayedColumns: string[] = [
        'user_id',
        'user_name',
        'user_email',
        'user_phone',
        'account_type_descr',
        'contractor_descr',
        'role_descr',
        'job_post',
        'start_date',
        'end_date'
    ];

    ngOnInit(): void {
        this.initForm();
        this.loadDataSelects();
        this.changesForm();
    }

    ngAfterViewInit() {
        this.dataAccounts.sort = this.sort;
    }

    loadDataSelects(): void {
        this.getAccounts();

        this.accountsService.getContractors()
            .pipe(take(1))
            .subscribe(contractors => this.contractors = contractors);

        this.menuService.getRolesList({paginate: false})
            .pipe(take(1))
            .subscribe(sysRoles => this.sysRoles = sysRoles);
    }

    initForm(): void {
        this.searchForm = this.fb.group({
            contractorId: [null],
            roleId: [null],
            userName: [null],
            userEmail : [null]
        });
    }

    changesForm(): void {
        this.searchForm.valueChanges
            .pipe(debounceTime(700), takeUntil(this.destroyRef))
            .subscribe(data => this.fetchData());
    }

    getAccounts(params?: SearchParams): void {
        this.isLoaded = true;
        this.cdr.markForCheck();
        this.accountsService.getAccounts(params)
            .pipe(take(1))
            .subscribe((accounts: DataAccounts) => {
                const { total, data, per_page, current_page } = accounts;
                this.totalItems = total;
                this.pageSize = per_page;
                this.pageIndex = current_page - 1;
                this.dataAccounts.data = data as Accounts[];
                this.isLoaded = false;
                this.cdr.markForCheck();
            });
    }

    fetchData(): void {
        this.getAccounts(this.getSearchParams());
    }

    onPaginateData($event: PageEvent): void {
        this.pageSize = $event.pageSize;
        this.pageIndex = $event.pageIndex;
        this.fetchData();
    }

    downloadExel(): void {
        const params = this.getSearchParams();
        delete params.page;
        delete params.per_page;
        params.format = 'excel';

        this.accountsService.getAccounts(params, 'blob')
            .pipe(take(1))
            .subscribe((dataAccounts: Blob)  => {
                const blob = new Blob([dataAccounts]);

                saveAs(blob, 'accounts.xlsx');
                this.cdr.markForCheck();
            });
    }

    getSearchParams(): any {
        const sortField = this.dataAccounts.sort.active;
        const sortDirection = this.dataAccounts.sort.direction;

        const paramMappings = [
            { formField: 'contractorId', paramName: 'where[contractor_id]' },
            { formField: 'roleId', paramName: 'where[role_id]' },
            { formField: 'userName', paramName: 'where[user_name]', operator: 'like' },
            { formField: 'userEmail', paramName: 'where[user_email]', operator: 'like' }
        ];

        return this.searchParamsService.getSearchParams(
            paramMappings,
            this.searchForm,
            sortField,
            sortDirection,
            this.pageIndex,
            this.pageSize
        );
    }
}
