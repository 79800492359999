<div class="imus-row">
    <imus-file-input *ngIf="!uploadControl.value?.length; else uploadTpl" title="Добавить отчет" structure multiple [formControl]="uploadControl">
        <button mat-raised-button color="primary" size="xl" class="large">
            Добавить отчет
            <mat-icon>add</mat-icon>
        </button>
    </imus-file-input>
</div>
<div class="imus-row">
    <div class="imus-row-item">
        <app-uploaded-inspection-files-list [files]="files.projects$ | async" [allowed]="(allowedType$ | async) === 'project'" type="project" (resolve)="changeAllowedType($event)"></app-uploaded-inspection-files-list>
        <app-uploaded-inspection-files-list [files]="files.sections$ | async" [allowed]="(allowedType$ | async) === 'section'" type="section" (resolve)="changeAllowedType($event)"></app-uploaded-inspection-files-list>
        <app-uploaded-inspection-files-list [files]="files.observation$ | async" [allowed]="(allowedType$ | async) === 'observation'" type="observation" (resolve)="changeAllowedType($event)"></app-uploaded-inspection-files-list>
        <app-uploaded-inspection-files-list [files]="files.rest$ | async" [allowed]="(allowedType$ | async) === 'rest'" type="rest" (resolve)="changeAllowedType($event)"></app-uploaded-inspection-files-list>
    </div>
</div>

<ng-template #uploadTpl>
    <p class="imus-row">Загрузка...</p>
</ng-template>