import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { imus } from "@shared/imus";
import { SharedModule } from "@shared/shared.module";
import { InputType } from "@shared/input-type";
import { ButtonComponentSettings } from "@shared/components/button/button.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {
    ChangePasswordModalComponent
} from "@app/modules/account/components/change-password-modal/change-password-modal.component";
import { AlertService, AuthenticationService, UserService } from "@shared/services";
import { ImusDestroyService } from "@services/destroy.service";
import { BehaviorSubject, distinctUntilChanged, map, takeUntil } from "rxjs";
import { User } from "@shared/models";
import { AsyncPipe, NgIf } from "@angular/common";
import { finalize } from "rxjs/operators";

interface FormModel {
    name: string;
    email: string;
    phone: string;
}

interface Credentials {
    form: imus.form.IFormGroup<FormModel>;
    model: FormModel;
}

/** Учетные данные */
@Component({
    selector: 'app-credentials',
    standalone: true,
    imports: [ReactiveFormsModule, SharedModule, NgIf, AsyncPipe],
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.scss'],
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CredentialsComponent {
    private userId!: number;
    private readonly _fb = inject(FormBuilder);
    private readonly _matDialog = inject(MatDialog);
    private readonly _userService = inject(UserService);
    private readonly _alertService = inject(AlertService);
    private readonly _destroy$ = inject(ImusDestroyService);
    private readonly _authService = inject(AuthenticationService);
    public readonly user$ = this._authService.currentUser$.pipe(
        map(cur => cur?.user ? cur.user : {
            name: null,
            email: null,
            phone: null
        } as User)
    );
    public readonly credentials: Credentials = {
        form: null,
        model: {
            name: null,
            email: null,
            phone: null
        }
    }
    public readonly inputType = InputType;
    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public isLoaded$ = new BehaviorSubject(false);

    constructor() {
        this.credentials.form = this._fb.fromTypedModel(this.credentials.model);
        this.user$.pipe(
            distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
            takeUntil(this._destroy$)
        ).subscribe(user => {
            this.userId = user.id;
            this.credentials.form.patchValue({
                name: user.name,
                email: user.email,
                phone: user.phone
            })
        })
    }

    public onSubmit(): void {
        if (this.credentials.form.invalid || this.isLoaded$.getValue()) {
            return;
        }
        const data = this.credentials.form.value;
        this.isLoaded$.next(true);
        this._userService.updateUser(data as User, this.userId).pipe(
            finalize(() => this.isLoaded$.next(false)),
            takeUntil(this._destroy$)
        ).subscribe(user => {
            // this._authService.currentUserValue = user;
            this._alertService.openSnackbar('Данные успешно обновлены')
        });
    }

    public onChangePasswordClick(): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.width = '500px';
        dialogConfig.id = 'modal-change-password-component';

        this._matDialog.open(ChangePasswordModalComponent, dialogConfig);
    }
}
