import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { NavigationMenuItem } from '../../models/navigation';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-info-navigation-tree',
  templateUrl: './info-navigation-tree.component.html',
  styleUrls: ['./info-navigation-tree.component.scss'],
  imports: [
    MatIconModule
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoNavigationTreeComponent implements OnInit {

  @Input()
  public items: NavigationMenuItem[];

  @Output()
  public itemClick = new EventEmitter<NavigationMenuItem>();
  protected selectedItem = signal<NavigationMenuItem>(null)

  ngOnInit() {
    this.selectedItem.set(this.items[0]);
    this.itemClick.emit(this.items[0]);
    this.items.forEach(item => {
      if (item.expanded !== undefined) {
        this.items
          .filter(i => i.number.slice(0, item.number.length) === item.number)
          .forEach(i => i.visible = item.expanded);
          item.visible = true;
      }
  })
  
    
  }

  onItemClick(item: NavigationMenuItem) {
    this.selectedItem.set(item);
    this.itemClick.emit(item);
  }

  clickChevron(item: NavigationMenuItem) {
    item.expanded = !item.expanded
    this.items
          .filter(i => i.number.slice(0, item.number.length) === item.number)
          .forEach(i => i.visible = item.expanded);
    item.visible = true;
  }

}
