import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-dev-menu-button',
  templateUrl: './dev-menu-button.component.html',
  styleUrls: ['./dev-menu-button.component.scss']
})
export class DevMenuButtonComponent {

    constructor() {}

}
