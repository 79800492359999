import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { InputComponentSettings } from '@app/shared/components/input/input.component';
import { AuthenticationService } from '@app/shared/services';
import { imus } from '@shared/imus';
import { first, map, switchMap, takeUntil, tap } from 'rxjs';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from '@angular/material/dialog';
import { ChangePasswordModalComponent } from '../../components/change-password-modal/change-password-modal.component';
import { User } from '@app/shared/models';
import { ImusDestroyService } from '@app/shared/services/destroy.service';

interface FormModel {
    name: string;
    email: string;
    phone: string;
    // password: string
}

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent implements OnInit {
    inputType = InputComponentSettings.InputType;
    buttonType = ButtonComponentSettings.ButtonType;
    public readonly account = {
        form: null as imus.form.IFormGroup<FormModel>,
        model: {
            name: null,
            email: null,
            phone: null,
            // password: null
        } as FormModel,
    };

    constructor(
        public authenticationService: AuthenticationService,
        private router: Router,
        private readonly _fb: FormBuilder,
        public matDialog: MatDialog, // public dialogRef: MatDialogRef<ChangePasswordModalComponent>
        private readonly _destroy$: ImusDestroyService,
    ) {
        this.account.form = _fb.fromTypedModel<FormModel>(this.account.model);
        this.authenticationService.currentUser$
            .pipe(
                // tap(currentUser => console.log('tap currentUser', currentUser)),
                // switchMap(async (currentUser) => currentUser?.user),
                tap((currentUser) => {
                    // console.log('tap currentUser', currentUser)
                    if (currentUser) {
                        this.account.model = currentUser.user;
                    } else {
                        this.account.model = {
                            name: null,
                            email: null,
                            phone: null,
                        };
                    }
                    this.account.form = _fb.fromTypedModel<FormModel>(
                        this.account.model
                    );
                }),
                takeUntil(this._destroy$)
            )
            .subscribe();
    }
    ngOnInit(): void {
        // localStorage.getItem()
        // if (localStorage.getItem('settings')) {
        //     this.settings = JSON.parse(<string>localStorage.getItem('settings'));
        //   }
    }

    onProfAccountClick() {
        this.router.navigate(['account', 'professional']);
    }

    onAccountSettingsClick() {
        this.router.navigate(['account', 'settings']);
    }

    onAccountInformationClick() {
        this.router.navigate(['account', 'information']);
    }

    onMainPageClick() {
        this.router.navigate(['app-taris']);
    }

    onChangePasswordClick(event) {
        event.preventDefault();

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-change-password-component';

        this.matDialog.open(ChangePasswordModalComponent, dialogConfig);
    }

    onSubmit() {
        // console.log(this.account.form.value);

        if (this.account.form.invalid) return;

        this.authenticationService.currentUserValue = Object.assign(
            {},
            this.authenticationService.currentUserValue,
            {
                name: this.account.form.value['name'],
                phone: this.account.form.value['phone'],
            }
        );
    }
}
