<div class="modal">
    <div (click)="closeModal()" class="modal-close">CLOSE</div>
    <p>
        Введите тему и текст сообщения
    </p>
    <div class="modal-form form">
        <!-- <alert></alert> -->

        <form [formGroup]="account.form" (ngSubmit)="onSubmit()">

            <!-- <div class="modal-form_group form_group form_group--center">
                Введите тему сообщения
            </div> -->
            <app-input class="input-cell" [type]="inputType.text" imusRequired [placeholder]="'Тема'"
                formControlName="subject"></app-input>
            <field-errors formFieldName="subject">
            </field-errors>
            <!-- <field-errors formFieldName="subject">
                <field-error-label rule="minlength" message="Минимальная длина 6 символов"></field-error-label>
            </field-errors> -->

            <div class="separator"></div>

            <!-- <app-input class="input-cell" [type]="inputType.text" imusRequired [placeholder]="'Текст сообщения'"
                formControlName="message"></app-input> -->
            <app-text-area imusRequired [placeholder]="'Текст сообщения'"
            formControlName="message"></app-text-area>
            <!-- <input type="text" placeholder="Текст сообщения" formControlName="message"> -->
            <field-errors formFieldName="message">
            </field-errors>
            <!-- <field-errors formFieldName="message">
                <field-error-label rule="minlength" message="Минимальная длина 6 символов"></field-error-label>
            </field-errors> -->


            <div class="separator"></div>

            <app-button [label]="'Отправить'" [fullWidth]="true"></app-button>

        </form>

        <ng-container *ngIf="(messageSent$ | async)">
            <div class="modal-form_group form_group form_group--center">
                Сообщение отправлено.<br>
                Ответ придет на адрес электронной почты {{email}}
            </div>
        </ng-container>
    </div>
</div>