export { };

declare global {

    interface Array<T> {
        /** Метод возвращает копию массива исключая переданный */
        exclude: (value: T) => T[];
        /** Метод возвращает копию массива добавляя переданный */
        include: (value: T) => T[];
    }

}

Array.prototype.exclude = function(value: unknown): Array<unknown> {
	const items = [...(this as Array<unknown>)];
	if (items.includes(value)) {
		items.splice(items.indexOf(value), 1);
	}
	return items;
};

Array.prototype.include = function(value: unknown): Array<unknown> {
	return [...(this as Array<unknown>), value];
};
