import { Base64 } from './global/base64';

export interface AttachmentModel {
    id: number;
    name: string;
    src: string;
    thumbnail?: string;
}

/** Представление вложения */
export class Attachment {

    /** Имя вложения */
    public name: string;
    /** Ссылка на источник вложения */
    public src: string;
    /** Ссылка на миниатюру вложения */
    public thumbnail?: string;
    /** Содержимое вложения */
    public content?: Base64;
    /** Ошибка возникшая при загрузке файла вложения или при отправке его на сервер */
    public error?: string

    /**
     * Возвращает размер файла в KB
     * или null если тело файла не определено
     */
    public get size(): number | null {
        return !!this.content && !!this.content.length
            ? (new Blob([this.content])).size / 1024
            : null;
    }

    constructor(src: AttachmentModel) {
        Object.assign(this, src);
    }

}

export class Attachments extends Array<Attachment> {}
