import { AfterViewInit, booleanAttribute, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[setFocus]',
    standalone: true
})
export class SetFocusDirective implements AfterViewInit {
    @Input({ transform: booleanAttribute })
    setFocus = false;

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit() {
        this.elementRef.nativeElement.focus();
    }
}
