export enum InputType {
    color = 'color',
    date = 'date',
    datetimeLocal = 'datetime-local',
    email = 'email',
    month = 'month',
    number = 'number',
    password = 'password',
    search = 'search',
    tel = 'tel',
    text = 'text',
    time = 'time',
    url = 'url',
    week = 'week',
    coords = 'coords',
    area = 'area',
    select = 'select',
    checkbox = 'checkbox',
}
