<div class="table" *ngIf="(contractorsAccess$ | async)?.data[0].can_read || (contractorsAccess$ | async)?.data[0].can_update; else accessDeniedTpl">
    <mat-progress-bar *ngIf="isLoaded" mode="indeterminate"></mat-progress-bar>
    <mat-table
        matSort
        (matSortChange)="sort($event)"
        [dataSource]="(employees$ | async)?.data">

        <ng-container *ngFor="let item of displayed$ | async" [matColumnDef]="item.name">
            <mat-header-cell mat-sort-header  *matHeaderCellDef> {{item.title}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngSwitch]="item.name">
                <p class="cell-data" *ngSwitchCase="'user_name'">
                    <a [routerLink]="['/', 'account', 'accounts', 'user', element.user_id]">{{element[item.name]}}</a>
                </p>
                <p class="cell-data" *ngSwitchCase="item.name === 'start_date' || item.name === 'end_date' ? item.name : ''">{{element[item.name] | date}}</p>
                <p class="cell-data" *ngSwitchCase="item.name === 'is_primary' || item.name === 'is_signer' ? item.name : ''">{{element[item.name] ? 'Да' : 'Нет'}}</p>
                <p class="cell-data" *ngSwitchCase="'actions'"><mat-icon class="actions-icon" (click)="actionEdit(element)">edit</mat-icon></p>
                <p class="cell-data" *ngSwitchDefault>{{element[item.name]}}</p>
            </mat-cell>
        </ng-container>

        <mat-header-row  *matHeaderRowDef="displayedColumns$ | async"></mat-header-row>
        <mat-row *matRowDef="let row; columns: (displayedColumns$ | async);"></mat-row>
    </mat-table>
    <div class="imus-row"></div>
    <!-- <div class="imus-row" *ngIf="(onlyRead$ | async) === false">
        <app-button
            (click)="addEmployee()"
            [type]="buttonType.primaryButton"
            label="Добавить сотрудника"></app-button>
    </div> -->
    <app-paginator [pageSize]="(pageSettings$ | async)?.pageSize" [pageIndex]="(pageSettings$ | async)?.pageIndex" [length]="(employees$ | async)?.total" (page)="onPaginateData($event)"></app-paginator>
</div>

<ng-template #accessDeniedTpl>
    <div class="imus-row">Информация не доступна</div>
</ng-template>
