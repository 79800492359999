import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from '@environments/environment';
import { MenuService } from '../services/menu.service';
import { filter, first, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { ImusDestroyService } from '../services/destroy.service';
import { MenuCode } from '../models/menu';


// @Injectable({ providedIn: 'root' })
// export class AuthGuard  {
//     constructor(
//         private router: Router,
//         private authenticationService: AuthenticationService
//     ) {}

//     public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//         // console.log('AuthGuard canActivate', route, state);

//         const currentUser = this.authenticationService.currentUserValue;

//         // console.log('AuthGuard currentUser', currentUser);

//         if (route.routeConfig.path == 'email-confirmation') {
//             if (currentUser) {
//                 if (currentUser.email_verified_at !== null) {
//                     this.router.navigate(['/']);
//                     return false;
//                 } else {
//                     return true;
//                 }
//             }
//             this.router.navigate(['/login']);
//             return false;
//         }

//         if (currentUser && currentUser.email_verified_at !== null) {
//                 // logged in so return true
//                 return true;
//         }

//         // not logged in so redirect to login page with the return url
//         this.router.navigate(['/login'], {
//             queryParams: { returnUrl: state.url },
//         });
//         return false;
//     }
// }

export const canActivateFn: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ) => {
        
        const router = inject(Router)
        const authenticationService = inject(AuthenticationService);
        const currentUser = authenticationService.currentUserValue;

        // TODO: Disable for develop
        // if (!environment.production) {
        //     return true;    
        // }

        if (route.routeConfig.path == 'email-confirmation') {
            if (currentUser) {
                if (currentUser.email_verified_at !== null) {
                    router.navigate(['/']);
                    return false;
                } else {
                    return true;
                }
            }
            router.navigate(['/login']);
            return false;
        }

        if (currentUser && currentUser.email_verified_at !== null) {
                // logged in so return true
                return true;
        }

        // not logged in so redirect to login page with the return url
        router.navigate(['/login'], {
            queryParams: { returnUrl: state.url },
        });
        return false;
    
    
        // return true;
}


export const canActivateAccountFn: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const menuService = inject(MenuService);
    const router = inject(Router)

    if (state.url.split('/')[2] === route.routeConfig.path)
        return menuService.menuItems$
            .pipe(
                filter((items) => !!items?.length),
                map(items => items.map(item => MenuCode[item.front_code])),
                switchMap(items => of(items.some(item => item && item === route.routeConfig.path))),
                tap(value => value ? void(0) : router.navigateByUrl('/account'))
            )
    return true
};