import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { UserAccountsInfo } from "@shared/models";

@Component({
    selector: 'app-additional-accounts-info.dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule],
    templateUrl: './additional-accounts-info.dialog.component.html',
    styleUrls: ['./additional-accounts-info.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalAccountsInfoDialogComponent {
    public readonly data: UserAccountsInfo = inject(MAT_DIALOG_DATA);
}
