import { PlotRef } from "@shared/models/plot";

export enum EntityType {
    NODE = 'node',
    PLOT = 'plot',
    LAYER = 'layer',
    PLOT_POINT = 'plot_point'
}

export interface ISelectedEntity {
    type: EntityType;
    id: number;
}
