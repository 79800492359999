import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { SharedModule } from "@shared/shared.module";
import { ButtonComponentSettings } from "@shared/components/button/button.component";
import { DadataBank, DadataPerson } from "@app/modules/account/services/dadata.service";
import { FormsModule } from "@angular/forms";

@Component({
    selector: 'app-dadata-list-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, SharedModule, FormsModule],
    templateUrl: './dadata-list.dialog.component.html',
    styleUrls: ['./dadata-list.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DadataListDialogComponent {

    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public readonly dialogRef = inject(MatDialogRef<DadataListDialogComponent>);
    public data: { list: (DadataPerson | DadataBank)[], type: "inn" | "short_name" | "bik" } = inject(MAT_DIALOG_DATA);
    result: DadataPerson | DadataBank = null;
}
