import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { codeCategories } from "@shared/models/code-categories";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { BehaviorSubject, first, Observable, shareReplay } from "rxjs";
import { CodeCategory } from "@shared/models/code-category";
import { toSignal } from "@angular/core/rxjs-interop";

@Injectable({
    providedIn: 'root'
})
export class CodeCategoryService {

    private readonly _categoriesSubj$ = new BehaviorSubject<Map<CodeCategory, codeCategories.CollectionItemCodesProperties[]>>(new Map());

    private readonly _categories$: Observable<Map<number, codeCategories.CollectionItemCodesProperties[]>> = this._http.get<codeCategories.CollectionItem[]>(`${environment.apiUrl}/code-category`).pipe(
        // map(response => response[0]),
        map(collection => collection.reduce((collectionMap, collectionItem) => collectionMap.set(
            collectionItem.properties.id,
            collectionItem.codes.features
                .map(f => f.properties)
                .sort((a,b) => a.id - b.id)
        ), new Map<number, codeCategories.CollectionItemCodesProperties[]>())),
        shareReplay({ refCount: false, bufferSize: 1 })
    )

    public readonly categories = toSignal(this._categories$);
    constructor(
        private readonly _http: HttpClient
    ) {
        this._categories$.subscribe(c => this._categoriesSubj$.next(c))
    }

    getOptionsByCategory(category: CodeCategory): codeCategories.CollectionItemCodesProperties[] {
        return this._categoriesSubj$.getValue().get(category);
    }

    getOptionsByCategory$(category: CodeCategory): Observable<codeCategories.CollectionItemCodesProperties[]> {
        return this._categories$.pipe(
            map(collectionMap => collectionMap.get(category)),
            first()
        )
    }
}
