import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevIndexPageComponent } from './pages/dev-index-page/dev-index-page.component';
import { TemplateDemoPageComponent } from './pages/template-demo-page/template-demo-page.component';


export const devRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DevIndexPageComponent,
  },
  {
    path: 'template',
    component: TemplateDemoPageComponent,
  },
  // {
  //   path: '**',
  //   redirectTo: '/',
  // },
];

@NgModule({
  imports: [RouterModule.forChild(devRoutes)],
  exports: [RouterModule],
})
export class DevRoutingModule { }
