import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { ButtonComponentSettings } from "@app/shared/components/button/button.component";
import { InputComponentSettings } from "@app/shared/components/input/input.component";
import { AuthenticationService } from "@app/shared/services";
import { imus } from "@shared/imus";

interface FormModel {
    email: string,
    phone: string,
    password: string,
    inn: string
}
@Component({
    selector: 'app-professional-account',
    templateUrl: './professional-account.component.html',
    styleUrls: ['./professional-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfessionalAccountComponent {
    public account = {
        form: null as imus.form.IFormGroup<FormModel>,
        model: {
            email: null,
            phone: null,
            password: null,
            inn: null
        } as FormModel
    }
    inputType = InputComponentSettings.InputType;
    buttonType = ButtonComponentSettings.ButtonType;

    constructor(
      private router: Router,
      public authenticationService: AuthenticationService,
      private readonly _fb: FormBuilder
      ) {
        this.account.form = _fb.fromTypedModel<FormModel>(this.account.model);
    }

    onChildAccountClick() {
        this.router.navigate(['account', 'child']);
    }

    onAccountSettingsClick() {
        this.router.navigate(['account', 'settings']);
    }

    onAccountInformationClick() {
        this.router.navigate(['account', 'information']);
    }

    onMainPageClick() {
        this.router.navigate(["app-taris"]);
    }
}
