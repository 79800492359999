import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeasurementTypes } from '../shared/models/measurement';
import { HttpParamsUtility, HttpSettings } from '../shared/utilities/http-utility';

@Injectable({
  providedIn: 'root'
})
export class MeasurementRouteService {

  constructor(protected httpClient: HttpClient) { }

  loadDeviceMeasurement(query: MeasurementTypes.IMeasurementRequest){        
    const params = HttpParamsUtility.objectToParams(query);
    return this.httpClient
      .get<MeasurementTypes.IDeviceMeasurements>(`${HttpSettings.apiHost}/measurement`, { params: params});
  }

  addMesaurement(request: MeasurementTypes.IAddMeasurement){
    const params = HttpParamsUtility.objectToParams(request);    
    return this.httpClient.post(`${HttpSettings.apiHost}/measurement`, params);
  }

  deleteMesaurement(id: number){    
    return this.httpClient.get(`${HttpSettings.apiHost}/measurement/delete/${id}`);
  }
}
