<mat-card-content>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-around">
        <div fxLayout="column" fxFlex="70%">
            IMUS Инструменты разработчика

            <br>
            <br>
            <br>
            <ul>
                <li>-dev view_module</li>
                <li>-template-demo page</li>
                <!-- <li>-npm @angular-flex-layout</li> -->
            </ul>

        </div>
        <div fxLayout="column" fxFlex="30%">

            <mat-list role="list">
                <div mat-subheader>
                    <h3>Static</h3>
                </div>
                <mat-list-item role="listitem" *ngFor="let item of staticRoutes">
                    <a mat-button href="{{item.path}}" [attr.target]="item.target">{{item.name}}</a>
                </mat-list-item>
                <mat-divider></mat-divider>
                <div mat-subheader>
                    <h3>Dev menu</h3>
                </div>
                <mat-list-item role="listitem" *ngFor="let item of moduleRoutes">
                    <a mat-button [disabled]="item.path == ''" [routerLink]="['/', 'dev', item.path]">{{ item.component.name }}</a>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
</mat-card-content>

<app-dev-menu-button></app-dev-menu-button>