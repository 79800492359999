<app-spinner *ngIf="(loadingDeviceList$ | async)!!"></app-spinner>

<mat-dialog-header [title]="'Данные устройств телеметрии'">
    <section class="mat-dialog-header-buttons">
        <button mat-icon-button (click)="tooltipsShowAll()" #tooltipHelp="matTooltip" matTooltip="Помощь" [ngClass]="{active: helpActive}">
            <mat-icon>help</mat-icon>
        </button>
        <button mat-icon-button (click)="refreshData()">
            <mat-icon>refresh</mat-icon>
        </button>
    </section>
</mat-dialog-header>

<mat-dialog-content class="p-10-0">

    <mat-table [dataSource]="deviceList$ | async" matSort (matSortChange)="sortingPagingDeviceList()" matSortActive="created_at" matSortDirection="asc" matSortDisableClear>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Имя</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.name}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controlInfo">
            <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="icon-cell">
                <app-button title="Смотреть показания устройства" [type]="buttonType.icon" [icon]="'info'" [disabled]="!ifDialogOpeningAllowed" (onclick)="onMeasurementClick(row)" #tooltipInfo="matTooltip" matTooltip="Показания устройства" matTooltipPosition="left" [matTooltipShowDelay]="200"></app-button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controlLocation">
            <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="icon-cell">
                <app-button [type]="buttonType.icon" [icon]="'location_on'" [disabled]="!isLocationClickAllowed(row)" (click)="onLocationClick(row)" #tooltipLocation="matTooltip" matTooltip="Показать на карте" matTooltipPosition="right" [matTooltipShowDelay]="200"></app-button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Тип</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.type.name}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="units">
            <mat-header-cell *matHeaderCellDef>Единица измерения</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.type.units}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="measure">
            <mat-header-cell *matHeaderCellDef>Показания</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <app-device-measurement-latest [id]="row.ID"></app-device-measurement-latest>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="min">
            <mat-header-cell *matHeaderCellDef>Мин</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-chip-list *ngIf="row.min">
                    <mat-chip class="s-{{ row.min ? row.min.toLowerCase() : 0 }}">
                        {{row.min}}
                    </mat-chip>
                </mat-chip-list>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="max">
            <mat-header-cell *matHeaderCellDef>Макс</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-chip-list *ngIf="row.max">
                    <mat-chip class="s-{{ row.max ? row.max.toLowerCase() : 0 }}">
                        {{row.max}}
                    </mat-chip>
                </mat-chip-list>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef>Описание</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <p>{{row.notes}}</p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="token">
            <mat-header-cell *matHeaderCellDef>Токен</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <app-input [type]="inputType.password" [readonly]="true" [value]="row.token" [disabled]="!ifDialogOpeningAllowed" [noBorder]="true" [useDefaultIcon]="false"></app-input>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Дата создания</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.created_at | date: 'hh:mm dd.MM.yyyy' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Последнее обновление</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.updated_at | date: 'dd.MM.yyyy'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controlEdit">
            <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="icon-cell">
                <app-button title="Редактировать" [type]="buttonType.icon" [icon]="'edit'" [disabled]="!ifDialogOpeningAllowed" (onclick)="onEditDeviceClick(row)" #tooltipEdit="matTooltip" matTooltip="Изменить" matTooltipPosition="left" [matTooltipShowDelay]="200"></app-button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controlRemove">
            <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="icon-cell">
                <app-button title="Удалить устройство" [type]="buttonType.icon" [icon]="'delete'" [disabled]="!ifDialogOpeningAllowed" (onclick)="onDeleteDeviceClick(row)" #tooltipRemove="matTooltip" matTooltip="Удалить" matTooltipPosition="right" [matTooltipShowDelay]="200"></app-button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <section *ngIf="(deviceList$ | async).data && (deviceList$ | async).data.length < 1" class="not-found">
        No items
    </section>

</mat-dialog-content>

<mat-dialog-actions class="tools">
    <button mat-raised-button color="primary" size="xl" class="large" (click)="onAddDevicesClick()" [disabled]="false">
        + Добавить прибор
    </button>
    <app-paginator align="end" [length]="deviceData?.devices?.length" [pageSize]="pageSize" (page)="onPageEvent()" [pageSizeOptions]="pageSizeOptions" [disabled]="loadingDeviceList$ | async">
    </app-paginator>
</mat-dialog-actions>

<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Закрыть</button>
</mat-dialog-actions> -->
