import {
    booleanAttribute,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation
} from "@angular/core";

export namespace ButtonComponentSettings {
    export enum ButtonType {
        blueButton,
        deepBlueButton,
        icon,
        basic,
        primaryButton
    }
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {
    @HostBinding('class')
    get className(): string {
        return this.disabled ? 'btn-disabled' : '';
    }

    @Input({ transform: booleanAttribute })
    fit = false;

    @Input()
    type: ButtonComponentSettings.ButtonType = ButtonComponentSettings.ButtonType.blueButton;

    @Input()
    isSubmitted = false;
    /**
     * Добавлен ng-content внутрь тега button
     * @deprecated     */
    @Input()
    label: string;

    @Input()
    icon: string;

    @Input({ transform: booleanAttribute })
    disabled: boolean = false;

    @Input({ transform: booleanAttribute })
    fullWidth: boolean = false;

    @Input({ transform: booleanAttribute })
    large: boolean = false;

    @Input({ transform: booleanAttribute })
    active: boolean = false;

    @Input({ transform: booleanAttribute })
    primary: boolean = false;

    @Input()
    color: string = "#ffffff";

    @Output()
    onclick: EventEmitter<PointerEvent> = new EventEmitter();

    get basicColor() {
        return {
            // color: this.color
        }
    }

    buttonType = ButtonComponentSettings.ButtonType;

    public onButtonClick(event: PointerEvent): void {
        this.onclick.emit(event);
    }
}
