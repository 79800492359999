// import {
//     ChangeDetectionStrategy,
//     ChangeDetectorRef,
//     Component,
//     inject,
//     OnInit,
//     ViewChild,
//     ViewEncapsulation
// } from '@angular/core';
// import {CommonModule} from '@angular/common';
// import {MatFormFieldModule} from "@angular/material/form-field";
// import {MatInputModule} from "@angular/material/input";
// import {MatOptionModule} from "@angular/material/core";
// import {MatProgressBarModule} from "@angular/material/progress-bar";
// import {MatSelectModule} from "@angular/material/select";
// import {MatSort, MatSortModule} from "@angular/material/sort";
// import {MatTableDataSource, MatTableModule} from "@angular/material/table";
// import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
// import {debounceTime, take, takeUntil} from "rxjs";

// import {SharedModule} from "@shared/shared.module";
// import {ImusDestroyService} from "@services/destroy.service";
// import {ButtonComponentSettings} from "@shared/components/button/button.component";
// import {ContractorsService} from "@app/modules/account/services/contractors.service";
// import {SysContractors, SysStatus, SysTypes} from "@shared/models/contractors";
// import {PageEvent} from "@angular/material/paginator";
// import {SearchParamsService} from "@app/modules/account/services/search-params.service";

// @Component({
//     selector: 'app-contractor-edit',
//     standalone: true,
//     imports: [
//         CommonModule,
//         MatFormFieldModule,
//         MatInputModule,
//         MatOptionModule,
//         MatProgressBarModule,
//         MatSelectModule,
//         MatSortModule,
//         MatTableModule,
//         ReactiveFormsModule,
//         SharedModule
//     ],
//     templateUrl: './contractor-edit.component.html',
//     styleUrls: ['./contractor-edit.component.scss'],
//     changeDetection: ChangeDetectionStrategy.OnPush,
//     encapsulation: ViewEncapsulation.None
// })
// export class ContractorEditComponent implements OnInit {
//     // private fb = inject(FormBuilder);
//     // private destroyRef = inject(ImusDestroyService);
//     // private contractorsService = inject(ContractorsService);
//     // private searchParamsService = inject(SearchParamsService);
//     // private cdr = inject(ChangeDetectorRef);

//     // @ViewChild(MatSort) sort: MatSort;

//     // totalItems: number = 0;
//     // pageSize: number = 15;
//     // pageIndex: number = 0;
//     // isLoaded = true;
//     // searchForm: FormGroup;
//     // sysTypes: SysTypes[];
//     // sysStatus: SysStatus[];
//     // buttonType = ButtonComponentSettings.ButtonType;
//     // dataContractors: MatTableDataSource<SysContractors> = new MatTableDataSource<SysContractors>();
//     // displayedColumns: string[] = [
//     //     'id',
//     //     'descr',
//     //     'type_descr',
//     //     'requisite_inn',
//     //     'requisite_kpp',
//     //     'address',
//     //     'status_descr',
//     // ];

//     ngOnInit(): void {

//         console.log('ContractorEditComponent ngOnInit');

//         // this.initForm();
//         // this.loadDataSelects();
//         // this.changesForm();
//     }

//     ngAfterViewInit() {
//         // this.dataContractors.sort = this.sort;
//     }

//     // loadDataSelects(): void {
//     //     this.getContractors();

//     //     this.contractorsService.getSysTypes([['where[catalog_id]', 2], ['paginate', false]])
//     //         .pipe(take(1))
//     //         .subscribe(sysTypes => this.sysTypes = sysTypes);

//     //     this.contractorsService.getSysStatus()
//     //         .pipe(take(1))
//     //         .subscribe(sysStatus => this.sysStatus = sysStatus);
//     // }

//     // initForm(): void {
//     //     this.searchForm = this.fb.group({
//     //         sysTypesId: [null],
//     //         descr: [null],
//     //         sysStatusId: [null]
//     //     });
//     // }

//     // changesForm(): void {
//     //     this.searchForm.valueChanges
//     //         .pipe(debounceTime(700), takeUntil(this.destroyRef))
//     //         .subscribe(data => this.fetchData());
//     // }

//     // getContractors(params?: any): void {
//     //     this.isLoaded = true;
//     //     this.cdr.markForCheck();
//     //     this.contractorsService.getContractors(params)
//     //         .pipe(take(1))
//     //         .subscribe(dataAccounts => {
//     //             this.totalItems = dataAccounts.total;
//     //             this.pageSize = dataAccounts.per_page;
//     //             this.pageIndex = dataAccounts.current_page - 1;
//     //             this.dataContractors.data = dataAccounts.data as SysContractors[];
//     //             this.isLoaded = false;
//     //             this.cdr.markForCheck();
//     //         });
//     // }

//     // downloadExel(): void {
//     //     console.log('Скачать :)')
//     // }

//     // onPaginateData($event: PageEvent): void {
//     //     this.pageSize = $event.pageSize;
//     //     this.pageIndex = $event.pageIndex;
//     //     this.fetchData();
//     // }

//     // fetchData(): void {
//     //     this.getContractors(this.getSearchParams());
//     // }

//     // getSearchParams(): any {
//     //     const sortField = this.dataContractors.sort.active;
//     //     const sortDirection = this.dataContractors.sort.direction;

//     //     const paramMappings = [
//     //         { formField: 'sysTypesId', paramName: 'where[type_id]' },
//     //         { formField: 'sysStatusId', paramName: 'where[status_id]' },
//     //         { formField: 'descr', paramName: 'where[descr]', operator: 'like' },
//     //     ];

//     //     return this.searchParamsService.getSearchParams(
//     //         paramMappings,
//     //         this.searchForm,
//     //         sortField,
//     //         sortDirection,
//     //         this.pageIndex,
//     //         this.pageSize
//     //     );
//     // }

// }
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {AsyncPipe, CommonModule, NgForOf, NgIf} from '@angular/common';
import {UserService} from "@shared/services";
import {BehaviorSubject, map, Observable, of, switchMap, takeUntil, tap} from "rxjs";

import {ImusDestroyService} from "@services/destroy.service";
import {AccountsViewData, Credentials, UserAccountsInfo, UserAccountsPage} from "@shared/models";
import {MatExpansionModule} from "@angular/material/expansion";
import {PortalModule} from "@angular/cdk/portal";
import {SharedModule} from "@shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {CredentialsComponent} from "@app/modules/account/pages/user-profile/credentials/credentials.component";
import {MainAccountComponent} from "@app/modules/account/pages/user-profile/main-account/main-account.component";
import {
    AdditionalAccountsComponent
} from "@app/modules/account/pages/user-profile/additional-accounts/additional-accounts.component";
import {Accounts, Contractors, DataAccounts, SysContractors} from "@shared/models/contractors";
import {AccountsService} from "@app/modules/account/services/accounts.service";
import { ContactorRoute, ContractorType, CONTRACTROR_ROUTES } from '../../models/contractor';
import { Router, ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { AccountService } from '@app/modules/account/services/account.service';
import { ContractorsService } from '@app/modules/account/services/contractors.service';
import { ContractorService } from '@app/modules/account/services/contractor';

@Component({
    selector: 'app-contractor-edit',
    standalone: true,
    imports: [
        
        AsyncPipe,
        NgForOf,
        MatExpansionModule,
        PortalModule,
        // Router,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
        NgIf,
        CommonModule, MatExpansionModule, PortalModule, RouterLinkActive, SharedModule, ReactiveFormsModule, CredentialsComponent, MainAccountComponent, AdditionalAccountsComponent],
    templateUrl: './contractor-edit.component.html',
    styleUrls: ['./contractor-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ContractorEditComponent implements OnInit {
    private userService = inject(UserService);
    private destroy = inject(ImusDestroyService);
    private cdr = inject(ChangeDetectorRef);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    public readonly isLoading$ = new BehaviorSubject(false);
    private accountsService = inject(AccountsService);
    private contractorsService = inject(ContractorsService);
    private contractorService = inject(ContractorService);
    public readonly routes$ = this.contractorService.contractor$.pipe(
        map(contractor => contractor?.type_id 
            ? CONTRACTROR_ROUTES.filter(route => route.complianceContractor
                .includes(contractor.type_id))
                .sort((a, b) => a.order - b.order) 
            : CONTRACTROR_ROUTES)
    ) 



    userId: string;
    userAccountsInfo: UserAccountsInfo = {} as UserAccountsInfo;
    accounts$: Observable<Accounts>;
    contractors$: Observable<DataAccounts>;
    dataAccounts$: Observable<AccountsViewData[]>
    credentials: Credentials;
    userAccountSections = [
        {
            fields: [
                { label: 'ID', property: 'contractor_id' },
                { label: 'Юридическая форма', property: 'contractor_type_descr' },
                { label: 'Наименование', property: 'contractor_descr' },
                { label: 'Статус проверки', property: 'status_descr' },
            ]
        },
        {
            fields: [
                { label: 'Контактное лицо', property: 'user_name' },
                { label: 'Email', property: 'user_email' },
                { label: 'Телефон', property: 'user_phone' },
                { label: 'Дата и время создания', property: 'created_at' },
            ]
        }
    ];

    ngOnInit() {
        this.userId = this.route.snapshot.params['id']
        this.contractorService.contractorForGuests$.next({
                id: Number.parseInt(this.userId),
                component: 'contractor'
            })
        // this.route.params
        //     .pipe(
        //         tap(params => this.userId = params['id']),
        //         takeUntil(this.destroy),
        //     )
        //     .subscribe()

        this.reload();
    }

    get access(): string {
        if (!this.credentials) {
            return null;
        }

        if (this.credentials.can_update) {
            return 'update';
        } else if (this.credentials.can_read) {
            return 'read';
        } else {
            return null;
        }
    }

    getCredentials(): void {
        this.userService.getCredentials()
            .pipe(
                tap(_ => this.isLoading$.next(true)),
                takeUntil(this.destroy),
                )
            .subscribe(credentials => {
                this.isLoading$.next(false)
                this.credentials = credentials;
                this.cdr.markForCheck();
            });
    }


    isDateField(property: string): boolean {
        return property.toLowerCase().includes('date') || property.toLowerCase().includes('_at');
    }

    getUserId(): void {

        const params = {
            'where[id]': this.userId,
            'paginate': false
        };

        const paramsContactor = {
            'where[contractor_id]': this.userId,
            'where[is_main]': true,
            'where[is_primary]': true
        }

        // this.getUserAccountInfo(params);
        // this.getContractorInfo(paramsContactor);
        this.getData(params);
        this.getAccounts(paramsContactor)
        
        // this.getDataAccounts(paramsDataAccounts)
    }

    getData(params: any) {
        // this.accounts$ = this.accountsService.getAccounts(params)
        //     .pipe(
        //         takeUntil(this.destroy),
        //         map(dataAccounts => (dataAccounts.data as Accounts[])[0])
        //     )
        
        // console.log('getData', params);

        this.contractors$ = this.contractorsService.getContractors(params) as Observable<DataAccounts>

        this.contractors$
            .pipe(
                map((data: any) => (data as any[])[0]),
                takeUntil(this.destroy)
                )
            .subscribe((res) => {
                this.contractorService.contractor$.next(res);

                //TODO Custom redirect
                if(this.contractorService.contractor$.value.type_id == ContractorType.PERSONAL) {
                    this.router.navigate(['account', 'contractors', this.contractorService.contractor$.value.id, 'personal']);
                }
                this.isLoading$.next(false) 
                this.cdr.markForCheck() 
            })

    }

    getAccounts(params: any) {
        this.userService.getUserAccountsView(params)
            .pipe(
                map(dataAccounts => ((dataAccounts as UserAccountsPage).data)[0]),
                // tap(_ => !this.userAccountsInfo ? this.userAccountsInfo = {} as UserAccountsInfo : void(0)),
                tap(value => {
                    if (value) {
                        // console.log('getAccounts',value)
                        this.userAccountsInfo = {...value} //as unknown as UserAccountsInfo
                    }
                }),
                switchMap(_ => this.contractorService.contractor$),
                tap(value => this.userAccountsInfo.status_descr = value?.status_descr),
                takeUntil(this.destroy),
            )
            .subscribe()

    }

    // getDataAccounts(paramsDataAccounts: any) {
    //     this.dataAccounts$ = this.userService.getUserAccountsView(paramsDataAccounts)
    //         .pipe(map(data => data as AccountsViewData[]));
    // }

    // getContractorInfo(params: any): void {

    //     // console.log('getContactorInfo', params)

    //     // this.contractorsService.getContractors(params)
    //     // this.userService.getUserAccountInfo(params)
    //     this.accountsService.getAccountsBase(params)
    //     // this.userService.getUserContractorsView(params)
    //         .pipe(takeUntil(this.destroy))
    //         .subscribe((userAccountsFeature: any) => {
    //             // console.log('sdsd', userAccountsFeature);
    //             // this.userAccountsInfo = userAccountsFeature.data[0] as any;

    //             // this.userAccountsInfo = userAccountsFeature[0] as any;
                
    //             const params = {
    //                 'where[id]': this.userId,
    //                 'paginate': false
    //             };
    //             this.getData(params);
                
    //             // this.cdr.markForCheck();
    //         });
    // }

    // getUserAccountInfo(params: any): void {
    //     this.userService.getUserAccountInfo(params)
    //         .pipe(takeUntil(this.destroy))
    //         .subscribe(userAccountsFeature => {
    //             this.userAccountsInfo = userAccountsFeature[0] as UserAccountsInfo;
    //             this.cdr.markForCheck();
    //         });
    // }

    reload() {
        // this.isLoading$.next(true)
        this.getCredentials();
        this.getUserId();
    }

    backToList() {
        this.contractorService.contractor$.next(null)
        this.router.navigate(['/', 'account', 'contractors']);
    }

    // public readonly routes$ = this.accountService.account$.pipe(
    //     map(account => CONTRACTROR_ROUTES.filter(route => route.complianceContractor
    //         .includes(account.contractor_type_id))
    //         .sort((a, b) => a.order - b.order))
    // )

    // this.conter
    // public readonly routes$ = this.contractorService.contractor$ && this.contractorService.contractor$.value && this.contractorService.contractor$.value.type_id ? this.contractorService.contractor$.pipe(
    // public readonly routes$ =  this.contractorService.contractor$?.value?.type_id ? this.contractorService.contractor$.pipe(
    //         map(contractor => CONTRACTROR_ROUTES.filter(route => route.complianceContractor
    //             .includes(contractor.type_id))
    //             .sort((a, b) => a.order - b.order))
    //     ) : of(CONTRACTROR_ROUTES)
    // public readonly routes$ = of(CONTRACTROR_ROUTES);

    // constructor(
    //     public readonly accountService: AccountService,
    //     public readonly contractorService: ContractorService,
    // ) {
    // }



}
