<div class="component-area">
    <app-account-header 
        [userName]="account.form?.controls.name?.value" 
        [userType]="'Простой аккаунт'"
        [buttonLabel]="'Профессиональный аккаунт'" 
        (accountButtonClick)="onProfAccountClick()">
    </app-account-header>

    <div class="inputs-area">

        <!-- <div class="left-buttons">
            <app-button [label]="'Главная'" [fullWidth]="true" (onclick)="onMainPageClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Настройки'" [fullWidth]="true" (onclick)="onAccountSettingsClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Информация'" [fullWidth]="true" (onclick)="onAccountInformationClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Выход'" [fullWidth]="true" (click)="onLogoutClick()"></app-button>
            <div class="separator"></div>
        </div> -->

        <app-account-left-sidebar></app-account-left-sidebar>

        <div class="account-inputs">

            <form [formGroup]="account.form" (ngSubmit)="onSubmit()">
                <!-- <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.text" [placeholder]="'Имя'"
                        formControlName="name"></app-input>
                    <field-errors formFieldName="name"></field-errors>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div> 
                <div class="separator"></div> -->
               
                <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.email" imusRequired imusEmail
                        [placeholder]="'E-mail'" formControlName="email" readonly></app-input>
                    <field-errors formFieldName="email"></field-errors>
                    <!-- <app-button></app-button> -->
                    <!-- <div class="button-cell"></div> -->
                    <!-- <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button> -->
                </div>
                <div class="separator"></div>

                <!-- <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.tel" [placeholder]="'Phone'"
                        formControlName="phone"></app-input>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div>
                <div class="separator"></div> -->

                <!-- <div class="inputs-grid">
                    <app-input class="input-cell" [type]="inputType.password" imusRequired [placeholder]="'Password'" formControlName="password"></app-input>
                    <field-errors formFieldName="password"></field-errors>
                    <app-button class="button-cell" [type]="buttonType.icon" [icon]="'edit'"></app-button>
                </div> -->

                <!-- <app-button [label]="'Применить'" [fullWidth]="true" ></app-button>
                <div class="separator"></div> -->

            </form>
            <div class="separator"></div>
            <app-button [label]="'Изменить пароль'" [fullWidth]="true" (onclick)="onChangePasswordClick($event)"></app-button>
        </div>
    </div>

</div>