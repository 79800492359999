import { Component, Input } from '@angular/core';

@Component({
    selector: 'mat-dialog-header',
    templateUrl: './mat-dialog-header.component.html',
    styleUrls: ['./mat-dialog-header.component.scss'],
})
export class MatDialogHeaderComponent {
    @Input() public title: string;

    @Input() public titleHidden: boolean;
}
