import { Injectable } from '@angular/core';

@Injectable()
export class AzimuthService {
    constructor() {}

    private earthRadiusInMeters(latitudeRadians){
        let a = 6378137.0;
        let b = 6356752.3;
        let cos = Math.cos (latitudeRadians);
        let sin = Math.sin (latitudeRadians);
        let t1 = a * a * cos;
        let t2 = b * b * sin;
        let t3 = a * cos;
        let t4 = b * sin;
        return Math.sqrt((t1*t1 + t2*t2) / (t3*t3 + t4*t4));
    }

    private geoCentricLatitude(lat){
        let e2 = 0.00669437999014;
        let clat = Math.atan((1.0 - e2) * Math.tan(lat));
        return clat;
    }

    private locationToPoint(c) {
        let lat = c.lat * Math.PI / 180.0;
        let lon = c.lon * Math.PI / 180.0;
        let radius = this.earthRadiusInMeters(lat);
        let clat   = this.geoCentricLatitude(lat);

        let cosLon = Math.cos(lon);
        let sinLon = Math.sin(lon);
        let cosLat = Math.cos(clat);
        let sinLat = Math.sin(clat);
        let x = radius * cosLon * cosLat;
        let y = radius * sinLon * cosLat;
        let z = radius * sinLat;

        let cosGlat = Math.cos(lat);
        let sinGlat = Math.sin(lat);

        let nx = cosGlat * cosLon;
        let ny = cosGlat * sinLon;
        let nz = sinGlat;

        x += c.elv * nx;
        y += c.elv * ny;
        z += c.elv * nz;

        return {x: x, y: y, z: z, radius: radius, nx: nx, ny: ny, nz: nz};
    }

    private rotateGlobe(b, a, bradius, aradius) {
        let br = {'lat':b.lat, 'lon':(b.lon - a.lon), 'elv':b.elv};
        let brp = this.locationToPoint(br);
        let alat = -a.lat * Math.PI / 180.0;
        alat = this.geoCentricLatitude(alat);
        let acos = Math.cos(alat);
        let asin = Math.sin(alat);

        let bx = (brp.x * acos) - (brp.z * asin);
        let by = brp.y;
        let bz = (brp.x * asin) + (brp.z * acos);

        return {x: bx, y: by, z: bz, radius: bradius};
    }

    private azimuth(start, end) {
        let ph1 = start[0];
        let lm1 = start[1];
        let ph2 = end[0];
        let lm2 = end[1];

        let pi = Math.PI;
        let theta = 0;
        let azimuth = 0;
        let startPoint = {lat: ph1, lon: lm1, elv: 0};
        let endPoint = {lat: ph2, lon: lm2, elv: 0};
        let ap = this.locationToPoint(startPoint);
        let bp = this.locationToPoint(endPoint);

        let br = this.rotateGlobe(endPoint, startPoint, bp.radius, ap.radius);
        if (br.z * br.z + br. y* br.y > 1.0e-6) {
          theta = Math.atan2(br.z, br.y) * 180.0 / Math.PI;
          azimuth = 90.0 - theta;
          if (azimuth < 0.0) {
            azimuth += 360.0;
          }
          if (azimuth > 360.0) {
            azimuth -= 360.0;
          }
        }
        return azimuth * (pi/180);
    }

    public getCoordsByDistance(startPoint, endPoint, distance, ymaps) {
        let az = this.azimuth(startPoint, endPoint);
        let direction = [Math.cos(az), Math.sin(az)];
        let point = ymaps.coordSystem.geo.solveDirectProblem(startPoint, direction, distance);

        return point;
    }
}
