import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterRouteChange = false;

    constructor(
        private router: Router,
        private readonly _snackBar: MatSnackBar
    ) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert message
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'success', text: message });
    }

    error(message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'error', text: message });
    }

    clear() {
        // clear by calling subject.next() without parameters
        // this.subject.next();
    }

    public warning(data: string | { message: string }, action: string = 'OK', duration: number = 5000): void {
        this._snackBar.open((
            data && Object.prototype.hasOwnProperty.call(data, 'message')
                ? (data as { message: string }).message
                : data as string
        ), action, {
            duration
        });
    }

    openSnackbar(message: string, duration = 3000): any {
        this._snackBar.open(message, undefined, {
            duration: duration,
            horizontalPosition: 'start',
            verticalPosition: 'bottom',
        });
    }
}
