<mat-accordion   [multi]="false">
    <mat-expansion-panel
        #rla="routerLinkActive"
        routerLinkActive="active"
        [routerLink]="item.route"
        [expanded]="rla.isActive"
        [disabled]="rla.isActive"
        *ngFor="let item of routes$ | async">
        <mat-expansion-panel-header>
            <mat-panel-title>{{item.name}}</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="rla.isActive">
            <ng-container *cdkPortalHost="profileRouterOutlet"></ng-container>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<ng-template cdkPortal #profileRouterOutlet="cdkPortal">
    <router-outlet></router-outlet>
</ng-template>
