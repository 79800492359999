<mat-progress-bar *ngIf="isLoaded$ | async" mode="indeterminate"></mat-progress-bar>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
    <mat-tab [label]="'Общая информация ' + companyDetails?.contractorTypeTitle">
        <!-- <ng-container
            *ngIf="(userService.contractorsAccess$ | async)?.data[0].can_read || (userService.contractorsAccess$ | async)?.data[0].can_update; else accessDeniedTpl"> -->
        <ng-container
            >
            <form [formGroup]="details.main.form" (ngSubmit)="onSubmit('main')"
                  *ngIf="details.main.fields$ | async as fields">
                  <ng-container *ngFor="let field of fields[0]; index as i">
                    <div class="imus-row fields-container" *ngIf="!field.hidden">
                        <div class="imus-row-item">
                            <div class="imus-row">
                                <div class="imus-row-item">
                                    <mat-form-field *ngIf="field.name === 'status_id'" 
                                        [style.width.%]="50">
                                        <mat-label>{{field.label}}</mat-label>
                                        <mat-select [formControlName]="field.name">
                                            <mat-option [value]="null">Сбросить</mat-option>
                                            <mat-option *ngFor="let sysStatus of sysStatusTypes$ | async" [value]="sysStatus.id">{{ sysStatus.descr }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <app-imus-input *ngIf="field.name !== 'status_id'"
                                        [required]="!!field.required"
                                        [formControlName]="field.name"
                                        [type]="field.type"
                                        [imusRequired]="!!field.required"
                                        [placeholder]="field.label">
                                    </app-imus-input>
                                </div>
                                <app-button *ngIf="field.name === 'requisite_short_name' || field.name === 'person_name_last'"
                                    (click)="getInfo('short_name', details.main.form?.value[field.name])" 
                                    icon="rss_feed"
                                    label="ЕГРЮЛ" 
                                    [type]="buttonType.blueButton">
                                </app-button>
                            </div>
                        </div>
                        <div *ngIf="fields[1][i]" class="imus-row-item">
                            <div class="imus-row">
                                <div class="imus-row-item">
                                    <app-imus-input
                                        #input2
                                        [required]="!!fields[1][i].required"
                                        [readonly]="onlyRead$ | async"
                                        [formControlName]="fields[1][i].name"
                                        [type]="fields[1][i].type"
                                        [imusRequired]="!!fields[1][i].required"
                                        [placeholder]="fields[1][i].label"></app-imus-input>
                                </div>
                                <app-button (click)="getInfo('inn', input2?.value)" icon="rss_feed"
                                            *ngIf="fields[1][i].name === 'requisite_inn'"
                                            label="ЕГРЮЛ" [type]="buttonType.blueButton"></app-button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="imus-row" *ngIf="(onlyRead$ |  async) === false">
                    <app-button
                        [isSubmitted]="true"
                        [type]="buttonType.primaryButton"
                        [disabled]="details.main.form.pristine"
                        label="Сохранить"></app-button>
                    <app-button
                        [type]="buttonType.deepBlueButton"
                        label="Отменить"
                        class="right"
                        [disabled]="details.main.form.pristine"
                        (click)="resetChangeInForm('main')"></app-button>
                </div>
            </form>
        </ng-container>
    </mat-tab>
    <mat-tab label="Банковские реквизиты">
        <form [formGroup]="details.bank.form" *ngIf="details.bank.fields$ | async as fields" (ngSubmit)="onSubmit('bank')">
            <div class="imus-row fields-container" *ngFor="let field of fields[0]; index as i">
                <div class="imus-row-item">
                    <div *ngIf="!field.hidden" class="imus-row">
                        <div class="imus-row-item">
                            <app-imus-input 
                                #input3 
                                [required]="!!field.required" 
                                [formControlName]="field.name" 
                                [type]="field.type"
                                [imusRequired]="!!field.required" 
                                [placeholder]="field.label">
                            </app-imus-input>
                        </div>
                        <app-button (click)="getInfo('bik', input3?.value)" icon="rss_feed" *ngIf="field.name === 'bank_bik'"
                            label="ЕГРЮЛ" [type]="buttonType.blueButton"></app-button>
                    </div>
                </div>
                <div class="imus-row-item">
                    <app-imus-input *ngIf="fields[1][i] as item"
                                    [required]="!!item.required"
                                    [formControlName]="item.name"
                                    [type]="item.type"
                                    [imusRequired]="!!item.required"
                                    [placeholder]="item.label"></app-imus-input>
                </div>
            </div>
            <div class="imus-row" *ngIf="(onlyRead$ | async) === false">
                <app-button
                    [disabled]="details.bank.form.pristine"
                    [isSubmitted]="true"
                    [type]="buttonType.primaryButton"
                    label="Сохранить"></app-button>
                <app-button
                    [type]="buttonType.deepBlueButton"
                    [disabled]="details.bank.form.pristine"
                    label="Отменить"
                    class="right"
                    (click)="resetChangeInForm('bank')"></app-button>
            </div>
        </form>
    </mat-tab>
</mat-tab-group>

<ng-template #accessDeniedTpl>
    <div class="imus-row">Информация не доступна</div>
</ng-template>
