import { PlotRef } from './plot';

export class Report {
    id: number;
    plot: PlotRef;
    name: string;
    files: any[];
    info: {
        fields: any;
        sections: any[];
    };
    crashes: any[];
}
