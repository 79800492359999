<mat-progress-bar *ngIf="isLoaded$ | async" mode="indeterminate"></mat-progress-bar>
<form [formGroup]="personal.form" (ngSubmit)="onSubmit()" *ngIf="personal.fields$ | async as fields">
    <ng-container *ngFor="let field of fields[0]; index as i">
        <div class="imus-row fields-container" *ngIf="!field.hidden">
            <div class="imus-row-item">
                <ng-container [ngSwitch]="field.name">
                    <mat-form-field *ngSwitchCase="'person_sex_type_id'">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select [formControlName]="field.name" [required]="field.required">
                            <mat-option [value]="null">Сбросить</mat-option>
                            <mat-option *ngFor="let sysTypes of genderTypes$ | async" [value]="sysTypes.id">{{ sysTypes.descr }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field [style.width.%]="50" *ngSwitchCase="'status_id'">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select [formControlName]="field.name">
                            <mat-option [value]="null">Сбросить</mat-option>
                            <mat-option *ngFor="let sysStatus of sysStatusTypes$ | async" [value]="sysStatus.id">{{ sysStatus.descr }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-checkbox [style.width.%]="50" *ngSwitchCase="'is_rights_granted'" [checked]="personal.form.value.is_rights_granted" disabled>{{ field.label }}</mat-checkbox>
                    <app-imus-input
                        *ngSwitchDefault
                        [required]="!!field.required"
                        [readonly]="onlyRead$ | async"
                        [formControlName]="field.name"
                        [type]="field.type"
                        [placeholder]="field.label">
                    </app-imus-input>
                </ng-container>
            </div>
            <div class="imus-row-item" *ngIf="fields[1][i] as field">
                <ng-container [ngSwitch]="field.type === 'select'">
                    <mat-form-field *ngSwitchCase="true">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select [formControlName]="field.name">
                            <mat-option [value]="null">Сбросить</mat-option>
                            <mat-option *ngFor="let sysTypes of idCardTypes$ | async" [value]="sysTypes.id">{{ sysTypes.descr }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-imus-input
                        *ngSwitchDefault
                        [required]="!!field.required"
                        [readonly]="onlyRead$ | async"
                        [formControlName]="field.name"
                        [type]="field.type"
                        [imusRequired]="!!field.required"
                        [placeholder]="field.label"></app-imus-input>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <div class="imus-row" *ngIf="(onlyRead$ |  async) === false">
        <app-button
            [isSubmitted]="true"
            [type]="buttonType.primaryButton"
            [disabled]="personal.form.invalid || personal.form.pristine"
            label="Сохранить"></app-button>
        <app-button
            [type]="buttonType.deepBlueButton"
            label="Отменить"
            class="right"
            [disabled]="personal.form.pristine"
            (click)="resetChangeInForm()"></app-button>
    </div>
</form>

<ng-template #selectTpl let-field></ng-template>
