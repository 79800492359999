<div class="measurement">
    <div class="measurement-list">
        <div class="measurement-list-item" *ngFor="let measurement of measurements, let i = index">
            <div class="measurement-list-item_name">{{measurement.name}}</div>
            <div class="measurement-list-item_slug">{{measurement.slug}}</div>
            <div class="measurement-list-item_value">{{measurement.value}}</div>
            <div class="measurement-list-item_value" (click)="deleteMeasurement(measurement.id)">
                <img src="assets/img/icons/delete copy.svg" />
            </div>
        </div>
    </div>

    <form [formGroup]="measurementAddForm" (ngSubmit)="submitAddMeasurement($event)" [ngClass]="isMeasurementAdd ? 'open' : ''" class="measurement-form">
        <input (keyup)="makeSlug($event)" class="" name="measurementName" placeholder="Название измерения" type="text" />
        <input class="" name="measurementKeyword" placeholder="Название параметра" type="text" value="{{ slug }}" />
        <button type="submit">Добавить</button>
    </form>

    <div [ngClass]="isMeasurementAdd ? 'hide' : ''"  class="add-layer" (click)="addMeasurement()">
        Добавить измерение
    </div>
</div>
