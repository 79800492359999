import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ImusDestroyService } from "@services/destroy.service";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, shareReplay, switchMap, tap } from "rxjs";
import {
    Personal,
    PERSONAL_FIELDS,
    PersonalData
} from "@app/modules/account/pages/profile/components/personal/personal";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { SharedModule } from "@shared/shared.module";
import { UserService } from "@shared/services";
import { AccountService } from "@app/modules/account/services/account.service";
import { ContractorsView, ContractorsViewData, UserContractorsReq } from "@shared/models";
import { finalize, takeUntil } from "rxjs/operators";
import { ButtonComponentSettings } from "@shared/components/button/button.component";
import { ContractorsService } from "@app/modules/account/services/contractors.service";

/** Персональные данные */
@Component({
    selector: 'app-personal',
    standalone: true,
    imports: [
        MatProgressBarModule,
        NgIf,
        AsyncPipe,
        ReactiveFormsModule,
        NgForOf,
        SharedModule,
        NgSwitch,
        NgSwitchDefault,
        NgSwitchCase
    ],
    providers: [
        ImusDestroyService
    ],
    templateUrl: './personal.component.html',
    styleUrls: ['./personal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PersonalComponent implements OnInit {
    private readonly _destroy$ = inject(ImusDestroyService);
    private readonly _fb = inject(FormBuilder);
    private readonly _contractorsService = inject(ContractorsService);
    public readonly isLoaded$ = new BehaviorSubject(false);
    public readonly userService = inject(UserService);
    public readonly account$ = inject(AccountService).account$;
    public readonly refresh = new EventEmitter<void>();
    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public readonly contractorsView$: Observable<ContractorsViewData | null> = this.refresh.pipe(
        tap(() => this.isLoaded$.next(true)),
        switchMap(() => this.account$),
        switchMap(acc => this.userService.getUserContractorsView({['where[id]']: acc.contractor_id}).pipe(
            finalize(() => {
                this.isLoaded$.next(false);
                this.setPristineForm();
            })
        )),
        map((res: ContractorsView) => res.data.data[0]),
        catchError(_ => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    public readonly personal: Personal = {
        model: {
            person_name_last: null,
            person_name_first: null,
            person_name_second: null,
            person_sex_type_id: null,
            person_birthday: null,
            address: null,
            idcard_type_id: null,
            idcard_doc_num: null,
            idcard_doc_date: null,
            person_birthplace: null,
            idcard_doc_org: null,
            id: null,
            type_id: null
        },
        form: null,
        fields$: new ReplaySubject()
    }
    public readonly genderTypes$ = this._contractorsService.getSysTypes([['where[catalog_id]', 5], ['paginate', false]]).pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    )
    public readonly idCardTypes$ = this._contractorsService.getSysTypes([['where[catalog_id]', 6], ['orderBy[seq]', 'asc'], ['paginate', false]]).pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    )
    public onlyRead$: Observable<boolean>;

    constructor() {
        this.personal.form = this._fb.fromTypedModel(this.personal.model);
    }

    ngOnInit(): void {
        this.init();
        this.onlyRead$ = this.userService.contractorsAccess$.pipe(
            map(res => res.data[0].can_read && !res.data[0].can_update),
            tap(onlyRead => {
                if (onlyRead) {
                    this.personal.form.disable();
                }
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        )
    }

    public onSubmit(): void {
        if (this.personal.form.invalid) {
            return;
        }
        const value = this.personal.form.toModel(this.personal.model, {
            person_birthday: this.parseDate,
            idcard_doc_date: this.parseDate,
        })
        const res: UserContractorsReq = Object.assign({ is_rights_granted: true }, value) as unknown as UserContractorsReq;
        of(this.isLoaded$.next(true)).pipe(
            switchMap(() => this.userService.setUserContractors(res)),
            finalize(() => this.isLoaded$.next(false)),
            takeUntil(this._destroy$)
        ).subscribe(() => this.refresh.next());
    }

    public resetChangeInForm(): void {
        this.personal.form.patchValue(this.personal.model);
        this.setPristineForm();
    }

    private setPristineForm(): void {
        this.personal.form.markAsPristine();
        this.personal.form.markAsUntouched();
    }

    private init(): void {
        this.contractorsView$.pipe(
            takeUntil(this._destroy$)
        ).subscribe(info => {
            if (info) {
                this.personal.model = <PersonalData>Object.keys(this.personal.model).reduce((acc, key) => {
                    acc[key] = info[key];
                    return acc;
                }, {});
                this.personal.form.patchValue(this.personal.model);
            }
        })
        this.personal.fields$.next(PERSONAL_FIELDS);
        this.refresh.emit();
    }

    private parseDate(date: Date | string): string {
        return (!!date && date instanceof Date) ? date.toLocaleString() : date as string;
    }
}
