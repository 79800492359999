// Общая информация
// 1. Регистрация и вход в систему
// 1.1. Регистрация
// 1.1.1. Стандартный аккаунт
// 1.1.2. Профессиональный аккаунт
// 1.2. Вход в систему
// 2. Описание интерфейса
// 2.1. Главная страница
// 2.1.1. Базовая карта
// 2.1.2. Панель работы со слоями
// 2.1.3. Панель атрибутов
// 2.1.4. Таблица атрибутов
// 2.1.5. Поиск
// 2.1.6. Дополнительные инструменты
// 2.1.6.1. Линейка
// 2.1.6.2. Сохранить текущий фрейм
// 2.2. Режим статистики
// 2.3. Вывод на печать
// 2.4. Личный кабинет
// 2.4.1. Профиль
// 2.4.1.1. Учетные данные
// 2.4.1.2. Персональные данные
// 2.4.1.3. Реквизиты ИП
// 2.4.1.4. Реквизиты компании
// 2.4.1.5. Сотрудники
// 2.4.1.6. Дополнительные аккаунты
// 2.4.1.7. Гости
// 2.4.2. Руководство
// 2.4.3. Настройки пользователя
// 2.4.4. Обратная связь
// 3. Слои
// 3.1. Подложка
// 3.2. Растровые слои
// 3.2.1. Файл привязки
// 3.2.2. Подготовка растрового изображения
// 3.2.3. Ручная привязка растрового изображения
// 3.3. Рабочие слои
// 3.3.1. Тип слоев Канализация
// 3.3.2. Тип слоев Водопровод
// 3.4. Глобальный слой
// 4. Редактирование данных
// 4.1. Добавление слоев
// 4.2. Управление растровыми слоями
// 4.3. Управление рабочими слоями
// 4.3.1. Уровни доступа
// 4.4. Редактирование рабочих слоев
// 4.4.1. Инструменты
// 4.4.2. Создание точечных объектов
// 4.4.3. Список координат точек для загрузки
// 4.4.4. Редактирование точечных объектов
// 4.4.5. Создание участков
// 4.4.6. Редактирование участков
// 4.4.7. Копирование объектов между слоями
// 4.4.8. Объединение слоев
// 4.4.9. Редактирование атрибутов
// 5. Телеметрия
// 5.1. Список устройств телеметрии
// 5.2. Добавление устройства телеметрии
// 6. Телеинспекции
// 6.1. Список отчетов телеинспекций
// 6.2. Загрузка отчета телеинспекции
// 6.3. Работа с отчетами телеинспекций
// 7. API
export interface NavigationMenuItem {
    number: string;
    name: string;
    link: string;
    children: NavigationMenuItem[];
    level?: number;
    expanded?: boolean;
    visible?: boolean;
    // [key: string]: string[]
}
// remove children anf make flat array
export const INFO_NAVIGATION_RAW: NavigationMenuItem[] = [
    {
        number: '0',
        name: 'Общая информация',
        link: '00.html',
        children: [],
    },
    {
        number: '1.',
        name: 'Регистрация и вход в систему',
        link: '01.html',
        children: [
            {
                number: '1.1.',
                name: 'Регистрация',
                link: '01.html',
                children: [
                    {
                        number: '1.1.1.',
                        name: 'Стандартный аккаунт',
                        link: '0111.html',
                        children: []
                    },
                    {
                        number: '1.1.2.',
                        name: 'Профессиональный аккаунт',
                        link: '0112.html',
                        children: []
                    }
                ]
            },
            {
                number: '1.2.',
                name: 'Вход в систему',
                link: '012.html',
                children: []
            }
        ]
    },
    {
        number: '2.',
        name: 'Описание интерфейса',
        link: '02.html',
        children: [
            {
                number: '2.1.',
                name: 'Главная страница',
                link: '02.html',
                children: [
                    {
                        number: '2.1.1.',
                        name: 'Базовая карта',
                        link: '0211.html',
                        children: []
                    },
                    {
                        number: '2.1.2.',
                        name: 'Панель работы со слоями',
                        link: '0212.html',
                        children: []
                    },
                    {
                        number: '2.1.3.',
                        name: 'Панель атрибутов',
                        link: '0213.html',
                        children: []
                    },
                    {
                        number: '2.1.4.',
                        name: 'Таблица атрибутов',
                        link: '0214.html',
                        children: []
                    },
                    {
                        number: '2.1.5.',
                        name: 'Поиск',
                        link: '0215.html',
                        children: []
                    },
                    {
                        number: '2.1.6.',
                        name: 'Дополнительные инструменты',
                        link: '0216.html',
                        children: [
                            {
                                number: '2.1.6.1.',
                                name: 'Линейка',
                                link: '02161.html',
                                children: []
                            },
                            {
                                number: '2.1.6.2.',
                                name: 'Сохранить текущий фрейм',
                                link: '02162.html',
                                children: []
                            }
                        ]
                    }
                ]
            },
            // 2.2. Режим статистики
            // 2.3. Вывод на печать
            // 2.4. Личный кабинет
            {
                number: '2.2.',
                name: 'Режим статистики',
                link: '022.html',
                children: []
            },
            {
                number: '2.3.',
                name: 'Вывод на печать',
                link: '023.html',
                children: []
            },
            {
                number: '2.4.',
                name: 'Личный кабинет',
                link: '024.html',
                children: [
                    // 2.4.1. Профиль
                    // 2.4.1.1. Учетные данные
                    // 2.4.1.2. Персональные данные
                    // 2.4.1.3. Реквизиты ИП
                    // 2.4.1.4. Реквизиты компании
                    // 2.4.1.5. Сотрудники
                    // 2.4.1.6. Дополнительные аккаунты
                    // 2.4.1.7. Гости

                    {
                        number: '2.4.1.',
                        name: 'Профиль',
                        link: '0241.html',
                        children: [
                            {
                                number: '2.4.1.1.',
                                name: 'Учетные данные',
                                link: '02411.html',
                                children: []
                            },
                            {
                                number: '2.4.1.2.',
                                name: 'Персональные данные',
                                link: '02412.html',
                                children: []
                            },
                            {
                                number: '2.4.1.3.',
                                name: 'Реквизиты ИП',
                                link: '02413.html',
                                children: []
                            },
                            {
                                number: '2.4.1.4.',
                                name: 'Реквизиты компании',
                                link: '02414.html',
                                children: []
                            },
                            {
                                number: '2.4.1.5.',
                                name: 'Сотрудники',
                                link: '02415.html',
                                children: []
                            },
                            {
                                number: '2.4.1.6.',
                                name: 'Дополнительные аккаунты',
                                link: '02416.html',
                                children: []
                            },
                            {
                                number: '2.4.1.7.',
                                name: 'Гости',
                                link: '02417.html',
                                children: []
                            }

                        ]
                    },
                    // 2.4.2. Руководство
                    // 2.4.3. Настройки пользователя
                    // 2.4.4. Обратная связь

                    {
                        number: '2.4.2.',
                        name: 'Руководство',
                        link: '0242.html',
                        children: []
                    },
                    {
                        number: '2.4.3.',
                        name: 'Настройки пользователя',
                        link: '0243.html',
                        children: []
                    },
                    {
                        number: '2.4.4.',
                        name: 'Обратная связь',
                        link: '0244.html',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        number: '3.',
        name: 'Слои',
        link: '03.html',
        children: [
            {
                number: '3.1.',
                name: 'Подложка',
                link: '031.html',
                children: []
            },
            {
                number: '3.2.',
                name: 'Растровые слои',
                link: '032.html',
                children: [
                    {
                        number: '3.2.1.',
                        name: 'Файл привязки',
                        link: '0321.html',
                        children: []
                    },
                    {
                        number: '3.2.2.',
                        name: 'Подготовка растрового изображения',
                        link: '0322.html',
                        children: []
                    },
                    {
                        number: '3.2.3.',
                        name: 'Ручная привязка растрового изображения',
                        link: '0323.html',
                        children: []
                    }
                ]
            },
            {
                number: '3.3.',
                name: 'Рабочие слои',
                link: '033.html',
                children: [
                    {
                        number: '3.3.1.',
                        name: 'Тип слоев канализация',
                        link: '0331.html',
                        children: []
                    },
                    {
                        number: '3.3.2.',
                        name: 'Тип слоев водопровод',
                        link: '0332.html',
                        children: []
                    }
                ]
            },
            {
                number: '3.4.',
                name: 'Глобальный слой',
                link: '034.html',
                children: []
            }
        ]
    },
    {
        number: '4.',
        name: 'Редактирование данных',
        link: '04.html',
        children: [
            // 4.1. Добавление слоев
            // 4.2. Управление растровыми слоями
            // 4.3. Управление рабочими слоями
            // 4.3.1. Уровни доступа
            {
                number: '4.1.',
                name: 'Добавление слоев',
                link: '041.html',
                children: []
            },
            {
                number: '4.2.',
                name: 'Управление растровыми слоями',
                link: '042.html',
                children: []
            },
            {
                number: '4.3.',
                name: 'Управление рабочими слоями',
                link: '043.html',
                children: [
                    {
                        number: '4.3.1.',
                        name: 'Уровни доступа',
                        link: '0431.html',
                        children: []
                    }
                ]
            },
            // 4.4. Редактирование рабочих слоев
            // 4.4.1. Инструменты
            // 4.4.2. Создание точечных объектов
            // 4.4.3. Список координат точек для загрузки
            // 4.4.4. Редактирование точечных объектов
            // 4.4.5. Создание участков
            // 4.4.6. Редактирование участков
            // 4.4.7. Копирование объектов между слоями
            // 4.4.8. Объединение слоев
            // 4.4.9. Редактирование атрибутов
            {
                number: '4.4.',
                name: 'Редактирование рабочих слоев',
                link: '044.html',
                children: [
                    {
                        number: '4.4.1.',
                        name: 'Инструменты',
                        link: '0441.html',
                        children: []
                    },
                    {
                        number: '4.4.2.',
                        name: 'Создание точечных объектов',
                        link: '0442.html',
                        children: []
                    },
                    {
                        number: '4.4.3.',
                        name: 'Список координат точек для загрузки',
                        link: '0443.html',
                        children: []
                    },
                    {
                        number: '4.4.4.',
                        name: 'Редактирование точечных объектов',
                        link: '0444.html',
                        children: []
                    },
                    {
                        number: '4.4.5.',
                        name: 'Создание участков',
                        link: '0445.html',
                        children: []
                    },
                    {
                        number: '4.4.6.',
                        name: 'Редактирование участков',
                        link: '0446.html',
                        children: []
                    },
                    {
                        number: '4.4.7.',
                        name: 'Копирование объектов между слоями',
                        link: '0447.html',
                        children: []
                    },
                    {
                        number: '4.4.8.',
                        name: 'Объединение слоев',
                        link: '0448.html',
                        children: []
                    },
                    {
                        number: '4.4.9.',
                        name: 'Редактирование атрибутов',
                        link: '0449.html',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        number: '5.',
        name: 'Телеметрия',
        link: '05.html',
        children: [
            // 5.1. Список устройств телеметрии
            // 5.2. Добавление устройства телеметрии
            {
                number: '5.1.',
                name: 'Список устройств телеметрии',
                link: '051.html',
                children: []
            },
            {
                number: '5.2.',
                name: 'Добавление устройства телеметрии',
                link: '052.html',
                children: []
            }

        ]
    },
    {
        number: '6.',
        name: 'Телеинспекция',
        link: '06.html',
        children: [
            // 6.1. Список отчетов телеинспекций
            // 6.2. Загрузка отчета телеинспекции
            // 6.3. Работа с отчетами телеинспекций

            {
                number: '6.1.',
                name: 'Список отчетов телеинспекций',
                link: '061.html',
                children: []
            },
            {
                number: '6.2.',
                name: 'Загрузка отчета телеинспекции',
                link: '062.html',
                children: []
            },
            {
                number: '6.3.',
                name: 'Работа с отчетами телеинспекций',
                link: '063.html',
                children: []
            }
        ]

    },
    {
        number: '7.',
        name: 'API',
        link: '07.html',
        children: []
    },
    {
        number: ' ',
        name: 'Информация о стоимости',
        link: '08.html',
        children: []
    }
]


function getItems(item: NavigationMenuItem): NavigationMenuItem[] {
    let items = [item]
    if (item.children.length > 0) {
        for (let child of item.children) {
            items = [...items, ...getItems(child)]
        }
    }
    return items
}

export const INFO_NAVIGATION: NavigationMenuItem[] = INFO_NAVIGATION_RAW
    .map(item => {
        item.visible = true
        if (item.children.length > 0) item.expanded = item.number === '1.'
        return item
    })
    .reduce((acc, item) => [...acc, ...getItems(item)], [])

//add levels to all items, first level is 0, level is number of dots in number minus 1
export const INFO_NAVIGATION_LEVELS = INFO_NAVIGATION.map(item => {
    // item.children = []
    item.level = item.number.split(".").length - 2;
    if (item.level === -1) item.level = 0
    return item
}) 
