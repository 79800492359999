import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { INFO_NAVIGATION, INFO_NAVIGATION_LEVELS, NavigationMenuItem } from './models/navigation';
import { InfoNavigationTreeComponent } from './components/info-navigation-tree/info-navigation-tree.component';
import { InfoContentComponent } from './components/info-content/info-content.component';

@Component({
  selector: 'app-info',
  standalone: true,
  imports: [CommonModule, InfoNavigationTreeComponent, InfoContentComponent],
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent {
  protected readonly navigationItems = INFO_NAVIGATION_LEVELS;
  protected content =  signal<string>('')

  constructor() { 
    // console.log('navigationItems', this.navigationItems)
  }
  onItemClick(item: NavigationMenuItem) {
    // console.log('onItemClick', item);
    this.content.set(item.link)
  }
}
