import {Component, Inject, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {take, takeUntil} from "rxjs";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MenuService} from "@services/menu.service";
import {ImusDestroyService} from "@services/destroy.service";
import {AlertService} from "@shared/services";
import {MenuItem, SysRoles, SysSubjects} from "@shared/models/menu";
import {SharedModule} from "@shared/shared.module";
import {NgForOf, NgIf} from "@angular/common";
import {ButtonComponentSettings} from "@shared/components/button/button.component";

@Component({
    selector: 'app-add-edit-menu-settings-modal',
    templateUrl: './add-edit-menu-settings.component.html',
    styleUrls: ['./add-edit-menu-settings.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        SharedModule,
        NgIf,
        NgForOf
    ]
})
export class AddEditMenuSettingsComponent {
    private menuService = inject(MenuService);
    private readonly _destroy$ = inject(ImusDestroyService);
    public dialogRef = inject(MatDialogRef<AddEditMenuSettingsComponent>);
    private fb = inject(FormBuilder);
    private alertService = inject(AlertService);
    protected readonly ButtonComponentSettings = ButtonComponentSettings;

    type: string;
    sysRolesList: SysRoles[];
    sysSubjectsList: SysSubjects[];
    form: FormGroup;
    data: MenuItem;
    getRolesIds: number[];

    constructor(@Inject(MAT_DIALOG_DATA) public row: MenuItem) {
        this.data = row;
        this.type = this.dialogRef.id;
        this.loadData();
        this.initForm();
    }

    initForm(): void {
        if (this.data && this.data.menu_roles) {
            this.getRolesIds = this.data.menu_roles.map(item => item.role_id);
        }
        this.form = this.fb.group({
            id: [this.type !== 'add' ? this.type : null],
            subject_id: [
                this.data &&
                this.data.subject_id ? this.data.subject_id : null
            ],
            descr: [
                this.data &&
                this.data.descr ? this.data.descr : null,
                [Validators.required]
            ],
            front_code: [
                this.data &&
                this.data.front_code ? this.data.front_code : null,
                [Validators.required]
            ],
            icon: [
                this.data &&
                this.data.icon ? this.data.icon : 'edit',
                [Validators.required]
            ],
            roles: [
                this.getRolesIds ? this.getRolesIds : null,
                [Validators.required]
            ]
        });
    }

    loadData(): void {
        this.menuService.getTablesList()
            .pipe(takeUntil(this._destroy$))
            .subscribe((data: SysSubjects[]) => this.sysSubjectsList = data );
        this.menuService.getRolesList({paginate: false})
            .pipe(takeUntil(this._destroy$))
            .subscribe((sysRoles: SysRoles[]) => this.sysRolesList = sysRoles);
    }

    submit(): void {
        if (this.type === 'add') {
            this.form.removeControl('id');
            this.menuService.menuChange(this.form.value)
                .pipe(take(1))
                .subscribe((data) => {
                    if (data.success) {
                        this.alertService.success('Пункт меню успешно добавлен!')
                        this.dialogRef.close();
                    }
                });
        } else {
            this.menuService.menuChange(this.form.value)
                .pipe(take(1))
                .subscribe((data) => {
                    if (data.success) {
                        this.alertService.success('Пункт меню успешно изменен!')
                        this.dialogRef.close();
                    }
                });
        }
    }

    close(): void {
        this.dialogRef.close();
    }

}
