import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

// export function passwordMatchValidator(): ValidatorFn {
//     // control: AbstractControl
//     return (control: AbstractControl): { [key: string]: any } | null => {
//         const password: string = control.get('password')?.value; // get password from our password form control
//         const confirmPassword: string = control.get('confirmPassword')?.value; // get password from our confirmPassword form control
//         // compare is the password math
//         if (password !== confirmPassword) {
//             // if they don't match, set an error in our confirmPassword form control
//             control
//                 .get('confirmPassword')
//                 ?.setErrors({ NoPassswordMatch: true });
//             return { NoPassswordMatch: true };
//         }

//         return null;
//     };
// }

export function passwordMatchValidator(g: AbstractControl) {
    // console.log('FormGroup', g)
    return g.get('password')?.value == g.get('confirmPassword')?.value
        ? null
        : { mismatch: true };
}
