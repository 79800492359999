import { Component, Host, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { Collection, Feature } from 'ol';
import { Color } from 'ol/color';
import { ColorLike } from 'ol/colorlike';
import { GeoJSON } from 'ol/format';
import { Geometry } from 'ol/geom';
import { ProjectionLike } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { StyleLike } from 'ol/style/Style';
import { LayerVectorComponent } from '../layers/layervector.component';
import { SourceComponent } from './source.component';

@Component({
  selector: 'aol-source-geojson',
  template: ` <ng-content></ng-content> `,
  providers: [{ provide: SourceComponent, useExisting: forwardRef(() => SourceGeoJSONComponent) }],
})
export class SourceGeoJSONComponent extends SourceComponent implements OnInit, OnChanges {
  override instance: VectorSource;
  format: GeoJSON;
  features: Feature<Geometry>[] | Collection<Feature<Geometry>> | undefined;
  @Input()
  defaultDataProjection: ProjectionLike = 'EPSG:4326';
  @Input()
  featureProjection: ProjectionLike = 'EPSG:3857';
  @Input()
  geometryName: string;
  @Input()
  url: string;
  @Input()
  data: string;
  @Input()
  objectLabels: boolean = false;
  @Input()
  color: ColorLike;
  @Input()
  styleFunction?: (color: Color | ColorLike | null, objectLabels?: boolean, name?: string) => StyleLike;


  constructor(@Host() layer: LayerVectorComponent) {
    super(layer);
  }

  ngOnInit() {
    if (this.url) this.format = new GeoJSON(this);
    if (this.data) {
      this.features = new GeoJSON(this).readFeatures(JSON.parse(this.data));
      if (this.objectLabels) {

        this.features.forEach((feature) => {
          if (feature.getProperties()['name']) {
            feature.setStyle(this.styleFunction(this.color, this.objectLabels, feature.getProperties()['name']))
          }
        });
      }
    }
    this.instance = new VectorSource(this);
    this.host.instance.setSource(this.instance);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.features && changes['objectLabels'].currentValue !== changes['objectLabels'].previousValue) {
      this.features.forEach((feature) => {
        if (feature.getProperties()['name']) {
          feature.setStyle(this.objectLabels ? this.styleFunction(this.color, this.objectLabels, feature.getProperties()['name']) : undefined)
        }
      });
    }
  }
}
