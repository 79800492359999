import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, shareReplay, switchMap } from "rxjs";
import {UserAccountsInfo, UserAccountsPage} from "@shared/models";
import { AuthenticationService, UserService } from "@shared/services";

@Injectable({
    providedIn: 'root'
})
export class ContractorService {

    // public readonly account$: Observable<UserAccountsInfo> = this.authenticationService.currentUser$.pipe(
    //     switchMap(res => this.userService.getUserAccountInfoByUserId(res.user.id).pipe(
    //         map((userAccount) => (userAccount.data as UserAccountsPage).data.find(a => a.user_id === res.user.id))
    //     )),
    //     shareReplay({ refCount: true, bufferSize: 1 })
    // )
    public contractorForGuests$ = 
        new BehaviorSubject<{
            id: number,
            component: 'contractor' | 'profile'
            }>(null);
    public contractor$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    // public contractor$: Observable<UserAccountsInfo> = this.authenticationService.currentUser$.pipe(
    // public contractor$: Observable<UserAccountsInfo> = this.authenticationService.currentUser$.pipe(
    //     switchMap(res => this.userService.getUserAccountInfoByUserId(res.user.id).pipe(
    //         map((userAccount) => (userAccount.data as UserAccountsPage).data.find(a => a.user_id === res.user.id))
    //     )),
    //     shareReplay({ refCount: true, bufferSize: 1 })
    // )

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UserService
    ) {
    }
}
