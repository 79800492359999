<mat-table matSort [dataSource]="accountsViewData" (matSortChange)="sort($event)">
    <tr *matNoDataRow>
        <td colspan="4">Гостевые аккаунты не подключены</td>
    </tr>

    <ng-container matColumnDef="contractor_id">
        <mat-header-cell mat-sort-header *matHeaderCellDef> ID контрагента </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <a [routerLink]="['../../../', 'contractors', element.contractor_id, 'personal']">
                {{ element.contractor_id }}
            </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="contractor_descr">
        <mat-header-cell mat-sort-header *matHeaderCellDef> Наименование / ФИО контрагента </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <a [routerLink]="['../../../', 'contractors', element.contractor_id, 'personal']">
                {{ element.contractor_descr }}
            </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role_descr">
        <mat-header-cell mat-sort-header *matHeaderCellDef> Роль </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.role_descr }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="start_date">
        <mat-header-cell mat-sort-header *matHeaderCellDef> Дата подключения </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.start_date | date }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="end_date">
        <mat-header-cell mat-sort-header *matHeaderCellDef> Дата отключения </mat-header-cell>
        <mat-cell *matCellDef="let element">
            {{ element.end_date | date }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="logout">
        <mat-header-cell mat-sort-header class="table_action" *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
            <mat-icon
                *ngIf="!element.end_date"
                (click)="openDialogExit(element)"
                class="pointer"
                [matTooltip]="'Закрыть аккаунт (отписаться)'"
            >exit_to_app</mat-icon>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
