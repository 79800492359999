import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { imus } from "@shared/imus";
import { delay, Observable, of, shareReplay, tap } from "rxjs";
import { HttpSettings } from "@app/shared/utilities/http-utility";
import { inspection } from "@shared/models/inspection";
import { environment } from '@environments/environment';

// const API_TELE_PATH = 'https://tele.imus.city/api'
// const API_TELE_PATH = 'https://backend.imus.city/api/v2'
const API_TELE_PATH = `${environment.apiUrl}`

@Injectable({
    providedIn: 'root'
})
export class TelevisionInspectionService {

    public readonly inspectionList$ = this.getInspectionList().pipe(
        shareReplay({ refCount: false, bufferSize: 1 })
    )
    constructor(
        private readonly _http: HttpClient
    ) {
    }

    public uploadFile(file: File): Observable<Object> {
        const data = new FormData();
        data.append('file', file, file.name);
        // имитация удачной загрузки и ошибок
        // const random = Math.floor(Math.random() * 10);
        // return random < 6
        //     ? of(undefined).pipe(
        //         delay(Math.floor(Math.random() * 10) * 400)
        //     )
        //     : of(undefined).pipe(
        //         delay(Math.floor(Math.random() * 10) * 400),
        //         tap(() => {
        //             throw new Error('Upload error')
        //         })
        //     );
        return this._http.post(`${API_TELE_PATH}/upload`, data);
    }

    //TODO: почему вложенный массив??
    public getInspectionList(): Observable<inspection.IResponse[][]> {
        return this._http.get<inspection.IResponse[][]>(`${HttpSettings.apiHost}/inspection`)
    }

    static createStructure(files: File[]): imus.foundation.StructureFolders[] {
        const _structure: imus.foundation.StructureFolders[] = [];
        files.forEach(f => {
            const path: string = f['webkitRelativePath'];
            const pathPieces = path.split('/');
            this.setNextFolder(pathPieces.slice(0, pathPieces.length - 1), 0, f, _structure);
        })
        return _structure;
    }

    static setNextFolder(pathPieces: string[], currentIndex: number, file: File, structure: imus.foundation.StructureFolders[]): void {
        const _newFolder = {
            key: pathPieces[currentIndex],
            value: {
                folders: [],
                files: []
            }
        };
        const _existingFolder = structure.find(_f => _f.key === pathPieces[currentIndex]);
        if (currentIndex + 1 === pathPieces.length) {
            if (!_existingFolder) {
                _newFolder.value.files.push(file);
                structure.push(_newFolder)
            } else {
                _existingFolder.value.files.push(file)
            }
            return;
        }
        if (!_existingFolder) {
            structure.push(_newFolder);
            this.setNextFolder(pathPieces, currentIndex + 1, file, structure);
        } else {
            this.setNextFolder(pathPieces, currentIndex + 1, file, _existingFolder.value.folders);
        }
    }

}
