<div class="accounts">
    <form [formGroup]="searchForm" class="accounts__body filters-form">

        <div class="accounts__block">
            <mat-form-field>
                <mat-label>Контрагент</mat-label>
                <mat-select formControlName="contractorId">
                    <mat-option *ngIf="!contractors?.length">Список пуст</mat-option>
                    <mat-option [value]="null">Сбросить контрагента</mat-option>
                    <mat-option *ngFor="let contractors of contractors" [value]="contractors.id">{{contractors.descr}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Пользователь</mat-label>
                <input formControlName="userName" matInput type="text" placeholder="Пользователь">
            </mat-form-field>
        </div>

        <div class="accounts__block">
            <mat-form-field>
                <mat-label>Роль</mat-label>
                <mat-select formControlName="roleId">
                    <mat-option *ngIf="!sysRoles?.length">Список пуст</mat-option>
                    <mat-option [value]="null">Сбросить роль</mat-option>
                    <mat-option *ngFor="let sysRoles of sysRoles" [value]="sysRoles.id">{{sysRoles.descr}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Email</mat-label>
                <input formControlName="userEmail" matInput type="text" placeholder="Email">
            </mat-form-field>
        </div>

        <div class="accounts__block sb">
            <app-button
                label="Очистить фильтр"
                [primary]="true"
                [icon]="'clear'"
                (onclick)="searchForm.reset()" />
            <app-button
                label="Скачать Excel"
                [primary]="true"
                [icon]="'save'"
                (onclick)="downloadExel()" />
        </div>
    </form>

    <div class="accounts__table">
        <mat-progress-bar *ngIf="isLoaded" mode="indeterminate"></mat-progress-bar>
        <mat-table
            matSort
            (matSortChange)="fetchData()"
            [dataSource]="dataAccounts">

            <ng-container matColumnDef="user_id">
                <mat-header-cell mat-sort-header *matHeaderCellDef> id пользователя </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.user_id }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user_name">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Пользователь </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <a [routerLink]="['user', element.user_id]">{{ element.user_name }}</a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user_email">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Email </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <a [href]="'mailto:' + element.user_email"> {{ element.user_email }} </a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user_phone">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Телефон </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <a *ngIf="element.user_phone" [href]="'tel:' + element.user_phone">  {{ element.user_phone }} </a>
                    <p *ngIf="!element.user_phone">&mdash;</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="account_type_descr">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Тип аккаунта </mat-header-cell>
                <mat-cell  *matCellDef="let element"> {{ element.account_type_descr }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="contractor_descr">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Контрагент </mat-header-cell>
                <mat-cell  *matCellDef="let element">
                    <a [routerLink]="['../', 'contractors', element.contractor_id, 'personal']"> {{ element.contractor_descr }} </a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="role_descr">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Роль </mat-header-cell>
                <mat-cell  *matCellDef="let element">{{ element.role_descr }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="job_post">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Должность </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.job_post ? element.job_post : '&mdash;' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="start_date">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Дата подключения </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.start_date | date }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="end_date">
                <mat-header-cell mat-sort-header *matHeaderCellDef> Дата отключения </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.end_date | date }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <app-paginator [pageSize]="pageSize" [pageIndex]="pageIndex" [length]="totalItems" (page)="onPaginateData($event)"></app-paginator>
    </div>

</div>
