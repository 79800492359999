import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IAlertDialogConfig {
	title?: string;
	message?: string;
	isCloseButtonShown?: boolean;
	imgSrc?: string;
	okButtonText?: string;
	cancelButtonText?: string;
	hideDialogClose?: boolean;
	width?: string;
}

@Component({
	selector: 'sgm-alert-dialog',
	templateUrl: './alert.dialog.component.html',
	styleUrls: ['./alert.dialog.component.scss'],
	host: { class: 'sgm-dialog' }
})
export class AlertDialogComponent {

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: IAlertDialogConfig,
		private dialogRef: MatDialogRef<AlertDialogComponent>
	) {}

	public close(attr: boolean = null): void {
		this.dialogRef.close(attr);
	}

}
