import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MeasurementService } from '@shared/services';
import { MeasurementTypes } from '@app/shared/models/measurement';
import { DeviceTypes } from '@app/shared/models/device';
import { takeUntil } from 'rxjs';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
// import { url_slug } from 'cyrillic-slug'

@Component({
  selector: 'app-measurement',
  templateUrl: './measurement.component.html',
  styleUrls: ['./measurement.component.scss']
})
export class MeasurementComponent implements OnInit {
    @Input('device') device: DeviceTypes.IDevice | any;

    slug: string = "";
    isMeasurementAdd: boolean = false;
    measurementAddForm: FormGroup;
    measurements: MeasurementTypes.IDeviceMeasurements;

    constructor(
        private measurementService: MeasurementService,
        private readonly _destroy$: ImusDestroyService,
        ) {
        this.measurementAddForm = new FormGroup({});
    }

    ngOnInit() {
        this.measurementService.loadDeviceMeasurement(this.device.id);
        this.measurementService.measurements$.pipe(takeUntil(this._destroy$)).subscribe(measurements => {
            this.measurements = measurements;
        });
    }

    makeSlug(event) {
        // this.slug = url_slug(event.target.value);
        this.slug = event.target.value;
    }

    addMeasurement() {
        this.isMeasurementAdd = !this.isMeasurementAdd;
    }

    submitAddMeasurement(event) {        
        this.measurementService.addMeasurement(this.device, 0);
    }

    deleteMeasurement (id: number) {
        this.measurementService.deleteMeasurement(id, this.device.ID);
    }
}
