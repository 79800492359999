//@ts-ignore

import { Component, Injectable, Input, OnDestroy } from '@angular/core';
import Source from 'ol/source/Source';

import { LayerComponent } from '../layers/layer.component';


@Injectable({
  providedIn: 'root'
})
@Component({
  template: ` <ng-content></ng-content> `,
})
export abstract class SourceComponent implements OnDestroy {
  public instance: Source;
  public componentType = 'source';

  @Input()
  attributions: any;

  constructor(protected host: LayerComponent) {}

  ngOnDestroy() {
    if (this.host && this.host.instance) {
      this.host.instance.setSource(null);
    }
  }

  protected _register(s: Source) {
    if (this.host) {
      this.host.instance.setSource(s);
    }
  }
}
