<div mat-dialog-content>
    <div class="imus-row">
        <div class="imus-row-item">
            <mat-radio-group aria-labelledby="radio-group-label" [(ngModel)]="result">
                <mat-radio-button class="radio-button" *ngFor="let item of data.list; index as i" [value]="item">
                    <ng-container [ngSwitch]="data.type">
                        <ng-container
                            *ngSwitchCase="data.type === 'inn' || data.type === 'short_name' ? data.type : ''">
                            <div class="imus-row radio-button__content">
                                <div class="imus-row-item">
                                    <div class="imus-row"><span class="content-title">Полное наименование</span> {{item.requisite_full_name ?? '--'}}</div>
                                    <div class="imus-row"><span class="content-title">ИНН</span> {{item.requisite_inn ?? '--'}}</div>
                                    <div class="imus-row"><span class="content-title">Адрес</span> {{item.address ?? '--'}}</div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'bik'">
                            <div class="imus-row radio-button__content">
                                <div class="imus-row-item">
                                    <div class="imus-row"><span class="content-title">Наименование банка</span> {{item.bank_descr ?? '--'}}</div>
                                    <div class="imus-row"><span class="content-title">БИК</span> {{item.bank_bik ?? '--'}}</div>
                                    <div class="imus-row"><span class="content-title">Кор. счёт</span> {{item.bank_account ?? '--'}}</div>
                                    <div class="imus-row"><span class="content-title">ИНН</span> {{item.bank_inn ?? '--'}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <hr/>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="imus-row">
        <app-button [disabled]="!result"
                    (click)="dialogRef.close(result)"
                    [type]="buttonType.primaryButton"
                    [fullWidth]="true"
                    label='Выбрать'></app-button>
    </div>
</div>
