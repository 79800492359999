import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapSearchComponent } from './components/map-search/map-search.component';
import { MapSearchResultComponent } from './components/map-search-result/map-search-result.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { ImusOpenlayersModule } from '@app/shared/modules/openlayers/openlayers.module';


@NgModule({
  declarations: [
    MapSearchComponent,
    MapSearchResultComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ImusOpenlayersModule,
  ],
  exports: [
    MapSearchComponent,
    ImusOpenlayersModule,
  ],
  providers: [
    // MapService,
    // MapDataService,
    // MeasureService,
    // GeocodingService,
    // MapSearchService,
  ]
})
export class MapModule { }
