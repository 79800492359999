<div class="modal">
    <div (click)="closeModal()" class="modal-close">CLOSE</div>
    <div class="modal-form form">
        <!-- <alert></alert> -->

        <!-- <div class="loader" [ngClass]="loading ? 'show' : ''">Loading...</div> -->

        <!-- <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"></form> -->
        <form [formGroup]="loginForm" (submit)="onSubmit()">
            <div class="modal-form_group form_group form_group--icon login-icon">
                <input placeholder="Email" type="text" formControlName="email">
                <!-- <input placeholder="Email" type="text" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email']errors }" /> -->
                <!-- <div *ngIf="submitted && f['email'].errors" class="invalid">
                    <div *ngIf="f['email'].errors['required']">Обязательно для заполнения</div>
                </div> -->
            </div>
            <div class="modal-form_group form_group form_group--icon password-icon">
                <input placeholder="Пароль" [type]="passwordInputType" formControlName="password">
                <!-- <input placeholder="Пароль" [type]="passwordInputType" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" /> -->
                <span class="password-icon__show" (click)="showPassword()"></span>
                <!-- <div *ngIf="submitted && f['password'].errors" class="invalid">
                    <div *ngIf="f['email'].errors['required']">Обязательно для заполнения</div>
                </div> -->
            </div>
            <div class="modal-form_group form_group form_group--center">
                <!-- <label class="modal-form_group-container form_group-container">Запомнить
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                </label> -->

                <button [disabled]="loading" class="btn">Войти</button>
            </div>
            <ng-container *ngIf="submitted && (loginError$ | async)">
                <div  class="modal-form_group form_group form_group--center">
                    Неверное имя пользователя или пароль.<br>
                    Попробуйте ещё раз.
                </div>
            </ng-container>

            <div class="modal-form_group form_group form_group--flex">
                <a href="" (click)="fromLoginRegister($event)">Зарегистрироваться</a>
                <a href="" (click)="fromLoginChangePass($event)">Забыли пароль?</a>
            </div>

            <!-- <div *ngIf="error" class="alert">{{error}}</div> -->
        </form>
    </div>
</div>