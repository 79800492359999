import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
import { AlertService, UserService } from "@shared/services";
import { AccountService } from "@app/modules/account/services/account.service";
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    debounceTime, first,
    map, Observable,
    of,
    ReplaySubject, shareReplay,
    startWith,
    switchMap, takeUntil
} from "rxjs";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatSortModule, Sort } from "@angular/material/sort";
import { PageEvent } from "@angular/material/paginator";
import { ImusDestroyService } from "@services/destroy.service";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "@shared/shared.module";
import { Accounts } from "@shared/models/contractors";
import {
    ConfirmDialogComponent,
    ConfirmDialogSettings
} from "@shared/components/confirm-dialog/confirm-dialog.component";
import { AccountsService } from "@app/modules/account/services/accounts.service";
import {
    AdditionalAccountsInfoDialogComponent
} from "@app/modules/account/pages/profile/components/additional-accounts/components/additional-accounts-info.dialog/additional-accounts-info.dialog.component";
import { AccountsViewData, UserAccountsPage } from "@shared/models";
import { filter, tap } from "rxjs/operators";

/** Дополнительные аккаунты */
@Component({
    selector: 'app-additional-accounts',
    standalone: true,
    imports: [CommonModule, MatProgressBarModule, MatTableModule, MatDialogModule, MatSortModule, MatIconModule, SharedModule],
    templateUrl: './additional-accounts.component.html',
    styleUrls: ['./additional-accounts.component.scss'],
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalAccountsComponent implements OnInit {
    private readonly _cdRef = inject(ChangeDetectorRef);
    private readonly userService = inject(UserService);
    private readonly accountService = inject(AccountService);
    private readonly accountsService = inject(AccountsService);
    private readonly alertService = inject(AlertService);
    private readonly matDialog = inject(MatDialog);
    private readonly destroy$ = inject(ImusDestroyService);
    private readonly _sortSettings$ = new BehaviorSubject<Sort | null>(null);
    private readonly _refresh = new EventEmitter<void>();
    public accounts: UserAccountsPage;
    public readonly pageSettings$ = new ReplaySubject<{ pageIndex: number, pageSize: number }>(1);
    public readonly accounts$ = this.accountService.account$.pipe(
        switchMap(acc => combineLatest([
            this.pageSettings$,
            this._sortSettings$.pipe(
                map(sortSettings => {
                    if (!sortSettings) {
                        return {}
                    }
                    const key = `orderBy[${sortSettings.active}]`
                    return { [key]: sortSettings.direction }
                })
            ),
            this._refresh.pipe(
                startWith(undefined)
            )]).pipe(
            debounceTime(50),
            switchMap(([pageSettings, sortSettings]) => this.userService.getUserAccountsView(Object.assign({}, {
                ['where[user_id]']: acc.user_id,
                ['where[is_main]']: false,
                'per_page': pageSettings.pageSize,
                page: pageSettings.pageIndex
            }, sortSettings)) as Observable<UserAccountsPage>),
            shareReplay({ refCount: true, bufferSize: 1 }),
            catchError(() => of(null))
        ))
    );
    public readonly displayed$ = new BehaviorSubject([
        { name: 'contractor_descr', title: 'Наименование/ФИО' },
        { name: 'start_date', title: 'Дата подключения' },
        { name: 'end_date', title: 'Дата отключения' }
    ]);
    public readonly displayedColumns$ = this.displayed$.pipe(
        map(items => items.map(o => o.name))
    )
    public isLoaded = false;

    ngOnInit(): void {
        this.pageSettings$.next({
            pageSize: 15,
            pageIndex: 1
        });
        this._refresh.emit();
        this.accounts$
            .pipe(
                tap((value) => (this.accounts = value)),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this._cdRef.markForCheck());
    }

    public closeAccount(acc: Accounts): void {
        this.matDialog
            .open(ConfirmDialogComponent, {
                    data: {
                        title: 'Внимание!',
                        text: `Вы действительно хотите закрыть гостевой аккаунт?`,
                    },
                    width: '500px'
                }
            ).afterClosed().pipe(
            switchMap(result => result === ConfirmDialogSettings.ConfirmDialogResult.ok
                ? this.accountsService.exitGuest(acc.id)
                : of(null)),
            catchError(() => {
                this.alertService.openSnackbar('Произошла ошибка при попытке закрытия аккаунта. Попробуйте позже');
                return of(null);
            }),
        ).subscribe(res => {
            if (res) {
                this.alertService.openSnackbar('Аккаунт закрыт');
            }
            this._refresh.emit();
        });
    }

    public sort(event: Sort) {
        this._sortSettings$.next(event.direction ? event : null);
    }

    public onPaginateData(event: PageEvent) {
        this.pageSettings$.next({
            pageSize: event.pageSize,
            pageIndex: event.pageIndex + 1
        })
    }

    public showInfo(acc: AccountsViewData): void {
        (this.userService.getUserAccountsView({
            ['where[contractor_id]']: acc.contractor_id,
            ['where[is_primary]']: true
        }) as Observable<UserAccountsPage>).pipe(
            first(),
            catchError(() =>{
                this.alertService.openSnackbar('Произошла ошибка при получении детальной информации аккаунта. Попробуйте позже');
                return of(null)
            }),
            filter(Boolean),
            switchMap(res => this.matDialog.open(AdditionalAccountsInfoDialogComponent, {
                data: res.data[0],
                width: '500px'
            }).afterClosed()),
            takeUntil(this.destroy$)
        ).subscribe()
    }
}
