import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-v-player',
  templateUrl: './popup-v-player.component.html',
  styleUrls: ['./popup-v-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupVPlayerComponent {

  src: string;
  poster: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { src: string, poster: string }) {
    if (data) {
      this.src = data.src;
      this.poster = data.poster;
    }
  }
}
