<div class="component-area">
    <app-account-header [userName]="account.form?.controls.name?.value" [userType]="'Простой аккаунт'"
        [buttonLabel]="'Дочерний аккаунт'" (accountButtonClick)="onChildAccountClick()" [editable]="false">
    </app-account-header>

    <div class="inputs-area">

        <!-- <div class="left-buttons">
            <app-button [label]="'Главная'" [fullWidth]="true" (onclick)="onMainPageClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Настройки'" [fullWidth]="true" (onclick)="onAccountSettingsClick()"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Информация'" [fullWidth]="true"></app-button>
            <div class="separator"></div>
            <app-button [label]="'Выход'" [fullWidth]="true" (click)="authenticationService.logout()"></app-button>
            <div class="separator"></div>
        </div> -->

        <app-account-left-sidebar></app-account-left-sidebar>

        <div class="account-inputs">
            <!-- <section> -->
                <mat-accordion class="" multi>



                    <!-- <app-text-separator [text]="'Контакты'"></app-text-separator> -->
                    <ng-container *ngFor="let panel of panels, let i = index">
                        <mat-expansion-panel 
                            [ngClass]="'panel-' + panel.id" 
                            [expanded]="panelExpanded == panel.id" 
                            (opened)="panelExpanded = panel.id" 
                            >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <!-- <p class="imus-row title"> -->
                                        {{panel.title}}
                                    <!-- </p> -->
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section>
                                <!-- <div class="imus-row-item">
                                    <div class="imus-row file-container">
                                    </div>
                                </div> -->
                                <app-info-component *ngIf="panel.id == 'manual'"></app-info-component>  
                            </section>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            <!-- </section> -->

            <!--             
            <app-text-separator [text]="'Обратная связь'"></app-text-separator>

            <app-text-separator [text]="'Вопросы-ответы'"></app-text-separator>

            <app-text-separator [text]="'Руководство пользователя'"></app-text-separator>

            <app-text-separator [text]="'Условия пользовательского соглашения'"></app-text-separator> -->

            <div class="separator"></div>
            <app-button [label]="'Обратная связь'" [fullWidth]="true" (click)="onFeedbackClick()"></app-button>


        </div>

    </div>
</div>