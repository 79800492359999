import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { InputComponentSettings } from '@app/shared/components/input/input.component';
import { imus } from '@app/shared/imus';
import { PlotFeature } from '@app/shared/models';
import { PlotService } from '@app/shared/services';
import { ProjectService } from '@app/shared/services/project.service';

interface FormModel {
    plot: any;
}

@Component({
    selector: 'app-project-section-edit-panel',
    templateUrl: './project-section-edit-panel.component.html',
    styleUrls: ['./project-section-edit-panel.component.scss'],
})
export class ProjectSectionEditPanelComponent {
    plot: PlotFeature;
    section: any;

    inputType = InputComponentSettings.InputType;
    constructor(
        private formBuilder: FormBuilder,
        private projectService: ProjectService,
        private plotService: PlotService
    ) {}

    public readonly edit = {
        form: null as imus.form.IFormGroup<FormModel>,
        model: {
            plot: null,
        } as FormModel,
    };

    get actionName(): string {
        return this.section ? 'Изменить' : 'Добавить';
    }

    get isValid(): boolean {
        return this.edit.form.valid;
    }

    ngOnInit(): void {
        //Set editable device
        this.projectService.editableSection$.subscribe((section) => {
            this.section = section;
            this.initForm();
        });

        this.projectService.plotSelected$.subscribe((plotId: number) => {
            // this.node = undefined;
            if (!plotId) {
                this.plot = undefined;
                return;
            }
            this.plotService.getById(plotId).subscribe((plot) => {
                this.plot = plot;
                1;
                this.edit.form.patchValue({
                    plot: plot.properties.id,
                });
            });
        });
    }

    private initForm() {
        this.edit.form = this.formBuilder.fromTypedModel(this.edit.model);
        this.initData();
    }

    private initData() {
        if (this.section && this.section.id) {
            const editPars: any = {
                plot: this.section.plot_id ? this.section.plot_id : null,
            };
            this.edit.form.setValue(editPars);
        } else {
            let editPars: any = {
                plot: null,
            };
            if (this.plot) {
                editPars.plot = this.plot.properties.id;
            }
            this.edit.form.setValue(editPars);
        }
    }

    onAddEditDeviceClick() {
        // const pars: DeviceTypes.IAddEditDeviceRequest = this.edit.form.toModel(
        const pars: any = this.edit.form.toModel(this.edit.model);
        if (this.section)
            this.projectService.editSection(this.section.id, pars.plot);
        // else this.projectService.addSection(pars);
    }

    onCancelClick() {
        //TODO destroy
        // this.deviceService.formsHelperDataClear();
        this.projectService.editableSection.next(undefined);
    }
}
