<div class="user-profile">
    <div style="display: flex; align-items: center; padding: 0 0 15px 15px;">

        <button style="margin-right:15px" title="Вернуться к списку" mat-mini-fab color="grey" (click)="backToList()">
            <!-- <mat-icon class="badge-icon">back</mat-icon> -->
            <mat-icon>arrow_back</mat-icon>
        </button>
        <h3>Данные пользователя</h3>
    </div>

    <mat-card class="user-profile_card">
        <mat-progress-bar *ngIf="!userAccountsInfo" mode="indeterminate"></mat-progress-bar>

        <mat-card-content class="user-profile_content">
            <ng-container *ngIf="userAccountsInfo">
                <ul *ngFor="let section of userAccountSections">
                    <li *ngFor="let field of section.fields">
                        <div class="user-profile_title">{{ field.label }}:</div>
                        <div *ngIf="isDateField(field.property)">
                            {{ userAccountsInfo?.[field.property] | date }}
                        </div>
                        <div *ngIf="!isDateField(field.property)">
                            <ng-container *ngIf="field.property === 'contractor_descr' || field.property === 'contractor_id'; else other">
                                <a [routerLink]="['../../../', 'contractors', userAccountsInfo.contractor_id, 'personal']">{{ userAccountsInfo?.[field.property] }}</a>
                            </ng-container>
                            <ng-template #other>
                                {{ userAccountsInfo?.[field.property] }}
                            </ng-template>
                        </div>
                    </li>
                </ul>
            </ng-container>
        </mat-card-content>
    </mat-card>

    <mat-accordion [multi]="false">

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Учетные данные</mat-panel-title>
            </mat-expansion-panel-header>

            <credentials [userId]="userId" [userAccountsInfo]="userAccountsInfo" [access]="access"/>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Основной аккаунт</mat-panel-title>
            </mat-expansion-panel-header>

            <!-- <main-account [userId]="userId" [accounts]="accounts$ | async" [userAccountsInfo]="userAccountsInfo" (change)="reload()"/> -->
            <main-account [userId]="userId" [accounts]="accounts" [userAccountsInfo]="userAccountsInfo" (changed)="reload()"/>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Дополнительные аккаунты</mat-panel-title>
            </mat-expansion-panel-header>

            <additional-accounts [userId]="userId" [accountsData]="dataAccounts$ | async" (sortSettings)="sortChanges($event)" (changed)="reload()"/>
        </mat-expansion-panel>

    </mat-accordion>

</div>

