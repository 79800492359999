import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { InputComponentSettings } from '@app/shared/components/input/input.component';
import { imus } from '@app/shared/imus';
import { AlertService, UserService } from '@app/shared/services';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import {
    BehaviorSubject,
    Observable,
    catchError,
    map,
    takeUntil,
    tap,
    throwError,
} from 'rxjs';

interface FormModel {
    email: string,
    // phone: string,
    password: string;
    passwordConfirmation: string;
}

@Component({
    selector: 'app-page-change-password',
    templateUrl: './page-change-password.component.html',
    styleUrls: ['./page-change-password.component.scss'],
})
export class PageChangePasswordComponent implements OnInit {
    inputType = InputComponentSettings.InputType;
    buttonType = ButtonComponentSettings.ButtonType;
    public readonly account = {
        form: null as imus.form.IFormGroup<FormModel>,
        model: {
            email: null,
            password: null,
            passwordConfirmation: null,
        } as FormModel,
    };
    loading = false;
    submitted = false;
    passwordInputType = 'password';
    passwordRepeatInputType = 'password';

    _emailChanged = new BehaviorSubject<boolean>(false);
    emailChanged$ = this._emailChanged.asObservable();
    token: string;
    email: string;

    constructor(
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private userService: UserService,
        private alertService: AlertService,
        private readonly _fb: FormBuilder,
        private readonly _destroy$: ImusDestroyService,
    ) {
        this.account.form = _fb.fromTypedModel<FormModel>(this.account.model);
    }

    ngOnInit() {
        // this.state$ = this.activatedRoute.paramMap.pipe(
        //     map(() => window.history.state),
        //     tap((state) => console.log('state', state))
        //     // tap(state => this.email = state.user["email"]),
        // );
        this.activatedRoute.params.pipe(
            // tap(data => console.log('params',data))
            takeUntil(this._destroy$)
        )
        .subscribe()
        this.activatedRoute.queryParams
            .pipe(
                // tap(data => console.log('queryParams', data)),
                tap((queryParam) => {
                    this.token = queryParam['token'];
                    this.email = queryParam['email'];
                }),
                //           (queryParam: any) => {
                //     this.product = queryParam['token'];
                //     this.price = queryParam['price'];
                // }
                takeUntil(this._destroy$)
            )
            .subscribe();

        this._emailChanged.next(false);
    }

    // sendLinkAgain() {
    //     this.userService
    //         .resendEmail()
    //         .pipe(tap((data) => console.log('data', data)))
    //         .subscribe();
    // }

    // showPassword() {
    //     this.passwordInputType =
    //         this.passwordInputType === 'password' ? 'text' : 'password';
    // }

    // showRepeatPassword() {
    //     this.passwordRepeatInputType =
    //         this.passwordRepeatInputType === 'password' ? 'text' : 'password';
    // }

    toLoginFromChangePass(event) {
      this.router.navigate(['/', 'login']);
    }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid //this.loginForm.invalid
        if (this.account.form.invalid ) {
            this.alertService.error('Неправильно заполнена форма')
            return;
        }

        this.userService
            .resetPassword(
                this.token,
                this.account.form.value.password,
                this.email
            )
            .pipe(
                tap((data) => console.log('data', data)),
                catchError((err) => {
                    return throwError(() => err);
                }),
                takeUntil(this._destroy$)
            )
            .subscribe(() => this._emailChanged.next(true));
    }
}
