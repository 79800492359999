<form [formGroup]="credentials.form" (ngSubmit)="onSubmit()">
    <mat-progress-bar *ngIf="isLoaded$ | async" mode="indeterminate"></mat-progress-bar>
    <div class="imus-row">
        <div class="imus-row-item imus-form-item">
            <app-imus-input required="true" [type]="inputType.text" imusRequired
                [placeholder]="'Фамилия, Имя, Отчество'" formControlName="name"></app-imus-input>
            <field-errors formFieldName="name"></field-errors>
        </div>
    </div>
    <div class="imus-row">
        <div class="imus-row-item imus-form-item">
            <app-imus-input readonly [type]="inputType.email" imusEmail [placeholder]="'Электронная почта'"
                formControlName="email"></app-imus-input>
            <!-- <field-errors formFieldName="email"></field-errors> -->
        </div>
    </div>
    <div class="imus-row">
        <div class="imus-row-item imus-form-item">
            <app-imus-input [type]="inputType.text" [placeholder]="'Контактный телефон'"
                formControlName="phone"></app-imus-input>
        </div>
    </div>
    <div class="imus-row">
        <app-button [isSubmitted]="true" [type]="buttonType.primaryButton" label="Сохранить"></app-button>
        <app-button [type]="buttonType.deepBlueButton" label="Изменить пароль" class="right"
            (click)="onChangePasswordClick()"></app-button>
    </div>
</form>