//@ts-ignore

import { AfterViewInit, Component, ContentChildren, Host, QueryList } from '@angular/core';
import { Attribution } from 'ol/control';
import { SourceComponent } from './sources/source.component';
import { AttributionComponent } from './attribution.component';
import { Logger } from '@app/shared/services';

@Component({
  selector: 'aol-attributions',
  template: '<ng-content></ng-content>',
})
export class AttributionsComponent implements AfterViewInit {
  instance: Array<Attribution>;

  @ContentChildren(AttributionComponent)
  attributions: QueryList<AttributionComponent>;

  constructor(@Host() private source: SourceComponent) {}

  /* we can do this at the very end */
  ngAfterViewInit() {
    Logger.debug('ngAfterViewInit', this);
    Logger.error('attributions', this.attributions);
    if (this.attributions.length) {
      this.instance = this.attributions.map((cmp) => cmp.instance);
      // console.log('setting attributions:', this.instance);
      //TODO
      // this.source.instance.setAttributions(this.instance);
    }
  }
}
