import { Injectable } from '@angular/core';
import { environment } from "@environments/environment";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";

export interface DadataPerson {
    type_id: number;
    requisite_short_name: string;
    requisite_full_name: string;
    address: string;
    phone: string;
    email: string;
    requisite_inn: number;
    requisite_kpp: number;
    requisite_ogrn: number;
    requisite_director_full_name: string;
    requisite_director_post: string;
    person_name_last: string;
    person_name_first: string;
    person_name_second: string;
}

export interface DadataBank {
    bank_descr: string;
    bank_bik: string;
    bank_account: string;
    bank_inn: string;
    bank_kpp: string;
    bank_swift: string;
}

@Injectable({
    providedIn: 'root'
})
export class DadataService {

    constructor(private http: HttpClient) {
    }

    getDadata(params: Record<string, any>): Observable<(DadataPerson | DadataBank)[]> {
        return this.http.get<{ data: (DadataPerson | DadataBank)[] }>(`${environment.apiUrl}/dadata/search`, { params }).pipe(
            map(res => res.data)
        );
    }
}
