import {inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { filter, first, map, Observable, shareReplay, switchMap, tap } from "rxjs";

import { DataMenuSettingsSubset, MenuFeature, MenuItem, SysRoles, SysSubjects } from "@shared/models/menu";
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private readonly _http = inject(HttpClient)
    private readonly authService = inject(AuthenticationService)

    public readonly menuItems$ = this.authService.currentUser$.pipe(
        filter(user => !!user),
        switchMap(_ => this.getMenu()),
        map(feature => (feature.data as MenuItem[])
            .sort((a, b) => a.seq - b.seq)),
        shareReplay(1)
    );
    private getMenu(): Observable<MenuFeature> {
        return this._http.get<MenuFeature>(`${environment.apiUrl}/menus?paginate=false`);
    }

    getMenuSettings(): Observable<MenuItem[]> {
        return this._http.get<MenuFeature>(`${environment.apiUrl}/view/menus?paginate=false&with=menuRoles`)
            .pipe(map(menuFeature =>
                (menuFeature.data as MenuItem[]).sort((a, b) => a.seq - b.seq)
            ));
    }

    changeOfPosition(data: {
        self_menu_id: string,
        previous_menu_id?: string,
        next_menu_id?: string
    }): Observable<MenuFeature> {
        return this._http.post<MenuFeature>(`${environment.apiUrl}/menus/change_sequence`, data)
    }

    menuChange(data: DataMenuSettingsSubset): Observable<MenuFeature> {
        return this._http.post<MenuFeature>(`${environment.apiUrl}/menus/setting`, data)
    }

    deleteMenu(id: string): Observable<MenuFeature> {
        return this._http.post<MenuFeature>(`${environment.apiUrl}/menus/delete_setting`, { id })
    }

    getTablesList(): Observable<SysSubjects[]> {
        return this._http.get<MenuFeature>(`${environment.apiUrl}/sys_subjects?paginate=false`)
            .pipe(
                map((data) => data.data as SysSubjects[])
            )
    }

    //TODO: вынести из этого сервиса, это не только к меню относится
    getRolesList(params?: Record<string, any>): Observable<SysRoles[]> {
        return this._http.get<MenuFeature>(`${environment.apiUrl}/sys_roles`, { params })
            .pipe(
                map((data) => data.data as SysRoles[])
            )
    }
}
