import { inject, Injectable, Injector, signal, WritableSignal } from '@angular/core';
import { IPlot, ItemProperty, ItemSubLayer, NodeRef, PlotRef } from '@shared/models';
import { CodeCategoryService } from '@services/code-category.service';
import { Sort } from '@angular/material/sort';
import { LayerId, TABLE_CONFIG, TableConfig } from '@shared/models/layer-attribute';
import { CircledFeature } from '@app/modules/map/services/map-polygon.service';
import {
    AttributeValueByTypePipe
} from '@app/modules/main/components/layer-attribute-table/pipes/attribute-value-by-type.pipe';
import { animationFrameScheduler, BehaviorSubject, combineLatest, debounceTime, map, shareReplay } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { node } from '@shared/models/node-ref';
import NodeResponse = node.NodeResponse;
import { LayersStateService } from '@services/layers-state.service';
import { LayerService } from '@services/layer.service';

export interface TableData {
    layerId: number;
    name: string;
    nodes: Record<string, any>[];
    plots: Record<string, any>[];
    isEditable: boolean;
}

type SortSettings = { event: Sort, layerId: number, columnType: 'plots' | 'nodes' } | null;

@Injectable({
    providedIn: 'root'
})
export class LayerAttributeTableService {

    private readonly injector = inject(Injector);
    private readonly codeCategories = inject(CodeCategoryService).categories;
    private readonly layersState = inject(LayersStateService);
    private readonly layerService = inject(LayerService);
    private addedIndexesLayers: Record<number, number> = {};
    private _currentSortSettings: {
        layerId: number | null;
        columnType: 'nodes' | 'plots';
        sort: Sort;
    };
    private readonly _layersInTable$ = new BehaviorSubject<Map<LayerId, TableData>>(new Map());
    private readonly _refresh: WritableSignal<LayerId> = signal(undefined);
    private readonly _sort$ = new BehaviorSubject<SortSettings | null>(null);
    public readonly selectedIndex = signal(0);
    public readonly layersInTable$ = combineLatest([this._layersInTable$, this._sort$, toObservable(this._refresh, { injector: this.injector })]).pipe(
        debounceTime(50),
        map(([layersInTable, sortSetting]) => {
            const result: TableData[] = [];
            for (const key of layersInTable.keys()) {
                if (!sortSetting || !sortSetting.event.direction || sortSetting.layerId !== key || this.equalCurrentSort()) {
                    result.push(layersInTable.get(key));
                } else {
                    const sort = sortSetting.event;
                    const type = sortSetting.columnType;
                    const columnConfig = this.config.columns[type].find(c => c.name === sort.active);
                    layersInTable.get(key)[type] = [...layersInTable.get(key)[type]].sort((a, b) => {
                        let aValue;
                        let bValue;
                        switch (columnConfig.type) {
                            case 'NodeType':
                            case 'PlotType':
                                aValue = a[sort.active]['russian'] ?? 'a';
                                bValue = b[sort.active]['russian'] ?? 'a';
                                break;
                            case 'operations':
                            case 'NodeFeature':
                                // @ts-ignore
                                aValue = AttributeValueByTypePipe.transformData(a, columnConfig, this.config.categories());
                                // @ts-ignore
                                bValue = AttributeValueByTypePipe.transformData(b, columnConfig, this.config.categories());
                                break;
                            case 'coords':
                                aValue = a[sort.active]?.join() ?? 0;
                                bValue = b[sort.active]?.join() ?? 0;
                                break;
                            case 'number':
                                aValue = +(!!a[sort.active] ? a[sort.active] : 0);
                                bValue = +(!!b[sort.active] ? b[sort.active] : 0);
                                break;
                            case 'CodeCategory':
                                aValue = (a[sort.active] as ItemProperty)?.properties?.russian ?? 'a';
                                bValue = (b[sort.active] as ItemProperty)?.properties?.russian ?? 'a';
                                break;
                            default:
                                aValue = `${a[sort.active]}`;
                                bValue = `${b[sort.active]}`;
                                break;
                        }
                        return sort.direction === 'asc' ? aValue > bValue ? 1 : -1 : aValue < bValue ? 1 : -1
                    })
                    result.push(layersInTable.get(key));
                    this.setCurrentTab(sortSetting);
                }
            }
            return result;
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    public config: TableConfig;

    constructor() {
        this.initConfig();
    }

    public get isTableMode(): boolean {
        return this._layersInTable$.value.size > 0;
    }

    public deleteFromTable(layer: number): void {
        const state = this._layersInTable$.value;
        state.delete(layer);
        this._layersInTable$.next(state);
        this.refresh();
    }


    public addToTable(layer: ItemSubLayer): void {
        const state = this._layersInTable$.value;
        if (!state.has(layer.layerId)) {
            state.set(layer.layerId, {
                layerId: layer.layerId,
                name: layer.name,
                nodes: layer.nodes$.getValue().map(n => Object.assign({}, n, { selected: false })),
                plots: layer.plots$.getValue().map(n => Object.assign({}, n, { selected: false })),
                isEditable: this.isCanUpdate(layer.layerId) && !this.layerService.globalLayerIds.includes(layer.layerId)
            });
        }
        this._layersInTable$.next(state);
        this.refresh();
        if (!(layer.layerId in this.addedIndexesLayers)) {
            this.addedIndexesLayers[layer.layerId] = this._layersInTable$.value.size - 1;
        }
        this.resetSort();
        this.setSelectedIndex(this.addedIndexesLayers[layer.layerId]);
    }

    public isCanUpdate(id: number): boolean {
        const layer = <ItemSubLayer>this.layersState.state.getLayerById(id);
        return this.layersState.state.hasLayerRights(layer, 'can_update');
    }

    /**
     * @param event - событие сортировки
     * @param layerId - layerId слоя, на котором произошла смена сортировки
     * @param columnType - 'plots' | 'nodes'
     * */
    public sortChange(event: Sort, layerId: number, columnType: 'plots' | 'nodes') {
        this._sort$.next({ event, layerId, columnType });
        this.refresh();
    }

    public clear(): void {
        this._layersInTable$.next(new Map());
        this.addedIndexesLayers = {};
        this._sort$.next(null);
        this.refresh();
    }

    public setSelectedIndex(index: number): void {
        this.selectedIndex.set(index);
        this.initConfig();
        this.resetSort();
    }

    public selectedHandler(list: CircledFeature[]): void {
        list?.forEach(feature => {
            if (feature.type === 'Point') {
                this.toggleSelectedNode(feature.id, feature.layerId, true);
            } else {
                this.toggleSelectedPlot(feature.id, feature.layerId, true);
            }
        })
    }

    public hasSelectedNode(nodeId: number, layerId: number): boolean {
        const nodes =  this._layersInTable$.value.get(layerId)?.nodes;
        return !nodes ? false : nodes.findIndex(node => node['id'] === nodeId && node['selected']) !== -1;
    }

    public hasSelectedPlot(plotId: number, layerId: number): boolean {
        const plots = this._layersInTable$.value.get(layerId)?.plots;
        return !plots ? false : plots.findIndex(plot => plot['id'] === plotId && plot['selected']) !== -1;
    }

    public toggleSelectedNode(nodeId: number, layerId: number, state?: boolean, all?: { on: boolean }): void {
        const data = this._layersInTable$.value;
        const layer = data.get(layerId);
        if (!layer) {
            return;
        }
        if (all) {
            layer.nodes.forEach(node => (node as { selected: boolean }).selected = all.on)
        } else {
            const node = layer.nodes.find(n => n['id'] === nodeId) as { selected: boolean };
            node.selected = state ?? !node.selected;
        }
        this._layersInTable$.next(data);
        this.refresh();
    }

    public toggleSelectedPlot(plotId: number, layerId: number, state?: boolean, all?: { on: boolean }): void {
        const data = this._layersInTable$.value;
        const layer = data.get(layerId);
        if (!layer) {
            return;
        }
        if (all) {
            layer.plots.forEach(p => (p as { selected: boolean }).selected = all.on)
        } else {
            const plot = layer.plots.find(p => p['id'] === plotId) as { selected: boolean };
            plot.selected = state ?? !plot.selected;
        }
        this._layersInTable$.next(data);
        this.refresh();
    }

    public resetSort(): void {
        this._sort$.next(null);
        this._currentSortSettings = null;
    }

    public refresh(entity?: NodeResponse | IPlot, columnName?: string, plotId?: number): void {
        this.updateEntity(entity, columnName, plotId);
        animationFrameScheduler.schedule(() => {
            this._refresh.set(Math.random() * 10000);
        })
    }

    public layerRefresh(layerId: number): void {
        const layer = this.layersState.state.getLayerById(layerId) as ItemSubLayer;
        const stateLayer = this._layersInTable$.value.get(layerId);
        if (stateLayer) {
            stateLayer.nodes = layer.nodes$.getValue().map(n => Object.assign({}, n, { selected: false }));
            stateLayer.plots = layer.plots$.getValue().map(n => Object.assign({}, n, { selected: false }))
        }
        this.refresh();
    }

    private updateEntity(entity?: NodeResponse | IPlot, columnName?: string, plotId?: number): void {
        if (entity) {
            if (entity['node_type']) {
                const node = new NodeRef(entity as NodeResponse);
                const layer = this._layersInTable$.value.get(node.layer_id);
                if (plotId) {
                    const plot = this.layersState.state.getPlotByPlotId(plotId);
                    plot[columnName].properties.name = node.name;
                    plot[columnName.split('_').map((segment, i) => !i ? segment : `${segment[0].toUpperCase()}${segment.slice(1)}`).join('')].name = node.name;
                } else {
                    for (let i = 0; i < layer.nodes.length; i++) {
                        if (layer.nodes[i]?.['id'] === node.id) {
                            layer.nodes[i] = Object.assign(layer.nodes[i], node);
                            break;
                        }
                    }
                }
            } else {
                const plot = new PlotRef(entity as IPlot);
                const layer = this._layersInTable$.value.get(plot.layer);
                for (let i = 0; i < layer.plots.length; i++) {
                    if (layer.plots[i]?.['id'] === plot.id) {
                        layer.plots[i] = Object.assign(layer.plots[i], plot);
                        break;
                    }
                }
            }
        }
    }

    private initConfig(): void {
        this.config = Object.assign({}, JSON.parse(JSON.stringify(TABLE_CONFIG)), {
            categories: this.codeCategories
        })
    }

    private setCurrentTab(sortSetting: SortSettings): void {
        if (!this._currentSortSettings) {
            this._currentSortSettings = {
                layerId: null,
                columnType: null,
                sort: null
            };
        }
        this._currentSortSettings.layerId = sortSetting.layerId;
        this._currentSortSettings.columnType = sortSetting.columnType;
        this._currentSortSettings.sort = JSON.parse(JSON.stringify(sortSetting.event));
    }

    private equalCurrentSort(): boolean {
        const _sort = this._sort$.value;
        // const _sort = this._sort();
        const _cur = this._currentSortSettings;
        return !_cur ? false : _sort.columnType === _cur.columnType && _sort.layerId === _cur.layerId && _sort.event.direction === _cur.sort.direction && _sort.event.active === _cur.sort.active;
    }
}
