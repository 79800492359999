import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { InputComponentSettings } from 'src/app/shared/components/input/input.component';
import { MeasurementService } from 'src/app/shared/services/measurement.service';
import { DeviceTypes } from '@app/shared/models/device';
import { AddEditDeviceDialogComponent } from '../../devices/add-edit-device-dialog/add-edit-device-dialog.component';
import { DeviceService } from 'src/app/shared/services/device.service';
import { ButtonComponentSettings } from 'src/app/shared/components/button/button.component';
import { takeUntil } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { MeasurementTypes } from 'src/app/shared/models/measurement';
import { ImusDestroyService } from '@app/shared/services/destroy.service';

@Component({
  selector: 'app-device-measurements',
  templateUrl: './device-measurements.component.html',
  styleUrls: ['./device-measurements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ImusDestroyService]
})
export class DeviceMeasurementsComponent implements OnInit {

  inputType = InputComponentSettings.InputType;
  buttonType = ButtonComponentSettings.ButtonType;

  deviceTableSource = new MatTableDataSource([]);
  devicesDisplayedColumns: string[] = ['name', 'type', 'lat', 'lon', 'min', 'max', 'token', 'notes', 'edit', 'delete'];
  measurementsDisplayedColumns: string[] = ['value', 'updated_at'];

  device$ = this.deviceService.device$;
  measurements$ = this.measurementService.measurements$;
  measurements: MeasurementTypes.IMeasurementProperties[];
  measurementDataSource = new MatTableDataSource();


  measurementsLenth = 0;
  pageSize = 20;
  pageIndex = 0;

  get ifDialogOpeningAllowed(){
    return (this.dialog === undefined || this.dialog.closed) && !this.deleteDialogOpened;
  }
  private dialog;
  private deleteDialogOpened;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { device: DeviceTypes.IDevice},
  private measurementService: MeasurementService,
  private matDialog: MatDialog,
  private deviceService: DeviceService,
  private dialogRef: MatDialogRef<DeviceMeasurementsComponent>,
  private changeDetector: ChangeDetectorRef,
  private readonly _destroy$: ImusDestroyService){}

  ngOnInit(): void {
    this.initData();
  }

  private initData(){
    this.deviceTableSource.data = [this.data.device];
    if(this.data)
      this.measurementService.loadDeviceMeasurement(this.data.device.ID, 100000000);

    this.measurements$.pipe(takeUntil(this._destroy$)).subscribe((measurement) => {
        this.measurementsLenth = measurement.data.length;
        this.measurements = measurement.data;
        this.pagingData();
    });

    this.device$.pipe(takeUntil(this._destroy$)).subscribe((device) => {
      if(device)
        this.deviceTableSource.data = [device];
    });

  }

  onEditDeviceClick(device: DeviceTypes.IDevice){

    console.log('DeviceMeasurementsComponent onEditDeviceClick', device);

    this.deviceService.editableDevice.next(device);
    this.deviceService.gridDialogRolledUp = true;
    this.dialogRef.close();
    this.matDialog.closeAll();

    //   this.dialog = this.matDialog.open(AddEditDeviceDialogComponent, { data: { device: device} })
    //   .afterClosed().subscribe(()=> this.changeDetector.markForCheck());
  }

  onDeleteDeviceClick(device: DeviceTypes.IDevice){
    this.deleteDialogOpened = true;
    this.changeDetector.markForCheck();
    this.deviceService.deleteDevice(device).subscribe((result)=>{
      if(result === true)
        this.dialogRef.close();

      if(result === false){
        this.deleteDialogOpened = false;
        this.changeDetector.markForCheck();
      }
    });
  }

  onPageEvent(pageEvent: PageEvent){
    this.pageIndex = pageEvent.pageIndex;
    this.pagingData();
  }

  private pagingData(){
    const start = this.pageIndex * this.pageSize;
    this.measurementDataSource.data = this.measurements.slice(start, start + this.pageSize);
  }

  onAddMeasurement(device: DeviceTypes.IDevice){
    this.measurementService.addMeasurement(device, 20);
  }

}
