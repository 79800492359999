import { Component } from '@angular/core';

@Component({
  selector: 'app-template-demo-page',
  templateUrl: './template-demo-page.component.html',
  styleUrls: ['./template-demo-page.component.scss']
})
export class TemplateDemoPageComponent {

}
