<form [formGroup]="searchForm" (ngSubmit)="doSearch()">
    <mat-form-field appearance="outline">
        <mat-label>Поиск</mat-label>
        <input matInput type="text" formControlName="query" [(ngModel)]="searchQuery" (keyup)="onInputKeyup($event)" (click)="$event.stopPropagation()" />
        <!-- <button *ngIf="searchQuery" matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); clearAll()">
      <mat-icon>close</mat-icon>
    </button> -->
        <mat-spinner *ngIf="(searchService.inProgress$ | async) == true" [diameter]="20"></mat-spinner>
        <button color="primary" matSuffix mat-icon-button aria-label="Search" type="submit" [disabled]="!searchQuery.length">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </mat-form-field>
</form>

<button *ngIf="searchQuery" class="clear-all" mat-mini-fab color="white" aria-label="Clear" (click)="clearAll()">
    <mat-icon>close</mat-icon>
</button>

<!-- *ngIf="(searchResults && searchResults.length > 0) || (localSearchResults && localSearchResults.length > 0)" -->
<!-- [results]="searchResults" [localResults]="localSearchResults" -->
<app-map-search-result *ngIf="
        (searchService.coordsResults$ | async).length ||
        (searchService.localSearchResults$ | async).length ||
        ((searchService.searchResults$ | async).length &&
            (searchQuery || searchQuery != ''))
    " (locationSelected)="setCenter($event)"></app-map-search-result>

<section class="map-search-not-found" *ngIf="(searchQuery && searchQuery != '') && firstSearchSuccessed && (searchService.searchTypeCoords$ | async) == 'base' && ((searchService.searchResults$ | async).length == 0 && (searchService.localSearchResults$ | async).length == 0)">
    <mat-chip (click)="clearAll()">Объектов и мест не найдено</mat-chip>
</section>

<!-- <section class="map-search-not-found" *ngIf="searchQuery != '' && (searchService.searchTypeCoords$ | async) == 'coords' && ((searchService.coordsResults$ | async).length == 0)">
    <mat-chip (click)="clearAll()">Не корректные координаты</mat-chip>
</section> -->

<!-- <section class="map-search-not-found" [hidden]="
        ((searchService.searchTypeCoords$ | async) == 'coords' &&
            (searchService.coordsResults$ | async).length == 0) ||
        ((searchService.searchTypeCoords$ | async) == 'base' &&
            ((searchService.searchResults$ | async).length == 0 ||
                (searchService.localSearchResults$ | async).length == 0))
    ">
    <mat-chip (click)="clearAll()">Ничего не найдено</mat-chip>
</section> -->