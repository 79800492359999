import { SharedModule } from './shared/shared.module';
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
    NgModule, LOCALE_ID,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule, routes } from './app-routing.module';

import {
    CommonModule,
    HashLocationStrategy,
    LocationStrategy, registerLocaleData,
} from '@angular/common';

import { RouterModule } from '@angular/router';
import { httpInterceptorProviders } from './shared/interceptors';
import { MaterialModule } from './shared/modules/material/material.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './shared/utilities/custom-mat-paginator';
import './shared/global/array';
import './shared/form/form-group'
import { ProjectsModule } from './modules/projects/projects.module';
import { FullComponent } from './shared/components/layouts/full/full.component';
import { DevicesModule } from './modules/devices/device.module';
import { AccountModule } from './modules/account/account.module';
import { DevModule } from './shared/modules/dev/dev.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu, 'ru');
import { LayerAddModule } from './modules/main/modules/layer-add/layer-add.module';
import { LayerModule } from './modules/layer/layer.module';
// import { LayerEditPanelModule } from './modules/layer-edit-panel/layer-edit-panel.module';
// import { RouterModule } from '@angular/router';
// import { FullComponent } from './shared/components/layouts/full/full.component';

// export const options: Partial<IConfig> | (() => Partial<IConfig>);
// const mapConfig: IConfig = {
//     apikey: '210aa559-8b89-4cd2-bce9-b3b95e0d3597',
//     lang: 'ru_RU'
// };

@NgModule({
    imports: [
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, {
            // initialNavigation: 'enabled',
        }),
        SharedModule,
        MaterialModule,
        ProjectsModule,
        // DevicesModule,
        // LayerAddModule,
        AccountModule,
		DevModule,
        LayerModule
        // LayerEditPanelModule,
    ],
    declarations: [
        AppComponent,
        FullComponent
    ],
    providers: [
        // {
        //   provide: LocationStrategy,
        //   // useClass: PathLocationStrategy
        //   useClass: HashLocationStrategy,
        // },
        httpInterceptorProviders,
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
        { provide: LOCALE_ID, useValue: 'ru' }
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
