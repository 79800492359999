<!-- <app-spinner *ngIf="loadingTypes$ | async" [scrennCenter]="true"></app-spinner> -->

<h2>Редактирование</h2>

<form [formGroup]="edit.form" (ngSubmit)="onAddEditDeviceClick()">

    <mat-expansion-panel [expanded]="true" [disabled]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="imus-row title">Привязка</p>
            </mat-panel-title>
        </mat-expansion-panel-header>



        <!-- <section *ngIf="edit?.form?.value?.node">
        <app-input [label]="'Привязано к точке '" [type]="inputType.number" [disabled]="true" formControlName="node"></app-input>
        <field-errors formFieldName="node"></field-errors>
    </section> -->

        <section *ngIf="edit?.form?.value?.plot">
            <!-- <button mat-icon-button (click)="onObjectLocationSelectClick(); $event.preventDefault()" style="position: absolute; right: 0;">
            <mat-icon>location_on</mat-icon>
        </button> -->
            <app-input [label]="'Привязано к линии '" [type]="inputType.number" [disabled]="true" formControlName="plot"></app-input>
            <field-errors formFieldName="plot"></field-errors>
        </section>

        <!-- <p *ngIf="(edit.form.value.node || edit.form.value.plot) || (!edit.form.value.node && !edit.form.value.plot)">Нажмите на точку или линию на карте для привязки</p> -->

        <!-- <p *ngIf="!edit.form.value.node && !edit.form.value.plot">Или введите координаты</p> -->
        <!-- <mat-form-field> -->
        <app-input [label]="'Координаты'" [type]="inputType.text" [disabled]="true"></app-input>
        <!-- </mat-form-field> -->

    </mat-expansion-panel>

</form>

<section class="panel-action">
    <button mat-raised-button color="primary" [disabled]="!isValid" (click)="onAddEditDeviceClick()">{{actionName}}</button>
    <button mat-raised-button color="danger" (click)="onCancelClick()">Отменить</button>
</section>