import { Injectable } from '@angular/core';
import {FormGroup} from "@angular/forms";

interface Params {
    formField: string,
    paramName: string,
    operator?: string,
}

export interface SearchParams {
    [key: string]: string | number | { [key: string]: string };
}

@Injectable({
    providedIn: 'root',
})
export class SearchParamsService {
    constructor() {}

    getSearchParams(
        paramMappings: Params[],
        searchForm: FormGroup<any>,
        sortField?: string,
        sortDirection?: string,
        pageIndex?: number,
        pageSize?: number
    ): SearchParams {
        const params: SearchParams = {};

        paramMappings.forEach(mapping => {
            const formValue = searchForm.value[mapping.formField];
            if (formValue) {
                if (mapping.operator) {
                    params[`${mapping.paramName}[value]`] = `%${formValue}%`;
                    params[`${mapping.paramName}[operator]`] = mapping.operator;
                } else {
                    params[mapping.paramName] = formValue;
                }
            }
        });

        if (sortDirection) {
            params[`orderBy[${sortField}]`] = sortDirection;
        }

        params['per_page'] = pageSize;
        params['page'] = pageIndex + 1;

        return params;
    }
}
